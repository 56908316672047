// ** Next Import
// import { Link } from 'react-router-dom'

// ** MUI Imports
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { Link } from '@mui/material'

import { Icon } from '@iconify/react'

const StyledCompanyName = styled(Link)(({ theme }) => ({
  fontWeight: 500,
  textDecoration: 'none',
  color: `${theme.palette.primary.main} !important`
}))

const LinkStyled = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: `${theme.palette.text.secondary} !important`,
  '&:hover': {
    color: `${theme.palette.primary.main} !important`
  }
}))

const FooterBar = () => {
  const Version = process.env.REACT_APP_VERSION

  // console.log('Version', Version)

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography variant='body2' sx={{ mr: 2, display: 'flex', color: 'text.secondary' }}>
        {`© ${new Date().getFullYear()}, Made with`}
        <Box component='span' sx={{ mx: 1, color: 'error.main' }}>
          ❤️
        </Box>
        {`by`}
        <Typography variant='body2' sx={{ ml: 1 }} style={{fontWeight: 500,textDecoration: 'none', color: '#2E76D3'}}>
          <span>KIU Software Engineering Division</span>
          {` | `}
          <span>
            <a
              href='/changelog'
              style={{
                textDecoration: 'none',
                color: 'inherit'
              }}
            >
              v.{Version}
            </a>
          </span>
        </Typography>
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', '& :not(:last-child)': { mr: 4 } }}>
        <Typography
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: '600' }}
          component={LinkStyled}
          href='http://tickets.kiu.lk/user/support/create_ticket'
          target='_blank'
          variant='body2'
        >
          <Icon icon='mdi:support' fontSize={25} />
          &ensp;Need Support?
        </Typography>
      </Box>
    </Box>
  )
}

export default FooterBar
