import { Icon } from '@iconify/react'
import { TabContext, TabPanel } from '@mui/lab'
import MuiTabList from '@mui/lab/TabList'
import { Avatar, Box, Card, CardContent, Grid, Tab, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { useCurrentRoute, useUserID, useUserItemRole } from '../../hooks/customHooks'

import toast from 'react-hot-toast'

import Badge from '@mui/material/Badge'

import AssignedItems from './AssignedItems'
import AssignRequests from './AssignRequests'
import AssignApproval from './AssignApproval'
import UnassignApproval from './UnassignApproval'
import UnassignRequests from './UnassignRequests'
import AssignPendingL2 from './AssignPendingL2'
import AssignPendingL3 from './AssignPendingL3'
import apiDefinitions from '../../api/apiDefinitions'

const TabList = styled(MuiTabList)(({ theme }) => ({
  borderBottom: '0 !important',
  '&, & .MuiTabs-scroller': {
    boxSizing: 'content-box',
    padding: theme.spacing(0, 0.5, 1),
    margin: `${theme.spacing(-1.25)}px ${theme.spacing(-1.25)}px ${theme.spacing(-2)}px !important`
  },
  '& .MuiTabs-indicator': {
    display: 'none'
  },
  '& .Mui-selected': {
    boxShadow: theme.shadows[4],
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white + ' !important'
  },
  '& .MuiTab-root': {
    lineHeight: 1,
    borderRadius: 50,
    '&:hover': {
      color: theme.palette.primary.main
    },
    border: '1px solid #57A5F1',
    margin: theme.spacing(0, 1)
  }
}))

const Index = () => {
  const role = useUserItemRole() ?? ''
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''

  const [notifsReset, setNotifsReset] = useState(false)

  const [value, setValue] = useState('1')

  const [assAppLen, setAssAppLen] = useState(0)
  const [unassAppLen, setUnassAppLen] = useState(0)
  const [assReqLen, setAssReqLen] = useState(0)
  const [unassReqLen, setUnassReqLen] = useState(0)
  const [assPendingL2Len, setAssPendingL2Len] = useState(0)
  const [assPendingL3Len, setAssPendingL3Len] = useState(0)

  useEffect(() => {
    const types = {
      assAppLen: 'super-admin-approval-pending',
      unassAppLen: 'self-unassigned-pending',
      assReqLen: 'assign-pending-request',
      unassReqLen: 'unassigned-pending',
      assPendingL2Len: 'admin-assign-pending',
      assPendingL3Len: 'assign-pending'
    }

    let errorCount = 0

    const fetchAssignRequestByType = async (key, value) => {
      try {
        const response = await apiDefinitions.getAssignRequestByTypes(userID, currentRouteString, value, 0, 1)
        if (response.data.code === 200) {
          switch (key) {
            case 'assAppLen':
              setAssAppLen(response.data.data.count)
              break
            case 'unassAppLen':
              setUnassAppLen(response.data.data.count)
              break
            case 'assReqLen':
              setAssReqLen(response.data.data.count)
              break
            case 'unassReqLen':
              setUnassReqLen(response.data.data.count)
              break
            case 'assPendingL2Len':
              setAssPendingL2Len(response.data.data.count)
              break
            case 'assPendingL3Len':
              setAssPendingL3Len(response.data.data.count)
              break
            default:
              break
          }
        } else {
          console.log('Error in fetching data:', response.data.message)
          errorCount++

          //set respective state to 0

          switch (key) {
            case 'assAppLen':
              setAssAppLen(0)
              break
            case 'unassAppLen':
              setUnassAppLen(0)
              break
            case 'assReqLen':
              setAssReqLen(0)
              break
            case 'unassReqLen':
              setUnassReqLen(0)
              break
            case 'assPendingL2Len':
              setAssPendingL2Len(0)
              break
            case 'assPendingL3Len':
              setAssPendingL3Len(0)
              break
            default:
              break
          }
        }
      } catch (error) {
        console.log('Error in fetching data', error)
        errorCount++

        //set respective state to 0

        switch (key) {
          case 'assAppLen':
            setAssAppLen(0)
            break
          case 'unassAppLen':
            setUnassAppLen(0)
            break
          case 'assReqLen':
            setAssReqLen(0)
            break
          case 'unassReqLen':
            setUnassReqLen(0)
            break
          case 'assPendingL2Len':
            setAssPendingL2Len(0)
            break
          case 'assPendingL3Len':
            setAssPendingL3Len(0)
            break
          default:
            break
        }
      }
    }

    const fetchData = async () => {
      for (const [key, value] of Object.entries(types)) {
        await fetchAssignRequestByType(key, value)
      }

      const typesLength = Object.keys(types).length

      console.log('errorCount', errorCount)
      console.log('typesLength', typesLength)

      if (errorCount >= typesLength) {
        toast.error('Error in fetching data counts')
      }
    }

    fetchData()
  }, [userID, currentRouteString, notifsReset])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    console.log('role', role)
  }, [role])

  return (
    <>
      {/* <Grid container spacing={3} sx={{ mb: 3, px: 1 }}>
        <Grid item xs={4}>
          <Card
            sx={{
              borderRadius: 2,
              transition: 'box-shadow 0.3s',
              '&:hover': {
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)' // Add your desired shadow values here
              }
            }}
          >
            <CardContent sx={{ gap: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant='h6' sx={{ mb: 0.5 }}>
                  XXXX
                </Typography>
                <Typography variant='body2' sx={{ fontWeight: 600 }}>
                  XXXXXXXXXXXXX
                </Typography>
              </Box>
              <Avatar sx={{ width: 52, height: 52, bgcolor: '#DCF6E8' }}>
                <Icon icon='fluent-mdl2:work-item-bug' fontSize='1.725rem' color='#28C76F' />
              </Avatar>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card
            sx={{
              borderRadius: 2,
              transition: 'box-shadow 0.3s',
              '&:hover': {
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)' // Add your desired shadow values here
              }
            }}
          >
            <CardContent sx={{ gap: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant='h6' sx={{ mb: 0.5 }}>
                  XXX
                </Typography>
                <Typography variant='body2' sx={{ fontWeight: 600 }}>
                  XXXXXXXXXXXXXXXX
                </Typography>
              </Box>
              <Avatar sx={{ width: 52, height: 52, bgcolor: '#FCE4E4' }}>
                <Icon icon='fluent-mdl2:work-item-bug' fontSize='1.725rem' color='#EA5455' />
              </Avatar>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card
            sx={{
              borderRadius: 2,
              transition: 'box-shadow 0.3s',
              '&:hover': {
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)' // Add your desired shadow values here
              }
            }}
          >
            <CardContent sx={{ gap: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant='h6' sx={{ mb: 0.5 }}>
                  XXXX
                </Typography>
                <Typography variant='body2' sx={{ fontWeight: 600 }}>
                  XXXXXXXXXXXXXXXXXX
                </Typography>
              </Box>
              <Avatar sx={{ width: 52, height: 52, bgcolor: '#D6F7FB' }}>
                <Icon icon='fluent-mdl2:work-item-bug' fontSize='1.725rem' color='#00CFE8' />
              </Avatar>
            </CardContent>
          </Card>
        </Grid>
      </Grid> */}

      <TabContext value={value}>
        {role === 'item-admin' && (
          <TabList onChange={handleChange} aria-label='customized tabs example'>
            <Tab value='1' label='Assigned Items' sx={{ px: 4 }} />
            <Tab
              value='2'
              label={
                (assAppLen > 0 && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2.5 }}>
                    <Typography variant='body2'>Assign Approval</Typography>
                    <Badge
                      badgeContent={assAppLen}
                      color='primary'
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: value === '2' ? 'white' : 'primary',
                          color: value === '2' ? 'black' : 'white'
                        }
                      }}
                    />
                  </Box>
                )) ||
                'Assign Approval'
              }
              sx={{ px: 4 }}
            />
            <Tab
              value='3'
              label={
                (unassAppLen > 0 && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2.5 }}>
                    <Typography variant='body2'>Unassign Approval</Typography>
                    <Badge
                      badgeContent={unassAppLen}
                      color='primary'
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: value === '3' ? 'white' : 'primary',
                          color: value === '3' ? 'black' : 'white'
                        }
                      }}
                    />
                  </Box>
                )) ||
                'Unassign Approval'
              }
              sx={{ px: 4 }}
            />
          </TabList>
        )}
        {role === 'item-department-head' && (
          <TabList onChange={handleChange} aria-label='customized tabs example'>
            <Tab value='1' label='Assigned Items' sx={{ px: 4 }} />
            <Tab
              value='3'
              label={
                (unassAppLen > 0 && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2.5 }}>
                    <Typography variant='body2'>Unassign Approval</Typography>
                    <Badge
                      badgeContent={unassAppLen}
                      color='primary'
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: value === '3' ? 'white' : 'primary',
                          color: value === '3' ? 'black' : 'white'
                        }
                      }}
                    />
                  </Box>
                )) ||
                'Unassign Approval'
              }
              sx={{ px: 4 }}
            />
            <Tab
              value='4'
              label={
                (assReqLen > 0 && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2.5 }}>
                    <Typography variant='body2'>Assign Requests</Typography>
                    <Badge
                      badgeContent={assReqLen}
                      color='primary'
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: value === '4' ? 'white' : 'primary',
                          color: value === '4' ? 'black' : 'white'
                        }
                      }}
                    />
                  </Box>
                )) ||
                'Assign Requests'
              }
              sx={{ px: 4 }}
            />
            <Tab
              value='5'
              label={
                (unassReqLen > 0 && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2.5 }}>
                    <Typography variant='body2'>Unassign Requests</Typography>
                    <Badge
                      badgeContent={unassReqLen}
                      color='primary'
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: value === '5' ? 'white' : 'primary',
                          color: value === '5' ? 'black' : 'white'
                        }
                      }}
                    />
                  </Box>
                )) ||
                'Unassign Requests'
              }
              sx={{ px: 4 }}
            />
            <Tab
              value='6'
              label={
                (assPendingL2Len > 0 && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2.5 }}>
                    <Typography variant='body2'>Assign Pending</Typography>
                    <Badge
                      badgeContent={assPendingL2Len}
                      color='primary'
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: value === '6' ? 'white' : 'primary',
                          color: value === '6' ? 'black' : 'white'
                        }
                      }}
                    />
                  </Box>
                )) ||
                'Assign Pending'
              }
              sx={{ px: 4 }}
            />
          </TabList>
        )}
        {role === 'item-user' && (
          <TabList onChange={handleChange} aria-label='customized tabs example'>
            <Tab value='1' label='Assigned Items' sx={{ px: 4 }} />
            <Tab
              value='4'
              label={
                (assReqLen > 0 && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2.5 }}>
                    <Typography variant='body2'>Assign Requests</Typography>
                    <Badge
                      badgeContent={assReqLen}
                      color='primary'
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: value === '4' ? 'white' : 'primary',
                          color: value === '4' ? 'black' : 'white'
                        }
                      }}
                    />
                  </Box>
                )) ||
                'Assign Requests'
              }
              sx={{ px: 4 }}
            />
            <Tab
              value='5'
              label={
                (unassReqLen > 0 && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2.5 }}>
                    <Typography variant='body2'>Unassign Requests</Typography>
                    <Badge
                      badgeContent={unassReqLen}
                      color='primary'
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: value === '5' ? 'white' : 'primary',
                          color: value === '5' ? 'black' : 'white'
                        }
                      }}
                    />
                  </Box>
                )) ||
                'Unassign Requests'
              }
              sx={{ px: 4 }}
            />
            <Tab
              value='7'
              label={
                (assPendingL3Len > 0 && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2.5 }}>
                    <Typography variant='body2'>Assign Pending</Typography>
                    <Badge
                      badgeContent={assPendingL3Len}
                      color='primary'
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: value === '7' ? 'white' : 'primary',
                          color: value === '7' ? 'black' : 'white'
                        }
                      }}
                    />
                  </Box>
                )) ||
                'Assign Pending'
              }
              sx={{ px: 4 }}
            />
          </TabList>
        )}
        <TabPanel value='1' sx={{ p: 0, pt: 1 }}>
          <AssignedItems />
        </TabPanel>
        <TabPanel value='2' sx={{ p: 0, pt: 1 }}>
          <AssignApproval notifsReset={notifsReset} setNotifsReset={setNotifsReset} />
        </TabPanel>
        <TabPanel value='3' sx={{ p: 0, pt: 1 }}>
          <UnassignApproval notifsReset={notifsReset} setNotifsReset={setNotifsReset} />
        </TabPanel>
        <TabPanel value='4' sx={{ p: 0, pt: 1 }}>
          <AssignRequests notifsReset={notifsReset} setNotifsReset={setNotifsReset} />
        </TabPanel>
        <TabPanel value='5' sx={{ p: 0, pt: 1 }}>
          <UnassignRequests notifsReset={notifsReset} setNotifsReset={setNotifsReset} />
        </TabPanel>
        <TabPanel value='6' sx={{ p: 0, pt: 1 }}>
          <AssignPendingL2 />
        </TabPanel>
        <TabPanel value='7' sx={{ p: 0, pt: 1 }}>
          <AssignPendingL3 />
        </TabPanel>
      </TabContext>
    </>
  )
}

export default Index
