import { Avatar, Box, Divider, Grid, IconButton, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import React, { useRef } from 'react'

import { Icon } from '@iconify/react'
import { useEffect, useState } from 'react'
import { useCurrentQueryParams, useCurrentRoute, useUserID } from '../../../../../hooks/customHooks'
import apiDefinitions from '../../../../../api/apiDefinitions'
import toast from 'react-hot-toast'

// const Messages = [
//   {
//     id: 1,
//     message: [
//       {
//         text: 'Hello Hijhgy6tgfjugy hyusdgfergf dfchsehcgbs'
//       },
//       {
//         text: 'Hello Hijhgy6tgfjugy hyusdgfergf dfchsehcgbs'
//       }
//     ],
//     userType: 'sender',
//     userName: 'Username',
//     userAvatar: '',
//     date: '2021-10-10',
//     time: '09:38'
//   },
//   {
//     id: 2,
//     message: [
//       {
//         text: 'Hello Hijhgy6tgfjugy hyusdgfergf dfchsehcgbs'
//       }
//     ],
//     userType: 'receiver',
//     userName: 'Username',
//     userAvatar: '',
//     date: '2021-10-11',
//     time: '12:30'
//   },
//   {
//     id: 3,
//     message: [
//       {
//         text: 'Hello Hijhgy6tgfjugy hyusdgfergf dfchsehcgbs'
//       }
//     ],
//     userType: 'sender',
//     userName: 'Username',
//     userAvatar: '',
//     date: '2021-10-11',
//     time: '16:45'
//   },
//   {
//     id: 4,
//     message: [
//       {
//         text: 'Hello Hijhgy6tgfjugy hyusdgfergf dfchsehcgbs'
//       },
//       {
//         text: 'Hello Hijhgy6tgfjugy hyusdgfergf dfchsehcgbs'
//       },
//       {
//         text: 'Hello Hijhgy6tgfjugy hyusdgfergf dfchsehcgbs'
//       }
//     ],
//     userType: 'receiver',
//     userName: 'Username',
//     userAvatar: '',
//     date: '2021-10-12',
//     time: '13:20'
//   },
//   {
//     id: 5,
//     message: [
//       {
//         text: 'Hello Hijhgy6tgfjugy hyusdgfergf dfchsehcgbs'
//       }
//     ],
//     userType: 'sender',
//     userName: 'Username',
//     userAvatar: '',
//     date: '2021-10-13',
//     time: '15:30'
//   }
// ]

const RequestChat = ({ height }) => {
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''

  const poReqID = useCurrentQueryParams('id') ?? ''

  const [messages, setMessages] = useState([])

  const [refresh, setRefresh] = useState(false)

  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
  }

  const [scrollEnabled, setScrollEnabled] = useState(true)

  const [msg, setMsg] = useState('')

  // run setRefresh(!refresh) every five seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setRefresh(!refresh)
    }, 5000)
    return () => clearInterval(interval)
  }, [refresh])

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '' && poReqID !== '') {
      apiDefinitions
        .getChatByPORID(userID, currentRouteString, poReqID)
        .then(response => {
          // console.log(response.data.data)

          const chatMsgs = response.data.data.map(chat => {
            return {
              id: chat.id,
              message: chat.message,
              userType: chat.user_type.toLowerCase(),
              userName: chat.user_name,
              userAvatar: '',
              date: chat.date_time.split(' ')[0],
              time: chat.date_time.split(' ')[1].slice(0, 5)
            }
          })

          setMessages(chatMsgs)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }, [userID, currentRouteString, poReqID, refresh])

  useEffect(() => {
    if (messagesEndRef !== null && scrollEnabled && messages.length > 0) {
      scrollToBottom()
      setScrollEnabled(false)
    }
  }, [messages, scrollEnabled])

  const handleSendMsg = () => {
    if (msg === '') {
      return
    }

    const payload = {
      message: msg,
      purchase_order_request_id: poReqID
    }

    apiDefinitions
      .postChatCreate(userID, currentRouteString, payload)
      .then(response => {
        if (response.data.code === 201) {
          console.log('Message sent successfully')
          setMsg('')

          setRefresh(!refresh)
        } else {
          throw new Error(response.data.message)
        }
      })
      .then(() => {
        setTimeout(() => {
          scrollToBottom()
        }, 1000)
      })
      .catch(error => {
        console.log(error)
        toast.error('Failed to send message!')
      })
  }

  let prevDate = ''

  return (
    <Box
      sx={{
        display: 'flex',
        height: height,
        flexDirection: 'column',
        justifyContent: 'space-between',
        py: 2
      }}
    >
      <Box
        id='chatBox'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          mb: 1,
          overflowY: 'auto',
          px: 2,
          '&::-webkit-scrollbar': {
            width: '0.3em'
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)'
          }
        }}
      >
        {messages.map((message, index) => {
          const currentDate = message.date
          const renderDivider = prevDate !== null && prevDate !== currentDate

          // Update the previous date
          prevDate = currentDate

          return (
            <React.Fragment key={index}>
              {renderDivider && (
                <Divider
                  sx={{
                    mx: 4
                  }}
                >
                  <Typography variant='caption'>{currentDate}</Typography>
                </Divider>
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',

                  justifyContent: message.userType === 'receiver' ? 'flex-end' : 'flex-start'
                }}
              >
                <Typography
                  variant='caption'
                  align={message.userType === 'receiver' ? 'left' : 'right'}
                  sx={{
                    ml: message.userType === 'receiver' ? 5 : 0,
                    mr: message.userType === 'sender' ? 5 : 0
                  }}
                >
                  {message.userName}
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: message.userType === 'receiver' ? 'flex-start' : 'flex-end',

                    gap: 1
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 1
                    }}
                  >
                    {message.userType === 'receiver' && (
                      <Avatar
                        src={message.userAvatar}
                        sx={{
                          width: 30,
                          height: 30
                        }}
                      />
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: message.userType === 'receiver' ? 'flex-start' : 'flex-end',
                        gap: 1
                      }}
                    >
                      {message.message.map((msg, i) => (
                        <Box
                          key={i}
                          sx={{
                            backgroundColor: '#dedede',
                            p: 1,
                            borderRadius: 2,
                            width: 'fit-content'
                          }}
                        >
                          <Typography variant='body2'>{msg.text}</Typography>
                        </Box>
                      ))}
                      <Typography variant='caption' textAlign={message.userType === 'receiver' ? 'left' : 'right'}>
                        {message.time}
                      </Typography>
                    </Box>
                    {message.userType === 'sender' && (
                      <Avatar
                        src={message.userAvatar}
                        sx={{
                          width: 30,
                          height: 30
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </React.Fragment>
          )
        })}
        <div style={{ float: 'left', clear: 'both' }} ref={messagesEndRef}></div>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mx: 2
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={10.5}>
            <TextField
              id='outlined-multiline-flexible'
              size='small'
              value={msg}
              onChange={e => setMsg(e.target.value)}
              multiline
              maxRows={3}
              fullWidth
              placeholder='Type a message...'
              //on press enter send message on press shift+enter new line
              onKeyDown={e => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault()

                  if (e.target.value === '') {
                    return
                  }

                  const payload = {
                    message: e.target.value,
                    purchase_order_request_id: poReqID
                  }

                  apiDefinitions
                    .postChatCreate(userID, currentRouteString, payload)
                    .then(response => {
                      if (response.data.code === 201) {
                        console.log('Message sent successfully')
                        e.target.value = ''
                        setMsg('')

                        setRefresh(!refresh)
                      } else {
                        throw new Error(response.data.message)
                      }
                    })
                    .then(() => {
                      setTimeout(() => {
                        scrollToBottom()
                      }, 1000)
                    })
                    .catch(error => {
                      console.log(error)
                      toast.error('Failed to send message!')
                    })
                }
              }}
            />
          </Grid>
          <Grid item xs={1.5}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%'
              }}
            >
              <IconButton onClick={handleSendMsg}>
                <Icon icon='bi:send' fontSize={22} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default RequestChat
