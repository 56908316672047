import { Icon } from '@iconify/react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { useEffect, useState } from 'react'
import LotImageUploader from './LotImageUploader'
import toast from 'react-hot-toast'
import apiDefinitions from '../../../../api/apiDefinitions'
import { useUserID, useCurrentQueryParams, useCurrentRoute } from '../../../../hooks/customHooks'

const LotAddForm = ({ lotData, onFormChange, selectedCurrency, lotNameError, setLotNameError }) => {
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''
  const itemID = useCurrentQueryParams('id') ?? ''

  const [lotTotalAmount, setLotTotalAmount] = useState(0)
  const [lotQuantity, setLotQuantity] = useState(0)
  const [lotFinalAmount, setLotFinalAmount] = useState(0)
  const [lotDiscount, setLotDiscount] = useState(0)
  const [lotTax, setLotTax] = useState(0)
  const [lotImage, setLotImage] = useState(null)
  const [lotName, setLotName] = useState('')

  const [lotCurrency, setLotCurrency] = useState(223)
  const [currencyList, setCurrencyList] = useState([])

  const handleFileUpload = file => {
    setLotImage(file)
  }

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '' && itemID !== '') {
      // Fetch the currency from the API
      apiDefinitions
        .getAllDataList(userID, currentRouteString, 'currency')
        .then(response => {
          if (response.data.code === 200) {
            // console.log('currency:', response.data.data)
            // setCurrencyList(response.data.data)

            // Sort the currency list by currency_name
            const sortedCurrencyList = response.data.data.sort((a, b) => {
              if (a.country_name < b.country_name) {
                return -1
              }
              if (a.country_name > b.country_name) {
                return 1
              }
              return 0
            })

            setCurrencyList(sortedCurrencyList)
          } else {
            console.log('Error fetching currency list:', response.data.message)
            toast.error('Error: ' + response.data.message)
          }
        })
        .catch(error => {
          console.error('Error fetching currency list:', error)
          toast.error('Error fetching currency list!')
        })
    }
  }, [currentRouteString, itemID, userID])

  useEffect(() => {
    if (!Array.isArray(lotData)) return

    let totalQuantity = 0
    let totalAmount = 0

    lotData.forEach(lot => {
      totalQuantity += parseInt(lot.quantity, 10) || 0 // Ensure quantity is a number
      totalAmount += parseFloat(lot.finalPrice) || 0 // Ensure finalPrice is a number
    })

    setLotQuantity(totalQuantity)
    setLotTotalAmount(totalAmount.toFixed(2))
  }, [lotData])

  useEffect(() => {
    const finalAmount = parseFloat(lotTotalAmount) + parseFloat(lotTax) - parseFloat(lotDiscount)
    setLotFinalAmount(finalAmount.toFixed(2))
  }, [lotDiscount, lotTax, lotTotalAmount])

  useEffect(() => {
    const formPayload = {
      lot_name: lotName,
      lot_quantity: lotQuantity,
      lot_total_amount: Number(lotTotalAmount),
      lot_discount: lotDiscount,
      lot_tax: lotTax,
      lot_final_amount: Number(lotFinalAmount),
      lot_currency: lotCurrency
    }
    onFormChange(formPayload, lotImage)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotDiscount, lotFinalAmount, lotImage, lotQuantity, lotTax, lotTotalAmount, lotCurrency, lotName])

  useEffect(() => {
    //get currency name
    if (currencyList.length > 0) {
      const currency = currencyList.find(currency => currency.id === lotCurrency)
      if (currency) {
        selectedCurrency(currency.currency_code)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotCurrency, currencyList])

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1-content' id='panel1-header'>
        <Typography variant='h5'>LOT DETAILS</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} sx={{ px: 1 }}>
          <Grid item xs={8}>
            <Grid container columnSpacing={2} rowSpacing={4}>
              <Grid item xs={4.5}>
                <TextField
                  fullWidth
                  label='Lot Name'
                  size='small'
                  value={lotName}
                  required
                  helperText={lotNameError}
                  error={lotNameError.length > 0}
                  onChange={e => {
                    setLotName(e.target.value)
                    setLotNameError('')
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                        <Icon icon='fluent:tray-item-remove-24-filled' />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={2.5}>
                <TextField
                  fullWidth
                  label='Lot Total Amount'
                  value={lotTotalAmount}
                  size='small'
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                        <Icon icon='fluent-emoji-high-contrast:money-bag' />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={2.5}>
                <TextField
                  fullWidth
                  label='Lot Quantity'
                  value={lotQuantity}
                  size='small'
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                        <Icon icon='oui:token-token-count' />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={2.5}>
                <TextField
                  fullWidth
                  label='Lot Final Amount'
                  value={lotFinalAmount}
                  size='small'
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                        <Icon icon='solar:tag-price-outline' />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label='Lot Tax'
                  type='number'
                  onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                  value={lotTax}
                  onFocus={e => e.target.select()}
                  onChange={e => {
                    setLotTax(e.target.value)
                  }}
                  size='small'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                        <Icon icon='tabler:receipt-tax' />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label='Lot Discount'
                  type='number'
                  onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                  onFocus={e => e.target.select()}
                  value={lotDiscount}
                  onChange={e => {
                    setLotDiscount(e.target.value)
                  }}
                  size='small'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                        <Icon icon='ri:discount-percent-line' />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth size='small'>
                  <InputLabel id='lotCurrency'>Lot Currency</InputLabel>
                  <Select
                    id='lotCurrency'
                    value={lotCurrency}
                    onChange={event => setLotCurrency(event.target.value)}
                    inputProps={{
                      name: 'lotCurrency'
                    }}
                    label='Lot Currency'
                    fullWidth
                    size='small'
                  >
                    {currencyList.map(currency => (
                      <MenuItem key={currency.id} value={currency.id}>
                        {currency.country_name} - {currency.currency_name} ({currency.currency_code})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <LotImageUploader onUpload={handleFileUpload} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default LotAddForm
