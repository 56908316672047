import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Grid, Tab, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { DataGrid } from '@mui/x-data-grid'
import * as React from 'react'
import { useState } from 'react'

const LogsAndAttributes = () => {
  const [activityLogRows, setActivityLogRows] = useState([
    {
      id: 1,
      date: '2024-05-20T17:10:08',
      changes_made: ['Purchase created.', 'Purchase status changed from Draft to Ordered.'],
      user: 'sandupa@kiu.ac.lk'
    },
    {
      id: 2,
      date: '2024-05-20T17:10:32',
      changes_made: [
        'Purchase status changed from Ordered to Invoiced.',
        'Invoicing status changed from Not billed to Fully billed.'
      ],
      user: 'sandupa@kiu.ac.lk'
    },
    {
      id: 3,
      date: '2024-05-20T17:16:30',
      changes_made: ['Purchase undone.'],
      user: 'sandupa@kiu.ac.lk'
    },
    {
      id: 4,
      date: '2024-05-20T17:16:31',
      changes_made: [
        'Purchase status changed from Draft to Ordering.',
        'Invoicing status changed from Fully billed to Not available.',
        'Stock receiving status changed from NOT RECEIVED to Not available.'
      ],
      user: 'sandupa@kiu.ac.lk'
    },
    {
      id: 5,
      date: '2024-05-20T17:16:48',
      changes_made: [
        'Purchase status changed from Ordering to Ordered.',
        'Invoicing status changed from Not available to Not billed.',
        'Stock receiving status changed from NOT AVAILABLE to Not received.'
      ],
      user: 'sandupa@kiu.ac.lk'
    },
    {
      id: 6,
      date: '2024-05-20T17:18:44',
      changes_made: [
        'Purchase status changed from Ordered to Invoiced.',
        'Invoicing status changed from Not billed to Fully billed.'
      ],
      user: 'sandupa@kiu.ac.lk'
    },
    {
      id: 7,
      date: '2024-05-20T17:28:55',
      changes_made: ['Purchase undone.'],
      user: 'sandupa@kiu.ac.lk'
    },
    {
      id: 8,
      date: '2024-05-20T17:28:56',
      changes_made: [
        'Purchase status changed from Draft to Ordering.',
        'Invoicing status changed from Fully billed to Not available.',
        'Stock receiving status changed from NOT RECEIVED to Not available.'
      ],
      user: 'sandupa@kiu.ac.lk'
    },
    {
      id: 9,
      date: '2024-05-20T17:29:00',
      changes_made: [
        'Purchase status changed from Ordering to Ordered.',
        'Invoicing status changed from Not available to Not billed.',
        'Stock receiving status changed from NOT AVAILABLE to Not received.'
      ],
      user: 'sandupa@kiu.ac.lk'
    },
    {
      id: 10,
      date: '2024-05-20T17:29:15',
      changes_made: [
        'Purchase status changed from Ordered to Invoiced.',
        'Invoicing status changed from Not billed to Fully billed.'
      ],
      user: 'sandupa@kiu.ac.lk'
    },
    {
      id: 11,
      date: '2024-05-20T17:29:42',
      changes_made: ['Purchase undone.'],
      user: 'sandupa@kiu.ac.lk'
    },
    {
      id: 12,
      date: '2024-05-20T17:29:42',
      changes_made: [
        'Purchase status changed from Draft to Ordering.',
        'Invoicing status changed from Fully billed to Not available.',
        'Stock receiving status changed from NOT RECEIVED to Not available.'
      ],
      user: 'sandupa@kiu.ac.lk'
    },
    {
      id: 13,
      date: '2024-05-20T17:33:12',
      changes_made: [
        'Purchase status changed from Ordering to Ordered.',
        'Invoicing status changed from Not available to Not billed.',
        'Stock receiving status changed from NOT AVAILABLE to Not received.'
      ],
      user: 'sandupa@kiu.ac.lk'
    },
    {
      id: 14,
      date: '2024-05-20T17:33:31',
      changes_made: [
        'Purchase status changed from Ordered to Invoiced.',
        'Invoicing status changed from Not billed to Fully billed.'
      ],
      user: 'sandupa@kiu.ac.lk'
    },
    {
      id: 15,
      date: '2024-05-21T09:43:07',
      changes_made: ['Purchase undone.'],
      user: 'sandupa@kiu.ac.lk'
    },
    {
      id: 16,
      date: '2024-05-21T09:43:08',
      changes_made: [
        'Purchase status changed from Draft to Ordering.',
        'Invoicing status changed from Fully billed to Not available.',
        'Stock receiving status changed from NOT RECEIVED to Not available.'
      ],
      user: 'sandupa@kiu.ac.lk'
    },
    {
      id: 17,
      date: '2024-05-21T09:43:13',
      changes_made: [
        'Purchase status changed from Ordering to Ordered.',
        'Invoicing status changed from Not available to Not billed.',
        'Stock receiving status changed from NOT AVAILABLE to Not received.'
      ],
      user: 'sandupa@kiu.ac.lk'
    },
    {
      id: 18,
      date: '2024-05-21T10:01:13',
      changes_made: [
        'Purchase status changed from Ordered to Invoiced.',
        'Invoicing status changed from Not billed to Fully billed.'
      ],
      user: 'sandupa@kiu.ac.lk'
    },
    {
      id: 19,
      date: '2024-05-21T14:14:55',
      changes_made: [
        'Purchase status changed from Invoiced to Received.',
        'Stock receiving status changed from NOT RECEIVED to Received.'
      ],
      user: 'sandupa@kiu.ac.lk'
    },
    {
      id: 20,
      date: '2024-05-21T19:52:28',
      changes_made: ['Credit note voided.'],
      user: 'sandupa@kiu.ac.lk'
    }
  ])
  const [wActivityLogRows, setWActivityLogRows] = useState([])

  const [value, setValue] = React.useState('1')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const [loading, setLoading] = useState(false)

  const activityLogCols = [
    {
      field: 'date',
      headerName: 'Date',
      flex: 1.5,
      type: 'datetime',
      width: 180,
      valueFormatter: params => {
        const date = new Date(params.value)
        const formattedDate = date
          .toLocaleString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
          })
          .replace(',', '')
        return formattedDate
      }
    },
    {
      field: 'changes_made',
      headerName: 'Changes Made',
      flex: 4,
      width: 350,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              my: 2
            }}
          >
            {params.value.map((change, index) => (
              <Typography key={index} variant='body2'>
                {change}
              </Typography>
            ))}
          </Box>
        )
      }
    },
    {
      field: 'user',
      headerName: 'User',
      flex: 2,
      width: 200
    }
  ]

  const wActivityLogCols = [
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      type: 'date',
      width: 150,
      valueFormatter: params => {
        return new Date(params.value).toLocaleDateString()
      }
    },
    {
      field: 'workflowName',
      headerName: 'Workflow Name',
      flex: 1,
      width: 150
    },
    {
      field: 'event',
      headerName: 'Event',
      flex: 1,
      width: 150
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      width: 150
    },
    {
      field: 'state',
      headerName: 'State',
      flex: 1,
      width: 150
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      width: 200
    }
  ]

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleChange} aria-label='lab API tabs example'>
          <Tab label='Activity Log' value='1' />
          <Tab label='Workflow Activity Log' value='2' />
        </TabList>
      </Box>
      <TabPanel
        value='1'
        sx={{
          px: 0
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DataGrid
              autoHeight
              getRowHeight={() => 'auto'}
              rows={activityLogRows}
              columns={activityLogCols}
              loading={loading}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5
                  }
                }
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
              slots={{
                noRowsOverlay: () => {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%'
                      }}
                    >
                      <Typography variant='body2' color='textSecondary'>
                        Activity log is not available now.
                      </Typography>
                    </Box>
                  )
                }
              }}
            />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel
        value='2'
        sx={{
          px: 0
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DataGrid
              autoHeight
              rows={wActivityLogRows}
              columns={wActivityLogCols}
              loading={loading}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5
                  }
                }
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
              slots={{
                noRowsOverlay: () => {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%'
                      }}
                    >
                      <Typography variant='body2' color='textSecondary'>
                        Workflow history is not available now.
                      </Typography>
                    </Box>
                  )
                }
              }}
            />
          </Grid>
        </Grid>
      </TabPanel>
    </TabContext>
  )
}

export default LogsAndAttributes
