import { Icon } from '@iconify/react'
import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

//layout imports
import AppBar from '../components/AppBar'
import FooterBar from '../components/FooterBar'
import NavBar from '../components/NavBar'

import { Toaster } from 'react-hot-toast'

import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import navigation from '../navigation/nav'
import subnavigation from '../navigation/subnav'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import { grey } from '@mui/material/colors'

//TODO: Fix Params Error on BreadCrumb Navigation

const theme = createTheme({
  palette: {
    secondary: grey
  }
  // typography: {
  //   h1: {
  //     fontSize: '4rem'
  //   },
  //   h2: {
  //     fontSize: '3rem'
  //   },
  //   h3: {
  //     fontSize: '2rem'
  //   },
  //   h4: {
  //     fontSize: '1.75rem'
  //   },
  //   h5: {
  //     fontSize: '1.5rem'
  //   },
  //   h6: {
  //     fontSize: '1.25rem'
  //   },
  //   body1: {
  //     fontSize: '1rem'
  //   },
  //   body2: {
  //     fontSize: '0.5rem'
  //   }
  // }
})

const findCurrentNavPoint = (navArray, path) => {
  for (const navItem of navArray) {
    if (navItem.path === path) {
      return { current: navItem, parent: null }
    }
    if (navItem.children) {
      const childResult = findCurrentNavPoint(navItem.children, path)
      if (childResult.current) {
        return { current: childResult.current, parent: navItem }
      }
    }
  }
  return { current: null, parent: null }
}

const findCurrentSubNavPoint = (subNavArray, path) => {
  for (const subNavItem of subNavArray) {
    if (subNavItem.path === path) {
      const subNavParent = navigation().find(navItem => navItem.id === subNavItem.pID)
      const subNavSubParents = subNavItem?.subPIDs?.map(element => {
        return subNavArray.find(subNav => subNav.id === element)
      })

      let parents
      if (Array.isArray(subNavSubParents)) {
        parents = [subNavParent, ...subNavSubParents]
      } else {
        parents = [subNavParent]
      }

      return { current: subNavItem, parent: parents }
    }
  }
  return { current: null, parent: null }
}

const VerticalLayout = () => {
  const location = useLocation()
  const routerNavigation = useNavigate()

  const [isLeftGridCollapsed, setIsLeftGridCollapsed] = useState(true)
  // const [breadCrumb, setBreadCrumb] = useState([])

  const handleNavCollapse = value => {
    setIsLeftGridCollapsed(value)
  }

  const [currentNavPoints, setCurrentNavPoints] = useState({ current: null, parent: null })

  useEffect(() => {
    const currentPath = location.pathname
    const navPoints = findCurrentNavPoint(navigation(), currentPath)

    if (navPoints.current === null) {
      const subNavPoints = findCurrentSubNavPoint(subnavigation(), currentPath)
      setCurrentNavPoints(subNavPoints)
      return
    }

    // console.log('navPoints', navPoints)
    setCurrentNavPoints(navPoints)
  }, [location.pathname])

  useEffect(() => {
    console.log(currentNavPoints)
  }, [currentNavPoints])

  return (
    <ThemeProvider theme={theme}>
      <Toaster position='top-right' reverseOrder={false} />
      <Grid container>
        {isLeftGridCollapsed && (
          <Grid item xs={1.75}>
            <NavBar />
          </Grid>
        )}
        <Grid
          item
          xs={isLeftGridCollapsed ? 10.25 : 12}
          sx={{
            display: 'flex',
            overflowY: 'auto'
          }}
        >
          <Grid
            container
            sx={{ padding: 2, paddingBottom: 0, display: 'flex', alignItems: 'space-between', height: '100vh' }}
          >
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <AppBar navCollapse={handleNavCollapse} />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    minHeight: '30px',
                    my: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                  className='content-display-grid-scroll'
                >
                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    <Icon icon='clarity:home-solid' className='breadcrumbIcon' />
                    {currentNavPoints.parent && (
                      <>
                        {Array.isArray(currentNavPoints.parent) ? (
                          currentNavPoints.parent.map((parent, index) => (
                            <React.Fragment key={index}>
                              <span>&ensp;{'>'}&ensp;</span>
                              <Box
                                className='linkDisabled'
                                // className={parent.path === undefined ? 'linkDisabled' : 'breadcrumbLink'}
                                // onClick={e => parent.path && routerNavigation(parent.path)}
                              >
                                {parent.title}
                              </Box>
                            </React.Fragment>
                          ))
                        ) : (
                          <>
                            <span>&ensp;{'>'}&ensp;</span>
                            <Box
                              className={currentNavPoints.parent.path === undefined ? 'linkDisabled' : 'breadcrumbLink'}
                              // onClick={e => currentNavPoints.parent && routerNavigation(currentNavPoints.parent.path)}
                            >
                              {currentNavPoints.parent.title}
                            </Box>
                          </>
                        )}
                      </>
                    )}
                    &ensp;{'>'}&ensp;
                    <Box
                      className={
                        currentNavPoints.current && currentNavPoints.current.path === undefined
                          ? 'linkDisabled'
                          : 'breadcrumbLink'
                      }
                      // onClick={e => currentNavPoints.current && routerNavigation(currentNavPoints.current.path)}
                    >
                      {currentNavPoints.current ? currentNavPoints.current.title : null}
                    </Box>
                  </Typography>
                  <Button
                    startIcon={<Icon icon='icon-park-outline:back' />}
                    onClick={() => routerNavigation(-1)}
                    size='small'
                    variant='tonal'
                  >
                    Back
                  </Button>
                </Grid>

                <Grid item xs={12} sx={{ height: '74vh', pr: 1, py: 0.5 }} className='content-display-grid-scroll'>
                  <Routes>
                    {navigation().map(item => {
                      if (item.path === undefined) {
                        // Flatten the routes for child components
                        return item.children.map(child => (
                          <Route key={child.title} path={child.path} element={React.createElement(child.component)} />
                        ))
                      }
                      return <Route key={item.title} path={item.path} element={React.createElement(item.component)} />
                    })}

                    {subnavigation().map(subNavItem => (
                      <Route
                        key={subNavItem.title}
                        path={subNavItem.path}
                        element={React.createElement(subNavItem.component)}
                      />
                    ))}
                  </Routes>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <Box sx={{ my: 1, width: '100%' }}>
                <FooterBar />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

export default VerticalLayout
