import { Checkbox, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { DataGrid } from '@mui/x-data-grid'
import * as React from 'react'

import { Icon } from '@iconify/react'
const UploadedFiles = ({ processedFiles, refreshProcessedFiles }) => {
  const columns = [
    {
      field: 'attachment',
      headerName: 'Attachment',
      minWidth: 350,
      flex: 4,
      renderCell: params => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1.5,
            my: 1.5
          }}
        >
          <Box
            onClick={() => {
              window.open(params.row.preview, '_blank')
            }}
            sx={{ cursor: 'pointer' }}
          >
            <Icon
              icon={
                params.row.type === 'application/pdf'
                  ? 'vscode-icons:file-type-pdf2'
                  : params.row.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  ? 'vscode-icons:file-type-word'
                  : params.row.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  ? 'vscode-icons:file-type-excel'
                  : params.row.type.includes('image')
                  ? 'flat-color-icons:image-file'
                  : ''
              }
              minWidth={40}
              height={40}
            />
          </Box>

          <Typography variant='body1' component='span'>
            {params.row.name.substring(0, params.row.name.lastIndexOf('.'))}
          </Typography>
        </Box>
      )
    },
    {
      field: 'attached_date',
      headerName: 'Date',
      minWidth: 180,
      flex: 0.5,
      valueFormatter: params =>
        new Date(params.value).toLocaleDateString() + ' ' + new Date(params.value).toLocaleTimeString()
    },
    {
      field: 'attached_by',
      headerName: 'Attached By',
      minWidth: 200,
      flex: 1
    },
    {
      field: 'isProcessed',
      headerName: 'Processed',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 0.5,
      renderCell: params => (
        <Checkbox
          checked={params.row.isProcessed}
          inputProps={{ 'aria-label': 'primary checkbox' }}
          onChange={e => {}}
        />
      )
    }
  ]

  return (
    <Box sx={{ height: 390, minWidth: '100%' }}>
      <DataGrid
        getRowHeight={() => 'auto'}
        rows={processedFiles}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5
            }
          }
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        slots={{
          noRowsOverlay: () => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%'
                }}
              >
                <Typography variant='body2' color='textSecondary'>
                  You do not have any files attached.
                </Typography>
              </Box>
            )
          }
        }}
      />
    </Box>
  )
}

export default UploadedFiles
