import { Icon } from '@iconify/react'
import TabList from '@mui/lab/TabList'
import { Typography } from '@mui/material'
import Tab from '@mui/material/Tab'

const HeaderTabsH = ({ contextValue, setContextValue }) => {
  const iconSize = 20

  const handleChange = (event, newValue) => {
    event.stopPropagation()
    setContextValue(newValue)
  }

  return (
    <TabList
      onChange={handleChange}
      aria-label='lab API tabs example'
      variant='scrollable'
      scrollButtons={false}
      onClick={e => e.stopPropagation()}
    >
      <Tab
        icon={<Icon icon='maki:arrow' fontSize={iconSize} />}
        label={
          <Typography variant='caption' fontWeight={600}>
            Order
          </Typography>
        }
        value='1'
      />
      <Tab
        icon={<Icon icon='la:file-invoice-dollar' fontSize={iconSize} />}
        label={
          <Typography variant='caption' fontWeight={600}>
            Invoice
          </Typography>
        }
        value='2'
      />
      <Tab
        icon={<Icon icon='hugeicons:shopping-cart-check-02' fontSize={iconSize} />}
        label={
          <Typography variant='caption' fontWeight={600}>
            Stock Recieved
          </Typography>
        }
        value='3'
      />
      <Tab
        icon={<Icon icon='fluent:notepad-24-regular' fontSize={iconSize} />}
        label={
          <Typography variant='caption' fontWeight={600}>
            Credit Note
          </Typography>
        }
        value='4'
      />
      <Tab
        icon={<Icon icon='hugeicons:shopping-cart-check-out-01' fontSize={iconSize} />}
        label={
          <Typography variant='caption' fontWeight={600}>
            Unstock
          </Typography>
        }
        value='5'
      />
      <Tab
        icon={<Icon icon='bi:journal-bookmark' fontSize={iconSize} />}
        label={
          <Typography variant='caption' fontWeight={600}>
            Manual Journals
          </Typography>
        }
        value='6'
      />
      <Tab
        icon={<Icon icon='icon-park-outline:history-query' fontSize={iconSize} />}
        label={
          <Typography variant='caption' fontWeight={600}>
            Related Orders
          </Typography>
        }
        value='7'
      />
      <Tab
        icon={<Icon icon='teenyicons:attachment-outline' fontSize={iconSize} />}
        label={
          <Typography variant='caption' fontWeight={600}>
            Attachments
          </Typography>
        }
        value='8'
      />
      <Tab
        icon={<Icon icon='ic:round-double-arrow' fontSize={iconSize} />}
        label={
          <Typography variant='caption' fontWeight={600}>
            Logs and Attributes
          </Typography>
        }
        value='9'
      />
      <Tab
        icon={<Icon icon='flowbite:wallet-outline' fontSize={iconSize} />}
        label={
          <Typography variant='caption' fontWeight={600}>
            Financials
          </Typography>
        }
        value='10'
      />
    </TabList>
  )
}

export default HeaderTabsH
