import { Box, Divider, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'

import { useState } from 'react'
import AdditionalCosts from './OrderComponents/AdditionalCosts'
import OrderSummary from './OrderComponents/OrderSummary'
import OrderValueSummary from './OrderComponents/OrderValueSummary'

const Order = () => {
  const [taxInclusive, setTaxInclusive] = useState(true)
  const [taxRate, setTaxRate] = useState(0.1)

  const [rawLineTotal, setRawLineTotal] = useState(0)
  const [rawAdditionalTotal, setRawAdditionalTotal] = useState(0)

  const [summaryValues, setSummaryValues] = useState({
    lineTotal: 0,
    lineTax: 0,
    lineWithoutTax: 0,
    additionalTotal: 0,
    additionalTax: 0,
    additionalWithoutTax: 0
  })

  useEffect(() => {
    let lineTaxVal = 0
    let additionalTaxVal = 0

    if (taxInclusive) {
      lineTaxVal = rawLineTotal - rawLineTotal / (1 + taxRate)
      additionalTaxVal = rawAdditionalTotal - rawAdditionalTotal / (1 + taxRate)

      setSummaryValues({
        lineTotal: rawLineTotal,
        lineTax: lineTaxVal,
        lineWithoutTax: rawLineTotal - lineTaxVal,
        additionalTotal: rawAdditionalTotal,
        additionalTax: additionalTaxVal,
        additionalWithoutTax: rawAdditionalTotal - additionalTaxVal
      })
    } else {
      lineTaxVal = rawLineTotal * taxRate
      additionalTaxVal = rawAdditionalTotal * taxRate

      setSummaryValues({
        lineTotal: rawLineTotal + lineTaxVal,
        lineTax: lineTaxVal,
        lineWithoutTax: rawLineTotal,
        additionalTotal: rawAdditionalTotal + additionalTaxVal,
        additionalTax: additionalTaxVal,
        additionalWithoutTax: rawAdditionalTotal
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawLineTotal, rawAdditionalTotal, taxRate, taxInclusive])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <OrderSummary setLineTotal={setRawLineTotal} />
          </Box>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Box>
            <AdditionalCosts setAdditionalTotal={setRawAdditionalTotal} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth multiline rows={4.5} label='Purchase Order Memo' variant='outlined' />
        </Grid>
        <Grid item xs={6}>
          <Box>
            <OrderValueSummary summaryValues={summaryValues} />
          </Box>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'felx-end',
              border: '1px solid #e0e0e0',
              borderRadius: '10px',
              padding: '10px',
              gap: 1
            }}
          >
            <Typography variant='body1'>Balance Due:</Typography>
            <Typography variant='body1' fontWeight={600}>
              {(summaryValues.lineTotal + summaryValues.additionalTotal).toFixed(2)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Order
