import { Icon } from '@iconify/react'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  styled
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import apiDefinitions from '../../../api/apiDefinitions'
import { useCurrentRoute, useUserID, useUserItemRole, validColorCodes } from '../../../hooks/customHooks'

import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CreditScoreIcon from '@mui/icons-material/CreditScore'

const rows = [
  {
    id: 1,
    status: {
      type: 'Pending',
      color: 'warning'
    },
    order_number: 'PO-001',
    order_date: '2021-10-01',
    supplier: 'Supplier 1',
    document_number: 'DOC-001',
    required_date: '2021-10-10',
    total_amount: 1000
  }
]

const POView = () => {
  const navigate = useNavigate()
  const role = useUserItemRole() ?? ''
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''

  // const [rows, setRows] = useState([])
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 5 })
  const [recordCount, setRecordCount] = useState(0)
  const [tableRefresh, setTableRefresh] = useState(false)

  const navigateToCreate = () => {
    navigate('/purchase-order/purchase-order-view/create-purchase-order')
  }

  const navigateToViewPODocument = () => {
    navigate('/purchase-order/purchase-order-view/view-purchase-order-document')
  }

  const userColumns = [
    {
      field: 'status',
      filterable: false,
      sortable: false,
      headerName: 'Status',
      minWidth: 250,
      flex: 0.3,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return (
          <Box
            sx={{
              px: 2,
              py: 1,
              gap: 4,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <ShoppingCartCheckoutIcon color={params.row.status.color} style={{ fontSize: '25px' }} />
            <RequestQuoteIcon color={params.row.status.color} style={{ fontSize: '25px' }} />
            <AccountTreeIcon color={params.row.status.color} style={{ fontSize: '25px' }} />
            <CreditScoreIcon color={params.row.status.color} style={{ fontSize: '25px' }} />
          </Box>
        )
      }
    },
    {
      field: 'order_number',
      filterable: false,
      sortable: false,
      headerName: 'Order #',
      minWidth: 120,
      flex: 0.25,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.order_number || 'N/A'}</Typography>
      }
    },
    {
      field: 'order_date',
      filterable: false,
      sortable: false,
      headerName: 'Order Date',
      minWidth: 120,
      flex: 0.25,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.order_date || 'N/A'}</Typography>
      }
    },
    {
      field: 'supplier',
      filterable: false,
      sortable: false,
      headerName: 'Supplier',
      minWidth: 120,
      flex: 0.25,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.supplier || 'N/A'}</Typography>
      }
    },
    {
      field: 'document_number',
      filterable: false,
      sortable: false,
      headerName: 'Document #',
      minWidth: 120,
      flex: 0.25,
      renderCell: params => {
        return (
          <Button onClick={navigateToViewPODocument}>
            <Typography variant='body2'>{params.row.document_number || 'N/A'}</Typography>
          </Button>
        )
      }
    },
    {
      field: 'required_date',
      filterable: false,
      sortable: false,
      headerName: 'Required Date',
      minWidth: 120,
      flex: 0.25,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.required_date || 'N/A'}</Typography>
      }
    },
    {
      field: 'total_amount',
      filterable: false,
      sortable: false,
      headerName: 'Total Amount',
      minWidth: 120,
      flex: 0.25,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.total_amount || 'N/A'}</Typography>
      }
    }
  ]

  const adminColumns = [
    {
      field: 'status',
      filterable: false,
      sortable: false,
      headerName: 'Status',
      minWidth: 300,
      flex: 0.1,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return (
          <Box
            sx={{
              px: 2,
              py: 2,
              gap: 5,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <ShoppingCartCheckoutIcon color={params.row.status.color} style={{ fontSize: '25px' }} />
            <RequestQuoteIcon color={params.row.status.color} style={{ fontSize: '25px' }} />
            <AccountTreeIcon color={params.row.status.color} style={{ fontSize: '25px' }} />
            <CreditScoreIcon color={params.row.status.color} style={{ fontSize: '25px' }} />
          </Box>
        )
      }
    },
    {
      field: 'order_number',
      filterable: false,
      sortable: false,
      headerName: 'Order #',
      minWidth: 120,
      flex: 0.25,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.order_number || 'N/A'}</Typography>
      }
    },
    {
      field: 'order_date',
      filterable: false,
      sortable: false,
      headerName: 'Order Date',
      minWidth: 120,
      flex: 0.25,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.order_date || 'N/A'}</Typography>
      }
    },
    {
      field: 'supplier',
      filterable: false,
      sortable: false,
      headerName: 'Supplier',
      minWidth: 120,
      flex: 0.25,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.supplier || 'N/A'}</Typography>
      }
    },
    {
      field: 'document_number',
      filterable: false,
      sortable: false,
      headerName: 'Document #',
      minWidth: 120,
      flex: 0.25,
      renderCell: params => {
        return (
          <Button onClick={navigateToViewPODocument}>
            <Typography variant='body2'>{params.row.document_number || 'N/A'}</Typography>
          </Button>
        )
      }
    },
    {
      field: 'required_date',
      filterable: false,
      sortable: false,
      headerName: 'Required Date',
      minWidth: 120,
      flex: 0.25,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.required_date || 'N/A'}</Typography>
      }
    },
    {
      field: 'total_amount',
      filterable: false,
      sortable: false,
      headerName: 'Total Amount',
      minWidth: 120,
      flex: 0.25,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.total_amount || 'N/A'}</Typography>
      }
    }
  ]

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant='h6'>Purchase Orders</Typography>
            {role === 'item-admin' ? (
              <Button variant='contained' onClick={navigateToCreate} startIcon={<Icon icon='fa6-solid:plus' />}>
                Create
              </Button>
            ) : null}
          </Box>
        </Grid>

        <Grid item xs={12}>
          {role === 'item-admin' ? (
            <Card sx={{ boxShadow: 2 }}>
              <Box
                sx={{
                  height: 400,
                  width: '100%',
                  '& .actions': {
                    color: 'text.secondary'
                  },
                  '& .textPrimary': {
                    color: 'text.primary'
                  }
                }}
              >
                <DataGrid
                  getRowHeight={() => 'auto'}
                  rows={rows}
                  rowCount={recordCount}
                  columns={adminColumns}
                  pageSizeOptions={[5, 10, 25, 50, 100]}
                  paginationMode='server'
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  disableRowSelectionOnClick
                />
              </Box>
            </Card>
          ) : (
            <Card sx={{ boxShadow: 2 }}>
              <Box
                sx={{
                  height: 400,
                  width: '100%',
                  '& .actions': {
                    color: 'text.secondary'
                  },
                  '& .textPrimary': {
                    color: 'text.primary'
                  }
                }}
              >
                <DataGrid
                  getRowHeight={() => 'auto'}
                  rows={rows}
                  rowCount={recordCount}
                  columns={userColumns}
                  pageSizeOptions={[5, 10, 25, 50, 100]}
                  paginationMode='server'
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  disableRowSelectionOnClick
                />
              </Box>
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default POView
