import { Icon } from '@iconify/react'
import { Box, Button, Card, Grid, IconButton, Typography, styled } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import apiDefinitions from '../../../api/apiDefinitions'
import { useCurrentRoute, useUserID } from '../../../hooks/customHooks'
import Swal from 'sweetalert2'

import AddCategoryForm from './addcategory'

const Category = () => {
  const [rows, setRows] = useState([])
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 5 })
  const [recordCount, setRecordCount] = useState(0)
  const [tableRefresh, setTableRefresh] = useState(false)

  const [addCategoryFormOpen, setAddCategoryFormOpen] = useState(false)

  const handleAddCategoryFormOpen = () => {
    setAddCategoryFormOpen(true)
  }

  const handleAddCategoryFormClose = () => {
    setAddCategoryFormOpen(false)
  }

  const handleFormSubmit = () => {
    // console.log('Form Submitted!')
    setTableRefresh(!tableRefresh)
  }

  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''

  const columns = [
    // {
    //   field: 'category_code',
    //   headerName: 'Category Code',
    //   minWidth: 100,
    //   flex: 1,
    //   renderCell: params => {
    //     return <Typography variant='body2'>{params.row.id || 'N/A'}</Typography>
    //   }
    // },
    {
      field: 'category_name',
      headerName: 'Category',
      minWidth: 100,
      flex: 1,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.category_name || 'N/A'}</Typography>
      }
    },
    {
      field: 'category_prefix',
      headerName: 'Category Prefix',
      minWidth: 100,
      flex: 1,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.category_code || 'N/A'}</Typography>
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      renderCell: params => {
        return (
          <Box sx={{ my: 1 }}>
            <IconButton
              aria-label='view'
              color='primary'
              disabled={params.row.assign_status}
              onClick={() => handleDelete(params.row.id)}
            >
              <Icon icon='fluent:delete-32-regular' />
            </IconButton>
          </Box>
        )
      }
    }
  ]

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '') {
      apiDefinitions
        .getAllCategories(userID, currentRouteString, paginationModel.page, paginationModel.pageSize)
        .then(res => {
          if (res.data.code === 200) {
            // console.log(res)
            setRows(res.data.data.data)
            setRecordCount(res.data.data.count)
          } else {
            console.log('error')
            toast.error(`Error: ${res.data.message}`)
            setRows([])
            setRecordCount(0)
          }
        })
        .catch(err => {
          console.log(err)
          toast.error('Error fetching Items List!')
          setRows([])
          setRecordCount(0)
        })
    }
  }, [currentRouteString, paginationModel, userID, tableRefresh])

  const handleDelete = id => {
    Swal.fire({
      title: 'Do you want to delete this category?',
      showDenyButton: true,
      confirmButtonText: 'Accept',
      denyButtonText: 'Cancel',
      customClass: {
        popup: 'swal2-popup'
      }
    }).then(result => {
      if (result.isConfirmed) {
        if (userID !== '' && currentRouteString !== '') {
          apiDefinitions
            .putCategoryDelete(userID, currentRouteString, id)
            .then(res => {
              if (res.data.code === 200) {
                toast.success('Category Deleted Successfully')
                setTableRefresh(!tableRefresh)
              } else {
                console.log('error')
                toast.error(`Error: ${res.data.message}`)
              }
            })
            .catch(err => {
              console.log(err)
              toast.error('Error Deleting Category!')
            })
        }
      } else if (result.isDenied) {
        Swal.fire('Category Delete Canceled', '', 'info')
      }
    })
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2 }}>
            <Typography variant='h5'>CATEGORIES</Typography>
            <Button
              variant='contained'
              onClick={handleAddCategoryFormOpen}
              startIcon={<Icon icon='material-symbols-light:add' />}
            >
              Create Category
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ boxShadow: 2 }}>
            <Box
              sx={{
                height: 395,
                width: '100%',
                '& .actions': {
                  color: 'text.secondary'
                },
                '& .textPrimary': {
                  color: 'text.primary'
                }
              }}
            >
              <DataGrid
                getRowHeight={() => 'auto'}
                rows={rows}
                rowCount={recordCount}
                columns={columns}
                paginationMode='server'
                pageSizeOptions={[5, 10, 25, 50, 100]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                disableRowSelectionOnClick
              />
            </Box>
          </Card>
        </Grid>
      </Grid>

      <AddCategoryForm
        addCategoryFormOpen={addCategoryFormOpen}
        onCloseCategoryForm={handleAddCategoryFormClose}
        onFormSubmit={handleFormSubmit}
      />
    </>
  )
}

export default Category
