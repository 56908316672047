import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import * as React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

function createData(rowName, orderLines, additionalCosts, total) {
  return { rowName, orderLines, additionalCosts, total }
}

const OrderValueSummary = ({ summaryValues }) => {
  const [rows, setRows] = useState([])

  useEffect(() => {
    const { lineTotal, lineTax, lineWithoutTax, additionalTotal, additionalTax, additionalWithoutTax } = summaryValues

    setRows([
      createData('Before Tax', lineWithoutTax, additionalWithoutTax, lineWithoutTax + additionalWithoutTax),
      createData('Tax', lineTax, additionalTax, lineTax + additionalTax),
      createData('Total', lineTotal, additionalTotal, lineTotal + additionalTotal)
    ])
  }, [summaryValues])

  return (
    <Table size='small' aria-label='a dense table'>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell align='right'>Order Lines</TableCell>
          <TableCell align='right'>Additional Costs</TableCell>
          <TableCell align='right'>Total</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map(row => (
          <TableRow key={row.rowName} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component='th' scope='row' align='right'>
              {row.rowName === 'Total' ? <strong>{row.rowName}</strong> : row.rowName}
            </TableCell>
            <TableCell align='right'>{Number(row.orderLines).toFixed(2)}</TableCell>
            <TableCell align='right'>{Number(row.additionalCosts).toFixed(2)}</TableCell>
            <TableCell align='right'>{Number(row.total).toFixed(2)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default OrderValueSummary
