import { Icon } from '@iconify/react'
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import toast from 'react-hot-toast'
import apiDefinitions from '../../api/apiDefinitions'
import { useCurrentRoute, useUserID, useUserItemRole } from '../../hooks/customHooks'

import { styled } from '@mui/material/styles'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Collapse from '@mui/material/Collapse'
import PropTypes from 'prop-types'

import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Switch from '@mui/material/Switch'

import AssignHistory from './AssignHistory'

import Pagination from '@mui/material/Pagination'
import Swal from 'sweetalert2'

const CustomCloseButton = styled(IconButton)(({ theme }) => ({
  top: 0,
  right: 0,
  color: 'grey.500',
  position: 'absolute',
  boxShadow: theme.shadows[2],
  transform: 'translate(10px, -10px)',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: `${theme.palette.background.paper} !important`,
  transition: 'transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out',
  '&:hover': {
    transform: 'translate(7px, -5px)'
  }
}))

// function createData(name, assignedQuantity, assignedDate, action, subRows) {
//   return {
//     name,
//     assignedQuantity,
//     assignedDate,
//     action,
//     subRows
//   }
// }

// const rows2 = [
//   createData('Parent 1', 10, '2024-03-04', '', [
//     { user: 'User 1', assignedQuantity: 5, assignedDate: '2024-03-04' },
//     { user: 'User 2', assignedQuantity: 5, assignedDate: '2024-03-04' }
//   ]),
//   createData('Parent 2', 15, '2024-03-04', '', [
//     { user: 'User 3', assignedQuantity: 10, assignedDate: '2024-03-04' },
//     { user: 'User 4', assignedQuantity: 5, assignedDate: '2024-03-04' }
//   ])
// ]

// const rows = [
//   {
//     user: 'User 1 Sandupa Abeywardana',
//     assinged_qty: 5,
//     assinged_date: '2021-10-10'
//   },
//   {
//     user: 'User 2',
//     assinged_qty: 3,
//     assinged_date: '2021-10-10'
//   },
//   {
//     user: 'User 3',
//     assinged_qty: 200,
//     assinged_date: '2021-10-10',
//     subRows: [
//       {
//         user: 'User 3.1',
//         assinged_qty: 50,
//         assinged_date: '2021-10-10'
//       },
//       {
//         user: 'User 3.2',
//         assinged_qty: 50,
//         assinged_date: '2021-10-10'
//       }
//     ]
//   }
// ]

const AssignedItems = () => {
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''
  const role = useUserItemRole() ?? ''

  const [userRows, setUserRows] = useState([])
  const [userPaginationModel, setUserPaginationModel] = useState({ page: 0, pageSize: 5 })
  const [userRecordCount, setUserRecordCount] = useState(0)
  const [tableRefresh, setTableRefresh] = useState(false)

  const [headRows, setHeadRows] = useState([])
  const [headPaginationModel, setHeadPaginationModel] = useState({ page: 0, pageSize: 5 })
  const [headRecordCount, setHeadRecordCount] = useState(0)

  const navigate = useNavigate()

  const [openUnassignDialog, setOpenUnassignDialog] = useState(false)
  const [openViewAssignmentDialog, setOpenViewAssignmentDialog] = useState(false)
  const [assignHistoryOpen, setAssignHistoryOpen] = useState(false)
  const [openUnassignRequestDialog, setOpenUnassignRequestDialog] = useState(false)

  const [adminChecked, setAdminChecked] = useState(false)

  const [selfUnassignDetails, setSelfUnassignDetails] = useState([])
  const [unassignRequestDetails, setUnassignRequestDetails] = useState([])

  const [selfUnassignQuantityError, setSelfUnassignQuantityError] = useState('')
  const [selfUnassignReasonError, setSelfUnassignReasonError] = useState('')

  const [openedAssignmentID, setOpenedAssignmentID] = useState('')
  const [openedAssignmentType, setOpenedAssignmentType] = useState('')
  const [openedAssignmentDetailID, setOpenedAssignmentDetailID] = useState('')
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const handlePageChange = (event, value) => {
    setPage(value)
  }

  const handleAdminChecked = event => {
    setAdminChecked(event.target.checked)
  }

  const [assignHistoryLog, setAssignHistoryLog] = useState([
    {
      from_user: 'sandupa@kiu.ac.lk',
      to_user: 'shadhir@kiu.ac.lk',
      assigned_qty: 50,
      assigned_date: '2022-05-25',
      from_level: 'Level 1',
      to_level: 'Level 2',
      assignment_type: 'Assignment'
    }
  ])

  const [assignmentDetailsDH, setAssignmentDetailsDH] = useState([])

  const [assignmentDetailsAD, setAssignmentDetailsAD] = useState([])

  const handleFormSubmit = () => {
    // console.log('Form Submitted!')
    setTableRefresh(!tableRefresh)
  }

  const handleSelfUnassign = () => {
    let hasError = false

    if (
      selfUnassignDetails?.unassignment_quantity?.trim() === undefined ||
      selfUnassignDetails?.unassignment_quantity === ''
    ) {
      setSelfUnassignQuantityError('Quantity cannot be empty')
      hasError = true
    } else {
      setSelfUnassignQuantityError('')
    }

    if (
      selfUnassignDetails?.unassignment_reason?.trim() === undefined ||
      selfUnassignDetails?.unassignment_reason === ''
    ) {
      setSelfUnassignReasonError('Reason cannot be empty')
      hasError = true
    } else {
      setSelfUnassignReasonError('')
    }

    console.log('Has Error:', hasError)

    if (hasError) return
    Swal.fire({
      title: 'Are you sure you want unassign this item?',
      showCancelButton: true,
      confirmButtonText: 'Unassign',
      cancelButtonText: 'Cancel',
      customClass: {
        popup: 'swal2-popup'
      }
    }).then(result => {
      if (result.isConfirmed) {
        const payload = {
          item_assign_id: selfUnassignDetails?.id,
          assign_quantity: selfUnassignDetails?.unassignment_quantity,
          reason: selfUnassignDetails?.unassignment_reason
        }

        console.log('Payload:', payload)

        apiDefinitions
          .putRequestSelfUnAssign(userID, currentRouteString, payload)
          .then(res => {
            if (res.data.code === 200) {
              toast.success('Successfully Unassigned')
              handleFormSubmit()
              handleCloseUnassignDialog()
            } else {
              throw new Error(res.data.message)
            }
          })
          .catch(err => {
            console.log(err)
            toast.error('Error Unassigning Item!')
          })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled Unassigning Item', '', 'info')
      }
    })
  }

  function Row(props) {
    const { row } = props
    const [open, setOpen] = useState(false)

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell
            sx={{
              p: 0
            }}
          >
            <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component='th' scope='row'>
            <Typography
              variant='body2'
              sx={{
                fontSize: '0.75rem'
              }}
            >
              {row.name}
            </Typography>
          </TableCell>
          <TableCell align='center'>
            <Typography
              variant='body2'
              sx={{
                fontSize: '0.75rem'
              }}
            >
              {row.assignedQuantity}
            </Typography>
          </TableCell>
          <TableCell align='center'>
            <Typography
              variant='body2'
              sx={{
                fontSize: '0.75rem'
              }}
            >
              {row.assignedDate}
            </Typography>
          </TableCell>
          <TableCell align='center'>
            <Button variant='outlined' onClick={handleOpenUnassignRequestDialog}>
              <Typography
                variant='body2'
                sx={{
                  fontSize: '0.6rem'
                }}
              >
                Request Unassignment
              </Typography>
            </Button>
          </TableCell>
        </TableRow>
        {row.subRows && (
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout='auto' unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Typography variant='body1' gutterBottom component='div' sx={{ fontWeight: 500 }}>
                    Sub Assignments
                  </Typography>
                  <Table size='small' aria-label='sub-rows'>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant='body2'
                            sx={{
                              fontSize: '0.75rem'
                            }}
                          >
                            User
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: 'center'
                          }}
                        >
                          <Typography
                            variant='body2'
                            sx={{
                              fontSize: '0.75rem'
                            }}
                          >
                            Assigned Quantity
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: 'center'
                          }}
                        >
                          <Typography
                            variant='body2'
                            sx={{
                              fontSize: '0.75rem'
                            }}
                          >
                            Assigned Date
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: 'center'
                          }}
                        >
                          <Typography
                            variant='body2'
                            sx={{
                              fontSize: '0.75rem'
                            }}
                          >
                            Actions
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.subRows.map(subRow => (
                        <TableRow key={subRow.user}>
                          <TableCell component='th' scope='row'>
                            <Typography
                              variant='body2'
                              sx={{
                                fontSize: '0.75rem'
                              }}
                            >
                              {subRow.user}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: 'center'
                            }}
                          >
                            <Typography
                              variant='body2'
                              sx={{
                                fontSize: '0.75rem'
                              }}
                            >
                              {subRow.assignedQuantity}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: 'center'
                            }}
                          >
                            <Typography
                              variant='body2'
                              sx={{
                                fontSize: '0.75rem'
                              }}
                            >
                              {subRow.assignedDate}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: 'center'
                            }}
                          >
                            <IconButton color='primary' onClick={handleOpenUnassignRequestDialog}>
                              <Icon icon='icons8:remove-user' style={{ fontSize: '20px' }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    )
  }

  Row.propTypes = {
    row: PropTypes.shape({
      assignedQuantity: PropTypes.number.isRequired,
      assignedDate: PropTypes.string.isRequired,
      action: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      subRows: PropTypes.arrayOf(
        PropTypes.shape({
          user: PropTypes.string.isRequired,
          assignedQuantity: PropTypes.number.isRequired,
          assignedDate: PropTypes.string.isRequired
        })
      )
    }).isRequired
  }

  const handleCloseUnassignDialog = () => {
    setOpenUnassignDialog(false)
  }

  const handleOpenUnassignDialog = rowData => {
    if (role === 'item-admin' || role === 'item-department-head') {
      if (rowData.remaining_quantity === 0) {
        toast.error('No remaining quantity to unassign')
        return
      }
    } else if (role === 'item-user') {
      if (rowData.assign_quantity === 0) {
        toast.error('No assigned quantity to unassign')
        return
      }
    }
    setSelfUnassignDetails(rowData)
    setOpenUnassignDialog(true)
  }

  const handleCloseViewAssignmentDialog = () => {
    setOpenedAssignmentID('')
    setOpenViewAssignmentDialog(false)
    setPage(1)
  }

  const handleOpenViewAssignmentDialog = (id, type, detailID) => {
    setOpenedAssignmentID(id)
    setOpenedAssignmentType(type)
    setOpenedAssignmentDetailID(detailID)
    setPage(1)
    // setOpenViewAssignmentDialog(true)

    console.log('View Assignment:', id, type, detailID)
  }

  const handleOpenAssignHistory = () => {
    setAssignHistoryOpen(true)
  }

  const handleCloseAssignHistory = () => {
    setAssignHistoryOpen(false)
  }

  const handleCloseUnassignRequestDialog = () => {
    setOpenUnassignRequestDialog(false)
  }

  const handleOpenUnassignRequestDialog = row => {
    if (row) {
      console.log(row)
      setUnassignRequestDetails(row)
      setOpenUnassignRequestDialog(true)
    }
  }

  const handleAssignItem = () => {
    navigate('/profile/assignitem')
  }

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '') {
      apiDefinitions
        .getAssignedItemByType(
          'user',
          userID,
          currentRouteString,
          userPaginationModel.page,
          userPaginationModel.pageSize,
          ''
        )
        .then(res => {
          if (res.data.code === 200) {
            setUserRows(res.data.data.data.user_assigned_items)
            setUserRecordCount(res.data.data.count)
          } else {
            console.log('error')
            toast.error(`Error: ${res.data.message}`)
            setUserRows([])
            setUserRecordCount(0)
          }
        })
        .catch(err => {
          console.log(err)
          toast.error('Error fetching Items List!')
          setUserRows([])
          setUserRecordCount(0)
        })
    }
  }, [currentRouteString, userPaginationModel, userID, tableRefresh])

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '' && (role === 'item-department-head' || role === 'item-admin')) {
      apiDefinitions
        .getAssignedItemByType(
          'department',
          userID,
          currentRouteString,
          headPaginationModel.page,
          headPaginationModel.pageSize,
          ''
        )
        .then(res => {
          if (res.data.code === 200) {
            setHeadRows(res.data.data.data.department_assigned_items)
            setHeadRecordCount(res.data.data.count)
          } else {
            console.log('error')
            toast.error(`Error: ${res.data.message}`)
            setHeadRows([])
            setHeadRecordCount(0)
          }
        })
        .catch(err => {
          console.log(err)
          toast.error('Error fetching Items List!')
          setHeadRows([])
          setHeadRecordCount(0)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRouteString, headPaginationModel, userID, tableRefresh])

  const columns = [
    {
      field: 'id',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            fontWeight: '500',
            whiteSpace: 'pre-line',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineHeight: '1em',
            py: 1,
            textAlign: 'justify',
            letterSpacing: 0
          }}
        >
          Assignment ID
        </Typography>
      ),
      minWidth: 130
    },
    {
      field: 'item_detail_code',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            fontWeight: '500',
            whiteSpace: 'pre-line',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineHeight: '1em',
            py: 1,
            textAlign: 'justify',
            letterSpacing: 0
          }}
        >
          Item Detail
        </Typography>
      ),
      minWidth: 130,
      flex: 0.1,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.item_detail_code || 'N/A'}</Typography>
      }
    },
    {
      field: 'item_name',
      headerName: 'Item Name',
      valueGetter: params => params.row.item_detail.items.short_name,
      minWidth: 100,
      flex: 0.15,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.item_detail.items.short_name || 'N/A'}</Typography>
      }
    },
    {
      field: 'category_name',
      headerName: 'Category Name',
      valueGetter: params => params.row.item_detail.items.category.category_name,
      minWidth: 100,
      flex: 0.1,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.item_detail.items.category.category_name || 'N/A'}</Typography>
      }
    },
    {
      field: 'qty',
      headerName: 'QTY',
      valueGetter: params => params.row.remaining_quantity,
      headerAlign: 'center',
      align: 'center',
      minWidth: 80,
      flex: 0.1,
      renderCell: params => {
        return role === 'item-admin' ? (
          <Typography variant='body2'>
            {params.row.remaining_quantity || '0'} / {params.row.assign_quantity || '0'}
          </Typography>
        ) : (
          <Typography variant='body2'>{params.row.remaining_quantity || '0'}</Typography>
        )
      }
    },
    ...(role === 'item-admin'
      ? [
          {
            field: 'assigned_to',
            headerName: 'Assigned To',
            sortable: false,
            filterable: false,
            minWidth: 100,
            flex: 0.15,
            renderCell: params => {
              return (
                <Button
                  variant='outlined'
                  sx={{
                    my: 1.5,
                    fontSize: '0.7rem'
                  }}
                  onClick={e => handleOpenViewAssignmentDialog(params.row.id, 'DeptHead', params.row.item_detail_id)}
                >
                  View Assignments
                </Button>
              )
            }
          }
        ]
      : []),
    ...(role === 'item-admin'
      ? []
      : [
          {
            field: 'date',
            headerName: (
              <Typography
                variant='body2'
                sx={{
                  fontWeight: '500',
                  whiteSpace: 'pre-line',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  lineHeight: '1em',
                  py: 1,
                  textAlign: 'center',
                  letterSpacing: 0
                }}
              >
                Assigned Date
              </Typography>
            ),
            minWidth: 80,
            align: 'center',
            flex: 0.1,
            renderCell: params => (
              <Typography variant='body2'>{new Date(params.row.date).toLocaleDateString() || 'N/A'}</Typography>
            )
          }
        ]),
    ...(role === 'item-admin'
      ? []
      : [
          {
            field: 'assigned_by',
            headerName: 'Assigned By',
            valueGetter: params => params.row.assigned_by.user_name,
            minWidth: 120,
            flex: 0.15,
            renderCell: params => <Typography variant='body2'>{params.row.assigned_by.user_name || 'N/A'}</Typography>
          }
        ]),
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      minWidth: 150,
      flex: 0.1,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return (
          <Box
            sx={{
              px: 2,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {role !== 'item-admin' && (
              <IconButton color='primary' onClick={e => handleOpenUnassignDialog(params.row)}>
                <Icon icon='icons8:remove-user' style={{ fontSize: '25px' }} />
              </IconButton>
            )}
            {role === 'item-admin' ? (
              <IconButton color='primary' onClick={handleOpenAssignHistory}>
                <Icon icon='ic:sharp-update' style={{ fontSize: '25px' }} />
              </IconButton>
            ) : null}
          </Box>
        )
      }
    }
  ]

  const columns2 = [
    {
      field: 'id',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            fontWeight: '500',
            whiteSpace: 'pre-line',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineHeight: '1em',
            py: 1,
            textAlign: 'justify',
            letterSpacing: 0
          }}
        >
          Assignment ID
        </Typography>
      ),
      minWidth: 130
    },
    {
      field: 'item_detail_code',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            fontWeight: '500',
            whiteSpace: 'pre-line',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineHeight: '1em',
            py: 1,
            textAlign: 'justify',
            letterSpacing: 0
          }}
        >
          Item Detail
        </Typography>
      ),
      minWidth: 130,
      flex: 0.1,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.item_detail_code || 'N/A'}</Typography>
      }
    },
    {
      field: 'item_name',
      headerName: 'Item Name',
      valueGetter: params => params.row.item_detail.items.short_name,
      minWidth: 100,
      flex: 0.15,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.item_detail.items.short_name || 'N/A'}</Typography>
      }
    },
    {
      field: 'category_name',
      headerName: 'Category Name',
      valueGetter: params => params.row.item_detail.items.category.category_name,
      minWidth: 100,
      flex: 0.1,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.item_detail.items.category.category_name || 'N/A'}</Typography>
      }
    },
    {
      field: 'qty',
      headerName: 'QTY',
      valueGetter: params => params.row.remaining_quantity,
      headerAlign: 'center',
      align: 'center',
      minWidth: 80,
      flex: 0.1,
      renderCell: params => {
        return role === 'item-admin' || role === 'item-department-head' ? (
          <Typography variant='body2'>
            {params.row.remaining_quantity || '0'} / {params.row.assign_quantity || '0'}
          </Typography>
        ) : (
          <Typography variant='body2'>{params.row.remaining_quantity || '0'}</Typography>
        )
      }
    },
    ...(role === 'item-admin' || role === 'item-department-head'
      ? [
          {
            field: 'assigned_to',
            headerName: 'Assigned To',
            minWidth: 100,
            flex: 0.15,
            sortable: false,
            filterable: false,
            renderCell: params => {
              return (
                <Button
                  variant='outlined'
                  sx={{
                    my: 1.5,
                    fontSize: '0.7rem'
                  }}
                  onClick={e => handleOpenViewAssignmentDialog(params.row.id, 'Admin', params.row.item_detail_id)}
                >
                  View Assignments
                </Button>
              )
            }
          }
        ]
      : []),
    ...(role === 'item-admin'
      ? []
      : [
          {
            field: 'date',
            headerName: (
              <Typography
                variant='body2'
                sx={{
                  fontWeight: '500',
                  whiteSpace: 'pre-line',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  lineHeight: '1em',
                  py: 1,
                  textAlign: 'center',
                  letterSpacing: 0
                }}
              >
                Assigned Date
              </Typography>
            ),
            headerAlign: 'center',
            minWidth: 80,
            align: 'center',
            flex: 0.1,
            renderCell: params => (
              <Typography variant='body2'>{new Date(params.row.date).toLocaleDateString() || 'N/A'}</Typography>
            )
          }
        ]),
    ...(role === 'item-admin'
      ? []
      : [
          {
            field: 'assigned_by',
            headerName: 'Assigned By',
            valueGetter: params => params.row.assigned_by.user_name,
            minWidth: 120,
            flex: 0.15,
            renderCell: params => <Typography variant='body2'>{params.row.assigned_by.user_name || 'N/A'}</Typography>
          }
        ]),
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      minWidth: 150,
      flex: 0.1,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return (
          <Box
            sx={{
              px: 2,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {role !== 'item-admin' && (
              <IconButton color='primary' onClick={e => handleOpenUnassignDialog(params.row)}>
                <Icon icon='icons8:remove-user' style={{ fontSize: '25px' }} />
              </IconButton>
            )}
            {role === 'item-admin' || role === 'item-department-head' ? (
              <IconButton color='primary' onClick={handleOpenAssignHistory}>
                <Icon icon='ic:sharp-update' style={{ fontSize: '25px' }} />
              </IconButton>
            ) : null}
          </Box>
        )
      }
    }
  ]

  useEffect(() => {
    const pageNumber = Number(page) - 1
    if (openedAssignmentID !== '' && openedAssignmentType === 'DeptHead') {
      apiDefinitions
        .getSubAssingmentsByAssingmentID(openedAssignmentID, userID, currentRouteString, pageNumber, 3)
        .then(res => {
          if (res.data.code === 200) {
            console.log(res.data.data.data)
            const data = res.data.data.data.map(item => {
              return {
                assignmentID: item.item_assign_details?.id,
                assignmentLevel: `Level ${item.item_assign_details?.level}`,
                user: item.user?.name,
                assinged_qty: item.assigned_quantity,
                assinged_date: item.assigned_date ? new Date(item.assigned_date).toLocaleDateString() : 'N/A'
              }
            })
            setAssignmentDetailsDH(data)
            setPageCount(Math.ceil(res.data.data.count / 3))

            setOpenViewAssignmentDialog(true)
          } else {
            throw new Error(res.data.message)
          }
        })
        .catch(err => {
          console.log(err)
          toast.error('Error fetching Sub Assignments List!')
          setAssignmentDetailsDH([])
          setOpenedAssignmentID('')
          setPageCount(1)
        })
    }

    if (openedAssignmentID !== '' && openedAssignmentType === 'Admin') {
      apiDefinitions
        .getAdminLevelAssignments(userID, currentRouteString, pageNumber, 3, 'admin', openedAssignmentDetailID)
        .then(res => {
          if (res.data.code === 200) {
            const response = res.data.data.data

            if (response.length === 0) {
              throw new Error('No Sub Assignments Found!')
            }

            const assignments = response.map(item => {
              return {
                name: item.name,
                assignedQuantity: item.assigned_qty,
                assignedDate: item.assigned_date,
                action: '',
                subRows: [
                  { user: 'User 1', assignedQuantity: 5, assignedDate: '2024-03-04' },
                  { user: 'User 2', assignedQuantity: 5, assignedDate: '2024-03-04' }
                ]
              }
            })

            setAssignmentDetailsAD(assignments)
            setOpenViewAssignmentDialog(true)
          } else {
            throw new Error(res.data.message)
          }
        })
        .catch(err => {
          console.log('error', err)
          toast.error(`Error: ${err.message}`)
          setAssignmentDetailsAD([])
          setOpenedAssignmentID('')
          setPageCount(1)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedAssignmentID, page, tableRefresh])

  return (
    <>
      <Grid container spacing={2}>
        {role !== 'item-admin' && (
          <>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h6'>Assigned Items</Typography>
                {role === 'item-admin' ? (
                  <Button
                    variant='contained'
                    startIcon={<Icon icon='material-symbols-light:add' />}
                    onClick={handleAssignItem}
                  >
                    Assign Item
                  </Button>
                ) : null}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ boxShadow: 2 }}>
                <Box
                  sx={{
                    height: 320,
                    width: '100%',
                    '& .actions': {
                      color: 'text.secondary'
                    },
                    '& .textPrimary': {
                      color: 'text.primary'
                    }
                  }}
                >
                  <DataGrid
                    getRowHeight={() => 'auto'}
                    rows={userRows}
                    rowCount={userRecordCount}
                    columns={columns}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    paginationMode='server'
                    paginationModel={userPaginationModel}
                    onPaginationModelChange={setUserPaginationModel}
                    disableRowSelectionOnClick
                  />
                </Box>
              </Card>
            </Grid>
          </>
        )}
        {(role === 'item-department-head' || role === 'item-admin') && (
          <>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 3 }}>
                <Typography variant='h6'>Department Assigned Items</Typography>
                <Button
                  variant='contained'
                  startIcon={<Icon icon='material-symbols-light:add' />}
                  onClick={handleAssignItem}
                >
                  Assign Item
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ boxShadow: 2 }}>
                <Box
                  sx={{
                    height: 392,
                    width: '100%',
                    '& .actions': {
                      color: 'text.secondary'
                    },
                    '& .textPrimary': {
                      color: 'text.primary'
                    }
                  }}
                >
                  <DataGrid
                    getRowHeight={() => 'auto'}
                    rows={headRows}
                    rowCount={headRecordCount}
                    columns={columns2}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    paginationMode='server'
                    paginationModel={headPaginationModel}
                    onPaginationModelChange={setHeadPaginationModel}
                    disableRowSelectionOnClick
                  />
                </Box>
              </Card>
            </Grid>
          </>
        )}
      </Grid>

      <Dialog
        open={openViewAssignmentDialog}
        onClose={handleCloseViewAssignmentDialog}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
        scroll='paper'
      >
        <DialogTitle variant='h5'>
          Assignment Details
          <CustomCloseButton aria-label='close' onClick={handleCloseViewAssignmentDialog}>
            <Icon icon='tabler:x' fontSize='1.25rem' />
          </CustomCloseButton>
        </DialogTitle>
        <DialogContent>
          {role === 'item-department-head' && (
            <Table aria-label='sticky table' sx={{ borderCollapse: 'separate' }} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '45%', textAlign: 'center' }}>User</TableCell>
                  <TableCell style={{ width: '25%', textAlign: 'center' }}>Assigned Quantity</TableCell>
                  <TableCell style={{ width: '25%', textAlign: 'center' }}>Assigned Date</TableCell>
                  <TableCell style={{ width: '5%', textAlign: 'center' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assignmentDetailsDH.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.user}</TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center'
                      }}
                    >
                      {row.assinged_qty}
                    </TableCell>
                    <TableCell>{row.assinged_date}</TableCell>
                    <TableCell>
                      <Button variant='outlined' onClick={e => handleOpenUnassignRequestDialog(row)}>
                        <Typography
                          variant='body2'
                          sx={{
                            fontSize: '0.75rem'
                          }}
                        >
                          Request Unassignment
                        </Typography>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}

          {role === 'item-admin' && (
            <Table aria-label='collapsible table'>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      p: 0
                    }}
                  />
                  <TableCell>
                    <Typography
                      variant='body2'
                      sx={{
                        fontSize: '0.75rem'
                      }}
                    >
                      Name
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography
                      variant='body2'
                      sx={{
                        fontSize: '0.75rem'
                      }}
                    >
                      Assigned Quantity
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography
                      variant='body2'
                      sx={{
                        fontSize: '0.75rem'
                      }}
                    >
                      Assigned Date
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography
                      variant='body2'
                      sx={{
                        fontSize: '0.75rem'
                      }}
                    >
                      Action
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assignmentDetailsAD.map(row => (
                  <Row key={row.name} row={row} />
                ))}
              </TableBody>
            </Table>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 2
            }}
          >
            <Pagination count={pageCount} page={page} onChange={handlePageChange} size='small' />
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openUnassignDialog}
        onClose={handleCloseUnassignDialog}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
        scroll='paper'
      >
        <DialogTitle variant='h5'>
          Self Unassign Item | {selfUnassignDetails?.id || ''}
          <CustomCloseButton aria-label='close' onClick={handleCloseUnassignDialog}>
            <Icon icon='tabler:x' fontSize='1.25rem' />
          </CustomCloseButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ pt: 1 }}>
            <Grid item xs={3.5}>
              <TextField
                id='outlined-basic'
                label='Item Detail ID'
                variant='outlined'
                value={selfUnassignDetails?.item_detail_code || ''}
                fullWidth
                disabled
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={4.5}>
              <TextField
                id='outlined-basic'
                label='Item Name'
                variant='outlined'
                value={selfUnassignDetails?.item_detail?.items?.short_name || ''}
                fullWidth
                disabled
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id='outlined-basic'
                label='Unassignment Quantity'
                variant='outlined'
                value={selfUnassignDetails?.unassignment_quantity || ''}
                onChange={e => {
                  // const cleanedEntry = e.target.value.replace(/[^0-9]/g, '')
                  const cleanedEntry = e.target.value

                  if (
                    role === 'item-admin' || role === 'item-department-head'
                      ? cleanedEntry > selfUnassignDetails?.remaining_quantity &&
                        cleanedEntry !== '' &&
                        cleanedEntry !== 0
                      : cleanedEntry > selfUnassignDetails?.assign_quantity && cleanedEntry !== '' && cleanedEntry !== 0
                  ) {
                    toast.error('Unassignment Quantity should be less than or equal to Assigned Quantity')
                  } else {
                    setSelfUnassignQuantityError('')
                    setSelfUnassignDetails({ ...selfUnassignDetails, unassignment_quantity: cleanedEntry })
                  }
                }}
                error={!!selfUnassignQuantityError}
                helperText={selfUnassignQuantityError}
                fullWidth
                type='number'
                onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                inputProps={{
                  min: 0,
                  max: 5
                }}
                InputProps={{
                  endAdornment:
                    role === 'item-admin' || role === 'item-department-head' ? (
                      <Typography>/{selfUnassignDetails?.remaining_quantity || '0'}</Typography>
                    ) : (
                      <Typography>/{selfUnassignDetails?.assign_quantity || '0'}</Typography>
                    )
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='outlined-basic'
                label='Reason for Unassignment'
                variant='outlined'
                value={selfUnassignDetails?.unassignment_reason || ''}
                onChange={e => {
                  setSelfUnassignDetails({ ...selfUnassignDetails, unassignment_reason: e.target.value })
                  setSelfUnassignReasonError('')
                }}
                error={!!selfUnassignReasonError}
                helperText={selfUnassignReasonError}
                fullWidth
                multiline
                rows={3}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ m: 2, mt: 0, gap: 2 }}>
          <Button variant='contained' sx={{ px: 4 }} onClick={handleSelfUnassign}>
            Request Unassignment
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openUnassignRequestDialog}
        onClose={handleCloseUnassignRequestDialog}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
        scroll='paper'
      >
        <DialogTitle variant='h5'>
          Request Item Unassignment
          <CustomCloseButton aria-label='close' onClick={handleCloseUnassignRequestDialog}>
            <Icon icon='tabler:x' fontSize='1.25rem' />
          </CustomCloseButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ pt: 1 }}>
            <Grid item xs={4}>
              <TextField
                id='outlined-basic'
                label='Assignment ID'
                value={unassignRequestDetails?.assignmentID || ''}
                variant='outlined'
                fullWidth
                disabled
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                id='outlined-basic'
                label='Assigned To'
                value={unassignRequestDetails?.user || ''}
                variant='outlined'
                fullWidth
                disabled
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='outlined-basic'
                label='Assignment Level'
                value={unassignRequestDetails?.assignmentLevel || ''}
                variant='outlined'
                fullWidth
                disabled
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                id='outlined-basic'
                label='Unassignment Quantity'
                variant='outlined'
                value={unassignRequestDetails?.unassignment_quantity}
                onChange={e => {
                  // const cleanedEntry = e.target.value.replace(/[^0-9]/g, '')
                  const cleanedEntry = e.target.value

                  if (
                    cleanedEntry > unassignRequestDetails?.assinged_qty &&
                    cleanedEntry !== '' &&
                    cleanedEntry !== 0
                  ) {
                    toast.error('Unassignment Quantity should be less than or equal to Assigned Quantity')
                  } else {
                    setUnassignRequestDetails({ ...unassignRequestDetails, unassignment_quantity: cleanedEntry })
                  }
                }}
                fullWidth
                type='number'
                onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                inputProps={{
                  min: 0,
                  max: unassignRequestDetails?.assinged_qty
                }}
                InputProps={{
                  endAdornment: <Typography>/{unassignRequestDetails?.assinged_qty}</Typography>
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ m: 2, mt: 0, display: 'flex', justifyContent: role === 'item-admin' ? 'space-between' : 'flex-end' }}
        >
          {/* TODO: Do a access control based on Assignment Level where level is 3 only */}
          {role === 'item-admin' && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={adminChecked}
                    onChange={handleAdminChecked}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label='Unassign All Levels'
              />
            </FormGroup>
          )}
          <Button variant='contained' sx={{ px: 4 }}>
            Request Unassignment
          </Button>
        </DialogActions>
      </Dialog>

      <AssignHistory openLogHistory={assignHistoryOpen} onCloseLogHistory={handleCloseAssignHistory} />
    </>
  )
}

export default AssignedItems
