import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import { DataGrid } from '@mui/x-data-grid'
import { useCurrentQueryParams, useCurrentRoute, useUserID, useUserItemRole } from '../../../../../hooks/customHooks'
import apiDefinitions from '../../../../../api/apiDefinitions'
import Swal from 'sweetalert2'

const CustomCloseButton = styled(IconButton)(({ theme }) => ({
  top: 0,
  right: 0,
  color: 'grey.500',
  position: 'absolute',
  boxShadow: theme.shadows[2],
  transform: 'translate(10px, -10px)',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: `${theme.palette.background.paper} !important`,
  transition: 'transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out',
  '&:hover': {
    transform: 'translate(7px, -5px)'
  }
}))

function getFileType(url) {
  if (!url) return null

  const extension = url.toLowerCase().split('.').pop()

  if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif') {
    return 'image'
  } else if (extension === 'pdf') {
    return 'pdf'
  } else {
    return 'document'
  }
}

const RequestQuotations = ({ refreshQuotations }) => {
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''
  const poReqID = useCurrentQueryParams('id') ?? ''
  const role = useUserItemRole() ?? ''

  const [rows, setRows] = useState([])
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 5 })
  const [recordCount, setRecordCount] = useState(0)

  const [quotationDialog, setQuotationDialog] = useState(false)

  const [openedQuotation, setOpenedQuotation] = useState({})

  const handleQuotationDialogOpen = rowData => {
    console.log(rowData)
    setOpenedQuotation(rowData)

    setQuotationDialog(true)
  }

  const handleQuotationDialogClose = () => {
    setQuotationDialog(false)
  }

  const columns = [
    {
      field: 'quotation_code',
      headerName: (
        <Typography variant='body2' sx={{ fontWeight: 500, whiteSpace: 'pre-line', lineHeight: '1.5em' }}>
          Quotation No.
        </Typography>
      ),
      minWidth: 120,
      flex: 1,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.quotation_code || 'N/A'}</Typography>
      }
    },
    {
      field: 'vendor',
      headerName: 'Vendor',
      minWidth: 150,
      flex: 1,
      valueGetter: params => params.row.vendor.vendor_name || 'N/A',
      renderCell: params => {
        return <Typography variant='body2'>{params.row.vendor.vendor_name || 'N/A'}</Typography>
      }
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 100,
      flex: 1,
      renderCell: params => {
        return <Typography variant='body2'>{Number(params.row.amount).toFixed(2) || 'N/A'}</Typography>
      }
    },
    {
      field: 'discount',
      headerName: 'Discount',
      minWidth: 100,
      flex: 1,
      renderCell: params => {
        return <Typography variant='body2'>{Number(params.row.discount).toFixed(2) || 'N/A'}</Typography>
      }
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 200,
      flex: 2,
      renderCell: params => {
        return (
          <Box sx={{ py: 1 }}>
            <Typography
              variant='body2'
              sx={{
                whiteSpace: 'pre-line',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                lineHeight: '1.5em'
              }}
            >
              {params.row.description || 'N/A'}
            </Typography>
          </Box>
        )
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 180,
      flex: 1.5,
      valueGetter: params => params.row.status.type || 'N/A',
      renderCell: params => {
        return (
          <Chip
            label={params.row.status?.type.toUpperCase() || 'N/A'}
            color={params.row.status?.color_code || 'secondary'}
            size='small'
          />
        )
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: role === 'item-admin' ? 280 : 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      renderCell: params => {
        return (
          <Box sx={{ my: 1, display: 'flex', gap: 2 }}>
            <Button
              variant='outlined'
              color='primary'
              size='small'
              onClick={() => {
                handleQuotationDialogOpen(params.row)
              }}
            >
              View
            </Button>

            {role === 'item-admin' && (
              <>
                <Button
                  variant='outlined'
                  color='success'
                  size='small'
                  onClick={() => {
                    ApproveQuotation(params.row.id)
                  }}
                  disabled={params.row.status?.id !== 3}
                >
                  Approve
                </Button>

                <Button
                  variant='outlined'
                  color='error'
                  size='small'
                  onClick={() => {
                    RejectQuotation(params.row.id)
                  }}
                  disabled={params.row.status?.id !== 3}
                >
                  Reject
                </Button>
              </>
            )}
          </Box>
        )
      }
    }
  ]

  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    if (userID && poReqID && currentRouteString) {
      apiDefinitions
        .getAllQuotationByPORID(
          userID,
          currentRouteString,
          poReqID,
          paginationModel.page,
          paginationModel.pageSize,
          true
        )
        .then(response => {
          if (response.status === 200) {
            // setRows(response.data.data.data)

            // add file type to each row
            const updatedRows = response.data.data.data.map(row => {
              return {
                ...row,
                quotation_file: {
                  ...row.quotation_file,
                  type: getFileType(row.quotation_file?.url)
                }
              }
            })

            setRows(updatedRows)

            setRecordCount(response.data.data.total_record_count)
          } else throw new Error(response.data.message)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }, [poReqID, paginationModel, userID, currentRouteString, refresh, refreshQuotations])

  const RejectQuotation = quotationID => {
    Swal.fire({
      title: 'Reject Quotation',
      text: 'Are you sure you want to reject this quotation?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, reject it!'
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Reject Reason',
          input: 'text',
          inputLabel: 'Please Enter A Rejection Reason'
        }).then(result => {
          if (result.isConfirmed) {
            const payload = {
              quotation_id: quotationID,
              reason: result.value,
              status: 'rejected'
            }

            if (result.value === '') {
              Swal.fire('Error', 'Please enter a rejection reason', 'error')
            } else {
              apiDefinitions
                .putQuotationUpdate(userID, currentRouteString, payload)
                .then(response => {
                  if (response.status === 200) {
                    Swal.fire('Quotation Rejected', '', 'success')
                    setRefresh(!refresh)
                  } else {
                    throw new Error(response.data.message)
                  }
                })
                .catch(error => {
                  Swal.fire('Error', error.message, 'error')
                })
            }
          }
        })
      } else {
        Swal.fire('Quotation Rejection Cancelled', '', 'info')
      }
    })
  }

  const ApproveQuotation = quotationID => {
    Swal.fire({
      title: 'Approve Quotation',
      text: 'Are you sure you want to approve this quotation?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, approve it!'
    }).then(result => {
      if (result.isConfirmed) {
        const payload = {
          quotation_id: quotationID,
          status: 'approved'
        }

        apiDefinitions
          .putQuotationUpdate(userID, currentRouteString, payload)
          .then(response => {
            if (response.status === 200) {
              Swal.fire('Quotation Approved', '', 'success')
              setRefresh(!refresh)
            } else {
              throw new Error(response.data.message)
            }
          })
          .catch(error => {
            Swal.fire('Error', error.message, 'error')
          })
      } else {
        Swal.fire('Quotation Approval Cancelled', '', 'info')
      }
    })
  }

  return (
    <>
      <DataGrid
        autoHeight
        getRowHeight={() => 'auto'}
        rows={rows}
        columns={columns}
        pageSize={paginationModel.pageSize}
        rowCount={recordCount}
        pagination
        paginationMode='server'
        onPageChange={e => {
          setPaginationModel({ ...paginationModel, page: e.page })
        }}
        disableRowSelectionOnClick
      />
      <Dialog
        open={quotationDialog}
        onClose={handleQuotationDialogClose}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        scroll='paper'
        sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
      >
        <DialogTitle variant='h5'>
          Quotation | {openedQuotation?.quotation_code}
          <CustomCloseButton aria-label='close' onClick={handleQuotationDialogClose}>
            <Icon icon='tabler:x' fontSize='1.25rem' />
          </CustomCloseButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant='h6'>Review Quotation</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Typography variant='body2'>Vendor</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant='body2'>{openedQuotation?.vendor?.vendor_name || 'N/A'}</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant='body2'>Description</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant='body2'>{openedQuotation?.description || 'N/A'}</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant='body2'>Quotation Value</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant='body2'>
                                {Number(openedQuotation?.amount ? openedQuotation.amount : 0).toFixed(2)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant='body2'>Discount</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant='body2'>
                                {Number(openedQuotation?.discount ? openedQuotation.discount : 0).toFixed(2)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflowY: 'auto',
                        overflowX: 'hidden'
                      }}
                    >
                      {openedQuotation.quotation_file?.url && openedQuotation.quotation_file.type === 'image' && (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 3,
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Box
                            sx={{
                              mt: 6,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <img src={openedQuotation.quotation_file?.url} alt='quotation' width='90%' />
                          </Box>

                          <Button
                            variant='outlined'
                            color='primary'
                            size='small'
                            onClick={() => {
                              const newWindow = window.open(
                                openedQuotation.quotation_file?.url,
                                '_blank',
                                'noopener,noreferrer'
                              )

                              if (newWindow) newWindow.opener = null
                            }}
                          >
                            View File
                          </Button>
                        </Box>
                      )}
                      {openedQuotation.quotation_file?.url && openedQuotation.quotation_file?.type === 'pdf' && (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 3,
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Box
                            sx={{
                              mt: 6,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <embed src={openedQuotation.quotation_file?.url} type='application/pdf' width='90%' />
                          </Box>

                          <Button
                            variant='outlined'
                            color='primary'
                            size='small'
                            onClick={() => {
                              const newWindow = window.open(
                                openedQuotation.quotation_file?.url,
                                '_blank',
                                'noopener,noreferrer'
                              )

                              if (newWindow) newWindow.opener = null
                            }}
                          >
                            View File
                          </Button>
                        </Box>
                      )}

                      {openedQuotation.quotation_file?.url && openedQuotation.quotation_file?.type === 'document' && (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 3,
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                            px: 1,
                            minHeight: '260px'
                          }}
                        >
                          <Typography
                            variant='body2'
                            textAlign={'center'}
                            color='error'
                            sx={{
                              mt: 10
                            }}
                          >
                            File type not supported for preview. Download to view the file contents.
                          </Typography>
                          <Button
                            variant='outlined'
                            color='primary'
                            size='small'
                            onClick={() => {
                              const newWindow = window.open(
                                openedQuotation.quotation_file?.url,
                                '_blank',
                                'noopener,noreferrer'
                              )

                              if (newWindow) newWindow.opener = null
                            }}
                          >
                            View File
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h6'>Quotation Items</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Unit Price</TableCell>
                      <TableCell>Tax</TableCell>
                      <TableCell>Discount</TableCell>

                      <TableCell>Total Price</TableCell>
                      <TableCell>Remarks</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {openedQuotation?.quotation_details?.map(row => (
                      <TableRow key={row.id}>
                        <TableCell>{row.item.short_name || 'N/A'}</TableCell>
                        <TableCell>{row.available_qty || 0}</TableCell>
                        <TableCell>{Number(row.unit_price || 0).toFixed(2)}</TableCell>
                        <TableCell>{Number(row.tax || 0).toFixed(2)}</TableCell>
                        <TableCell>{Number(row.discount || 0).toFixed(2)}</TableCell>
                        <TableCell>{Number(row.total_price || 0).toFixed(2)}</TableCell>

                        <TableCell>{row.note || 'N/A'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            mx: 2,
            mb: 1
          }}
        ></DialogActions>
      </Dialog>
    </>
  )
}

export default RequestQuotations
