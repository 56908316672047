import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Grid, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import CardSection from './CardSection'
import { EditItemForm } from './EditItemForm'
import ItemDetailsTable from './ItemDetailsTable'

const ViewItem = () => {
  // const location = useLocation()

  // const queryParams = new URLSearchParams(location.search)
  // const itemId = queryParams.get('id')
  // console.log('Item ID', itemId)

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1-content' id='panel1-header'>
          <Typography variant='h5'>EDIT ITEM DETAILS</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <EditItemForm />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel2-content' id='panel2-header'>
          <Typography variant='h5'>LOT LIST</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ItemDetailsTable />
        </AccordionDetails>
      </Accordion>

      <Grid container spacing={3} sx={{ py: 1 }}>
        <CardSection />
      </Grid>
    </>
  )
}

export default ViewItem
