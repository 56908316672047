import { Icon } from '@iconify/react'
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Typography,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import apiDefinitions from '../../../api/apiDefinitions'
import { useCurrentRoute, useUserID } from '../../../hooks/customHooks'
import Swal from 'sweetalert2'

import AddOptionalField from './addOptionalField'
import UpdateOptionalField from './updateOptionalField'

const CustomCloseButton = styled(IconButton)(({ theme }) => ({
  top: 0,
  right: 0,
  color: 'grey.500',
  position: 'absolute',
  boxShadow: theme.shadows[2],
  transform: 'translate(10px, -10px)',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: `${theme.palette.background.paper} !important`,
  transition: 'transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out',
  '&:hover': {
    transform: 'translate(7px, -5px)'
  }
}))

const OptionalField = () => {
  const [rows, setRows] = useState([])
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 5 })
  const [recordCount, setRecordCount] = useState(0)
  const [tableRefresh, setTableRefresh] = useState(false)

  const [addOptionalFieldFormOpen, setAddOptionalFieldFormOpen] = useState(false)
  const [updateOptionalFieldFormOpen, setUpdateOptionalFieldFormOpen] = useState(false)
  const [updateOptionalFieldID, setUpdateOptionalFieldID] = useState('')
  const [litsItemsOpen, setListItemsOpen] = useState(false)
  const [listValues, setListValues] = useState([])

  const handleListItemsOpen = id => {
    const selectedRow = rows.find(row => row.id === id)
    if (selectedRow) {
      setListValues(selectedRow.list_values) // Set the list values for the selected row
      setListItemsOpen(true)
    }
  }

  const handleListItemsClose = () => {
    setListItemsOpen(false)
  }

  const handleAddOptionalFieldFormOpen = () => {
    setAddOptionalFieldFormOpen(true)
  }

  const handleAddOptionalFieldFormClose = () => {
    setAddOptionalFieldFormOpen(false)
  }

  const handleUpdateOptionalFieldFormOpen = id => {
    // console.log('Update Optional Field ID:', id)
    setUpdateOptionalFieldID(id)
    setUpdateOptionalFieldFormOpen(true)
  }

  const handleUpdateOptionalFieldFormClose = () => {
    setUpdateOptionalFieldFormOpen(false)
  }

  const handleFormSubmit = () => {
    // console.log('Form Submitted!')
    setTableRefresh(!tableRefresh)
  }

  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''

  const columns = [
    // {
    //   field: 'field_id',
    //   headerName: 'Field ID',
    //   minWidth: 100,
    //   flex: 1,
    //   renderCell: params => {
    //     return <Typography variant='body2'>{params.row.id || 'N/A'}</Typography>
    //   }
    // },
    {
      field: 'field_label',
      headerName: 'Field Label',
      minWidth: 100,
      flex: 1,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.field_label || 'N/A'}</Typography>
      }
    },
    {
      field: 'type',
      headerName: 'Field Type',
      minWidth: 100,
      flex: 1,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.type || 'N/A'}</Typography>
      }
    },
    {
      field: 'required',
      headerName: 'Required',
      minWidth: 100,
      flex: 1,
      renderCell: params => {
        const fieldType = params.row.required
        return <Typography variant='body2'>{fieldType !== null ? (fieldType ? 'true' : 'false') : 'N/A'}</Typography>
      }
    },
    {
      field: 'is_disable',
      headerName: 'Disabled',
      minWidth: 100,
      flex: 1,
      renderCell: params => {
        const isDisable = params.row.is_disable
        return <Typography variant='body2'>{isDisable !== null ? (isDisable ? 'true' : 'false') : 'N/A'}</Typography>
      }
    },
    {
      field: 'field_select_list',
      headerName: 'List Items',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      renderCell: params => {
        if (params.row.type !== 'select' && params.row.type !== 'multiple_select') {
          // If not, render a disabled IconButton
          return (
            <IconButton aria-label='view' color='primary' disabled>
              <Icon icon='majesticons:open-line'></Icon>
            </IconButton>
          )
        }

        // If params.row.type is 'select' or 'multiple_select', render enabled IconButton
        return (
          <IconButton aria-label='view' color='primary' onClick={() => handleListItemsOpen(params.row.id)}>
            <Icon icon='majesticons:open-line'></Icon>
          </IconButton>
        )
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      renderCell: params => {
        return (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1, gap: 2 }}>
            <IconButton
              aria-label='view'
              color='primary'
              onClick={() => handleUpdateOptionalFieldFormOpen(params.row.id)}
            >
              <Icon icon='mynaui:edit-one' />
            </IconButton>

            {/* <IconButton
              aria-label='view'
              color='primary'
              disabled={params.row.assign_status}
              // onClick={() => handleDelete(params.row.id)}
            >
              <Icon icon='fluent:delete-32-regular' />
            </IconButton> */}
          </Box>
        )
      }
    }
  ]

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '') {
      apiDefinitions
        .getAllOptionalField(userID, currentRouteString, paginationModel.page, paginationModel.pageSize)
        .then(res => {
          if (res.data.code === 200) {
            // console.log(res)
            setRows(res.data.data.data)
            setRecordCount(res.data.data.count)
          } else {
            console.log('error')
            toast.error(`Error: ${res.data.message}`)
            setRows([])
            setRecordCount(0)
          }
        })
        .catch(err => {
          console.log(err)
          toast.error('Error fetching Items List!')
          setRows([])
          setRecordCount(0)
        })
    }
  }, [currentRouteString, paginationModel, userID, tableRefresh])

  // const handleDelete = id => {
  //   Swal.fire({
  //     title: 'Do you want to delete this category?',
  //     showDenyButton: true,
  //     confirmButtonText: 'Accept',
  //     denyButtonText: 'Cancel',
  //     customClass: {
  //       popup: 'swal2-popup'
  //     }
  //   }).then(result => {
  //     if (result.isConfirmed) {
  //       if (userID !== '' && currentRouteString !== '') {
  //         apiDefinitions
  //           .putCategoryDelete(userID, currentRouteString, id)
  //           .then(res => {
  //             if (res.data.code === 200) {
  //               toast.success('Category Deleted Successfully')
  //               setTableRefresh(!tableRefresh)
  //             } else {
  //               console.log('error')
  //               toast.error(`Error: ${res.data.message}`)
  //             }
  //           })
  //           .catch(err => {
  //             console.log(err)
  //             toast.error('Error Deleting Category!')
  //           })
  //       }
  //     } else if (result.isDenied) {
  //       Swal.fire('Category Delete Canceled', '', 'info')
  //     }
  //   })
  // }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2 }}>
            <Typography variant='h5'>OPTIONAL FIELDS</Typography>
            <Button
              variant='contained'
              onClick={handleAddOptionalFieldFormOpen}
              startIcon={<Icon icon='material-symbols-light:add' />}
            >
              Create Optional Field
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ boxShadow: 2 }}>
            <Box
              sx={{
                height: 395,
                width: '100%',
                '& .actions': {
                  color: 'text.secondary'
                },
                '& .textPrimary': {
                  color: 'text.primary'
                }
              }}
            >
              <DataGrid
                getRowHeight={() => 'auto'}
                rows={rows}
                rowCount={recordCount}
                columns={columns}
                paginationMode='server'
                pageSizeOptions={[5, 10, 25, 50, 100]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                disableRowSelectionOnClick
              />
            </Box>
          </Card>
        </Grid>
      </Grid>

      <AddOptionalField
        addOptionalFieldFormOpen={addOptionalFieldFormOpen}
        onCloseOptionalFieldForm={handleAddOptionalFieldFormClose}
        onFormSubmit={handleFormSubmit}
      />

      <UpdateOptionalField
        updateOptionalFieldFormOpen={updateOptionalFieldFormOpen}
        onCloseOptionalFieldForm={handleUpdateOptionalFieldFormClose}
        onFormSubmit={handleFormSubmit}
        updateOptionalFieldID={updateOptionalFieldID}
      />

      <Dialog
        open={litsItemsOpen}
        onClose={handleListItemsClose}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        scroll='paper'
        fullWidth
        sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
      >
        <DialogTitle variant='h5' sx={{ pb: 1 }}>
          List Items
          <CustomCloseButton aria-label='close' onClick={handleListItemsClose}>
            <Icon icon='tabler:x' fontSize='1.25rem' />
          </CustomCloseButton>
        </DialogTitle>
        <DialogContent className='content-display-grid-scroll'>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Key</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listValues.map(item => (
                  <TableRow key={item.key}>
                    <TableCell>{item.key}</TableCell>
                    <TableCell>{item.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default OptionalField
