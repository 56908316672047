import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useState } from 'react'
import { Icon } from '@iconify/react'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
    // iconPosition: 'start'

  }
}

const HeaderStepper = () => {
  const [value, setValue] = useState(0)

  const iconSize = 20

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 350 }}>
      <Tabs
        orientation='vertical'
        variant='scrollable'
        value={value}
        onChange={handleChange}
        aria-label='Vertical tabs example'
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab
          icon={<Icon icon='maki:arrow' fontSize={iconSize} />}
          label={
            <Typography variant='caption' fontWeight={600}>
              Order
            </Typography>
          }
          {...a11yProps(0)}
        />
        <Tab
          icon={<Icon icon='la:file-invoice-dollar' fontSize={iconSize} />}
          label={
            <Typography variant='caption' fontWeight={600}>
              Invoice
            </Typography>
          }
          {...a11yProps(1)}
        />
        <Tab
          icon={<Icon icon='hugeicons:shopping-cart-check-02' fontSize={iconSize} />}
          label={
            <Typography variant='caption' fontWeight={600}>
              Stock
              <br />
              Recieved
            </Typography>
          }
          {...a11yProps(2)}
        />
        <Tab
          icon={<Icon icon='fluent:notepad-24-regular' fontSize={iconSize} />}
          label={
            <Typography variant='caption' fontWeight={600}>
              Credit
              <br />
              Note
            </Typography>
          }
          {...a11yProps(3)}
        />
        <Tab
          icon={<Icon icon='hugeicons:shopping-cart-check-out-01' fontSize={iconSize} />}
          label={
            <Typography variant='caption' fontWeight={600}>
              Unstock
            </Typography>
          }
          {...a11yProps(4)}
        />
        <Tab
          icon={<Icon icon='bi:journal-bookmark' fontSize={iconSize} />}
          label={
            <Typography variant='caption' fontWeight={600}>
              Manual
              <br />
              Journals
            </Typography>
          }
          {...a11yProps(5)}
        />
        <Tab
          icon={<Icon icon='icon-park-outline:history-query' fontSize={iconSize} />}
          label={
            <Typography variant='caption' fontWeight={600}>
              Related
              <br />
              Orders
            </Typography>
          }
          {...a11yProps(6)}
        />
        <Tab
          icon={<Icon icon='teenyicons:attachment-outline' fontSize={iconSize} />}
          label={
            <Typography variant='caption' fontWeight={600}>
              Attachments
            </Typography>
          }
          {...a11yProps(7)}
        />
        <Tab
          icon={<Icon icon='ic:round-double-arrow' fontSize={iconSize} />}
          label={
            <Typography variant='caption' fontWeight={600}>
              Logs and
              <br />
              Attributes
            </Typography>
          }
          {...a11yProps(8)}
        />
        <Tab
          icon={<Icon icon='flowbite:wallet-outline' fontSize={iconSize} />}
          label={
            <Typography variant='caption' fontWeight={600}>
              Financials
            </Typography>
          }
          {...a11yProps(9)}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        Item One
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
      <TabPanel value={value} index={3}>
        Item Four
      </TabPanel>
      <TabPanel value={value} index={4}>
        Item Five
      </TabPanel>
      <TabPanel value={value} index={5}>
        Item Six
      </TabPanel>
      <TabPanel value={value} index={6}>
        Item Seven
      </TabPanel>
    </Box>
  )
}

export default HeaderStepper
