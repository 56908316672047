import Dashboard from '../pages/dashboard'
import Items from '../pages/items'
import Profile from '../pages/profile'
import CategoryControl from '../pages/configurationSettings/categoryControl'
import VendorControl from '../pages/configurationSettings/vendorControl'
import OptionalField from '../pages/configurationSettings/optionalFieldControl'
import PORequest from '../pages/purchaseOrder/porequest'
import POView from '../pages/purchaseOrder/purchaseorder'

const navigation = () => {
  return [
    {
      id: 'dashboard',
      title: 'Dashboard',
      path: '/',
      icon: 'clarity:dashboard-solid',
      component: Dashboard
    },
    {
      id: 'profile',
      title: 'Profile',
      path: '/profile',
      icon: 'iconamoon:profile-bold',
      component: Profile
    },
    {
      id: 'items',
      title: 'Items',
      path: '/items',
      icon: 'ant-design:shopping-outlined',
      component: Items
    },
    // {
    //   id: 'purchaseOrder',
    //   title: 'Purchasing',
    //   icon: 'bxs:purchase-tag',
    //   children: [
    //     { title: 'Requests', path: '/purchase-order/purchase-order-request', component: PORequest },
    //     { title: 'Purchase Orders', path: '/purchase-order/purchase-order-view', component: POView },
    //   ]
    // },
    {
      id: 'configuration',
      title: 'Master Data',
      icon: 'eos-icons:configuration-file',
      children: [
        { title: 'Category', path: '/configuration/category', component: CategoryControl },
        { title: 'Vendor', path: '/configuration/vendor', component: VendorControl },
        { title: 'Optional Field', path: '/configuration/optional-field', component: OptionalField }
      ]
    },
  ]
}

export default navigation
