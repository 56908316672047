const changes = [
  {
    version: '1.0.0',
    date: 'YYYY-MM-DD',
    author: '🅺🅸🆄 🆂🅴 - тєαм αℓρнα',
    changes: [
      { type: 'added', description: 'Initial Release' },
      { type: 'changed', description: 'Initial Release' },
      { type: 'fixed', description: 'Initial Release' },
      { type: 'removed', description: 'Initial Release' }
    ]
  }
]

export default changes
