import { Icon } from '@iconify/react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import apiDefinitions from '../../../api/apiDefinitions'
import { useCurrentRoute, useUserID } from '../../../hooks/customHooks'

import toast from 'react-hot-toast'
import { MuiChipsInput } from 'mui-chips-input'

const CustomCloseButton = styled(IconButton)(({ theme }) => ({
  top: 0,
  right: 0,
  color: 'grey.500',
  position: 'absolute',
  boxShadow: theme.shadows[2],
  transform: 'translate(10px, -10px)',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: `${theme.palette.background.paper} !important`,
  transition: 'transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out',
  '&:hover': {
    transform: 'translate(7px, -5px)'
  }
}))

const AddOptionalField = ({ addOptionalFieldFormOpen, onCloseOptionalFieldForm, onFormSubmit }) => {
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''

  const [addFormOpen, setAddFormOpen] = useState(addOptionalFieldFormOpen)

  const [fieldLabel, setFieldLabel] = useState('')
  const [fieldType, setFieldType] = useState('')
  const [isRequired, setIsRequired] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [listItem, setListItem] = useState('')
  const [addedItems, setAddedItems] = useState([])

  const [fieldLabelError, setFieldLabelError] = useState(false)
  const [fieldTypeError, setFieldTypeError] = useState(false)
  const [isRequiredError, setIsRequiredError] = useState(false)
  const [isDisabledError, setIsDisabledError] = useState(false)
  const [listItemError, setListItemError] = useState(false)
  const [listItemsError, setListItemsError] = useState(false)

  const handleAddOptionalFieldFormClose = () => {
    setAddFormOpen(false)
    onCloseOptionalFieldForm()
    setFieldLabel('')
    setFieldType('')
    setIsRequired(false)
    setIsDisabled(false)
    setListItem('')
    setAddedItems([])
    setFieldLabelError(false)
    setFieldTypeError(false)
    setListItemError(false)
    setListItemsError(false)
  }

  useEffect(() => {
    setAddedItems([])
  }, [fieldType])

  const handleSelect = e => {
    setFieldType(e.target.value)
    setFieldTypeError('')
  }

  const handleRequired = e => {
    setIsRequired(e.target.checked)
    setIsRequiredError('')
  }

  const handleDisabled = e => {
    setIsDisabled(e.target.checked)
    setIsDisabledError('')
  }

  const handleAddItem = () => {
    if (listItem.trim() !== '') {
      setAddedItems(prevItems => [...prevItems, listItem])
      setListItem('') // Clear the listItem state here
      document.getElementById('listItemInput').value = '' // Clear the input field directly
    }
  }

  const handleAddOptionalField = () => {
    let hasError = false

    // Check if Field Label is empty
    if (fieldLabel.trim() === '') {
      setFieldLabelError('Field Label cannot be empty')
      hasError = true
    } else {
      setFieldLabelError('')
    }

    // Check if Field Type is empty
    if (fieldType.trim() === '') {
      setFieldTypeError('Field Type cannot be empty')
      hasError = true
    } else {
      setFieldTypeError('')
    }

    // Check if Added List Items is empty for select or multiple_select field types
    if ((fieldType === 'select' || fieldType === 'multiple_select') && addedItems.length === 0) {
      setListItemsError('Added List Items cannot be empty for Select or Multiple Select field types')
      hasError = true
    } else {
      setListItemsError('')
    }

    // If any error is detected, return early
    if (hasError) return

    // If all validations pass, proceed with adding the optional field
    Swal.fire({
      title: 'Are you sure you want to add this Optional Field?',
      showCancelButton: true,
      confirmButtonText: 'Add',
      cancelButtonText: 'Cancel',
      customClass: {
        popup: 'swal2-popup'
      }
    }).then(result => {
      if (result.isConfirmed) {
        const payload = {
          field_label: fieldLabel,
          field_type: fieldType,
          is_required: isRequired ? 1 : 0, // Convert true/false to 1/0
          is_disabled: isDisabled ? 1 : 0, // Convert true/false to 1/0
          list_items: addedItems
        }

        // console.log('Payload:', payload)

        apiDefinitions
          .postOptionalFieldCreate(userID, currentRouteString, payload)
          .then(res => {
            if (res.data.code === 201) {
              toast.success('Optional Field Added Successfully')
              onFormSubmit()
              handleAddOptionalFieldFormClose()
            } else {
              console.log('error')
              toast.error(`Error: ${res.data.message}`)
            }
          })
          .catch(err => {
            console.log(err)
            toast.error('Error Creating Optional Field!')
          })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled Adding New Optional Field', '', 'info')
      }
    })
  }

  return (
    <Dialog
      open={addOptionalFieldFormOpen}
      onClose={handleAddOptionalFieldFormClose}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      scroll='paper'
      fullWidth
      sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
    >
      <DialogTitle variant='h5' sx={{ pb: 1 }}>
        Add New Optional Field
        <CustomCloseButton aria-label='close' onClick={handleAddOptionalFieldFormClose}>
          <Icon icon='tabler:x' fontSize='1.25rem' />
        </CustomCloseButton>
      </DialogTitle>
      <DialogContent className='content-display-grid-scroll'>
        <Grid container spacing={2} sx={{ pt: 1 }}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Field Label'
              required
              onChange={e => {
                setFieldLabel(e.target.value)
                setFieldLabelError('')
              }}
              error={!!fieldLabelError}
              helperText={fieldLabelError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                    <Icon icon='bxs:category-alt' />
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth error={!!fieldTypeError}>
              <InputLabel>Field Type</InputLabel>
              <Select
                value={fieldType}
                label='Field Type'
                onChange={e => {
                  handleSelect(e)
                  setFieldTypeError('')
                }}
              >
                <MenuItem value='text'>Text</MenuItem>
                <MenuItem value='number'>Number</MenuItem>
                <MenuItem value='date'>Date</MenuItem>
                <MenuItem value='select'>Select</MenuItem>
                <MenuItem value='time'>Time</MenuItem>
                <MenuItem value='datetime'>Date & Time</MenuItem>
                <MenuItem value='multiline'>Multiline</MenuItem>
                <MenuItem value='multiple_select'>Multiple Select</MenuItem>
              </Select>
              {!!fieldTypeError && <FormHelperText>{fieldTypeError}</FormHelperText>}
            </FormControl>
          </Grid>

          {(fieldType === 'select' || fieldType === 'multiple_select') && (
            // <>
            //   <Grid item xs={10}>
            //     <TextField
            //       id='listItemInput'
            //       fullWidth
            //       label='List Item'
            //       required
            //       onChange={e => {
            //         setListItem(e.target.value)
            //         setListItemError('')
            //       }}
            //       error={!!listItemError}
            //       helperText={listItemError}
            //       InputProps={{
            //         startAdornment: (
            //           <InputAdornment position='start' sx={{ fontSize: '20px' }}>
            //             <Icon icon='bx:bx-list-ul' />
            //           </InputAdornment>
            //         )
            //       }}
            //     />
            //   </Grid>
            //   <Grid item xs={2} sx={{ mt: 1 }}>
            //     <IconButton color='primary' onClick={handleAddItem}>
            //       <Icon icon='akar-icons:plus' />
            //     </IconButton>
            //   </Grid>

            //   <Grid item xs={12}>
            //     <TextField
            //       fullWidth
            //       label='Added List Items'
            //       multiline
            //       disabled
            //       rows={4}
            //       value={addedItems.join('\n')}
            //       error={addedItems.length === 0 && !!listItemsError}
            //       helperText={listItemsError}
            //       InputProps={{
            //         startAdornment: (
            //           <InputAdornment position='start' sx={{ fontSize: '20px' }}>
            //             <Icon icon='bx:bx-list-ul' />
            //           </InputAdornment>
            //         )
            //       }}
            //     />
            //   </Grid>
            // </>

            <Grid item xs={12}>
              <MuiChipsInput
                fullWidth
                value={addedItems}
                error={addedItems.length === 0 && !!listItemsError}
                helperText={listItemsError}
                onChange={value => {
                  setAddedItems(value)
                  setListItemsError('')
                }}
                label='List Items'
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ m: 2, mt: 1, gap: 2 }}>
        <Grid item xs={3}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isRequired}
                  onChange={e => {
                    handleRequired(e)
                    setIsRequiredError('')
                  }}
                  error={!!isRequiredError}
                  helperText={isRequiredError}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label='Is Required'
            />
          </FormGroup>
        </Grid>
        <Grid item xs={3}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isDisabled}
                  onChange={e => {
                    handleDisabled(e)
                    setIsDisabledError('')
                  }}
                  error={!!isDisabledError}
                  helperText={isDisabledError}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label='Is Disabled'
            />
          </FormGroup>
        </Grid>
        <Button variant='contained' sx={{ px: 4 }} onClick={handleAddOptionalField}>
          Add Optional Field
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddOptionalField
