import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import * as React from 'react'

import { Sync } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useState } from 'react'

const Financials = () => {
  const [rows, setRows] = useState([])

  const [loading, setLoading] = useState(false)

  const [value, setValue] = useState('1')

  const columns = [
    {
      field: 'effectiveDate',
      headerName: 'Effective Date',
      flex: 1,
      type: 'date',
      width: 150,
      valueFormatter: params => {
        return new Date(params.value).toLocaleDateString()
      }
    },
    {
      field: 'debit',
      headerName: 'Debit',
      flex: 1,
      width: 150
    },
    {
      field: 'credit',
      headerName: 'Credit',
      flex: 1,
      width: 150
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      type: 'number',
      valueFormatter: params => {
        return Number(params.value).toFixed(2)
      },
      width: 110
    }
  ]

  const handleRefresh = () => {
    setRows([])
    setLoading(true)
    setTimeout(() => {
      setRows([])
      setLoading(false)
    }, 5000)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label='lab API tabs example'>
            <Tab label='Transactions' value='1' />
          </TabList>
        </Box>
        <TabPanel
          value='1'
          sx={{
            px: 0
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 2 }}>
              <Button variant='outlined' color='secondary' size='small' startIcon={<Sync />} onClick={handleRefresh}>
                Refresh
              </Button>
            </Box>
            <DataGrid
              autoHeight
              rows={rows}
              columns={columns}
              loading={loading}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5
                  }
                }
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
              slots={{
                noRowsOverlay: () => {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%'
                      }}
                    >
                      <Typography variant='body2' color='textSecondary'>
                        No transactions created.
                      </Typography>
                    </Box>
                  )
                }
              }}
            />
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default Financials
