import { Icon } from '@iconify/react'
import { Box, Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import apiDefinitions from '../../../../api/apiDefinitions'
import { useCurrentQueryParams, useCurrentRoute, useUserID } from '../../../../hooks/customHooks'
import Swal from 'sweetalert2'

const CustomCloseButton = styled(IconButton)(({ theme }) => ({
  top: 0,
  right: 0,
  color: 'grey.500',
  position: 'absolute',
  boxShadow: theme.shadows[2],
  transform: 'translate(10px, -10px)',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: `${theme.palette.background.paper} !important`,
  transition: 'transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out',
  '&:hover': {
    transform: 'translate(7px, -5px)'
  }
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const MultipleEdit = ({ open, onClose, idArray }) => {
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''
  const lotID = useCurrentQueryParams('lotId') ?? ''
  const itemID = useCurrentQueryParams('itemId') ?? ''

  const [fieldList, setFieldList] = useState([
    {
      id: 'vendor_id',
      name: 'Vendor',
      fieldType: 'select',
      isEditable: true
    },
    {
      id: 'quantity',
      name: 'Quantity',
      fieldType: 'number',
      isEditable: true
    },
    {
      id: 'unit_price',
      name: 'Unit Price',
      fieldType: 'number',
      isEditable: true
    },
    {
      id: 'tax',
      name: 'Tax',
      fieldType: 'number',
      isEditable: true
    },
    {
      id: 'discount',
      name: 'Discount',
      fieldType: 'number',
      isEditable: true
    },
    {
      id: 'special_note',
      name: 'Special Note',
      fieldType: 'multiline',
      isEditable: true
    }
  ])

  const [vendors, setVendors] = useState([{}])

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '') {
      // Fetch the vendors from the API
      apiDefinitions
        .getAllDataList(userID, currentRouteString, 'vendor')
        .then(response => {
          if (response.data.code === 200) {
            // console.log('Vendors:', response.data.data)
            setVendors(response.data.data)
          } else {
            console.log('Error fetching vendors:', response.data.message)
            toast.error('Error: ' + response.data.message)
          }
        })
        .catch(error => {
          console.error('Error fetching vendors:', error)
          toast.error('Error fetching vendors!')
        })
    }
  }, [currentRouteString, userID])

  const getFieldTypeByField = field => {
    const fieldData = fieldList.find(fieldData => fieldData.id === field)
    return fieldData ? fieldData.fieldType : 'text'
  }

  const getSelectListByField = field => {
    const fieldData = fieldList.find(fieldData => fieldData.id === field)
    return fieldData ? fieldData.listValues : [{}]
  }

  const [tableData, setTableData] = useState([])
  const [newRow, setNewRow] = useState({
    field: '',
    value: ''
  })

  const [optionalFields, setOptionalFields] = useState([])

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '' && itemID !== '') {
      // Fetch the optional fields from the API
      apiDefinitions
        .getItemOptFieldsByItemId(userID, currentRouteString, itemID)
        .then(response => {
          if (response.data.code === 200) {
            // console.log('Optional fields:', response.data.data)
            setOptionalFields(response.data.data)
          } else {
            console.log('Error fetching optional fields:', response.data.message)
            // toast.error('Error: ' + response.data.message)
          }
        })
        .catch(error => {
          console.error('Error fetching optional fields:', error)
          // toast.error('Error fetching optional fields!')
        })
    }
  }, [currentRouteString, itemID, userID])

  useEffect(() => {
    // console.log('Optional fieldszzzzzzzz:', optionalFields)
    if (optionalFields.length > 0) {
      // console.log('Optional fieldsssssssssssssssssss:', optionalFields)
      setFieldList(prevFieldList => [
        ...prevFieldList,
        ...optionalFields.map(field => ({
          id: field.optional_field.data_grid_field,
          name: field.optional_field.field_label,
          fieldType: field.optional_field.type,
          isEditable: field.optional_field.is_editable === 1,
          listValues: field.optional_field.list_values
        }))
      ])
    }
  }, [optionalFields])

  useEffect(() => {
    console.log('Field listzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz:', fieldList)
  }, [fieldList])

  const handleClose = () => {
    setTableData([])
    onClose()
  }

  const handleCreateTableRow = () => {
    // Append the new row to the tableData
    setTableData(prevTableData => [...prevTableData, { ...newRow }])
    // Reset the new row data
    setNewRow({
      field: '',
      value: ''
    })
  }

  const handleInputChange = (event, columnName, rowIndex) => {
    // Ensure that the value is non-negative for number fields
    let newValue = event.target.value

    // If the field is a number and the input is negative, set it to 0
    if (getFieldTypeByField(tableData[rowIndex].field) === 'number' && parseFloat(newValue) < 0) {
      newValue = '0'
    }

    // Update the row data based on the input changes
    setTableData(prevTableData =>
      prevTableData.map((row, index) => {
        if (index === rowIndex) {
          // If the columnName is 'field', set the value column to null
          if (columnName === 'field') {
            return { ...row, [columnName]: event.target.value, value: '' }
          } else {
            return { ...row, [columnName]: newValue }
          }
        } else {
          return row
        }
      })
    )
  }

  const handleMultipleSelectChange = (selectedValues, columnName, rowIndex) => {
    setTableData(prevTableData =>
      prevTableData.map((row, index) => (index === rowIndex ? { ...row, [columnName]: selectedValues } : row))
    )
  }

  const handleSubmitChanges = () => {
    // check for null or empty values
    for (const row of tableData) {
      if (row.field === '' || row.value === '') {
        alert('Field values cannot be empty!')
        return
      }
    }

    const payload = {
      lot_id: lotID,
      change_data: tableData,
      item_detail_ids: idArray
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to update the selected items!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, update it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        apiDefinitions
          .putItemDetailUpdateMultiple(userID, currentRouteString, payload)
          .then(response => {
            if (response.data.code === 200) {
              console.log('Update successful:', response.data.message)
              toast.success('Update successful!')
              handleClose()
            } else {
              console.log('Error updating:', response.data.message)
              toast.error('Error: ' + response.data.message)
            }
          })
          .catch(error => {
            console.error('Error updating:', error)
            toast.error('Error updating!')
          })
      }
    })

    console.log(payload)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      scroll='paper'
      fullWidth
      sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
    >
      <DialogTitle>
        <Button variant='outlined' onClick={handleCreateTableRow} sx={{ '& SVG': { marginRight: '0.25rem' } }}>
          <Icon icon='tabler:plus' fontSize='1.25rem' />
          Create Row
        </Button>
        <CustomCloseButton aria-label='close' onClick={handleClose}>
          <Icon icon='tabler:x' fontSize='1.25rem' />
        </CustomCloseButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          p: 0
        }}
      >
        {/* Display the table */}
        <TableContainer sx={{ height: 380 }}>
          <Table aria-label='sticky table' sx={{ borderCollapse: 'separate' }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '47.5%', textAlign: 'center' }}>Field</TableCell>
                <TableCell style={{ width: '47.5%', textAlign: 'center', paddingRight: 0 }}>Value</TableCell>
                <TableCell style={{ width: '5%', textAlign: 'center', padding: 0 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Select fullWidth value={row.field || ''} onChange={e => handleInputChange(e, 'field', index)}>
                      {fieldList.map((field, index) =>
                        field.isEditable === true ? (
                          <MenuItem key={index} value={field.id}>
                            {field.name}
                          </MenuItem>
                        ) : null
                      )}
                    </Select>
                  </TableCell>
                  <TableCell
                    sx={{
                      pr: 0
                    }}
                  >
                    {(() => {
                      const fieldType = getFieldTypeByField(row.field)

                      if (fieldType === 'multiline') {
                        return (
                          <TextField
                            fullWidth
                            multiline
                            value={row.value}
                            onChange={e => handleInputChange(e, 'value', index)}
                          />
                        )
                      } else if (fieldType === 'select') {
                        return (
                          <Select
                            fullWidth
                            value={row.value || ''}
                            onChange={e => handleInputChange(e, 'value', index)}
                          >
                            {row.field === 'vendor_id'
                              ? [
                                  <MenuItem key='none' value=''>
                                    None
                                  </MenuItem>,
                                  ...vendors.map((vendor, index) => (
                                    <MenuItem key={index} value={vendor.id}>
                                      {vendor.vendor_name}
                                    </MenuItem>
                                  ))
                                ]
                              : [
                                  <MenuItem key='none' value=''>
                                    None
                                  </MenuItem>,
                                  ...getSelectListByField(row.field).map((value, index) => (
                                    <MenuItem key={index} value={value.key}>
                                      {value.value}
                                    </MenuItem>
                                  ))
                                ]}
                          </Select>
                        )
                      } else if (fieldType === 'number') {
                        return (
                          <TextField
                            fullWidth
                            type='number'
                            onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                            value={row.value}
                            onChange={e => handleInputChange(e, 'value', index)}
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                        )
                      } else if (fieldType === 'text') {
                        return (
                          <TextField fullWidth value={row.value} onChange={e => handleInputChange(e, 'value', index)} />
                        )
                      } else if (fieldType === 'date') {
                        return (
                          <TextField
                            fullWidth
                            type='date'
                            value={row.value}
                            onChange={e => handleInputChange(e, 'value', index)}
                          />
                        )
                      } else if (fieldType === 'time') {
                        return (
                          <TextField
                            fullWidth
                            type='time'
                            value={row.value}
                            onChange={e => handleInputChange(e, 'value', index)}
                          />
                        )
                      } else if (fieldType === 'datetime') {
                        return (
                          <TextField
                            fullWidth
                            type='datetime-local'
                            value={row.value}
                            onChange={e => handleInputChange(e, 'value', index)}
                          />
                        )
                      } else if (fieldType === 'multiple_select') {
                        return (
                          <Select
                            labelId='demo-multiple-chip-label'
                            id='demo-multiple-chip'
                            multiple
                            fullWidth
                            value={Array.isArray(row.value) ? row.value : []} // Ensure row.value is always an array
                            onChange={e => handleMultipleSelectChange(e.target.value, 'value', index)}
                            renderValue={selected => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected?.map(value => (
                                  <Chip
                                    key={value}
                                    label={getSelectListByField(row.field).find(item => item.key === value).value}
                                  />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {getSelectListByField(row.field).map((value, index) => (
                              <MenuItem key={index} value={value.key}>
                                {value.value}
                              </MenuItem>
                            ))}
                          </Select>
                        )
                      } else {
                        return (
                          <Typography variant='body1' align='center'>
                            Invalid Column!
                          </Typography>
                        )
                      }
                    })()}
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      pr: 1
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        setTableData(prevTableData => prevTableData.filter((_, i) => i !== index))
                      }}
                    >
                      <Icon icon='fluent-mdl2:cancel' fontSize='1.25rem' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleSubmitChanges}>
          Update changes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MultipleEdit
