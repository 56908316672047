import { Icon } from '@iconify/react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  styled
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import apiDefinitions from '../../../api/apiDefinitions'
import { useCurrentRoute, useUserID } from '../../../hooks/customHooks'

import toast from 'react-hot-toast'
import ItemImageUploader from '../ItemImageUploader'

const CustomCloseButton = styled(IconButton)(({ theme }) => ({
  top: 0,
  right: 0,
  color: 'grey.500',
  position: 'absolute',
  boxShadow: theme.shadows[2],
  transform: 'translate(10px, -10px)',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: `${theme.palette.background.paper} !important`,
  transition: 'transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out',
  '&:hover': {
    transform: 'translate(7px, -5px)'
  }
}))

const AddItemForm = ({ openAddForm, onCloseAddForm, onFormSubmit }) => {
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''

  const [addFormOpen, setAddFormOpen] = useState(openAddForm)
  const [formPage, setFormPage] = useState(1)
  const [checked, setChecked] = useState([])

  const [categories, setCategories] = useState([])
  const [units, setUnits] = useState([])
  const [assetTypes, setAssetTypes] = useState([])
  const [optionalFieldData, setOptionalFieldData] = useState([])

  const [formData, setFormData] = useState({})
  const [uploadedImage, setUploadedImage] = useState(undefined)
  const [uploadedFile, setUploadedFile] = useState([])
  const [formErrors, setFormErrors] = useState({})

  const [allOptionalFields, setAllOptionalFields] = useState([])

  useEffect(() => {
    setAddFormOpen(openAddForm)
    setFormData({})
    setFormErrors({})
  }, [openAddForm])

  const handleAddFormClose = () => {
    setAddFormOpen(false)
    setFormData({})
    setUploadedImage(undefined)
    setUploadedFile([])
    setFormErrors({})
    setFormPage(1)
    onCloseAddForm()
    setChecked([])
  }

  const handleUploadedFiles = files => {
    setUploadedImage(files[0])
    setUploadedFile(files)
    console.log('Upload', files[0])
  }

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '') {
      apiDefinitions
        .getAllItemCategories(userID, currentRouteString)
        .then(res => {
          if (res.data.code === 200) {
            setCategories(res.data.data)
            console.log('All Categories', res.data.data)
          } else {
            console.log('error')
            toast.error(`Error: ${res.data.message}`)
          }
        })
        .catch(error => {
          console.error('Error fetching categories:', error)
          toast.error('Error fetching item categories!')
        })

      apiDefinitions
        .getAllItemUnits(userID, currentRouteString)
        .then(res => {
          if (res.data.code === 200) {
            setUnits(res.data.data)
            console.log('All Units', res.data.data)
          } else {
            console.log('error')
            toast.error(`Error: ${res.data.message}`)
          }
        })
        .catch(error => {
          console.error('Error fetching units:', error)
          toast.error('Error fetching item units!')
        })

      apiDefinitions
        .getAllItemAssetTypes(userID, currentRouteString)
        .then(res => {
          if (res.data.code === 200) {
            setAssetTypes(res.data.data)
            console.log('All Asset Types', res.data.data)
          } else {
            console.log('error')
            toast.error(`Error: ${res.data.message}`)
          }
        })
        .catch(error => {
          console.error('Error fetching asset types:', error)
          toast.error('Error fetching item asset types!')
        })

      apiDefinitions
        .getAllItemOptionalFields(userID, currentRouteString)
        .then(res => {
          if (res.data.code === 200) {
            console.log('All Optional Fields', res.data.data)
            setOptionalFieldData(res.data.data)
            setAllOptionalFields(res.data.data)
          } else {
            console.log('error')
            toast.error(`Error: ${res.data.message}`)
          }
        })
        .catch(error => {
          console.error('Error fetching optional fields:', error)
          toast.error('Error fetching item optional fields!')
        })
    }
  }, [userID, currentRouteString])

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '' && formData?.category !== '' && formData?.category !== undefined) {
      apiDefinitions
        .getNextItemID(userID, currentRouteString, formData.category)
        .then(res => {
          if (res.data.code === 200) {
            console.log('Next Item ID', res.data.data.item_code)
            setFormData({ ...formData, itemCode: res.data.data.item_code })
          } else {
            console.log('error')
            toast.error(`Error: ${res.data.message}`)
          }
        })
        .catch(error => {
          console.error('Error fetching next item id:', error)
          toast.error('Error fetching next item id!')
        })
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRouteString, formData?.category, userID])

  const validateField = (fieldName, value) => {
    if (value !== '' && value !== undefined && value !== null) {
      setFormErrors({ ...formErrors, [fieldName]: false })
    } else {
      setFormErrors({ ...formErrors, [fieldName]: true })
    }
  }

  useEffect(() => {
    validateField('category', formData?.category)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.category])

  useEffect(() => {
    validateField('itemName', formData?.itemName)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.itemName])

  useEffect(() => {
    validateField('itemDescription', formData?.itemDescription)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.itemDescription])

  useEffect(() => {
    validateField('assetType', formData?.assetType)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.assetType])

  useEffect(() => {
    validateField('itemThreshold', formData?.itemThreshold)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.itemThreshold])

  useEffect(() => {
    validateField('baseUnit', formData?.baseUnit)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.baseUnit])

  useEffect(() => {
    validateField('itemImage', uploadedImage)
    console.log('uploadedImage', uploadedImage)

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedImage])

  const validateField2 = (fieldName, value) => {
    const isValid = !(value === '' || value === undefined || value === null)
    return { field: fieldName, isValid: isValid }
  }

  const validateForm = () => {
    const validations = [
      validateField2('category', formData.category),
      validateField2('itemName', formData.itemName),
      validateField2('itemDescription', formData.itemDescription),
      validateField2('assetType', formData.assetType),
      validateField2('itemThreshold', formData.itemThreshold),
      validateField2('baseUnit', formData.baseUnit),
      validateField2('itemImage', uploadedImage)
    ]

    const errors = validations.filter(validation => !validation.isValid)
    errors.forEach(error => {
      setFormErrors(prevErrors => ({ ...prevErrors, [error.field]: true }))
    })

    return errors.length === 0
  }

  const handleFormNext = () => {
    if (validateForm()) {
      setFormPage(2)
    }
  }

  const handleAddItemSubmit = () => {
    console.log('Form Data:', formData)

    if (validateForm()) {
      const payload = {
        item_short_name: formData.itemName,
        item_code: formData.itemCode,
        item_description: formData.itemDescription,
        item_status: 1,
        asset_id: formData.assetType,
        category_id: formData.category,
        item_threshold: Number(formData.itemThreshold),
        item_unit: formData.baseUnit,
        item_config: checked
      }

      console.log('Payload:', payload)
      Swal.fire({
        title: 'Do you want to save the changes?',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Save',
        denyButtonText: `Don't save`,
        customClass: {
          popup: 'swal2-popup'
        }
      }).then(result => {
        if (result.isConfirmed) {
          const payloadData = new FormData()
          payloadData.append('json', new Blob([JSON.stringify(payload)], { type: 'application/json' }))
          payloadData.append('file', uploadedImage)

          const myPromise = new Promise((resolve, reject) => {
            apiDefinitions
              .postAddNewItem(userID, currentRouteString, payloadData)
              .then(res => {
                console.log('API Response:', res)
                if (res.data.code === 201) {
                  console.log('Successfully added item')
                  resolve(res.data.message) // Pass the message to resolve
                } else {
                  reject(res.data.message) // Pass the message to reject
                }
              })
              .catch(error => {
                console.error('Error adding item:', error)
                reject('Error adding item!') // Provide a generic error message
              })
          })

          toast
            .promise(myPromise, {
              loading: 'Creating item...',
              success: message => `Success: ${message}`, // Use the message from the resolved promise
              error: errorMessage => `Error: ${errorMessage}`, // Use the message from the rejected promise
              duration: 5000
            })
            .then(() => {
              setFormData({})
              setUploadedImage(undefined)
              setUploadedFile([])
              setFormErrors({})
              setFormPage(1)
              handleAddFormClose()
              onFormSubmit()
              setChecked([])
              onCloseAddForm()
            })
            .catch(() => {
              console.log('Error')
              toast.error('Error adding item!')
            })
        } else if (result.isDenied) {
          Swal.fire('Item not created', '', 'info')
        }
      })
    }
  }

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]
    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  useEffect(() => {
    console.log('Checked', checked)
  }, [checked])

  return (
    <Dialog
      open={addFormOpen}
      onClose={handleAddFormClose}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      scroll='paper'
      fullWidth
      sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
    >
      <DialogTitle variant='h5' sx={{ pb: 1 }}>
        Add Item
        <CustomCloseButton aria-label='close' onClick={handleAddFormClose}>
          <Icon icon='tabler:x' fontSize='1.25rem' />
        </CustomCloseButton>
      </DialogTitle>
      <DialogContent className='content-display-grid-scroll'>
        {formPage === 1 && (
          <Grid container spacing={2} sx={{ pt: 1 }}>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ mb: 1 }}>
                <InputLabel id='demo-multiple-name-label'>Item Category *</InputLabel>
                <Select
                  labelId='demo-multiple-name-label'
                  id='demo-multiple-name'
                  value={formData.category || ''}
                  onChange={event => setFormData({ ...formData, category: event.target.value })}
                  input={
                    <OutlinedInput
                      label='Item Category *'
                      error={formErrors.category}
                      helperText={formErrors.category ? 'Item category is required' : ''}
                    />
                  }
                >
                  {categories?.map(category => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.category_name}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.category && (
                  <Typography variant='body2' color='error' sx={{ mt: 0.5, fontSize: '12px' }}>
                    Item category is required
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Code'
                value={formData?.itemCode || ''}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                      <Icon icon='mdi:barcode-scan' />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                value={formData?.itemName || ''}
                onChange={event => setFormData({ ...formData, itemName: event.target.value })}
                label='Name'
                placeholder='Enter Item Name'
                required
                error={formErrors.itemName}
                helperText={formErrors.itemName ? 'Item name is required' : ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                      <Icon icon='majesticons:box-line' />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth sx={{ mb: 1 }}>
                <InputLabel id='demo-multiple-name-label'>Asset Type *</InputLabel>
                <Select
                  labelId='demo-multiple-name-label'
                  id='demo-multiple-name'
                  value={formData.assetType || ''}
                  onChange={event => setFormData({ ...formData, assetType: event.target.value })}
                  input={<OutlinedInput label='Asset Type *' error={formErrors.assetType} />}
                >
                  {assetTypes?.map(assetType => (
                    <MenuItem key={assetType.value} value={assetType.value}>
                      {assetType.text}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.assetType && (
                  <Typography variant='body2' color='error' sx={{ mt: 0.5, fontSize: '12px' }}>
                    Item asset type is required
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <TextField
                id='outlined-number'
                label='Threshold'
                value={formData?.itemThreshold || ''}
                onChange={event => {
                  // const newValue = event.target.value.replace(/[^0-9]/g, '')
                  const newValue = event.target.value
                  setFormData({ ...formData, itemThreshold: newValue })
                }}
                placeholder='Enter Threshold'
                type='number'
                onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                fullWidth
                required
                error={formErrors.itemThreshold}
                helperText={formErrors.itemThreshold ? 'Item threshold is required' : ''}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start' sx={{ fontSize: '22px' }}>
                      <Icon icon='material-symbols-light:data-thresholding-outline-rounded' />
                    </InputAdornment>
                  )
                }}
                inputProps={{
                  min: 0
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControl fullWidth sx={{ mb: 1 }}>
                <InputLabel id='demo-multiple-name-label'>Base Unit *</InputLabel>
                <Select
                  labelId='demo-multiple-name-label'
                  id='demo-multiple-name'
                  value={formData.baseUnit || ''}
                  onChange={event => setFormData({ ...formData, baseUnit: event.target.value })}
                  input={<OutlinedInput label='Asset Type *' error={formErrors.baseUnit} />}
                >
                  {units?.map(unit => (
                    <MenuItem key={unit.id} value={unit.id}>
                      {unit.base_unit}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.baseUnit && (
                  <Typography variant='body2' color='error' sx={{ mt: 0.5, fontSize: '12px' }}>
                    Base Unit is required
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                value={formData?.itemDescription || ''}
                onChange={event => setFormData({ ...formData, itemDescription: event.target.value })}
                label='Description'
                placeholder='Enter Item Description'
                required
                error={formErrors.itemDescription}
                helperText={formErrors.itemDescription ? 'Item description is required' : ''}
                multiline
                minRows={1.5}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' sx={{ mb: 1 }}>
                Item Image *
              </Typography>
              <ItemImageUploader onUpload={handleUploadedFiles} uploadedFile={uploadedFile} />
              {formErrors.itemImage && (
                <Typography variant='body2' color='error' sx={{ mt: 1, fontSize: '13px' }}>
                  Item image is required
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
        {formPage === 2 && (
          <Grid container spacing={2} sx={{ pt: 1 }}>
            <Grid item xs={12}>
              <Typography variant='body1' sx={{ mt: 1 }}>
                Item Elective Fields
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size='small'
                label='Search'
                variant='outlined'
                onChange={e => {
                  const searchValue = e.target.value

                  if (searchValue.length > 0) {
                    const filteredFields = allOptionalFields.filter(field =>
                      field.field_label.toLowerCase().includes(searchValue.toLowerCase())
                    )
                    setOptionalFieldData(filteredFields)
                  } else {
                    setOptionalFieldData(allOptionalFields)
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Icon icon='carbon:search' />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <List>
                {optionalFieldData.map(fieldOption => {
                  return (
                    <ListItem disablePadding key={fieldOption.id}>
                      <ListItemButton onClick={handleToggle(fieldOption.id)}>
                        <Checkbox
                          edge='start'
                          tabIndex={-1}
                          disableRipple
                          onChange={handleToggle(fieldOption.id)}
                          checked={checked.indexOf(fieldOption.id) !== -1}
                          inputProps={{ 'aria-labelledby': `checkbox-list-label-${fieldOption.id}` }}
                        />
                        <ListItemText id={`checkbox-list-label-${fieldOption.id}`} primary={fieldOption.field_label} />
                      </ListItemButton>
                    </ListItem>
                  )
                })}
              </List>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions sx={{ m: 2, mt: 1, gap: 2 }}>
        {formPage === 1 && (
          <Button variant='contained' onClick={handleFormNext} sx={{ px: 4 }}>
            Next
          </Button>
        )}
        {formPage === 2 && (
          <>
            <Button variant='contained' onClick={() => setFormPage(1)} sx={{ px: 4 }}>
              Back
            </Button>
            <Button variant='contained' onClick={() => handleAddItemSubmit()} sx={{ px: 4 }}>
              Add
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default AddItemForm
