import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import apiDefinitions from '../../../../../api/apiDefinitions'
import { useCurrentRoute, useUserID } from '../../../../../hooks/customHooks'
import toast from 'react-hot-toast'
import { FormControl } from '@mui/material'
import { FormHelperText } from '@mui/material'

const filter = createFilterOptions()

const TestComponent = ({ selectedVendors, setSelectedVendors, selectedVendorsError, setSelectedVendorsError }) => {
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''

  const [vendors, setVendors] = useState([])

  const [refresh, setRefresh] = useState(false)

  // useEffect(() => {
  //   console.log('selectedVendors:', selectedVendors)
  // }, [selectedVendors])

  useEffect(() => {
    apiDefinitions
      .getAllDataList(userID, currentRouteString, 'vendor')
      .then(response => {
        if (response.data.code === 200) {
          setVendors(response.data.data)
        } else throw new Error(response.data.message)
      })
      .catch(error => {
        console.error('Error fetching vendors:', error)
        toast.error(`Error fetching vendors: ${error}`)
      })
  }, [userID, currentRouteString, refresh])

  const onSelectAddOption = async name => {
    try {
      const response = await apiDefinitions.postVendorCreate(userID, currentRouteString, {
        vendor_name: name
      })
      setSelectedVendors(prev => [...prev, response.data.data])
      setRefresh(!refresh)
    } catch (error) {
      console.error('Error creating vendor:', error)
    }
  }

  return (
    <FormControl fullWidth error={!!selectedVendorsError}>
      <Autocomplete
        multiple // Enable multiple selections
        value={selectedVendors}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            console.log('1:', newValue)
            setSelectedVendors([...selectedVendors, { vendor_name: newValue }])
          } else if (newValue && newValue.inputValue) {
            console.log('2:', newValue)
            onSelectAddOption(newValue.inputValue)
          } else {
            console.log('3:', newValue)

            const newVendor = newValue.map(
              (
                vendor //check if vendor has property inputValue
              ) => (vendor.inputValue ? vendor.inputValue : null)
            )

            console.log('newVendor:', newVendor)

            //filter not null values
            const filteredVendor = newVendor.filter(vendor => vendor !== null)

            console.log('filteredVendor:', filteredVendor)

            if (filteredVendor.length > 0) {
              filteredVendor.forEach(vendor => {
                onSelectAddOption(vendor)
              })
            } else {
              setSelectedVendors(newValue)
            }
          }
          setSelectedVendorsError('')
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)

          const { inputValue } = params
          // Suggest the creation of a new value
          const isExisting = options.some(option => inputValue === option.vendor_name)
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              vendor_name: `Add "${inputValue}"`,
              inputValue,
              id: options.length + 1 // Generate a unique id for the new option
            })
          }

          return filtered
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        autoHighlight
        id='free-solo-with-text-demo'
        options={vendors}
        getOptionLabel={option => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.vendor_name
          }
          // Regular option
          return option.vendor_name
        }}
        renderOption={(props, option) => <li {...props}>{option.vendor_name}</li>}
        freeSolo
        renderInput={params => <TextField {...params} label='Vendor' />}
      />
      {!!selectedVendorsError && <FormHelperText>{selectedVendorsError}</FormHelperText>}
    </FormControl>
  )
}

export default TestComponent
