import { Icon } from '@iconify/react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'
import FsLightbox from 'fslightbox-react'
import { useState } from 'react'
import LazyLoad from 'react-lazy-load'
import {
  convertImageLinkToBase64,
  useCurrentQueryParams,
  useCurrentRoute,
  useUserID
} from '../../../../hooks/customHooks'
import ItemImageUploader from '../../ItemImageUploader'
import { useEffect } from 'react'

const LotViewForm = ({ apiData }) => {
  const ItemFallBackImage = '/images/default-fallbacks/item-fallback.png'

  const [lightBoxImage, setLightBoxImage] = useState('')
  const [addUploaderOpen, setAddUploaderOpen] = useState(false)
  const [toggler, setToggler] = useState(false)

  const [uploadedImage, setUploadedImage] = useState(undefined)
  const [uploadedFile, setUploadedFile] = useState([])

  const [lotId, setLotId] = useState('')
  const [lotAmount, setLotAmount] = useState('')
  const [lotDiscount, setLotDiscount] = useState('')
  const [lotQuantity, setLotQuantity] = useState('')
  const [lotTax, setLotTax] = useState('')
  const [lotStatus, setLotStatus] = useState('')
  const [lotImage, setLotImage] = useState('')
  const [lotCurrency, setLotCurrency] = useState('')
  const [lotName, setLotName] = useState('')

  const handleToggler = async link => {
    try {
      const imageString = await convertImageLinkToBase64(link)
      setLightBoxImage(imageString)
      setToggler(!toggler)
    } catch (error) {
      console.error('Error handling toggler:', error)
    }
  }

  const handleAddUploaderOpen = () => {
    setAddUploaderOpen(true)
  }

  const handleAddUploaderClose = () => {
    setAddUploaderOpen(false)
  }

  const handleFileUpload = file => {}

  const handleUploadedFiles = () => {}

  const handleLotDetails = () => {
    if (apiData && apiData.lot_details) {
      const { id, amount, discount, quantity, tax, status, image, currency, lot_name } = apiData.lot_details

      setLotId(id || '') // Use an empty string as a fallback value
      setLotName(lot_name || '') // Use an empty string as a fallback value
      setLotAmount(amount || '') // Use an empty string as a fallback value
      setLotDiscount(discount || '') // Use an empty string as a fallback value
      setLotQuantity(quantity || '') // Use an empty string as a fallback value
      setLotTax(tax || '') // Use an empty string as a fallback value
      setLotCurrency(
        (currency.currency_name ? currency.currency_name + ' ' : '') +
          (currency.currency_code ? '(' + currency.currency_code + ')' : '')
      )

      // Assuming lotStatus is an object with a color_code property
      setLotStatus(status || { color_code: 'secondary' }) // Use a default value for color_code if status is undefined

      setLotImage(image?.url || '') // Use an empty string as a fallback value
    }
  }

  useEffect(() => {
    handleLotDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiData])

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1-content' id='panel1-header'>
          <Typography variant='h5'>LOT DETAILS</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container sx={{ px: 1 }}>
            <Grid item xs={2}>
              <Grid container>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <LazyLoad>
                    <img
                      src={lotImage || ItemFallBackImage}
                      height={100}
                      alt='item'
                      // onClick={() => handleToggler(itemImage.url || ItemFallBackImage)}
                      onClick={() => handleToggler(lotImage || ItemFallBackImage)}
                      onError={e => (e.target.src = ItemFallBackImage)}
                      style={{
                        cursor: 'pointer'
                      }}
                    />
                  </LazyLoad>
                  <FsLightbox toggler={toggler} sources={[`${lightBoxImage}`]} />
                </Grid>
                {/* <Grid item xs={12}>
                  <Button
                    variant='outlined'
                    fullWidth
                    color='primary'
                    onClick={handleAddUploaderOpen}
                    sx={{ '& svg': { mr: 2 }, mt: 0.15 }}
                    size='small'
                  >
                    <Icon fontSize='1.125rem' icon='solar:camera-bold' />
                    Change Image
                  </Button>
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <TextField
                    size='small'
                    fullWidth
                    disabled
                    value={lotName || ''}
                    label='Lot Name'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                          <Icon icon='icon-park-outline:id-card' />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    size='small'
                    fullWidth
                    disabled
                    value={lotId || ''}
                    label='Lot ID'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                          <Icon icon='icon-park-outline:id-card' />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    size='small'
                    fullWidth
                    disabled
                    value={lotQuantity || ''}
                    label='Quantity'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                          <Icon icon='oui:token-token-count' />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    size='small'
                    fullWidth
                    disabled
                    value={
                      isNaN(parseFloat(lotAmount)) || lotAmount === ''
                        ? '0.00'
                        : parseFloat(lotAmount)
                            .toFixed(2) // Ensure the number has two decimal points
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') // Add commas as thousand separators
                    }
                    label='Amount'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                          <Icon icon='fluent-emoji-high-contrast:money-bag' />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={2.5}>
                  <TextField
                    size='small'
                    fullWidth
                    disabled
                    value={
                      isNaN(parseFloat(lotDiscount)) || lotDiscount === ''
                        ? '0.00'
                        : parseFloat(lotDiscount)
                            .toFixed(2) // Ensure the number has two decimal points
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') // Add commas as thousand separators
                    }
                    label='Discount'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                          <Icon icon='ri:discount-percent-line' />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={2.5}>
                  <TextField
                    size='small'
                    fullWidth
                    disabled
                    value={
                      isNaN(parseFloat(lotTax)) || lotTax === ''
                        ? '0.00'
                        : parseFloat(lotTax)
                            .toFixed(2) // Ensure the number has two decimal points
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') // Add commas as thousand separators
                    }
                    label='Tax'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                          <Icon icon='tabler:receipt-tax' />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size='small'
                    fullWidth
                    disabled
                    value={lotCurrency}
                    label='Currency'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start' sx={{ fontSize: '25px' }}>
                          <Icon icon='material-symbols-light:universal-currency-alt-outline' />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Chip
                    variant='filled'
                    color={lotStatus.color_code || 'secondary'}
                    sx={{
                      p: 1
                    }}
                    label={
                      <Typography variant='body2'>
                        {String(lotStatus?.type).toUpperCase() || 'Status Not Found'}
                      </Typography>
                    }
                  ></Chip>
                </Grid>
              </Grid>

              {/* <table
                style={{
                  width: '100%',
                  height: '100%'
                }}
              >
                <tr>
                  <td
                    style={{
                      fontWeight: 'bold'
                    }}
                  >
                    Lot ID:
                  </td>
                  <td>{lotId || ''}</td>
                  <td
                    style={{
                      fontWeight: 'bold'
                    }}
                  >
                    Quantity:
                  </td>
                  <td>{lotQuantity || ''}</td>
                  <td
                    style={{
                      fontWeight: 'bold'
                    }}
                  >
                    Discount:
                  </td>
                  <td>{lotDiscount || ''}</td>
                </tr>

                <tr>
                  <td
                    style={{
                      fontWeight: 'bold'
                    }}
                  >
                    Status:
                  </td>
                  <td>
                    <Chip
                      variant='filled'
                      color={lotStatus.color_code || 'secondary'}
                      label={
                        <Typography variant='body2'>
                          {String(lotStatus?.type).toUpperCase() || 'Status Not Found'}
                        </Typography>
                      }
                    ></Chip>
                  </td>
                  <td
                    style={{
                      fontWeight: 'bold'
                    }}
                  >
                    Amount:
                  </td>
                  <td>{lotAmount || ''}</td>
                  <td
                    style={{
                      fontWeight: 'bold'
                    }}
                  >
                    Tax:
                  </td>
                  <td>{lotTax || ''}</td>
                </tr>
              </table> */}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Dialog
        open={addUploaderOpen}
        onClose={handleAddUploaderClose}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        scroll='paper'
        fullWidth
      >
        <DialogTitle variant='h5'>Update Lot Image</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ pt: 1 }}>
            <Grid item xs={12}>
              {/* Pass the handleFileUpload function to the ItemImageUploader component */}
              <ItemImageUploader onUpload={handleFileUpload} uploadedFile={uploadedFile} />
              <Typography variant='body2' color='error' sx={{ mt: 1 }}>
                {/* {formErrors.uploadedFile} */}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ m: 2, mt: 0, gap: 2 }}>
          <Button variant='contained' onClick={handleAddUploaderClose} sx={{ px: 4 }}>
            Cancel
          </Button>
          <Button variant='contained' onClick={handleUploadedFiles} sx={{ px: 4 }}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default LotViewForm
