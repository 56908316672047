import { Icon } from '@iconify/react'
import {
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { randomId } from '@mui/x-data-grid-generator'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import apiDefinitions from '../../../../api/apiDefinitions'
import { useCurrentQueryParams, useCurrentRoute, useUserID } from '../../../../hooks/customHooks'
import LotAddForm from './lotForm'
import Swal from 'sweetalert2'
import { Route, useNavigate } from 'react-router'

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626'
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959'
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343'
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c'
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff'
  }
}))

const currencyFormatting = value => {
  // Parse the value as a float
  const floatValue = parseFloat(value)

  // Check if floatValue is a valid number
  if (!isNaN(floatValue)) {
    // Convert the float value to a string with two decimal places using toFixed
    return floatValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  } else {
    // Return the original value if it's not a valid number
    return value
  }
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

export default function FullFeaturedTable() {
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''
  const itemID = useCurrentQueryParams('id') ?? ''

  const navigate = useNavigate()

  const [lotNameError, setLotNameError] = useState('')

  const [calcID, setCalcID] = useState(null)

  const [vendors, setVendors] = useState([])

  const [optionalFields, setOptionalFields] = useState([])
  const [isInitialized, setIsInitialized] = useState(false)
  const [columns, setColumns] = useState([
    {
      id: 'vendor',
      label: 'Vendor',
      minWidth: 150,
      fieldType: 'select'
    },
    {
      id: 'quantity',
      label: 'Quantity',
      minWidth: 100,
      colAlign: 'center',
      rowAlign: 'center',
      fieldType: 'number',
      isCurrency: false
    },
    {
      id: 'unitPrice',
      label: 'Unit Price',
      minWidth: 150,
      colAlign: 'center',
      rowAlign: 'center',
      fieldType: 'number',
      isCurrency: true
    },
    {
      id: 'totalPrice',
      label: 'Total Price',
      minWidth: 150,
      colAlign: 'center',
      rowAlign: 'center',
      fieldType: 'number',
      isCurrency: true,
      isCalculated: true
    },
    {
      id: 'tax',
      label: 'Tax',
      minWidth: 150,
      colAlign: 'center',
      rowAlign: 'center',
      fieldType: 'number',
      isCurrency: true
    },
    {
      id: 'discount',
      label: 'Discount',
      minWidth: 150,
      colAlign: 'center',
      rowAlign: 'center',
      fieldType: 'number',
      isCurrency: true
    },
    {
      id: 'finalPrice',
      label: 'Final Price',
      minWidth: 150,
      colAlign: 'center',
      rowAlign: 'center',
      fieldType: 'number',
      isCurrency: true,
      isCalculated: true
    },
    {
      id: 'specialNote',
      label: 'Special Note',
      minWidth: 300,
      colAlign: 'center',
      rowAlign: 'justify',
      fieldType: 'multiline'
    }
  ])

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '' && itemID !== '') {
      // Fetch the optional fields from the API
      apiDefinitions
        .getItemOptFieldsByItemId(userID, currentRouteString, itemID)
        .then(response => {
          if (response.data.code === 200) {
            // console.log('Optional fields:', response.data.data)
            setOptionalFields(response.data.data)
          } else {
            console.log('Error fetching optional fields:', response.data.message)
            // toast.error('Error: ' + response.data.message)
          }
        })
        .catch(error => {
          console.error('Error fetching optional fields:', error)
          // toast.error('Error fetching optional fields!')
        })

      // Fetch the vendors from the API
      apiDefinitions
        .getAllDataList(userID, currentRouteString, 'vendor')
        .then(response => {
          if (response.data.code === 200) {
            // console.log('Vendors:', response.data.data)
            setVendors(response.data.data)
          } else {
            console.log('Error fetching vendors:', response.data.message)
            toast.error('Error: ' + response.data.message)
          }
        })
        .catch(error => {
          console.error('Error fetching vendors:', error)
          toast.error('Error fetching vendors!')
        })
    }
  }, [currentRouteString, itemID, userID])

  useEffect(() => {
    // console.log('Optional fields:', optionalFields)

    if (optionalFields && optionalFields.length > 0 && !isInitialized) {
      const newColumns = optionalFields.map(field => {
        const column = {
          id: field.optional_field.data_grid_field,
          label: field.optional_field.field_label,
          minWidth: 150,
          colAlign: 'center',
          rowAlign: 'center',
          fieldType: field.optional_field.type,
          isCurrency: false
        }

        if (field.optional_field.list_values) {
          column.listValues = field.optional_field.list_values
        }

        return column
      })

      setColumns(oldColumns => [...oldColumns, ...newColumns])
      setIsInitialized(true)
    }
  }, [isInitialized, optionalFields])

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const [duplicateCount, setDuplicateCount] = useState(1)
  const [selectedDuplicationRow, setSelectedDuplicationRow] = useState(null)
  const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = useState(false)

  const [numRows, setNumRows] = useState(1)
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)

  const [editTempRows, setEditTempRows] = useState([])
  const [savedRows, setSavedRows] = useState([])

  const [isSaveEnabled, setIsSaveEnabled] = useState(false)

  const handleOpenDuplicateDialog = () => {
    setIsDuplicateDialogOpen(true)
  }

  const handleCloseDuplicateDialog = () => {
    setIsDuplicateDialogOpen(false)
  }

  const handleOpenAddDialog = () => {
    setIsAddDialogOpen(true)
  }

  const handleCloseAddDialog = () => {
    setIsAddDialogOpen(false)
  }

  const [rows, setRows] = useState([])

  const [lotCurrency, setLotCurrency] = useState('')

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleNewRowAdd = () => {
    for (let i = 0; i < numRows; i++) {
      const id = randomId()
      let newRow = {
        id: id,
        vendor: '',
        quantity: 0,
        unitPrice: 0,
        totalPrice: 0,
        tax: 0,
        discount: 0,
        finalPrice: 0,
        specialNote: '',
        editable: true
      }

      const additionalColumns = optionalFields.map(field => {
        const fieldName = field.optional_field.data_grid_field
        return {
          [fieldName]: null
        }
      })

      // Merge the additional columns into the newRow object
      additionalColumns.forEach(column => {
        newRow = { ...newRow, ...column }
      })

      setRows(oldRows => [...oldRows, newRow])
    }
    setIsAddDialogOpen(false)
    setNumRows(1)
  }

  const handleRowSave = id => {
    // console.log('Row ID:', id)

    const editedRow = rows.find(row => row.id === id)
    const updatedRow = { ...editedRow, editable: false }

    // Remove the editable property from the updatedRow
    const { editable, ...updatedRowWithoutEditable } = updatedRow

    setSavedRows(oldSavedRows => [
      ...oldSavedRows.filter(savedRow => savedRow.id !== id),
      updatedRowWithoutEditable // Add the updated row without the editable property
    ])

    const updatedRows = rows.map(row => (row.id === id ? { ...updatedRow } : row))
    setRows(updatedRows)
  }

  const handleRowEdit = id => {
    // Find the row to edit
    const editRowCopy = rows.find(row => row.id === id)

    // Make a copy of editRowCopy to store the initial state
    const editRowCopyInitial = { ...editRowCopy }

    // Update editTempRows with the initial state of the row
    setEditTempRows(prevEditTempRows => ({
      ...prevEditTempRows,
      [id]: editRowCopyInitial
    }))

    // Update the 'editable' property of the row being edited to true
    const updatedRows = rows.map(row => (row.id === id ? { ...row, editable: true } : row))
    setRows(updatedRows)
  }

  const handleRowDuplicate = selectedRowId => {
    const originalRow = rows.find(row => row.id === selectedRowId)
    for (let i = 0; i < duplicateCount; i++) {
      const idCopy = randomId()
      const copiedRow = { ...originalRow, id: idCopy, editable: true }
      setRows(oldRows => [...oldRows, copiedRow])
    }
    setIsDuplicateDialogOpen(false)
    setDuplicateCount(1)
  }

  const handleRowDelete = id => {
    // console.log('Row ID:', id)
    const newRows = rows.filter(row => row.id !== id)
    setRows(newRows)

    const updatedSavedRows = savedRows.filter(row => row.id !== id)
    setSavedRows(updatedSavedRows)
  }

  const handleRowCancel = id => {
    // Find the original row from editTempRows
    const originalRow = editTempRows[id]

    if (originalRow) {
      // Update rows with the original row to restore initial state
      const updatedRows = rows.map(row => (row.id === id ? { ...originalRow } : row))
      setRows(updatedRows)

      // Remove the canceled row from editTempRows
      const updatedEditTempRows = { ...editTempRows }
      delete updatedEditTempRows[id]
      setEditTempRows(updatedEditTempRows)
    } else {
      // If the original row is not found, remove the row from rows
      const newRows = rows.filter(row => row.id !== id)
      setRows(newRows)
    }
  }

  const calculateTotalPrice = id => {
    const editedRow = rows.find(row => row.id === id)

    if (!editedRow) return // Ensure the row is found

    // Parse quantity and unitPrice, replacing NaN with 0
    const quantity = parseInt(editedRow.quantity) || 0
    const unitPrice = parseFloat(editedRow.unitPrice) || 0

    // Calculate the total price
    const totalPrice = quantity * unitPrice

    // Update the row with the total price
    const updatedRow = { ...editedRow, totalPrice }

    // Update the rows array with the updated row
    const updatedRows = rows.map(row => (row.id === id ? updatedRow : row))
    setRows(updatedRows)

    // Set the calcID to trigger the useEffect
    setCalcID(id)
  }

  const calculateFinalPrice = id => {
    const editedRow = rows.find(row => row.id === id)

    if (!editedRow) return // Ensure the row is found

    // Parse tax and discount, replacing NaN with 0
    const tax = parseFloat(editedRow.tax) || 0
    const discount = parseFloat(editedRow.discount) || 0
    const totalPrice = parseFloat(editedRow.totalPrice) || 0

    // Calculate the final price
    const finalPrice = totalPrice + tax - discount

    // Update the row with the final price
    const updatedRow = { ...editedRow, finalPrice }

    // Update the rows array with the updated row
    const updatedRows = rows.map(row => (row.id === id ? updatedRow : row))
    setRows(updatedRows)
  }

  useEffect(() => {
    if (calcID !== null) {
      calculateFinalPrice(calcID)
      setCalcID(null)
    }

    if (rows.length > 0) {
      const checkRows = rows.some(element => element.editable === true)

      if (checkRows) {
        setIsSaveEnabled(true)
      } else {
        setIsSaveEnabled(false)
      }
    } else {
      setIsSaveEnabled(false)
    }

    //eslint-disable-next-line
  }, [calcID, rows])

  // useEffect(() => console.log('Rows:', rows), [rows])

  // useEffect(() => console.log('Edited Rows:', editTempRows), [editTempRows])

  // useEffect(() => console.log('Saved Rows:', savedRows), [savedRows])

  const handleSaveAll = async () => {
    const updatedSavedRows = rows.map(row => {
      // Make a copy of the row without the editable property
      const { editable, ...updatedRowWithoutEditable } = row
      return updatedRowWithoutEditable
    })

    setSavedRows(updatedSavedRows) // Update savedRows state

    const updatedRows = rows.map(row => ({
      ...row,
      editable: false // Set editable to false for all rows
    }))

    setRows(updatedRows) // Update rows state
  }

  const [lotPayload, setLotPayload] = useState([])
  const [lotImage, setLotImage] = useState(null)

  const handlePayload = () => {
    if (lotPayload.lot_name === '') {
      setLotNameError('Lot name cannot be empty!')
      return
    }

    let loopCount = 0
    // check if all rows has editable false
    const checkRows = rows.some(element => element.editable === true)

    if (rows.length > 0) {
      if (!checkRows) {
        // Extract data grid fields from optional fields
        const optionalFieldDataGridFields = optionalFields.map(field => field.optional_field.data_grid_field)

        // Modify each row in savedRows
        const modifiedSavedRows = savedRows.map(row => {
          loopCount++
          console.log('loop 1')

          // Extract keys of the current row
          const rowKeys = Object.keys(row)

          // Separate keys into optional and remaining fields
          const { matchingFields, remainingKeys } = rowKeys.reduce(
            (acc, key) => {
              if (optionalFieldDataGridFields.includes(key)) {
                acc.matchingFields.push(key)
              } else {
                acc.remainingKeys.push(key)
              }
              return acc
            },
            { matchingFields: [], remainingKeys: [] }
          )

          // Extract optional field details if there are matching fields
          if (matchingFields.length > 0) {
            const optionalFieldDetails = matchingFields.map(field => {
              loopCount++
              console.log('loop 2')

              return {
                optional_field_id:
                  optionalFields.find(opt => opt.optional_field.data_grid_field === field)?.optional_field.id || null,
                value: row[field]
              }
            })

            row.optional_field_details = optionalFieldDetails

            // Remove matchingFields keys from row
            matchingFields.forEach(field => {
              delete row[field]
              loopCount++
              console.log('loop 3')
            })
          }

          return row
        })

        const updatedModifiedSavedRows = modifiedSavedRows.map(field => {
          loopCount++
          console.log('loop 4')
          return {
            ...field,
            item_id: Number(itemID)
          }
        })

        const modifiedPayload = updatedModifiedSavedRows.map(field => {
          loopCount++
          console.log('loop 5')
          const newField = {
            id: field.id,
            item_id: field.item_id,
            vendor_id: field.vendor,
            quantity: field.quantity,
            discount: field.discount,
            unit_price: field.unitPrice,
            total_price: field.totalPrice,
            item_final_tot_price: field.finalPrice,
            tax: field.tax,
            special_note: field.specialNote,
            optional_field_details: field.optional_field_details
          }

          return newField
        })

        // Construct the final payload
        const payload = {
          item_details: modifiedPayload,
          lot_details: lotPayload
        }

        const payloadData = new FormData()
        payloadData.append('json', new Blob([JSON.stringify(payload)], { type: 'application/json' }))

        // payloadData.append('lot_image', lotImage[0] ? lotImage[0] : null)

        if (lotImage[0]) {
          payloadData.append('lot_image', lotImage[0])
        } else {
          payloadData.append('lot_image', new Blob([], { type: '' }))
        }

        console.log('Payload:', payload)
        console.log('Loop Count', loopCount)

        Swal.fire({
          title: 'Are you sure?',
          text: 'You wan to create a lot with the new item details!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, create it!',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true
        }).then(result => {
          if (result.isConfirmed) {
            const myPromise = new Promise((resolve, reject) => {
              apiDefinitions
                .postLotItemDetailCreate(userID, currentRouteString, payloadData)
                .then(response => {
                  if (response.data.code === 201) {
                    resolve(response.data.message)
                    setTimeout(() => {
                      navigate('/items/viewitem?id=' + itemID)
                    }, 2000)
                  } else {
                    reject(response.data.message)
                  }
                })
                .catch(error => {
                  console.error('Error adding lot:', error)
                  reject('Error adding lot!')
                })
            })

            toast
              .promise(myPromise, {
                loading: 'Adding lot...',
                success: message => `Success: ${message}`, // Use the message from the resolved promise
                error: errorMessage => `Error: ${errorMessage}`, // Use the message from the rejected promise
                duration: 5000
              })
              .then(() => {})
              .catch(() => {
                console.log('Error')
              })
          } else {
            Swal.fire('Cancelled', 'Your lot was not added :)', 'error')
          }
        })
      } else {
        alert('There are unsaved changes in rows!')
      }
    } else {
      alert('There are no rows!')
    }
  }

  const handleFormData = (data, image) => {
    // console.log('FormData:', data)
    // console.log('Image:', image)

    setLotPayload(data)
    setLotImage(image)
  }

  const handleCurrency = currency => {
    setLotCurrency(currency)
  }

  return (
    <>
      <LotAddForm
        lotData={rows}
        onFormChange={handleFormData}
        selectedCurrency={handleCurrency}
        lotNameError={lotNameError}
        setLotNameError={setLotNameError}
      />

      <Card
        sx={{
          mt: 1
        }}
      >
        <Grid
          container
          spacing={1}
          sx={{
            pt: 2,
            px: 2
          }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box>
                <Button variant='outlined' color='primary' sx={{ '& svg': { mr: 2 } }} onClick={handleOpenAddDialog}>
                  <Icon fontSize='1.125rem' icon='mingcute:add-fill' />
                  Add Item Record
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TableContainer sx={{ height: 280 }}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell key={column.id} align={column.colAlign} style={{ minWidth: column.minWidth }}>
                        {column.label}
                        {column.fieldType === 'number' && column.isCurrency ? ` (${lotCurrency})` : null}
                      </TableCell>
                    ))}
                    <TableCell key='actions' align='center' style={{ minWidth: 150 }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                    return (
                      <TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
                        {columns.map(column => {
                          const value = row[column.id]
                          return (
                            <TableCell key={column.id} align={column.rowAlign}>
                              {row.editable && !column.isCalculated ? (
                                column.fieldType === 'select' ? (
                                  <Select
                                    labelId='demo-simple-select-label'
                                    id='demo-simple-select'
                                    value={value || ''}
                                    fullWidth
                                    onChange={e => {
                                      const newValue = e.target.value
                                      row[column.id] = newValue
                                      setRows([...rows])
                                    }}
                                  >
                                    <MenuItem value='' disabled>
                                      <em>None</em>
                                    </MenuItem>

                                    {column.id === 'vendor'
                                      ? vendors.map(vendor =>
                                          vendor.vendor_name !== null && vendor.vendor_name !== '' ? (
                                            <MenuItem key={vendor.id} value={vendor.id}>
                                              {vendor.vendor_name}
                                            </MenuItem>
                                          ) : null
                                        )
                                      : column.listValues.map(listValue => (
                                          <MenuItem key={listValue.key} value={listValue.key}>
                                            {listValue.value}
                                          </MenuItem>
                                        ))}
                                  </Select>
                                ) : column.fieldType === 'multiline' ? (
                                  <TextField
                                    id='outlined-multiline-static'
                                    multiline
                                    variant='outlined'
                                    value={value}
                                    fullWidth
                                    onChange={e => {
                                      const newValue = e.target.value
                                      row[column.id] = newValue
                                      setRows([...rows])
                                    }}
                                  />
                                ) : column.fieldType === 'number' ? (
                                  <TextField
                                    id='outlined-number'
                                    type='number'
                                    onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                                    onFocus={e => e.target.select()}
                                    value={value}
                                    variant='outlined'
                                    fullWidth
                                    onChange={e => {
                                      const newValue = e.target.value
                                      let convertedValue

                                      if (column.isCurrency) {
                                        convertedValue = parseFloat(newValue)
                                      } else {
                                        // convertedValue = parseInt(newValue)
                                        convertedValue = newValue
                                      }

                                      row[column.id] = convertedValue
                                      setRows([...rows])

                                      // Check if the updated field is 'quantity' or 'unitPrice'
                                      if (column.id === 'quantity' || column.id === 'unitPrice') {
                                        calculateTotalPrice(row.id)
                                      }

                                      // Check if the updated field is 'tax' or 'discount'
                                      if (column.id === 'tax' || column.id === 'discount') {
                                        calculateFinalPrice(row.id)
                                      }
                                    }}
                                    InputProps={{
                                      inputProps: {
                                        min: 0
                                      }
                                    }}
                                  />
                                ) : column.fieldType === 'text' ? (
                                  <TextField
                                    id='outlined-basic'
                                    variant='outlined'
                                    value={value}
                                    fullWidth
                                    onChange={e => {
                                      const newValue = e.target.value
                                      row[column.id] = newValue
                                      setRows([...rows])
                                    }}
                                  />
                                ) : column.fieldType === 'date' ? (
                                  <TextField
                                    id='date'
                                    type='date'
                                    value={value}
                                    variant='outlined'
                                    fullWidth
                                    onChange={e => {
                                      const newValue = e.target.value
                                      row[column.id] = newValue
                                      setRows([...rows])
                                    }}
                                  />
                                ) : column.fieldType === 'time' ? (
                                  <TextField
                                    id='time'
                                    type='time'
                                    value={value}
                                    variant='outlined'
                                    fullWidth
                                    onChange={e => {
                                      const newValue = e.target.value
                                      row[column.id] = newValue
                                      setRows([...rows])
                                    }}
                                  />
                                ) : column.fieldType === 'datetime' ? (
                                  <TextField
                                    id='datetime-local'
                                    type='datetime-local'
                                    value={value}
                                    variant='outlined'
                                    fullWidth
                                    onChange={e => {
                                      const newValue = e.target.value
                                      row[column.id] = newValue
                                      setRows([...rows])
                                    }}
                                  />
                                ) : column.fieldType === 'multiple_select' ? (
                                  <Select
                                    labelId='demo-multiple-chip-label'
                                    id='demo-multiple-chip'
                                    multiple
                                    value={value || []}
                                    fullWidth
                                    onChange={e => {
                                      const newValue = e.target.value
                                      row[column.id] = newValue
                                      setRows([...rows])
                                    }}
                                    renderValue={selected => (
                                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected?.map(value => (
                                          <Chip
                                            key={value}
                                            label={column.listValues.find(listValue => listValue.key === value)?.value}
                                          />
                                        ))}
                                      </Box>
                                    )}
                                    MenuProps={MenuProps}
                                  >
                                    {column.listValues.map(listValue => (
                                      <MenuItem key={listValue.key} value={listValue.key}>
                                        {listValue.value}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                ) : (
                                  <Typography variant='body1'>Invalid Column</Typography>
                                )
                              ) : column.fieldType === 'number' && column.isCurrency ? (
                                currencyFormatting(value)
                              ) : column.fieldType === 'select' ? (
                                column.id === 'vendor' ? (
                                  vendors.find(vendor => vendor.id === value)?.vendor_name
                                ) : (
                                  column.listValues.find(listValue => listValue.key === value)?.value
                                )
                              ) : column.fieldType === 'multiple_select' ? (
                                value
                                  ?.map(val => column.listValues.find(listValue => listValue.key === val)?.value)
                                  .join(', ')
                              ) : column.fieldType === 'datetime' ? (
                                value ? (
                                  value.toLocaleString().split('T')[0] +
                                  ' ' +
                                  value.toLocaleString().split('T')[1].split('.')[0]
                                ) : (
                                  value
                                )
                              ) : (
                                value
                              )}
                            </TableCell>
                          )
                        })}
                        <TableCell key='actions'>
                          {row.editable ? (
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 1,
                                minWidth: '100%'
                              }}
                            >
                              <IconButton aria-label='save' color='primary' onClick={() => handleRowSave(row.id)}>
                                <Icon icon='bx:save' />
                              </IconButton>
                              <IconButton aria-label='cancel' color='primary' onClick={() => handleRowCancel(row.id)}>
                                <Icon icon='fluent-mdl2:cancel' />
                              </IconButton>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 1,
                                minWidth: '100%'
                              }}
                            >
                              <IconButton aria-label='edit' color='primary' onClick={() => handleRowEdit(row.id)}>
                                <Icon icon='tabler:edit' />
                              </IconButton>
                              <IconButton
                                aria-label='duplicate'
                                color='primary'
                                onClick={() => {
                                  setSelectedDuplicationRow(row.id)
                                  handleOpenDuplicateDialog()
                                }}
                              >
                                <Icon icon='tabler:copy' />
                              </IconButton>
                              <IconButton aria-label='delete' color='primary' onClick={() => handleRowDelete(row.id)}>
                                <Icon icon='fluent:delete-12-regular' />
                              </IconButton>
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  {rows.length <= 0 && (
                    <TableRow>
                      <TableCell colSpan={columns.length + 1} align='center'>
                        <StyledGridOverlay>
                          <svg width='120' height='150' viewBox='0 0 185 155' aria-hidden focusable='false'>
                            <g fill='none' fillRule='evenodd'>
                              <g transform='translate(24 31.67)'>
                                <ellipse className='ant-empty-img-5' cx='67.797' cy='106.89' rx='67.797' ry='12.668' />
                                <path
                                  className='ant-empty-img-1'
                                  d='M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z'
                                />
                                <path
                                  className='ant-empty-img-2'
                                  d='M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z'
                                />
                                <path
                                  className='ant-empty-img-3'
                                  d='M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z'
                                />
                              </g>
                              <path
                                className='ant-empty-img-3'
                                d='M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z'
                              />
                              <g className='ant-empty-img-4' transform='translate(149.65 15.383)'>
                                <ellipse cx='20.654' cy='3.167' rx='2.849' ry='2.815' />
                                <path d='M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z' />
                              </g>
                            </g>
                          </svg>
                          <Box sx={{ mt: 1.15 }}>No Rows</Box>
                        </StyledGridOverlay>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component='div'
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>

          <Grid container spacing={3} sx={{ pb: 2, justifyContent: 'flex-end' }}>
            <Grid item xs={2}>
              <Button
                variant='outlined'
                fullWidth
                color='primary'
                sx={{ '& svg': { mr: 2 }, mb: { xs: 2, md: 0 } }}
                onClick={handleSaveAll}
                disabled={!isSaveEnabled}
              >
                <Icon fontSize='1.125rem' icon='ion:save-sharp' />
                Save All
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant='outlined'
                fullWidth
                color='primary'
                sx={{ '& svg': { mr: 2 }, mb: { xs: 2, md: 0 } }}
                onClick={handlePayload}
              >
                <Icon fontSize='1.125rem' icon='formkit:submit' />
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <Dialog open={isDuplicateDialogOpen} onClose={handleCloseDuplicateDialog}>
        <DialogTitle>Duplicate Rows</DialogTitle>
        <DialogContent>
          <Box sx={{ py: 1 }}>
            <TextField
              label='Number of Duplicates'
              type='number'
              onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
              value={duplicateCount}
              onChange={e => {
                // Ensure the input is a non-negative number
                const inputValue = Number(e.target.value)
                const nonNegativeValue = inputValue >= 0 ? inputValue : 0
                setDuplicateCount(nonNegativeValue)
              }}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDuplicateDialog}>Cancel</Button>
          <Button onClick={() => handleRowDuplicate(selectedDuplicationRow)} color='primary'>
            Duplicate
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isAddDialogOpen} onClose={handleCloseAddDialog}>
        <DialogTitle>Add Rows</DialogTitle>
        <DialogContent>
          <Box sx={{ py: 1 }}>
            <TextField
              label='Number of Rows'
              type='number'
              onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
              value={numRows}
              onChange={e => {
                // Ensure the input is a non-negative number
                const inputValue = Number(e.target.value)
                const nonNegativeValue = inputValue >= 0 ? inputValue : 0
                setNumRows(nonNegativeValue)
              }}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog}>Cancel</Button>
          <Button onClick={handleNewRowAdd} color='primary'>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
