import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from './utils/Keycloak'
import VerticalLayout from './layouts/VerticalLayout'
import NavigationConfig from './navigation/nav' // Assuming this is your navigation configuration
import SubNavigationConfig from './navigation/subnav' // Assuming this is your navigation configuration
import Authentication from './helpers/Authentication'

// Import error pages
import NotAuthorized from './pages/401'
import NotFound from './pages/404'
import ServerError from './pages/500'
import ChangeLog from './pages/changelog'

const isValidPath = (navItems, path) => {
  for (const navItem of navItems) {
    if (navItem.path === path) {
      return true
    }
    if (navItem.children) {
      const isChildValid = isValidPath(navItem.children, path)
      if (isChildValid) {
        return true
      }
    }
  }

  for (const navItem of SubNavigationConfig()) {
    if (navItem.path === path) {
      return true
    }
  }
  return false
}

const App = () => {
  const currentPath = window.location.pathname
  const NaviArray = NavigationConfig()
  const SubNaviArray = SubNavigationConfig()

  const [isValidState, setIsValidState] = useState('-99')

  useEffect(() => {
    const isValid = isValidPath(NaviArray, currentPath, SubNaviArray)
    setIsValidState(isValid)
  }, [currentPath, NaviArray, SubNaviArray])

  return (
    <div>
      <ReactKeycloakProvider authClient={keycloak}>
        <BrowserRouter>
          <Routes>
            <Route path='/401' element={<NotAuthorized />} />
            <Route path='/404' element={<NotFound />} />
            <Route path='/500' element={<ServerError />} />
            <Route path='/changelog' element={<ChangeLog />} />

            <Route
              path='/*'
              element={
                isValidState ? (
                  <Authentication>
                    <VerticalLayout />
                  </Authentication>
                ) : (
                  <Navigate to='/404' replace />
                )
              }
            />
          </Routes>
        </BrowserRouter>
      </ReactKeycloakProvider>
    </div>
  )
}

export default App
