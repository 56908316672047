// ** Next Import

// ** MUI Components
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

// ** React Imports
import { useState } from 'react'

// ** MUI Imports
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'

// ** Icon Imports
import { Icon } from '@iconify/react'

import changes from '../@fakeDB/changes'

import Alert from '@mui/material/Alert'

const ChangeLog = () => {
  const [expanded, setExpanded] = useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleBack = () => {
    window.location.replace('/')
  }

  return (
    <Box
      sx={{
        minWidth: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh'
      }}
    >
      <Box sx={{ maxWidth: '70%', my: 10 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant='h4'>
            <strong>Change Log</strong>
          </Typography>
          <Button variant='contained' color='primary' onClick={handleBack}>
            Back to Home
          </Button>
        </Box>
        <Box>
          {changes.map((change, index) => (
            <Accordion expanded={expanded == `panel${index}`} onChange={handleChange(`panel${index}`)} key={index}>
              <AccordionSummary
                id={`controlled-panel-header-${index}`}
                aria-controls={`controlled-panel-content-${index}`}
                expandIcon={<Icon fontSize='1.25rem' icon='tabler:chevron-down' />}
              >
                <Typography variant='h5'>Version {change.version}</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  width: '800px'
                }}
              >
                <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                  Released On: {change.date}
                </Typography>
                <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                  Released By: {change.author}
                </Typography>

                {change.changes.map((change, index) => (
                  <Alert
                    severity={
                      change.type == 'added'
                        ? 'success'
                        : change.type == 'changed'
                        ? 'warning'
                        : change.type == 'removed'
                        ? 'error'
                        : 'info'
                    }
                    key={index}
                    icon={
                      <Icon
                        fontSize='1.25rem'
                        icon={
                          change.type == 'added'
                            ? 'tabler:plus'
                            : change.type == 'changed'
                            ? 'tabler:arrows-exchange'
                            : change.type == 'removed'
                            ? 'tabler:minus'
                            : 'tabler:alert-triangle'
                        }
                      />
                    }
                    sx={{ my: 3 }}
                  >
                    {change.description}
                  </Alert>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default ChangeLog
