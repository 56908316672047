import { api } from './apiBase'

const apiDefinitions = {
  /* Common Data Get All */
  getAllDataList: async function (userID, currentRoute, type, searchKey) {
    return await api.get(
      `account-payable/data-list/get-all-data?user_id=${userID}&current_route=${currentRoute}&type=${type}&search_key=${searchKey}`
    )
  },

  getUsersAndDepartmentsExcludingCurrentUser: async function (userID, currentRoute, type, searchKey) {
    return await api.get(
      `/account-payable/data-list/get-all/for-create-assignment/${type}?user_id=${userID}&current_route=${currentRoute}&search_key=${searchKey}`
    )
  },
  /* Common Data Get All */
  /**********************************/
  /* Item Section APIs Start */

  /********** Item Configs **********/
  getAllItemCategories: async function (userID, currentRoute) {
    return await api.get(`/account-payable/category/all?user_id=${userID}&current_route=${currentRoute}`)
  },

  getNextItemID: async function (userID, currentRoute, itemCategory) {
    return await api.get(
      `/account-payable/category/get-next-item-by-category/${itemCategory}?user_id=${userID}&current_route=${currentRoute}`
    )
  },

  getAllItemUnits: async function (userID, currentRoute) {
    return await api.get(`/account-payable/base-unit/all?user_id=${userID}&current_route=${currentRoute}`)
  },

  getAllItemAssetTypes: async function (userID, currentRoute) {
    return await api.get(`/account-payable/asset-type/all?user_id=${userID}&current_route=${currentRoute}`)
  },

  getAllItemOptionalFields: async function (userID, currentRoute) {
    return await api.get(`account-payable/item-master-config/get-all?user_id=${userID}&current_route=${currentRoute}`)
  },

  getItemOptFieldsByItemId: async function (userID, currentRoute, itemID) {
    return api.get(
      `/account-payable/item-master-config/get-all-by-item-id/${itemID}?user_id=${userID}&current_route=${currentRoute}`
    )
  },
  /********** Item Configs **********/
  /********** Items **********/

  postAddNewItem: async function (userID, currentRoute, item) {
    return await api.post(`/account-payable/item/create?user_id=${userID}&current_route=${currentRoute}`, item)
  },

  getAllItemsPaginated: async function (userID, currentRoute, offset, pageSize) {
    return await api.get(
      `/account-payable/item/all?user_id=${userID}&current_route=${currentRoute}&off_set=${offset}&page_size=${pageSize}`
    )
  },

  getItemById: async function (userID, currentRoute, itemID) {
    return await api.get(
      `/account-payable/item/get-item-by-id/${itemID}?user_id=${userID}&current_route=${currentRoute}`
    )
  },

  putItemImageUpdate: async function (userID, currentRoute, itemID, fileID, imageFile) {
    return await api.put(
      `/account-payable/item/update-image?file_id=${fileID}&item_id=${itemID}&user_id=${userID}&current_route=${currentRoute}`,
      imageFile
    )
  },

  putItemUpdate: async function (userID, currentRoute, item) {
    return await api.put(
      `/account-payable/item/update-item-by-id?user_id=${userID}&current_route=${currentRoute}`,
      item
    )
  },

  putOptFieldUpdate: async function (userID, currentRoute, field) {
    return await api.put(
      `/account-payable/item/optional-field/update-item-id?user_id=${userID}&current_route=${currentRoute}`,
      field
    )
  },

  /********** Items **********/
  /********** Item Details **********/

  getAllLotDetails: async function (userID, currentRoute, offset, pageSize) {
    return await api.get(
      `/account-payable/item-detail/lot/get-all?user_id=${userID}&current_route=${currentRoute}&off_set=${offset}&page_size=${pageSize}`
    )
  },

  getAllLotDetailsByItemId: async function (userID, currentRoute, itemID, offset, pageSize) {
    return await api.get(
      `/account-payable/item-detail/get-lots-by-item/${itemID}?user_id=${userID}&current_route=${currentRoute}&off_set=${offset}&page_size=${pageSize}`
    )
  },

  getItemDetailByLotId: async function (userID, currentRoute, lotID, payload) {
    return await api.post(
      `/account-payable/item-detail/get-item-detail-by-lot?lot_id=${lotID}&user_id=${userID}&current_route=${currentRoute}`,
      payload
    )
  },

  postLotItemDetailCreate: async function (userID, currentRoute, payload) {
    return await api.post(
      `/account-payable/item-detail/lot/create?user_id=${userID}&current_route=${currentRoute}`,
      payload
    )
  },

  putItemDetailUpdateMultiple: async function (userID, currentRoute, payload) {
    return await api.put(
      `/account-payable/item-detail/update-multiple?user_id=${userID}&current_route=${currentRoute}`,
      payload
    )
  },

  putItemDetailUpdateSingle: async function (userID, currentRoute, payload) {
    return await api.put(`/account-payable/item-detail/update?user_id=${userID}&current_route=${currentRoute}`, payload)
  },

  getItemDetailsByItemDetailsID: async function (userID, currentRoute, itemDetailID) {
    return await api.get(
      `/account-payable/item-detail/get-by-id/${itemDetailID}?user_id=${userID}&current_route=${currentRoute}`
    )
  },

  getItemDetailsBarcode: async function (userID, currentRoute, payload) {
    return await api.post(
      `/account-payable/item-detail/get-item-barcode-details?user_id=${userID}&current_route=${currentRoute}`,
      payload
    )
  },
  /********** Item Details **********/

  /********** Item Assign **********/
  getAssignedItemByType: async function (type, userID, currentRoute, offset, pageSize, searchKey) {
    return await api.get(
      `/account-payable/item-assign/get-assigned-items/by-user-type/${type}?user_id=${userID}&current_route=${currentRoute}&off_set=${offset}&page_size=${pageSize}&search_key=${searchKey}`
    )
  },

  getAssignRequestByTypes: async function (userID, currentRoute, type, offset, pageSize) {
    return await api.get(
      `/account-payable/item-assign/get-all-requests/${type}?user_id=${userID}&current_route=${currentRoute}&off_set=${offset}&page_size=${pageSize}`
    )
  },

  putItemAssignRequestByTypes: async function (userID, currentRoute, type, payload) {
    return await api.put(
      `/account-payable/item-assign/update-request/${type}?user_id=${userID}&current_route=${currentRoute}`,
      payload
    )
  },

  getSubAssingmentsByAssingmentID: async function (assignmentID, userID, currentRoute, offset, pageSize) {
    return await api.get(
      `/account-payable/item-assign/get-assigned-to-details/by-assigned-id/${assignmentID}?user_id=${userID}&current_route=${currentRoute}&off_set=${offset}&page_size=${pageSize}`
    )
  },

  getAdminLevelAssignments: async function (userID, currentRoute, offset, pageSize, type, itemDetailId) {
    return await api.get(
      `/account-payable/item-assign/view-assignment-list/${type}/${itemDetailId}?user_id=${userID}&current_route=${currentRoute}&off_set=${offset}&page_size=${pageSize}`
    )
  },

  createFirstLevelAssignment: async function (userID, currentRoute, payload) {
    return await api.post(
      `/account-payable/item-assign/create?user_id=${userID}&current_route=${currentRoute}`,
      payload
    )
  },
  putRequestSelfUnAssign: async function (userID, currentRoute, payload) {
    return await api.put(
      `/account-payable/item-assign/create-request/self-unassigned-pending-request?user_id=${userID}&current_route=${currentRoute}`,
      payload
    )
  },
  /********** Item Assign **********/

  /********** Category **********/
  getAllCategories: async function (userID, currentRoute, offset, pageSize) {
    return await api.get(
      `/account-payable/category/get-all?user_id=${userID}&current_route=${currentRoute}&off_set=${offset}&page_size=${pageSize}`
    )
  },

  postCategoryCreate: async function (userID, currentRoute, payload) {
    return await api.post(`/account-payable/category/create?user_id=${userID}&current_route=${currentRoute}`, payload)
  },

  putCategoryDelete: async function (userID, currentRoute, categoryID) {
    return await api.put(
      `/account-payable/category/delete?user_id=${userID}&current_route=${currentRoute}&id=${categoryID}`
    )
  },
  /**********Category***********/
  /**********Vendor***********/

  getAllVendors: async function (userID, currentRoute, offset, pageSize) {
    return await api.get(
      `/account-payable/vendor/get-all?user_id=${userID}&current_route=${currentRoute}&off_set=${offset}&page_size=${pageSize}`
    )
  },

  postVendorCreate: async function (userID, currentRoute, payload) {
    return await api.post(`/account-payable/vendor/create?user_id=${userID}&current_route=${currentRoute}`, payload)
  },

  putVendorDelete: async function (userID, currentRoute, vendorID) {
    return await api.put(
      `/account-payable/vendor/delete-by-id/${vendorID}?user_id=${userID}&current_route=${currentRoute}`
    )
  },

  putVendorUpdate: async function (userID, currentRoute, payload) {
    return await api.put(
      `/account-payable/vendor/update-by-id?user_id=${userID}&current_route=${currentRoute}`,
      payload
    )
  },

  getVendorByID: async function (userID, currentRoute, vendorID) {
    return await api.get(
      `/account-payable/vendor/get-by-id/${vendorID}?user_id=${userID}&current_route=${currentRoute}`
    )
  },

  /**********Vendor***********/

  /********** Optional Field **************/

  getAllOptionalField: async function (userID, currentRoute, offset, pageSize) {
    return await api.get(
      `/account-payable/item-master-config/get-all-paginate?user_id=${userID}&current_route=${currentRoute}&off_set=${offset}&page_size=${pageSize}`
    )
  },

  postOptionalFieldCreate: async function (userID, currentRoute, payload) {
    return await api.post(
      `/account-payable/item-master-config/create/item-master-config?user_id=${userID}&current_route=${currentRoute}`,
      payload
    )
  },

  getOptionalFieldByID: async function (userID, currentRoute, optionalFieldID) {
    return await api.get(
      `/account-payable/item-master-config/get-by-id/${optionalFieldID}?user_id=${userID}&current_route=${currentRoute}`
    )
  },

  putOptionalFieldUpdate: async function (userID, currentRoute, payload) {
    return await api.put(
      `/account-payable/item-master-config/update/item-master-config?user_id=${userID}&current_route=${currentRoute}`,
      payload
    )
  },
  /********** Optional Field **************/

  /************Purchase Order Request***************/
  postItemSummeryCreate: async function (userID, currentRoute, payload) {
    return await api.post(`/purchase-order/request/create?user_id=${userID}&current_route=${currentRoute}`, payload)
  },

  postItemSummaryCreateWithID: async function (userID, currentRoute, payload) {
    return await api.post(
      `/purchase-order/request/details/create?user_id=${userID}&current_route=${currentRoute}`,
      payload
    )
  },

  getItemSummeryByPORID: async function (userID, currentRoute, poRID, offset, pageSize, isPaginate) {
    return await api.get(
      `/purchase-order/request/details/get-all/by-por-id/${poRID}?user_id=${userID}&current_route=${currentRoute}&off_set=${offset}&page_size=${pageSize}&is_paginate=${isPaginate}`
    )
  },

  /* ---------- Quotation ---------- */

  postQuotationCreate: async function (userID, currentRoute, payload) {
    return await api.post(
      `/purchase-order/request/vendor-quotation/create?user_id=${userID}&current_route=${currentRoute}`,
      payload
    )
  },

  getAllQuotationByPORID: async function (userID, currentRoute, poRID, offset, pageSize, isPaginate) {
    return await api.get(
      `/purchase-order/request/vendor-quotation/get-all/by-por-id/${poRID}?user_id=${userID}&current_route=${currentRoute}&off_set=${offset}&page_size=${pageSize}&is_paginate=${isPaginate}`
    )
  },

  putQuotationUpdate: async function (userID, currentRoute, payload) {
    return await api.put(
      `/purchase-order/request/vendor-quotation/update?user_id=${userID}&current_route=${currentRoute}`,
      payload
    )
  },

  /* ---------- Quotation ---------- */

  /* ---------- Chat ---------- */

  getChatByPORID: async function (userID, currentRoute, poRequestID) {
    return await api.get(
      `/account-payable/chat/get-chat-by-por-id?por_id=${poRequestID}&user_id=${userID}&current_route=${currentRoute}`
    )
  },

  postChatCreate: async function (userID, currentRoute, payload) {
    return await api.post(`/account-payable/chat/create?user_id=${userID}&current_route=${currentRoute}`, payload)
  },

  /* ---------- Chat ---------- */

  getAllPurchaseOrderRequest: async function (userID, currentRoute, offset, pageSize) {
    return await api.get(
      `/purchase-order/request/get-all?user_id=${userID}&current_route=${currentRoute}&off_set=${offset}&page_size=${pageSize}`
    )
  },

  getPORGeneralDetails: async function (userID, currentRoute, poRID) {
    return await api.get(`/purchase-order/request/get-by-id/${poRID}?user_id=${userID}&current_route=${currentRoute}`)
  },

  putPORItemSummaryUpdate: async function (userID, currentRoute, payload) {
    return await api.put(
      `/purchase-order/request/details/update?user_id=${userID}&current_route=${currentRoute}`,
      payload
    )
  },

  getItemSummeryHistoryById: async function (userID, currentRoute, itemSummaryID) {
    return await api.get(
      `/purchase-order/request/history/details/get-by-id/${itemSummaryID}?user_id=${userID}&current_route=${currentRoute}`
    )
  }
  /************Purchase Order Request***************/

  /* Item Section APIs End */
}

export default apiDefinitions
