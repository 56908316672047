import Keycloak from 'keycloak-js'

const KeycloakURL = process.env.REACT_APP_KEYCLOAK_URL
const KeycloakRealm = process.env.REACT_APP_KEYCLOAK_REALM
const KeycloakClientID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID

// console.log('Keycloak URL', KeycloakURL)
// console.log('Keycloak Realm', KeycloakRealm)
// console.log('Keycloak Client ID', KeycloakClientID)

// const keycloak = new Keycloak({
//   url: 'https://auth.kiu.lk/',
//   realm: 'Account-Manager-Auth',
//   clientId: 'auth-client-frontend'
// })

const keycloak = new Keycloak({
  url: KeycloakURL,
  realm: KeycloakRealm,
  clientId: KeycloakClientID
})

export default keycloak
