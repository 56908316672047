import { Icon } from '@iconify/react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  styled
} from '@mui/material'
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import apiDefinitions from '../../../api/apiDefinitions'
import { useCurrentRoute, useUserID } from '../../../hooks/customHooks'

import toast from 'react-hot-toast'

const CustomCloseButton = styled(IconButton)(({ theme }) => ({
  top: 0,
  right: 0,
  color: 'grey.500',
  position: 'absolute',
  boxShadow: theme.shadows[2],
  transform: 'translate(10px, -10px)',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: `${theme.palette.background.paper} !important`,
  transition: 'transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out',
  '&:hover': {
    transform: 'translate(7px, -5px)'
  }
}))

const Addvendor = ({ addVendorFormOpen, onCloseVendorForm, onFormSubmit }) => {
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''

  const [addFormOpen, setAddFormOpen] = useState(addVendorFormOpen)

  const [vendorName, setVendorName] = useState('')
  const [description, setDescription] = useState('')
  const [address, setAddress] = useState('')
  const [telephone, setTelephone] = useState('')

  const [vendorNameError, setVendorNameError] = useState('')
  const [addressError, setAddressError] = useState('')
  const [telephoneError, setTelephoneError] = useState('')

  const handleAddVendorFormClose = () => {
    setAddFormOpen(false)
    onCloseVendorForm()
    setVendorName('')
    setVendorNameError('')
    setDescription('')
    setAddress('')
    setAddressError('')
    setTelephone('')
    setTelephoneError('')
  }

  const handleAddVendor = () => {
    let error = false

    if (vendorName.trim() === '') {
      setVendorNameError('Vendor Name is required')
      error = true
    }

    if (address.trim() === '') {
      setAddressError('Address is required')
      error = true
    }

    if (telephone.trim() === '') {
      setTelephoneError('Telephone Number is required')
      error = true
    }

    if (telephoneError !== '') {
      error = true
    }

    if (!error) {
      Swal.fire({
        title: 'Are you sure you want to add this vendor?',
        showCancelButton: true,
        confirmButtonText: 'Add',
        cancelButtonText: 'Cancel',
        customClass: {
          popup: 'swal2-popup'
        }
      }).then(result => {
        if (result.isConfirmed) {
          const payload = {
            vendor_name: vendorName,
            description: description,
            address: address,
            telephone_number: telephone
          }

          apiDefinitions
            .postVendorCreate(userID, currentRouteString, payload)
            .then(res => {
              if (res.data.code === 201) {
                toast.success('Vendor Added Successfully')
                onFormSubmit()
                handleAddVendorFormClose()
              } else {
                console.log('error')
                toast.error(`Error: ${res.data.message}`)
              }
            })
            .catch(err => {
              console.log(err)
              toast.error('Error Creating Vendor!')
            })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Cancelled Adding New Vendor', '', 'info')
        }
      })
    }
  }

  return (
    <Dialog
      open={addVendorFormOpen}
      onClose={handleAddVendorFormClose}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      scroll='paper'
      fullWidth
      sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
    >
      <DialogTitle variant='h5' sx={{ pb: 1 }}>
        Add New Vendor
        <CustomCloseButton aria-label='close' onClick={handleAddVendorFormClose}>
          <Icon icon='tabler:x' fontSize='1.25rem' />
        </CustomCloseButton>
      </DialogTitle>
      <DialogContent className='content-display-grid-scroll'>
        <Grid container spacing={3} sx={{ pt: 2 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Vendor Name'
              required
              onChange={e => {
                setVendorName(e.target.value)
                setVendorNameError('')
              }}
              error={!!vendorNameError}
              helperText={vendorNameError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                    <Icon icon='uil:shop' />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Description'
              multiline
              minRows={2}
              onChange={e => {
                setDescription(e.target.value)
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start' sx={{ fontSize: '25px' }}>
                    <Icon icon='material-symbols:edit-note' />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Address'
              required
              onChange={e => {
                setAddress(e.target.value)
                setAddressError('')
              }}
              error={!!addressError}
              helperText={addressError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                    <Icon icon='bx:bxs-home' />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Telephone Number'
              required
              onChange={e => {
                setTelephone(e.target.value)

                if (isNaN(e.target.value)) {
                  setTelephoneError('Telephone Number must be a number')
                }

                if (e.target.value.length < 10) {
                  setTelephoneError('Telephone Number must be at least 10 digits')
                }

                if (e.target.value.length > 10) {
                  setTelephoneError('Telephone Number must be at most 10 digits')
                }

                if (e.target.value.match(/[^0-9]/)) {
                  setTelephoneError('Telephone Number must be a number')
                }

                if (e.target.value.length === 10 || e.target.value === '') {
                  setTelephoneError('')
                }
              }}
              error={!!telephoneError}
              helperText={telephoneError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                    <Icon icon='bx:bxs-phone' />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ m: 2, mt: 1, gap: 2 }}>
        <Button variant='contained' sx={{ px: 4 }} onClick={() => handleAddVendor()}>
          Add Vendor
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Addvendor
