import { Icon } from '@iconify/react'
import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'

const CustomCloseButton = styled(IconButton)(({ theme }) => ({
  top: 0,
  right: 0,
  color: 'grey.500',
  position: 'absolute',
  boxShadow: theme.shadows[2],
  transform: 'translate(10px, -10px)',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: `${theme.palette.background.paper} !important`,
  transition: 'transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out',
  '&:hover': {
    transform: 'translate(7px, -5px)'
  }
}))

const LogHistory = ({ openLogHistory, onCloseLogHistory }) => {
  const [logHistoryDialogOpen, setLogHistoryDialogOpen] = useState(openLogHistory)

  useEffect(() => {
    setLogHistoryDialogOpen(openLogHistory)
  }, [openLogHistory])

  const handleLogHistoryDialogClose = () => {
    setLogHistoryDialogOpen(false)
    onCloseLogHistory()
  }

  return (
    <Dialog
      open={logHistoryDialogOpen}
      onClose={handleLogHistoryDialogClose}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      scroll='paper'
      fullWidth
      sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
    >
      <DialogTitle variant='h5'>
        Log History
        <CustomCloseButton aria-label='close' onClick={handleLogHistoryDialogClose}>
          <Icon icon='tabler:x' fontSize='1.25rem' />
        </CustomCloseButton>
      </DialogTitle>
      <DialogContent sx={{ height: 'auto', overflowY: 'scroll', mr: 2, mb: 3 }} className='content-display-grid-scroll'>
        <Grid container>
          <Grid item xs={12}>
            <Timeline
              sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0.2
                }
              }}
            >
              <TimelineItem>
                <TimelineOppositeContent color='textSecondary'>19/01/2024 09:30 am</TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ pb: 3 }}>
                  <Typography color='textSecondary' sx={{ fontSize: '12px' }}>
                    Sandupa Abeywardana
                  </Typography>
                  <Typography variant='body2'>
                    Updated Item. Lorem Ipsum Dolor Sit Amet. Lorem Ipsum Dolor Sit Amet.
                  </Typography>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent color='textSecondary'>19/01/2024 09:30 am</TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ pb: 3 }}>
                  <Typography color='textSecondary' sx={{ fontSize: '12px' }}>
                    Sandupa Abeywardana
                  </Typography>
                  <Typography variant='body2'>
                    Updated Item. Lorem Ipsum Dolor Sit Amet. Lorem Ipsum Dolor Sit Amet.
                  </Typography>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent color='textSecondary'>19/01/2024 09:30 am</TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ pb: 3 }}>
                  <Typography color='textSecondary' sx={{ fontSize: '12px' }}>
                    Sandupa Abeywardana
                  </Typography>
                  <Typography variant='body2'>
                    Updated Item. Lorem Ipsum Dolor Sit Amet. Lorem Ipsum Dolor Sit Amet.
                  </Typography>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent color='textSecondary'>19/01/2024 09:30 am</TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ pb: 3 }}>
                  <Typography color='textSecondary' sx={{ fontSize: '12px' }}>
                    Sandupa Abeywardana
                  </Typography>
                  <Typography variant='body2'>
                    Updated Item. Lorem Ipsum Dolor Sit Amet. Lorem Ipsum Dolor Sit Amet.
                  </Typography>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent color='textSecondary'>19/01/2024 09:30 am</TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ pb: 3 }}>
                  <Typography color='textSecondary' sx={{ fontSize: '12px' }}>
                    Sandupa Abeywardana
                  </Typography>
                  <Typography variant='body2'>
                    Updated Item. Lorem Ipsum Dolor Sit Amet. Lorem Ipsum Dolor Sit Amet.
                  </Typography>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent color='textSecondary'>19/01/2024 09:30 am</TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ pb: 3 }}>
                  <Typography color='textSecondary' sx={{ fontSize: '12px' }}>
                    Sandupa Abeywardana
                  </Typography>
                  <Typography variant='body2'>
                    Updated Item. Lorem Ipsum Dolor Sit Amet. Lorem Ipsum Dolor Sit Amet.
                  </Typography>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent color='textSecondary'>19/01/2024 09:30 am</TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ pb: 3 }}>
                  <Typography color='textSecondary' sx={{ fontSize: '12px' }}>
                    Sandupa Abeywardana
                  </Typography>
                  <Typography variant='body2'>
                    Updated Item. Lorem Ipsum Dolor Sit Amet. Lorem Ipsum Dolor Sit Amet.
                  </Typography>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent color='textSecondary'>19/01/2024 09:30 am</TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ pb: 3 }}>
                  <Typography color='textSecondary' sx={{ fontSize: '12px' }}>
                    Sandupa Abeywardana
                  </Typography>
                  <Typography variant='body2'>
                    Updated Item. Lorem Ipsum Dolor Sit Amet. Lorem Ipsum Dolor Sit Amet.
                  </Typography>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent color='textSecondary'>19/01/2024 09:30 am</TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ pb: 3 }}>
                  <Typography color='textSecondary' sx={{ fontSize: '12px' }}>
                    Sandupa Abeywardana
                  </Typography>
                  <Typography variant='body2'>
                    Updated Item. Lorem Ipsum Dolor Sit Amet. Lorem Ipsum Dolor Sit Amet.
                  </Typography>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent color='textSecondary'>19/01/2024 08:00 am</TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                </TimelineSeparator>
                <TimelineContent>
                  <Typography color='textSecondary' sx={{ fontSize: '12px' }}>
                    Shadhir Ameen
                  </Typography>
                  <Typography variant='body2'>Created Item</Typography>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default LogHistory
