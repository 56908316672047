import { Icon } from '@iconify/react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import React, { useState } from 'react'

const Search = () => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleClose = () => {
    setOpen(false)
  }

  const handleSearchPopup = () => {
    setOpen(true)
  }
  return (
    <>
      <Icon icon='ic:baseline-search' className='appbar-search-icon' onClick={handleSearchPopup} />
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
        sx={{ '& .MuiDialog-paper': { width: 'auto', height: '100%' } }}
      >
        <DialogContent>
          <TextField
            id='outlined-basic'
            label='Search'
            variant='outlined'
            sx={{ width: 550 }}
            InputProps={{
              endAdornment: <Icon icon='ic:baseline-search' className='appbar-search-icon' />
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Search
