import React, { useState } from 'react'
import { Icon } from '@iconify/react'

const ThemeToggle = () => {
  const [appTheme, setAppTheme] = useState('light')
  return (
    <Icon
      icon={appTheme === 'light' ? 'material-symbols-light:dark-mode' : 'material-symbols-light:light-mode'}
      className='appbar-theme-icon'
      onClick={() => {
        if (appTheme === 'light') {
          setAppTheme('dark')
        } else {
          setAppTheme('light')
        }
      }}
    />
  )
}

export default ThemeToggle
