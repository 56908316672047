import { Icon } from '@iconify/react'
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
  styled
} from '@mui/material'
import FsLightbox from 'fslightbox-react'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import apiDefinitions from '../../../api/apiDefinitions'
import { convertImageLinkToBase64, useCurrentQueryParams, useCurrentRoute, useUserID } from '../../../hooks/customHooks'
import ItemImageUploader from '../ItemImageUploader'
import LazyLoad from 'react-lazy-load'
import Swal from 'sweetalert2'

const CustomCloseButton = styled(IconButton)(({ theme }) => ({
  top: 0,
  right: 0,
  color: 'grey.500',
  position: 'absolute',
  boxShadow: theme.shadows[2],
  transform: 'translate(10px, -10px)',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: `${theme.palette.background.paper} !important`,
  transition: 'transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out',
  '&:hover': {
    transform: 'translate(7px, -5px)'
  }
}))

export const EditItemForm = () => {
  const [isEditable, setIsEditable] = useState(false)

  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''
  const viewItemID = useCurrentQueryParams('id') ?? ''

  const ItemFallBackImage = '/images/default-fallbacks/item-fallback.png'

  const [toggler, setToggler] = useState(false)
  const [lightBoxImage, setLightBoxImage] = useState('')

  const [addUploaderOpen, setAddUploaderOpen] = useState(false)
  const [selectElective, setSelectElective] = useState(false)
  const [resetForm, setResetForm] = useState(false)
  const [resetList, setResetList] = useState(false)

  const [optionalFieldData, setOptionalFieldData] = useState([])

  const [itemCategory, setItemCategory] = useState('')
  const [itemCode, setItemCode] = useState('')
  const [itemAssetType, setItemAssetType] = useState('')
  const [itemBaseUnit, setItemBaseUnit] = useState('')
  const [itemName, setItemName] = useState('')
  const [itemThreshold, setItemThreshold] = useState('')
  const [itemDescription, setItemDescription] = useState('')
  const [itemImage, setItemImage] = useState('')
  const [itemStatus, setItemStatus] = useState('')
  const [checked, setChecked] = useState([])
  const [uploadedImage, setUploadedImage] = useState(undefined)
  const [uploadedFile, setUploadedFile] = useState([])
  const [formErrors, setFormErrors] = useState({})
  const [allOptionalFields, setAllOptionalFields] = useState([])

  const handleFormErrors = () => {
    const errors = {}
    if (uploadedFile.length === 0) {
      errors.uploadedFile = 'Please upload an image'
    }
    setFormErrors(errors)
  }

  useEffect(() => {
    handleFormErrors() // Trigger form error check when uploadedFile changes
    //eslint-disable-next-line
  }, [uploadedFile])

  useEffect(() => {
    if (currentRouteString !== '' && userID !== '' && viewItemID !== '') {
      apiDefinitions
        .getItemById(userID, currentRouteString, viewItemID)
        .then(res => {
          // console.log('response:', res)
          if (res.data.code === 200) {
            const item = res.data.data.item_details
            setItemCategory(item.category?.category_name)
            setItemCode(item.item_code)
            setItemAssetType(item.asset?.asset_name)
            setItemBaseUnit(item.base_unit?.base_unit)
            setItemName(item.short_name)
            setItemThreshold(item.threshold)
            setItemDescription(item.item_description)
            setItemImage(item.image)
            setItemStatus(item.item_status)
          } else {
            toast.error('Error:' + res.data.message)
          }
        })
        .catch(error => {
          console.error('1 - Error getting item by id:', error)
          toast.error('Error getting item by id')
        })
    }
  }, [currentRouteString, userID, viewItemID, resetForm])

  useEffect(() => {
    if (currentRouteString !== '' && userID !== '' && viewItemID !== '') {
      apiDefinitions
        .getItemById(userID, currentRouteString, viewItemID)
        .then(res => {
          // console.log('response:', res)
          if (res.data.code === 200) {
            const item = res.data.data
            setChecked(item.optional_field)
          } else {
            toast.error('Error:' + res.data.message)
          }
        })
        .catch(error => {
          console.error('2 - Error getting item by id:', error)
          toast.error('Error getting item by id')
        })

      apiDefinitions
        .getAllItemOptionalFields(userID, currentRouteString)
        .then(res => {
          if (res.data.code === 200) {
            console.log('All Optional Fields', res.data.data)
            setOptionalFieldData(res.data.data)
            setAllOptionalFields(res.data.data)
          } else {
            console.log('error')
            toast.error(`Error: ${res.data.message}`)
          }
        })
        .catch(error => {
          console.error('Error fetching optional fields:', error)
          toast.error('Error fetching item optional fields!')
        })
    }
  }, [currentRouteString, userID, viewItemID, resetList])

  const handleEditClick = () => {
    setIsEditable(true)
  }

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]
    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  const handleToggler = async link => {
    try {
      const imageString = await convertImageLinkToBase64(link)
      setLightBoxImage(imageString)
      setToggler(!toggler)
    } catch (error) {
      console.error('Error handling toggler:', error)
    }
  }

  const handleAddUploaderOpen = () => {
    setAddUploaderOpen(true)
  }

  const handleAddUploaderClose = () => {
    setAddUploaderOpen(false)
  }

  const handleSelectElectiveOpen = () => {
    setSelectElective(true)
  }

  const handleSelectElectiveClose = () => {
    setSelectElective(false)
  }

  const handleResetForm = () => {
    // Omit the Swal part
    setResetForm(!resetForm)
    setIsEditable(false)
  }

  const handleResetList = () => {
    // Omit the Swal part
    setResetList(!resetList)
  }

  const handleFormUpdate = () => {
    Swal.fire({
      title: 'Do you want to save the changes?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Save',
      denyButtonText: `Don't save`,
      customClass: {
        popup: 'swal2-popup'
      }
    }).then(result => {
      if (result.isConfirmed) {
        const myPromise = new Promise((resolve, reject) => {
          if (currentRouteString !== '' && userID !== '' && viewItemID !== '') {
            apiDefinitions
              .putItemUpdate(userID, currentRouteString, {
                item_id: Number(viewItemID),
                item_short_name: itemName,
                item_description: itemDescription,
                threshold: Number(itemThreshold)
              })
              .then(res => {
                console.log('API Response:', res)
                if (res.data.code === 200) {
                  console.log('Item updated successfully!')
                  resolve('Item updated successfully!')
                } else {
                  reject('Error:' + res.data.message)
                }
              })
              .catch(error => {
                console.error('Error updating item:', error)
                reject('Error updating item!')
              })
          } else {
            reject('Error updating item!')
          }
        })

        toast
          .promise(myPromise, {
            loading: 'Updating item...',
            success: message => `Success: ${message}`,
            error: errorMessage => `Error: ${errorMessage}`,
            duration: 5000
          })
          .then(() => {
            handleResetForm()
            handleResetList()
            // Additional code to run on success
          })
          .catch(() => {
            console.log('Error')
            toast.error('Error updating item!')
          })
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

  const handleElectiveUpdate = () => {
    const myPromise = new Promise((resolve, reject) => {
      if (currentRouteString !== '' && userID !== '' && viewItemID !== '') {
        apiDefinitions
          .putOptFieldUpdate(userID, currentRouteString, {
            item_id: Number(viewItemID),
            field_id: checked
          })
          .then(res => {
            console.log('API Response:', res)
            if (res.data.code === 200) {
              console.log('Item updated successfully!')
              resolve('Item updated successfully!')
            } else {
              reject('Error:' + res.data.message)
            }
          })
          .catch(error => {
            console.error('Error updating item:', error)
            reject('Error updating item!')
          })
      } else {
        reject('Error updating item!')
      }
    })

    toast
      .promise(myPromise, {
        loading: 'Updating item...',
        success: message => `Success: ${message}`,
        error: errorMessage => `Error: ${errorMessage}`,
        duration: 5000
      })
      .then(() => {
        handleResetForm()
        handleResetList()
        handleSelectElectiveClose()
        // Additional code to run on success
      })
      .catch(() => {
        console.log('Error')
        toast.error('Error updating item!')
      })
  }

  const handleFileUpload = files => {
    // Save the uploaded file in the temporary state
    setUploadedImage(files[0])
    setUploadedFile(files)
    console.log('Upload', files[0])
  }

  const handleUploadedFiles = () => {
    Swal.fire({
      title: 'Do you want to save the changes?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Save',
      denyButtonText: `Don't save`,
      customClass: {
        popup: 'swal2-popup'
      }
    }).then(result => {
      if (result.isConfirmed) {
        const myPromise = new Promise((resolve, reject) => {
          const fileId = itemImage.id
          if (
            itemImage?.id !== '' &&
            uploadedImage !== '' &&
            currentRouteString !== '' &&
            userID !== '' &&
            viewItemID !== ''
          ) {
            const payloadData = new FormData()
            payloadData.append('file', uploadedImage) // Assuming only one file is selected

            // Append the FormData to the request payload
            apiDefinitions
              .putItemImageUpdate(userID, currentRouteString, viewItemID, fileId, payloadData)
              .then(res => {
                console.log('API Response:', res)
                if (res.data.code === 200) {
                  console.log('Item image updated successfully!')
                  resolve(res.data.message)
                } else {
                  reject('Error:' + res.data.message)
                }
              })
              .catch(error => {
                console.error('Error updating item image:', error)
                reject('Error updating item image!')
              })
          } else {
            reject('Error updating item image!')
          }
        })

        toast
          .promise(myPromise, {
            loading: 'Updating item image...',
            success: message => `Success: ${message}`,
            error: errorMessage => `Error: ${errorMessage}`,
            duration: 5000
          })
          .then(() => {
            handleResetForm()
            handleResetList()
            handleAddUploaderClose()
            setUploadedFile([])
            // Additional code to run on success
          })
          .catch(() => {
            console.log('Error')
            toast.error('Error updating item image!')
          })
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

  return (
    <>
      <Grid container spacing={2} sx={{ px: 1 }}>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Chip
                variant='filled'
                color={itemStatus.color_code || 'secondary'}
                label={
                  <Typography variant='body2'>
                    {String(itemStatus?.type).toUpperCase() || 'Status Not Found'}
                  </Typography>
                }
              ></Chip>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <LazyLoad height={150} threshold={0.95}>
                <img
                  src={itemImage.url || ItemFallBackImage}
                  alt='item'
                  onClick={() => handleToggler(itemImage.url || ItemFallBackImage)}
                  onError={e => (e.target.src = ItemFallBackImage)}
                  style={{
                    cursor: 'pointer',
                    height: 'auto',
                    width: 'auto',
                    maxHeight: '155px',
                    maxWidth: '380px'
                  }}
                />
              </LazyLoad>
              <FsLightbox toggler={toggler} sources={[`${lightBoxImage}`]} />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant='outlined'
                fullWidth
                color='primary'
                onClick={handleAddUploaderOpen}
                sx={{ '& svg': { mr: 2 } }}
              >
                <Icon fontSize='1.125rem' icon='solar:camera-bold' />
                Change Image
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant='outlined'
                fullWidth
                color='primary'
                onClick={handleSelectElectiveOpen}
                sx={{ '& svg': { mr: 2 } }}
              >
                <Icon fontSize='1.125rem' icon='iconamoon:edit-duotone' />
                Elective Inputs
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    value={itemCategory}
                    fullWidth
                    label='Item Category'
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                          <Icon icon='carbon:tag' />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    value={itemCode}
                    fullWidth
                    disabled
                    label='Item Code'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                          <Icon icon='mdi:barcode-scan' />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    value={itemAssetType}
                    fullWidth
                    label='Asset Type'
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                          <Icon icon='fluent:text-box-settings-20-regular' />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    value={itemBaseUnit}
                    fullWidth
                    label='Base Unit'
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                          <Icon icon='tabler:ruler-measure' />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    value={itemName}
                    onChange={event => setItemName(event.target.value)}
                    fullWidth
                    label='Item Name'
                    disabled={!isEditable}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                          <Icon icon='majesticons:box-line' />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    value={itemThreshold}
                    id='outlined-number'
                    label='Threshold'
                    onChange={event => {
                      // const newValue = event.target.value.replace(/[^0-9]/g, '')
                      const newValue = event.target.value
                      setItemThreshold(newValue)
                    }}
                    type='number'
                    onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                    fullWidth
                    disabled={!isEditable}
                    required
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start' sx={{ fontSize: '22px' }}>
                          <Icon icon='material-symbols-light:data-thresholding-outline-rounded' />
                        </InputAdornment>
                      )
                    }}
                    inputProps={{
                      min: 0
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={itemDescription}
                onChange={event => setItemDescription(event.target.value)}
                fullWidth
                label='Item Description'
                disabled={!isEditable}
                multiline
                minRows={2.5}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={6} sx={{ pt: 3, justifyContent: 'flex-end' }}>
          <Grid item xs={2}>
            <Button
              variant='contained'
              fullWidth
              color='primary'
              sx={{ '& svg': { mr: 2 }, mb: { xs: -2, md: 0 } }}
              disabled={isEditable}
              onClick={handleEditClick}
            >
              <Icon fontSize='1.125rem' icon='iconamoon:edit-duotone' />
              Edit
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant='contained'
              fullWidth
              color='primary'
              sx={{ '& svg': { mr: 2 }, mb: { xs: 2, md: 0 } }}
              disabled={!isEditable}
              onClick={handleResetForm}
            >
              <Icon fontSize='1.125rem' icon='bx:reset' />
              Reset
            </Button>
          </Grid>

          <Grid item xs={2}>
            <Button
              variant='contained'
              fullWidth
              color='primary'
              sx={{ '& svg': { mr: 2 }, mb: { xs: 2, md: 0 } }}
              disabled={!isEditable}
              onClick={handleFormUpdate}
            >
              <Icon fontSize='1.125rem' icon='ion:save-sharp' />
              Update
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={addUploaderOpen}
        onClose={handleAddUploaderClose}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        scroll='paper'
        fullWidth
      >
        <DialogTitle variant='h5'>Update Item Image</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ pt: 1 }}>
            <Grid item xs={12}>
              {/* Pass the handleFileUpload function to the ItemImageUploader component */}
              <ItemImageUploader onUpload={handleFileUpload} uploadedFile={uploadedFile} />
              <Typography variant='body2' color='error' sx={{ mt: 1 }}>
                {formErrors.uploadedFile}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ m: 2, mt: 0, gap: 2 }}>
          <Button variant='contained' onClick={handleAddUploaderClose} sx={{ px: 4 }}>
            Cancel
          </Button>
          <Button variant='contained' onClick={handleUploadedFiles} sx={{ px: 4 }}>
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={selectElective}
        onClose={handleSelectElectiveClose}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        scroll='paper'
        fullWidth
        sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
      >
        <DialogTitle variant='h5' sx={{ pb: 1 }}>
          Update Electives
          <CustomCloseButton aria-label='close' onClick={handleSelectElectiveClose}>
            <Icon icon='tabler:x' fontSize='1.25rem' />
          </CustomCloseButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ pt: 1 }}>
            <Grid item xs={12}>
              {/* <Typography variant='body1' sx={{ mt: 1 }}>
                Item Elective Fields
              </Typography> */}

              <TextField
                fullWidth
                size='small'
                label='Search'
                variant='outlined'
                onChange={e => {
                  const searchValue = e.target.value

                  if (searchValue.length > 0) {
                  const filteredFields = allOptionalFields.filter(field =>
                    field.field_label.toLowerCase().includes(searchValue.toLowerCase())
                  )
                    setOptionalFieldData(filteredFields)
                  }
                  else {
                    setOptionalFieldData(allOptionalFields)
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Icon icon='carbon:search' />
                    </InputAdornment>
                  )
                }}
              />
              <List>
                {optionalFieldData.map(fieldOption => {
                  return (
                    <ListItem disablePadding key={fieldOption.id}>
                      <ListItemButton onClick={handleToggle(fieldOption.id)}>
                        <Checkbox
                          edge='start'
                          tabIndex={-1}
                          disableRipple
                          onChange={handleToggle(fieldOption.id)}
                          checked={checked.indexOf(fieldOption.id) !== -1}
                          inputProps={{ 'aria-labelledby': `checkbox-list-label-${fieldOption.id}` }}
                        />
                        <ListItemText id={`checkbox-list-label-${fieldOption.id}`} primary={fieldOption.field_label} />
                      </ListItemButton>
                    </ListItem>
                  )
                })}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ m: 2, mt: 0, gap: 2 }}>
          <Button variant='contained' onClick={handleResetList} sx={{ px: 4 }}>
            Reset
          </Button>
          <Button variant='contained' onClick={handleElectiveUpdate} sx={{ px: 4 }}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
