import { Icon } from '@iconify/react'
import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import { Dialog, DialogContent, DialogTitle, Grid, Box, Chip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'

const CustomCloseButton = styled(IconButton)(({ theme }) => ({
  top: 0,
  right: 0,
  color: 'grey.500',
  position: 'absolute',
  boxShadow: theme.shadows[2],
  transform: 'translate(10px, -10px)',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: `${theme.palette.background.paper} !important`,
  transition: 'transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out',
  '&:hover': {
    transform: 'translate(7px, -5px)'
  }
}))

const LogHistory = ({ openLogHistory, onCloseLogHistory }) => {
  const [logHistoryDialogOpen, setLogHistoryDialogOpen] = useState(openLogHistory)

  useEffect(() => {
    setLogHistoryDialogOpen(openLogHistory)
  }, [openLogHistory])

  const handleLogHistoryDialogClose = () => {
    setLogHistoryDialogOpen(false)
    onCloseLogHistory()
  }

  return (
    <Dialog
      open={logHistoryDialogOpen}
      onClose={handleLogHistoryDialogClose}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      scroll='paper'
      fullWidth
      sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
    >
      <DialogTitle variant='h5'>
        Log History | Item #141
        <CustomCloseButton aria-label='close' onClick={handleLogHistoryDialogClose}>
          <Icon icon='tabler:x' fontSize='1.25rem' />
        </CustomCloseButton>
      </DialogTitle>
      <DialogContent
        sx={{ maxHeight: '450px', overflowY: 'scroll', mb: 3, p: 0 }}
        className='content-display-grid-scroll'
      >
        <Grid container>
          <Grid item xs={12}>
            <Timeline
              sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0.2
                }
              }}
            >
              <TimelineItem>
                <TimelineOppositeContent color='textSecondary'>
                  <Box
                    fullWidth
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      alignItems: 'center'
                    }}
                  >
                    19/01/2024
                    <Chip
                      label='Assignment'
                      color='success'
                      sx={{
                        px: 1
                      }}
                    />
                  </Box>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ pb: 5, pt: 0 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2
                    }}
                  >
                    <Box>
                      <Typography variant='caption'>Level 1</Typography>
                      <Typography
                        variant='body1'
                        sx={{
                          fontSize: '16px'
                        }}
                      >
                        sandupa@kiu.ac.lk
                      </Typography>
                    </Box>
                    <Icon icon='gg:arrow-long-right' fontSize={35} />
                    <Box sx={{ gap: 0 }}>
                      <Typography variant='caption'>Level 2</Typography>
                      <Typography
                        variant='body1'
                        sx={{
                          fontSize: '16px'
                        }}
                      >
                        shadhir@kiu.ac.lk
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant='body2' color='textSecondary'>
                    Quantity: 1/50
                  </Typography>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent color='textSecondary'>
                  <Box
                    fullWidth
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      alignItems: 'center'
                    }}
                  >
                    19/01/2024
                    <Chip
                      label='Unssignment'
                      color='error'
                      sx={{
                        px: 0.5
                      }}
                    />
                  </Box>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ pb: 5, pt: 0 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2
                    }}
                  >
                    <Box>
                      <Typography variant='caption'>Level 1</Typography>
                      <Typography
                        variant='body1'
                        sx={{
                          fontSize: '16px'
                        }}
                      >
                        sandupa@kiu.ac.lk
                      </Typography>
                    </Box>
                    <Icon icon='gg:arrow-long-right' fontSize={35} />
                    <Box sx={{ gap: 0 }}>
                      <Typography variant='caption'>Level 2</Typography>
                      <Typography
                        variant='body1'
                        sx={{
                          fontSize: '16px'
                        }}
                      >
                        shadhir@kiu.ac.lk
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant='body2' color='textSecondary'>
                    Quantity: 1/50
                  </Typography>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent color='textSecondary'>
                  <Box
                    fullWidth
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      alignItems: 'center'
                    }}
                  >
                    19/01/2024
                    <Chip
                      label='Assignment'
                      color='success'
                      sx={{
                        px: 1
                      }}
                    />
                  </Box>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ pb: 5, pt: 0 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2
                    }}
                  >
                    <Box>
                      <Typography variant='caption'>Level 1</Typography>
                      <Typography
                        variant='body1'
                        sx={{
                          fontSize: '16px'
                        }}
                      >
                        sandupa@kiu.ac.lk
                      </Typography>
                    </Box>
                    <Icon icon='gg:arrow-long-right' fontSize={35} />
                    <Box sx={{ gap: 0 }}>
                      <Typography variant='caption'>Level 2</Typography>
                      <Typography
                        variant='body1'
                        sx={{
                          fontSize: '16px'
                        }}
                      >
                        shadhir@kiu.ac.lk
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant='body2' color='textSecondary'>
                    Quantity: 1/50
                  </Typography>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent color='textSecondary'>
                  <Box
                    fullWidth
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      alignItems: 'center'
                    }}
                  >
                    19/01/2024
                    <Chip
                      label='Assignment'
                      color='success'
                      sx={{
                        px: 1
                      }}
                    />
                  </Box>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ pb: 5, pt: 0 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2
                    }}
                  >
                    <Box>
                      <Typography variant='caption'>Level 1</Typography>
                      <Typography
                        variant='body1'
                        sx={{
                          fontSize: '16px'
                        }}
                      >
                        sandupa@kiu.ac.lk
                      </Typography>
                    </Box>
                    <Icon icon='gg:arrow-long-right' fontSize={35} />
                    <Box sx={{ gap: 0 }}>
                      <Typography variant='caption'>Level 2</Typography>
                      <Typography
                        variant='body1'
                        sx={{
                          fontSize: '16px'
                        }}
                      >
                        shadhir@kiu.ac.lk
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant='body2' color='textSecondary'>
                    Quantity: 1/50
                  </Typography>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent color='textSecondary'>
                  <Box
                    fullWidth
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      alignItems: 'center'
                    }}
                  >
                    19/01/2024
                    <Chip
                      label='Assignment'
                      color='success'
                      sx={{
                        px: 1
                      }}
                    />
                  </Box>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ pb: 5, pt: 0 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2
                    }}
                  >
                    <Box>
                      <Typography variant='caption'>Level 1</Typography>
                      <Typography
                        variant='body1'
                        sx={{
                          fontSize: '16px'
                        }}
                      >
                        sandupa@kiu.ac.lk
                      </Typography>
                    </Box>
                    <Icon icon='gg:arrow-long-right' fontSize={35} />
                    <Box sx={{ gap: 0 }}>
                      <Typography variant='caption'>Level 2</Typography>
                      <Typography
                        variant='body1'
                        sx={{
                          fontSize: '16px'
                        }}
                      >
                        shadhir@kiu.ac.lk
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant='body2' color='textSecondary'>
                    Quantity: 1/50
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default LogHistory
