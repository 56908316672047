import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Autocomplete
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'

import RequestChat from './components/request-chat'
import RequestDetails from './components/request-details'
import ItemSummary from './components/request-items-summary'
import ItemQuotations from './components/request-quotations'

import { Icon } from '@iconify/react'

import styled from '@mui/material/styles/styled'

import toast from 'react-hot-toast'
import { useCurrentQueryParams, useCurrentRoute, useUserID, useUserItemRole } from '../../../../hooks/customHooks'
import Uploader from './components/FileUploaderSingle'
import QuotationItemTable from './components/QuotationItemTable'
import VendorAutoCompleteSingle from './components/VendorAutoComplete'

import { createFilterOptions } from '@mui/material/Autocomplete'

import Swal from 'sweetalert2'
import apiDefinitions from '../../../../api/apiDefinitions'
import MultiVendorAutoComplete from './components/MultiVendorAutoComplete'

const CustomCloseButton = styled(IconButton)(({ theme }) => ({
  top: 0,
  right: 0,
  color: 'grey.500',
  position: 'absolute',
  boxShadow: theme.shadows[2],
  transform: 'translate(10px, -10px)',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: `${theme.palette.background.paper} !important`,
  transition: 'transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out',
  '&:hover': {
    transform: 'translate(7px, -5px)'
  }
}))

const PoReqView = () => {
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''
  const navigate = useNavigate()

  const poReqID = useCurrentQueryParams('id') ?? ''
  const poReqType = useCurrentQueryParams('request') ?? ''
  const role = useUserItemRole() ?? ''

  // console.log('poReqID:', poReqID)
  // console.log('poReqType:', poReqType)
  // console.log('role', role)

  const [pageError, setPageError] = useState('')

  useEffect(() => {
    if (poReqType !== 'view-request') {
      setPageError(`Undefined Request Type!`)
    } else if (poReqID === '' || poReqID === null || poReqID === undefined) {
      setPageError('Request ID is required!')
    }
  }, [poReqID, poReqType])

  const [step, setStep] = useState(1)
  const [expanded, setExpanded] = useState('request-item-summary')
  const [expanded2, setExpanded2] = useState(false)

  const [rows, setRows] = useState([])

  const accordianHeight = '272.5px'

  const chatHeight = role === 'item-admin' ? '352.5px' : '405px'

  const [selectedVendor, setSelectedVendor] = useState(null)
  const [itemList, setItemList] = useState([])

  const [file, setFile] = useState(null)
  const [amount, setAmount] = useState('')
  const [description, setDescription] = useState('')
  const [discount, setDiscount] = useState('')
  const [tax, setTax] = useState('')

  const [vendorError, setVendorError] = useState(false)
  const [amountError, setAmountError] = useState(false)
  const [fileError, setFileError] = useState(false)

  useEffect(() => {
    if (selectedVendor) {
      setVendorError(false)
    }
  }, [selectedVendor])

  const [item, setItem] = useState(null)
  const [itemError, setItemError] = useState(false)
  const [quantity, setQuantity] = useState('')
  const [quantityError, setQuantityError] = useState(false)
  const [note, setNote] = useState('')
  const [noteError, setNoteError] = useState(false)

  const [selectedVendors, setSelectedVendors] = useState([])
  const [selectedVendorsError, setSelectedVendorsError] = useState(false)

  const [tableRefresh, setTableRefresh] = useState(false)

  const [rowsModelLen, setRowsModelLen] = useState('')

  const [refreshQuotations, setRefreshQuotations] = useState(false)

  const handleUpload = file => {
    setFile(file[0])
    setFileError(false)
  }

  const handleChange = panel => (event, isExpanded) => {
    setExpanded2(false)
    setExpanded(isExpanded ? panel : false)
  }

  const [addItemSummeryDialog, setAddItemSummeryDialog] = useState(false)
  const [addQuotationDialog, setAddQuotationDialog] = useState(false)

  const handleAddItemSummeryDialog = () => {
    setAddItemSummeryDialog(true)
  }

  const handleAddQuotationDialog = () => {
    setAddQuotationDialog(true)
  }

  const handleAddItemSummeryDialogClose = () => {
    setAddItemSummeryDialog(false)
    setItem(null)
    setQuantity('')
    setNote('')
    setSelectedVendors([])
    setItemError('')
    setQuantityError('')
    setNoteError('')
    setSelectedVendorsError('')
  }

  const handleAddQuotationDialogClose = () => {
    setAddQuotationDialog(false)

    setFile(null)
    setSelectedVendor(null)
    setAmount('')
    setDiscount('')
    setTax('')
    setDescription('')

    setVendorError(false)
    setAmountError(false)
    setFileError(false)

    setStep(1)
    setRows([])
  }

  const handleNext = () => {
    if (step === 1) {
      let error = false

      if (!selectedVendor) {
        setVendorError(true)
        error = true
      }
      if (!amount) {
        setAmountError(true)
        error = true
      }

      if (!file || file === null || file === undefined) {
        setFileError(true)
        error = true
      }

      if (error) {
        return
      } else {
        setStep(step + 1)
      }
    }
    if (step === 2) {
      if (rows.length === 0) {
        toast.error('Please add at least one item!')
        return
      } else {
        if (rowsModelLen > 0) {
          alert('You have unsaved changes! Please save them before proceeding.')
        } else {
          setStep(step + 1)
        }
      }
    }
  }

  const handleBack = () => {
    if (step === 2) {
      if (rowsModelLen > 0) {
        alert('You have unsaved changes! Please save them before proceeding.')
      } else {
        setStep(step - 1)
      }
    } else setStep(step - 1)
  }

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '') {
      apiDefinitions
        .getAllDataList(userID, currentRouteString, 'item')
        .then(res => {
          if (res.data.code === 200) {
            setItemList(res.data.data)
          } else {
            toast.error(`Error: ${res.data.message}`)
          }
        })
        .catch(err => {
          console.log(err)
          toast.error('Error fetching Vendor List!')
        })
    }
  }, [userID, currentRouteString])

  const handleFormSubmit = () => {
    // console.log('Form Submitted!')
    setTableRefresh(!tableRefresh)
  }

  const handleItemSummeryAdd = () => {
    let hasError = false

    if (item === null || item === '' || item === undefined) {
      setItemError('Item cannot be empty')
      hasError = true
    } else {
      setItemError('')
    }

    if (quantity.trim() === '') {
      setQuantityError('Quantity cannot be empty')
      hasError = true
    } else {
      setQuantityError('')
    }

    if (note.trim() === '') {
      setNoteError('Note cannot be empty')
      hasError = true
    } else {
      setNoteError('')
    }

    // if (selectedVendors.length === 0) {
    //   setSelectedVendorsError('Vendor cannot be empty')
    //   hasError = true
    // } else {
    //   setSelectedVendorsError('')
    // }

    if (hasError) return
    Swal.fire({
      title: 'Are you sure you want to add this Item?',
      showCancelButton: true,
      confirmButtonText: 'Add',
      cancelButtonText: 'Cancel',
      customClass: {
        popup: 'swal2-popup'
      }
    }).then(result => {
      if (result.isConfirmed) {
        const payload = {
          por_id: poReqID,
          item_id: item.id,
          item_qty: quantity,
          special_notes: note,
          vendors: selectedVendors.map(vendor => vendor.id)
        }

        console.log('Payload:', payload)

        apiDefinitions
          .postItemSummaryCreateWithID(userID, currentRouteString, payload)
          .then(res => {
            if (res.data.code === 201) {
              toast.success('Item summary Added Successfully')
              handleFormSubmit()
              handleAddItemSummeryDialogClose()
            } else {
              console.log('error')
              toast.error(`Error: ${res.data.message}`)
            }
          })
          .catch(err => {
            console.log(err)
            toast.error('Error Creating Item!')
          })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled Adding New Item', '', 'info')
      }
    })
  }

  const handleAddQuotationSubmit = () => {
    console.log(amount, discount)
    const QuotationValue = Number(amount) + Number(discount) - Number(tax)

    const ItemsTotalValue = rows.reduce((acc, row) => acc + Number(row.total_price), 0)

    console.log('QuotationValue:', QuotationValue)
    console.log('ItemsTotalValue:', ItemsTotalValue)

    if (QuotationValue !== ItemsTotalValue) {
      toast.error('Quotation Value does not match with the total value of the items!')
      return
    }

    Swal.fire({
      title: 'Are you sure you want to submit this Quotation?',
      showCancelButton: true,
      confirmButtonText: 'Submit',
      cancelButtonText: 'Cancel',
      customClass: {
        popup: 'swal2-popup'
      }
    }).then(result => {
      if (result.isConfirmed) {
        const payload = {
          vendor_id: selectedVendor.id,
          por_id: poReqID,
          amount: amount,
          discount: discount,
          tax: tax,
          description: description,
          items: rows.map(row => ({
            por_detail_id: row.item.por_detail_id,
            item_id: row.item.id,
            available_qty: row.available_qty,
            req_qty: row.req_qty,
            unit_price: row.unit_price,
            total_price: row.total_price,
            notes: row.notes
          }))
        }

        // console.log('Payload:', payload)
        // console.log('File:', file)

        const formData = new FormData()

        formData.append('json', new Blob([JSON.stringify(payload)], { type: 'application/json' }))
        formData.append('file', file)

        // console.log('formData:', formData)

        apiDefinitions
          .postQuotationCreate(userID, currentRouteString, formData)
          .then(res => {
            if (res.data.code === 201) {
              Swal.fire('Quotation Submitted Successfully', '', 'success')
              handleAddQuotationDialogClose()
              setRefreshQuotations(!refreshQuotations)
            } else throw new Error(res.data.message)
          })
          .catch(err => {
            console.log(err)
            Swal.fire('Error Submitting Quotation', '', 'error')
          })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled Submitting Quotation', '', 'info')
      }
    })
  }

  const rowEditModel = rowModesModel => {
    // console.log('rowModesModelXX', rowModesModel)

    // const arrayFromObject = Object.entries(rowModesModel).map(([key, value]) => {
    //   return {
    //     id: key,
    //     mode: value.mode,
    //     fieldToFocus: value.fieldToFocus
    //   }
    // })

    const length = Object.keys(rowModesModel).length

    setRowsModelLen(length)
  }

  if (pageError !== '') {
    return (
      <Alert severity='error' sx={{ mt: 2 }}>
        <AlertTitle>Error</AlertTitle>
        {pageError}
      </Alert>
    )
  } else {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Accordion
              expanded={expanded2}
              onChange={e => {
                setExpanded2(!expanded2)
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1bh-content' id='panel1bh-header'>
                <Typography>Request Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <RequestDetails tableRefresh={tableRefresh} />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={9}>
            <Accordion expanded={expanded === 'request-item-summary'} onChange={handleChange('request-item-summary')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1bh-content' id='panel1bh-header'>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  <Typography sx={{ py: 0.4 }}>Item Summary</Typography>
                  {role !== 'item-admin' ? (
                    <Button
                      variant='outlined'
                      color='primary'
                      size='small'
                      sx={{ mr: 2 }}
                      onClick={e => {
                        e.stopPropagation()
                        setExpanded('request-item-summary')
                        handleAddItemSummeryDialog()
                      }}
                    >
                      <Icon icon='fluent:add-12-filled' />
                      Add Item
                    </Button>
                  ) : null}
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: accordianHeight
                }}
              >
                <ItemSummary tableRefresh={tableRefresh} />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'request-quotations'} onChange={handleChange('request-quotations')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel2bh-content' id='panel2bh-header'>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  <Typography sx={{ py: 0.4 }}>Item Quotations</Typography>
                  <Button
                    variant='outlined'
                    color='primary'
                    size='small'
                    sx={{ mr: 2 }}
                    onClick={e => {
                      e.stopPropagation()
                      setExpanded('request-quotations')
                      handleAddQuotationDialog()
                    }}
                  >
                    <Icon icon='fluent:add-12-filled' />
                    Add Quotation
                  </Button>
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: accordianHeight
                }}
              >
                <ItemQuotations refreshQuotations={refreshQuotations} />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <CardContent
                    sx={{
                      minHeight: chatHeight,
                      p: '0 !important'
                    }}
                  >
                    <RequestChat height={chatHeight} />
                  </CardContent>
                </Card>
              </Grid>
              {role === 'item-admin' && (
                <Grid item xs={12}>
                  <Button variant='contained' color='primary' fullWidth>
                    Create Purchase Order
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          open={addQuotationDialog}
          onClose={handleAddQuotationDialogClose}
          aria-labelledby='scroll-dialog-title'
          aria-describedby='scroll-dialog-description'
          scroll='paper'
          sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
        >
          <DialogTitle variant='h5'>
            Add New Quotation
            <CustomCloseButton aria-label='close' onClick={handleAddQuotationDialogClose}>
              <Icon icon='tabler:x' fontSize='1.25rem' />
            </CustomCloseButton>
          </DialogTitle>
          <DialogContent>
            {step === 1 && (
              <Grid
                container
                spacing={2}
                sx={{
                  pt: 1
                }}
              >
                <Grid item xs={12}>
                  <VendorAutoCompleteSingle
                    selectedVendor={selectedVendor}
                    setSelectedVendor={setSelectedVendor}
                    vendorError={vendorError}
                    setVendorError={setVendorError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label='Quotation Value'
                    variant='outlined'
                    required
                    value={amount}
                    type='number'
                    onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                    inputProps={{ min: 0 }}
                    onChange={e => {
                      setAmount(e.target.value)
                      setAmountError(false)
                    }}
                    error={amountError}
                    helperText={
                      (amountError && 'Amount is required') || 'Enter Net Value including Discount and Taxes.'
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label='Discount'
                    variant='outlined'
                    type='number'
                    onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                    inputProps={{ min: 0 }}
                    value={discount}
                    onChange={e => {
                      setDiscount(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label='Tax'
                    variant='outlined'
                    type='number'
                    onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                    inputProps={{ min: 0 }}
                    value={tax}
                    onChange={e => {
                      setTax(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label='Additional Notes'
                    variant='outlined'
                    multiline
                    rows={3}
                    value={description}
                    onChange={e => {
                      setDescription(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth error={fileError}>
                    <Uploader onUpload={handleUpload} file={file} />
                    <FormHelperText>{fileError && 'File is required'}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {step === 2 && (
              <Grid
                container
                spacing={2}
                sx={{
                  pt: 1
                }}
              >
                <Grid item xs={12}>
                  <QuotationItemTable rows={rows} setRows={setRows} rowEditModel={rowEditModel} />
                </Grid>
              </Grid>
            )}
            {step === 3 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant='h6'>Review Quotation</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <Typography variant='body2'>Vendor</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant='body2'>{selectedVendor?.vendor_name || 'N/A'}</Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography variant='body2'>Additional Notes</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant='body2'>{description || 'N/A'}</Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography variant='body2'>Quotation Value</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant='body2'>{Number(amount).toFixed(2)}</Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography variant='body2'>Discount</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant='body2'>{Number(discount).toFixed(2)}</Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography variant='body2'>Tax</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant='body2'>{Number(tax).toFixed(2)}</Typography>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            maxHeight: file?.type.includes('image') ? '280px' : 'auto',
                            minHeight: !file?.type.includes('image') ? '280px' : 'auto'
                          }}
                        >
                          {file && file.type.includes('image') && (
                            <img
                              src={URL.createObjectURL(file)}
                              alt='Quotation'
                              style={{
                                height: '100%',
                                maxWidth: '90%'
                              }}
                            />
                          )}

                          {file && file.type.includes('pdf') && (
                            <embed src={URL.createObjectURL(file)} type='application/pdf' width='90%' />
                          )}

                          {file && file.type.includes('word') && (
                            <embed src={URL.createObjectURL(file)} type='application/msword' width='90%' />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='h6'>Quotation Items</Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Item</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Unit Price</TableCell>
                          <TableCell>Tax</TableCell>
                          <TableCell>Discount</TableCell>
                          <TableCell>Total Price</TableCell>
                          <TableCell>Remarks</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map(row => (
                          <TableRow key={row.id}>
                            <TableCell>{row.item.item}</TableCell>
                            <TableCell>{row.available_qty}</TableCell>
                            <TableCell>{row.unit_price}</TableCell>
                            <TableCell>{row.tax}</TableCell>
                            <TableCell>{row.discount}</TableCell>
                            <TableCell>{Number(row.total_price || 0).toFixed(2)}</TableCell>
                            <TableCell>{row.notes || 'N/A'}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions
            sx={{
              mx: 2,
              mb: 1
            }}
          >
            {step === 1 && (
              <Button variant='contained' color='primary' onClick={handleNext}>
                Next
              </Button>
            )}
            {step === 2 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <Button variant='outlined' color='primary' onClick={handleBack}>
                  Back
                </Button>
                <Button variant='contained' color='primary' onClick={handleNext}>
                  Next
                </Button>
              </Box>
            )}
            {step === 3 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <Button variant='outlined' color='primary' onClick={handleBack}>
                  Back
                </Button>
                <Button variant='contained' color='primary' onClick={handleAddQuotationSubmit}>
                  Submit
                </Button>
              </Box>
            )}
          </DialogActions>
        </Dialog>

        <Dialog
          open={addItemSummeryDialog}
          onClose={handleAddItemSummeryDialogClose}
          aria-labelledby='scroll-dialog-title'
          aria-describedby='scroll-dialog-description'
          scroll='paper'
          fullWidth
          sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
        >
          <DialogTitle variant='h5'>
            Add New Item Summary
            <CustomCloseButton aria-label='close' onClick={handleAddItemSummeryDialogClose}>
              <Icon icon='tabler:x' fontSize='1.25rem' />
            </CustomCloseButton>
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2} sx={{ py: 1 }}>
              <Grid item xs={6}>
                <FormControl fullWidth required error={!!itemError}>
                  <Autocomplete
                    options={itemList}
                    getOptionLabel={option => option.short_name}
                    getOptionKey={option => option.id}
                    value={item}
                    onChange={(event, newValue) => {
                      setItem(newValue)
                      setItemError('')
                    }}
                    renderInput={params => (
                      <TextField {...params} label='Item' error={!!itemError} helperText={itemError} />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label='Quantity'
                  required
                  onChange={e => {
                    setQuantity(e.target.value)
                    setQuantityError('')
                  }}
                  onInput={e => {
                    // Prevent input of letters and other special characters except for '.'
                    const inputValue = e.target.value
                    const sanitizedInput = inputValue.replace(/[^0-9.]/g, '') // Remove anything that's not a number or '.'
                    if (inputValue !== sanitizedInput) {
                      e.target.value = sanitizedInput // Set the input value to the sanitized version
                      setQuantity(sanitizedInput) // Update state if needed
                    }
                  }}
                  error={!!quantityError}
                  helperText={quantityError}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                        <Icon icon='iwwa:number-desc' />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Note'
                  required
                  multiline
                  minRows={2}
                  onChange={e => {
                    setNote(e.target.value)
                    setNoteError('')
                  }}
                  error={!!noteError}
                  helperText={noteError}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                        <Icon icon='octicon:note-24' />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <MultiVendorAutoComplete
                  selectedVendors={selectedVendors}
                  setSelectedVendors={setSelectedVendors}
                  selectedVendorsError={selectedVendorsError}
                  setSelectedVendorsError={setSelectedVendorsError}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='primary' onClick={handleItemSummeryAdd}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default PoReqView
