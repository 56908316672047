import { Icon } from '@iconify/react'
import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import apiDefinitions from '../../../../api/apiDefinitions'
import { useCurrentQueryParams, useCurrentRoute, useUserID } from '../../../../hooks/customHooks'
import EditSingleItemDetail from './EditSingleItemDetail'
import LotForm from './lotViewForm'
import MultipleEdit from './multipleEdit'

import JsBarcode from 'jsbarcode'
import { useRef } from 'react'

import qz from 'qz-tray'
// import LogoFile from '../../assets/2-KIU-LOGO.png'

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626'
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959'
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343'
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c'
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff'
  }
}))

const currencyFormatting = value => {
  // Parse the value as a float
  const floatValue = parseFloat(value)

  // Check if floatValue is a valid number
  if (!isNaN(floatValue)) {
    // Convert the float value to a string with two decimal places using toFixed
    return floatValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  } else {
    // Return the original value if it's not a valid number
    return value
  }
}

const ViewItemDetails = () => {
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''
  const itemID = useCurrentQueryParams('itemId') ?? ''
  const lotID = useCurrentQueryParams('lotId') ?? ''

  const [optionalFields, setOptionalFields] = useState([])
  const [isInitialized, setIsInitialized] = useState(false)
  const [openMultipleEdit, setOpenMultipleEdit] = useState(false)
  const [openSingleEdit, setOpenSingleEdit] = useState(false)
  const [selectedEditID, setSelectedEditID] = useState(null)
  const [selectedItemId, setSelectedItemId] = useState(null)
  const [columns, setColumns] = useState([
    {
      id: 'item_detail_code',
      label: 'Item Detail',
      minWidth: 120,
      fieldType: 'text'
    },
    {
      id: 'vendor',
      label: 'Vendor',
      minWidth: 120,
      fieldType: 'select'
    },
    {
      id: 'quantity',
      label: 'Quantity',
      minWidth: 40,
      colAlign: 'center',
      rowAlign: 'center',
      fieldType: 'number',
      isCurrency: false
    },
    {
      id: 'unitPrice',
      label: 'Unit Price',
      minWidth: 80,
      colAlign: 'center',
      rowAlign: 'center',
      fieldType: 'number',
      isCurrency: true
    },
    {
      id: 'totalPrice',
      label: 'Total Price',
      minWidth: 100,
      colAlign: 'center',
      rowAlign: 'center',
      fieldType: 'number',
      isCurrency: true,
      isCalculated: true
    },
    {
      id: 'tax',
      label: 'Tax',
      minWidth: 100,
      colAlign: 'center',
      rowAlign: 'center',
      fieldType: 'number',
      isCurrency: true
    },
    {
      id: 'discount',
      label: 'Discount',
      minWidth: 120,
      colAlign: 'center',
      rowAlign: 'center',
      fieldType: 'number',
      isCurrency: true
    },
    {
      id: 'finalPrice',
      label: 'Final Price',
      minWidth: 100,
      colAlign: 'center',
      rowAlign: 'center',
      fieldType: 'number',
      isCurrency: true,
      isCalculated: true
    },
    {
      id: 'specialNote',
      label: 'Special Note',
      minWidth: 250,
      colAlign: 'center',
      rowAlign: 'justify',
      fieldType: 'multiline'
    },
    {
      id: 'print_column',
      label: 'Print Column',
      minWidth: 150,
      colAlign: 'center',
      rowAlign: 'center',
      fieldType: 'text'
    }
  ])

  const [resetData, setResetData] = useState(false)

  const handleMultipleEdit = () => {
    setOpenMultipleEdit(true)
  }

  const handleCloseMultipleEdit = () => {
    setOpenMultipleEdit(false)
    setChecked([])
    setResetData(!resetData)
  }

  const handleSingleEdit = (rowId, itemId) => {
    setOpenSingleEdit(true)
    setSelectedEditID(rowId)
    setSelectedItemId(itemId)
  }

  const handleCloseSingleEdit = () => {
    setOpenSingleEdit(false)
    setSelectedEditID(null)
    setResetData(!resetData)
  }

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '' && itemID !== '') {
      // Fetch the optional fields from the API
      apiDefinitions
        .getItemOptFieldsByItemId(userID, currentRouteString, itemID)
        .then(response => {
          if (response.data.code === 200) {
            // console.log('Optional fields:', response.data.data)
            setOptionalFields(response.data.data)
          } else {
            console.log('Error fetching optional fields:', response.data.message)
            // toast.error('Error: ' + response.data.message)
          }
        })
        .catch(error => {
          console.error('Error fetching optional fields:', error)
          // toast.error('Error fetching optional fields!')
        })
    }
  }, [currentRouteString, itemID, userID])

  useEffect(() => {
    // console.log('Optional fields:', optionalFields)

    if (optionalFields && optionalFields.length > 0 && !isInitialized) {
      const newColumns = optionalFields.map(field => {
        const column = {
          id: field.optional_field.data_grid_field,
          label: field.optional_field.field_label,
          minWidth: 100,
          colAlign: 'center',
          rowAlign: 'center',
          fieldType: field.optional_field.type,
          isCurrency: false
        }

        if (field.optional_field.list_values) {
          column.listValues = field.optional_field.list_values
        }

        return column
      })

      setColumns(oldColumns => {
        // Split oldColumns into all but the last, and the last element
        const allButLast = oldColumns.slice(0, -1)
        const lastElement = oldColumns[oldColumns.length - 1]

        // Create a new array with allButLast, newColumns, and the last element
        return [...allButLast, ...newColumns, lastElement]
      })

      setIsInitialized(true)
    }
  }, [isInitialized, optionalFields])

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [apiData, setApiData] = useState([])

  useEffect(() => {
    if (lotID !== '' && userID !== '' && currentRouteString !== '') {
      const payload = {
        item_ids: [itemID]
      }
      apiDefinitions
        .getItemDetailByLotId(userID, currentRouteString, lotID, payload)
        .then(res => {
          if (res.data.code === 200) {
            setApiData(res.data.data)
            console.log('Lot details:', res.data.data)
          } else {
            console.log('Error fetching lot details:', res.data.message)
            toast.error('Error: ' + res.data.message)
          }
        })
        .catch(error => {
          console.error('Error fetching lot details:', error)
          toast.error('Error fetching lot details!')
        })
    }
  }, [currentRouteString, lotID, userID, itemID, resetData])

  const rowData = apiData.item?.[0]?.item_details

  const [rows, setRows] = useState([])

  useEffect(() => {
    if (rowData) {
      const newRows = rowData.map(row => {
        const newRow = {
          id: row.item_detail_id,
          item_detail_code: row.item_detail_code || 'N/A',
          vendor: row.vendor.vendor_name || 'N/A',
          quantity: row.quantity || 0,
          unitPrice: row.unit_price || 0,
          totalPrice: row.total_price || 0,
          tax: row.tax || 0,
          discount: row.discount || 0,
          finalPrice: row.final_price || 0,
          specialNote: row.special_note || 'N/A',
          print_column: row.print_column
        }

        // Map optional fields
        if (row.optional_field) {
          row.optional_field.forEach(optionalField => {
            newRow[optionalField.field.data_grid_field] = optionalField.value
          })
        }

        return newRow
      })

      console.log('New Rows:', newRows)

      setRows(newRows)
    }
  }, [rowData])

  const [lotCurrency, setLotCurrency] = useState('')
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const [checked, setChecked] = useState([])

  // const handleCheckAll = event => {
  //   if (event.target.checked) {
  //     const newChecked = rows.map(row => row.id)
  //     setChecked(newChecked)
  //     return
  //   }
  //   setChecked([])
  // }

  const handleCheckAll = event => {
    if (event.target.checked) {
      const startIndex = page * rowsPerPage
      const endIndex = Math.min(startIndex + rowsPerPage, rows.length)
      const newChecked = rows.slice(startIndex, endIndex).map(row => row.id)
      setChecked(newChecked)
    } else {
      setChecked([])
    }
  }

  const handleCheck = id => event => {
    const currentIndex = checked.indexOf(id)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(id)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  // useEffect(() => console.log('Checked:', checked), [checked])

  // useEffect(() => console.log('Rows:', rows), [rows])

  // useEffect(() => console.log('Edited Rows:', editTempRows), [editTempRows])

  // useEffect(() => console.log('Saved Rows:', savedRows), [savedRows])

  const labelContainerRef = useRef(null)

  const [barcodes, setBarcodes] = useState([])

  // useEffect(() => {
  //   if (labelContainerRef.current) {
  //     // Clear previous contents
  //     labelContainerRef.current.innerHTML = ''

  //     // Generate barcode for each item in barcodes array
  //     barcodes.forEach((barcode, index) => {
  //       const svgId = `barcode_${index}`
  //       const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  //       svgElement.setAttribute('id', svgId)
  //       labelContainerRef.current.appendChild(svgElement)
  //       // Pass barcode.id instead of entire barcode object
  //       JsBarcode(`#${svgId}`, barcode.id, { format: 'code39', margin: 5 })
  //     })
  //   }
  // }, [barcodes])

  // const handlePrint = () => {
  //   const svgs = labelContainerRef.current.querySelectorAll('svg')
  //   const labelsPerPage = 1

  //   const printWindow = window.open('', '_blank')
  //   printWindow.document.open()
  //   printWindow.document.write(`
  //     <html>
  //       <head>
  //         <title>Label Print</title>
  //         <style>
  //           @page {
  //             size: 3in 1in;
  //             margin: 0;
  //           }
  //           body {
  //             margin: 0;
  //             padding: 0;
  //           }
  //           svg {
  //             width: auto;
  //             height: 57.5%;
  //             display: block;
  //             margin:  auto;
  //             padding: 0;
  //           }
  //           .label {
  //             border: 1px solid black;
  //             height: 15px;
  //             width: 130px;
  //             display: flex;
  //             justify-content: center;
  //             align-items: center;
  //             font-size: 8px;
  //             font-weight: 900;
  //             margin-top: 5px;
  //           }
  //           .label-container {
  //             display: flex;
  //             width: 90%;
  //             justify-content: space-between;
  //             padding: 5px 0 0 10px ;
  //           }
  //           .logo {
  //             display: flex;
  //             justify-content: flex-start;
  //             align-items: center;
  //             margin: 0;
  //             padding: 0;
  //           }
  //         </style>
  //       </head>
  //       <body>
  //   `)

  //   let pageIndex = 0
  //   svgs.forEach((svg, index) => {
  //     if (index % labelsPerPage === 0 && index !== 0) {
  //       printWindow.document.write('</body></html>')
  //       printWindow.document.write('<div style="page-break-before: always;"></div>')
  //       printWindow.document.write('<html><body>')
  //       pageIndex++
  //     }

  //     printWindow.document.write('<div class="label-container">')

  //     printWindow.document.write('<div class="logo">')
  //     printWindow.document.write(
  //       '<img src="http://localhost:3001/images/KIU-LOGO.png" alt="logo" height="30px" width="auto"/>'
  //     )
  //     printWindow.document.write('</div>')
  //     printWindow.document.write('<div class="label">')
  //     printWindow.document.write(`
  //           <span>ITEM: ${barcodes[index].item}</span>
  //     `)
  //     printWindow.document.write('</div></div>')
  //     printWindow.document.write(svg.outerHTML)
  //   })

  //   printWindow.document.write('</body></html>')
  //   printWindow.document.close()
  //   printWindow.print()

  //   printWindow.addEventListener('afterprint', () => {
  //     printWindow.close()
  //   })
  // }

  const printBarcodes = () => {
    apiDefinitions
      .getItemDetailsBarcode(userID, currentRouteString, { item_detail_ids: checked })
      .then(res => {
        if (res.data.code === 200) {
          const barcodes = res.data.data

          console.log('Barcodes:', barcodes)
          setBarcodes(barcodes)
          printZPL()
        } else {
          console.log('Error fetching barcodes:', res.data.message)
          toast.error('Error: ' + res.data.message)
        }
      })
      .catch(error => {
        console.error('Error fetching barcodes:', error)
        toast.error('Error fetching barcodes!')
      })
  }

  const printZPL = () => {
    if (barcodes.length <= 0) {
      toast.error('Error Printing Barcodes!')
    } else {
      qz.websocket
        .connect()
        .then(() => {
          console.log('Connected to QZ')
          return qz.printers.find('ZDesigner ZD230-203dpi ZPL', {
            orientation: 'landscape',
            size: { width: 3, height: 1 },
            units: 'in',
            colorType: 'blackWhite',
            interpolation: 'nearest-neighbor',
            margins: { top: 0, right: 0, bottom: 0, left: 0 },
            jobName: 'Label Print'
          })
        })
        .then(printer => {
          let config = qz.configs.create(printer)
          let cmds = '^XA'
          for (let barcode of barcodes) {
            let itemName
            if (barcode.item.length > 27) {
              itemName = barcode.item.substring(0, 27)
            } else if (barcode.item.length < 27) {
              //add spaces to front and back of the string to center it
              let spaces = (21 - barcode.item.length) / 2
              itemName = barcode.item.padStart(spaces + barcode.item.length, ' ')
              itemName = itemName.padEnd(21, ' ')
            } else {
              itemName = barcode.item
            }
            cmds += '^XA'
            cmds += '^CFA,20'
            cmds += '^FO170,25^GB420,60,2^FS'
            cmds += `^FO185,50^FDITEM: ${itemName}^FS`
            cmds += '^FO100,100'
            cmds += `^BY2,3,80^BC^FD${barcode.id}^FS`
            cmds += '^CFA,50'
            cmds += '^FO30,35^FDKIU^FS'
            cmds += '^XZ'
          }
          cmds += '^XZ'
          let data = [cmds]
          console.log('Printing ZPL')
          return qz.print(config, data)
        })
        .catch(e => {
          alert(e)
        })
        .finally(() => {
          console.log('Printing done!')
          if (qz.websocket.isActive()) {
            qz.websocket.disconnect()
          }
        })
    }
  }

  useEffect(() => {
    setLotCurrency(apiData.lot_details?.currency?.currency_code)
  }, [apiData])

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12}>
          <LotForm apiData={apiData} />
        </Grid>
      </Grid>

      <Card
        sx={{
          mt: 1
        }}
      >
        <Grid container spacing={2} sx={{ m: 0.3 }}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                gap: 2
              }}
            >
              <Button
                variant='outlined'
                color='primary'
                startIcon={<Icon icon='icon-park-outline:edit-one' />}
                onClick={handleMultipleEdit}
                disabled={checked.length <= 1}
              >
                Edit Multiple
              </Button>
              <Button
                variant='outlined'
                color='primary'
                startIcon={<Icon icon='ic:outline-print' />}
                disabled={checked.length <= 0}
                onClick={printBarcodes}
              >
                Print Label
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          sx={{
            pt: 2,
            px: 2
          }}
        >
          <Grid item xs={12}>
            <TableContainer sx={{ height: 383 }}>
              <Table aria-label='sticky table' sx={{ borderCollapse: 'separate' }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      padding='checkbox'
                      style={{
                        position: 'sticky',
                        left: 0,
                        top: 0,
                        zIndex: 2,
                        background: 'white',
                        borderRight: '1px solid rgba(224, 224, 224, 1)'
                      }}
                    >
                      {/* <Checkbox checked={checked.length === rows.length} onChange={handleCheckAll} /> */}
                      <Checkbox
                        checked={rows
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .every(row => checked.includes(row.id))}
                        onChange={handleCheckAll}
                      />
                    </TableCell>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.colAlign}
                        style={{
                          minWidth: column.minWidth,
                          position: 'sticky',
                          top: 0,
                          background: 'white',
                          zIndex: 1 // Ensuring column headers are above checkboxes
                        }}
                      >
                        {column.label}
                        {column.fieldType === 'number' && column.isCurrency ? ` (${lotCurrency})` : null}
                      </TableCell>
                    ))}
                    <TableCell
                      key='actions'
                      align='center'
                      style={{
                        position: 'sticky',
                        background: 'white',
                        right: 0,
                        top: 0,
                        minWidth: 60,
                        zIndex: 2, // Ensuring actions column is above checkboxes
                        borderLeft: '1px solid rgba(224, 224, 224, 1)'
                      }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                    return (
                      <TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
                        <TableCell
                          padding='checkbox'
                          style={{
                            position: 'sticky',
                            background: 'white',
                            left: 0,
                            borderRight: '1px solid rgba(224, 224, 224, 1)'
                          }}
                        >
                          <Checkbox checked={checked.indexOf(row.id) !== -1} onChange={handleCheck(row.id)} />
                        </TableCell>
                        {columns.map(column => {
                          const value = row[column.id]
                          return (
                            <TableCell key={column.id} align={column.rowAlign}>
                              {column.fieldType === 'number' && column.isCurrency
                                ? (() => {
                                    return currencyFormatting(value || 0)
                                  })()
                                : column.fieldType === 'select'
                                ? column.id === 'vendor'
                                  ? (() => {
                                      return value || 'N/A'
                                    })()
                                  : (() => {
                                      return (
                                        column.listValues.find(listValue => Number(listValue.key) === value)?.value ||
                                        'N/A'
                                      )
                                    })()
                                : column.fieldType === 'multiple_select'
                                ? (() => {
                                    return typeof value === 'array'
                                      ? value
                                          ?.map(
                                            val =>
                                              column.listValues.find(listValue => Number(listValue.key) === val)?.value
                                          )
                                          .join(', ') || 'N/A'
                                      : column.listValues.find(listValue => Number(listValue.key) === value)?.value ||
                                          'N/A'
                                  })()
                                : column.fieldType === 'datetime'
                                ? (() => {
                                    return new Date(value).toLocaleString() || 'N/A'
                                  })()
                                : column.fieldType === 'time'
                                ? (() => {
                                    return value?.toLocaleString() || 'N/A'
                                  })()
                                : column.fieldType === 'text' && column.id === 'print_column'
                                ? (() => {
                                    // console.log('Print Column Value:', value)
                                    return (
                                      <>
                                        <Typography
                                          variant='body2'
                                          sx={{
                                            fontSize: '13px'
                                          }}
                                        >
                                          Print Count: {value?.print_count || 'N/A'}
                                        </Typography>
                                        <Typography
                                          variant='body2'
                                          sx={{
                                            fontSize: '13px'
                                          }}
                                        >
                                          Last Print: {value?.last_print || 'N/A'}
                                        </Typography>
                                      </>
                                    )
                                  })()
                                : (() => {
                                    return value || 'N/A'
                                  })()}
                            </TableCell>
                          )
                        })}
                        <TableCell
                          key='actions'
                          style={{
                            position: 'sticky',
                            background: 'white',
                            right: 0,
                            borderLeft: '1px solid rgba(224, 224, 224, 1)'
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              gap: 1
                            }}
                          >
                            <IconButton
                              aria-label='edit'
                              color='primary'
                              onClick={() => handleSingleEdit(row.id, itemID)}
                            >
                              <Icon icon='tabler:edit' />
                            </IconButton>
                            <IconButton aria-label='delete' color='primary'>
                              <Icon icon='fluent:delete-12-regular' />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  {rows.length <= 0 && (
                    <TableRow>
                      <TableCell colSpan={columns.length + 1} align='center'>
                        <StyledGridOverlay sx={{ mt: 5 }}>
                          <svg width='120' height='147.5' viewBox='0 0 184 152' aria-hidden focusable='false'>
                            <g fill='none' fillRule='evenodd'>
                              <g transform='translate(24 31.67)'>
                                <ellipse className='ant-empty-img-5' cx='67.797' cy='106.89' rx='67.797' ry='12.668' />
                                <path
                                  className='ant-empty-img-1'
                                  d='M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z'
                                />
                                <path
                                  className='ant-empty-img-2'
                                  d='M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z'
                                />
                                <path
                                  className='ant-empty-img-3'
                                  d='M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z'
                                />
                              </g>
                              <path
                                className='ant-empty-img-3'
                                d='M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z'
                              />
                              <g className='ant-empty-img-4' transform='translate(149.65 15.383)'>
                                <ellipse cx='20.654' cy='3.167' rx='2.849' ry='2.815' />
                                <path d='M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z' />
                              </g>
                            </g>
                          </svg>
                          <Box sx={{ mt: 1, mb: 5 }}>No Rows</Box>
                        </StyledGridOverlay>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component='div'
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Card>

      <MultipleEdit open={openMultipleEdit} onClose={handleCloseMultipleEdit} idArray={checked} />
      <EditSingleItemDetail
        open={openSingleEdit}
        onClose={handleCloseSingleEdit}
        rowId={selectedEditID}
        itemId={selectedItemId}
        lotId={lotID}
      />
      <div ref={labelContainerRef} style={{ display: 'none' }}></div>
    </>
  )
}

export default ViewItemDetails
