import { Icon } from '@iconify/react'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  styled
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import apiDefinitions from '../../../api/apiDefinitions'
import { useCurrentRoute, useUserID, useUserItemRole, validColorCodes } from '../../../hooks/customHooks'
import MultiVendorAutoComplete from './view-request/components/MultiVendorAutoComplete'

const CustomCloseButton = styled(IconButton)(({ theme }) => ({
  top: 0,
  right: 0,
  color: 'grey.500',
  position: 'absolute',
  boxShadow: theme.shadows[2],
  transform: 'translate(10px, -10px)',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: `${theme.palette.background.paper} !important`,
  transition: 'transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out',
  '&:hover': {
    transform: 'translate(7px, -5px)'
  }
}))

const Index = () => {
  const navigate = useNavigate()
  const role = useUserItemRole() ?? ''
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''

  const [rows, setRows] = useState([])
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 5 })
  const [recordCount, setRecordCount] = useState(0)

  const [vendorList, setVendorList] = useState([])
  const [itemList, setItemList] = useState([])

  const [item, setItem] = useState(null)
  const [itemError, setItemError] = useState(false)
  const [quantity, setQuantity] = useState('')
  const [quantityError, setQuantityError] = useState(false)
  const [note, setNote] = useState('')
  const [noteError, setNoteError] = useState(false)

  const [selectedVendors, setSelectedVendors] = useState([])
  const [selectedVendorsError, setSelectedVendorsError] = useState(false)

  const [itemSummeryID, setItemSummeryID] = useState('')
  const [tableRefresh, setTableRefresh] = useState(false)
  const [addItemSummeryDialog, setAddItemSummeryDialog] = useState(false)

  const handleAddItemSummeryDialog = () => {
    setAddItemSummeryDialog(true)
  }

  const handleAddItemSummeryDialogClose = () => {
    setAddItemSummeryDialog(false)
    setItem(null)
    setQuantity('')
    setNote('')
    setSelectedVendors([])
    setItemError('')
    setQuantityError('')
    setNoteError('')
    setSelectedVendorsError('')
  }

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '') {
      apiDefinitions
        .getAllDataList(userID, currentRouteString, 'item')
        .then(res => {
          if (res.data.code === 200) {
            setItemList(res.data.data)
          } else {
            toast.error(`Error: ${res.data.message}`)
          }
        })
        .catch(err => {
          console.log(err)
          toast.error('Error fetching Vendor List!')
        })
    }
  }, [userID, currentRouteString])

  const handleFormSubmit = () => {
    // console.log('Form Submitted!')
    setTableRefresh(!tableRefresh)
  }

  const handleItemSummeryAdd = () => {
    let hasError = false

    if (item === null || item === '' || item === undefined) {
      setItemError('Item cannot be empty')
      hasError = true
    } else {
      setItemError('')
    }

    if (quantity.trim() === '') {
      setQuantityError('Quantity cannot be empty')
      hasError = true
    } else {
      setQuantityError('')
    }

    if (note.trim() === '') {
      setNoteError('Note cannot be empty')
      hasError = true
    } else {
      setNoteError('')
    }

    // if (selectedVendors.length === 0) {
    //   setSelectedVendorsError('Vendor cannot be empty')
    //   hasError = true
    // } else {
    //   setSelectedVendorsError('')
    // }

    if (hasError) return
    Swal.fire({
      title: 'Are you sure you want to add this Item?',
      showCancelButton: true,
      confirmButtonText: 'Add',
      cancelButtonText: 'Cancel',
      customClass: {
        popup: 'swal2-popup'
      }
    }).then(result => {
      if (result.isConfirmed) {
        const payload = {
          item_id: item.id,
          item_qty: quantity,
          special_notes: note,
          vendors: selectedVendors.map(vendor => vendor.id)
        }

        console.log('Payload:', payload)

        apiDefinitions
          .postItemSummeryCreate(userID, currentRouteString, payload)
          .then(res => {
            if (res.data.code === 201) {
              setItemSummeryID(res.data.data.por_id)
              toast.success('Item Added Successfully')
              handleFormSubmit()
              handleAddItemSummeryDialogClose()
              navigate(
                `/purchase-order/purchase-order-request/view-request?request=view-request&id=${res.data.data.por_id}`
              )
            } else {
              console.log('error')
              toast.error(`Error: ${res.data.message}`)
            }
          })
          .catch(err => {
            console.log(err)
            toast.error('Error Creating Item!')
          })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled Adding New Item', '', 'info')
      }
    })
  }

  const handlePORequestView = id => {
    navigate(`/purchase-order/purchase-order-request/view-request?request=view-request&id=${id}`)
  }

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '') {
      apiDefinitions
        .getAllPurchaseOrderRequest(userID, currentRouteString, paginationModel.page, paginationModel.pageSize)
        .then(res => {
          if (res.data.code === 200) {
            console.log(res)
            setRows(res.data.data.data)
            setRecordCount(res.data.data.total_record_count)
          } else {
            console.log('error')
            toast.error(`Error: ${res.data.message}`)
            setRows([])
            setRecordCount(0)
          }
        })
        .catch(err => {
          console.log(err)
          toast.error('Error fetching Items List!')
          setRows([])
          setRecordCount(0)
        })
    }
  }, [currentRouteString, paginationModel, userID, tableRefresh])

  const userColumns = [
    {
      field: 'por_code',
      headerName: 'PO ID',
      minWidth: 120,
      flex: 0.25,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.por_code || 'N/A'}</Typography>
      }
    },
    {
      field: 'special_notes',
      headerName: 'Description',
      minWidth: 120,
      flex: 0.5,
      renderCell: params => {
        return (
          <Typography
            variant='body2'
            sx={{
              my: 2
            }}
          >
            {params.row.special_notes || 'N/A'}
          </Typography>
        )
      }
    },
    {
      field: 'tot_item',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            fontWeight: 500
          }}
        >
          Total Items
        </Typography>
      ),
      minWidth: 120,
      flex: 0.05,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.tot_item || 'N/A'}</Typography>
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 180,
      flex: 0.35,
      headerAlign: 'center',
      align: 'center',
      valueGetter: params => {
        return params.row.status?.type ? String(params.row.status?.type).toUpperCase() : 'Status Not Found'
      },
      renderCell: params => {
        const color = validColorCodes.includes(params.row.status?.color_code)
          ? params.row.status.color_code
          : 'secondary'
        return (
          <Chip
            variant='filled'
            color={color}
            sx={{ my: 2.15 }}
            label={
              <Typography
                variant='body2'
                sx={{
                  fontSize: '0.75rem'
                }}
              >
                {params.row.status?.type ? String(params.row.status?.type).toUpperCase() : 'Status Not Found'}
              </Typography>
            }
          ></Chip>
        )
      }
    },
    {
      field: 'created_date',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            fontWeight: 500
          }}
        >
          Created
          <br />
          Date
        </Typography>
      ),
      minWidth: 100,
      flex: 0.05,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.created_date || 'N/A'}</Typography>
      }
    },
    {
      field: 'last_updated_date',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            fontWeight: 500
          }}
        >
          Last Updated
          <br />
          Date
        </Typography>
      ),
      minWidth: 120,
      flex: 0.05,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.last_updated_date || 'N/A'}</Typography>
      }
    },
    {
      field: 'last_updated_user',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            fontWeight: 500
          }}
        >
          Last Updated
          <br />
          User
        </Typography>
      ),
      minWidth: 150,
      flex: 0.35,
      valueGetter: params => {
        return params.row.last_updated_user.user_name || 'N/A'
      },
      renderCell: params => {
        return <Typography variant='body2'>{params.row.last_updated_user.user_name || 'N/A'}</Typography>
      }
    },
    {
      field: 'actions',
      filterable: false,
      sortable: false,
      headerName: 'Actions',
      minWidth: 150,
      flex: 0.1,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return (
          <Box
            sx={{
              px: 2,
              py: 1,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <IconButton color='primary'>
              <Icon
                icon='fluent:open-24-filled'
                style={{ fontSize: '25px' }}
                onClick={() => handlePORequestView(params.row.id)}
              />
            </IconButton>
          </Box>
        )
      }
    }
  ]

  const adminColumns = [
    {
      field: 'por_code',
      headerName: 'PO ID',
      minWidth: 150,
      flex: 0.5,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.por_code || 'N/A'}</Typography>
      }
    },
    {
      field: 'special_notes',
      headerName: 'Description',
      minWidth: 350,
      flex: 0.5,
      renderCell: params => {
        return (
          <Typography
            variant='body2'
            sx={{
              my: 2
            }}
          >
            {params.row.special_notes || 'N/A'}
          </Typography>
        )
      }
    },
    {
      field: 'tot_item',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            fontWeight: 500
          }}
        >
          Total Items
        </Typography>
      ),
      minWidth: 120,
      flex: 0.05,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.tot_item || 'N/A'}</Typography>
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 200,
      flex: 0.35,
      headerAlign: 'center',
      align: 'center',
      valueGetter: params => {
        return params.row.status?.type ? String(params.row.status?.type).toUpperCase() : 'Status Not Found'
      },
      renderCell: params => {
        const color = validColorCodes.includes(params.row.status?.color_code)
          ? params.row.status.color_code
          : 'secondary'
        return (
          <Chip
            variant='filled'
            color={color}
            sx={{ my: 2 }}
            label={
              <Typography
                variant='body2'
                sx={{
                  fontSize: '0.75rem'
                }}
              >
                {params.row.status?.type ? String(params.row.status?.type).toUpperCase() : 'Status Not Found'}
              </Typography>
            }
          ></Chip>
        )
      }
    },
    {
      field: 'created_date',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            fontWeight: 500
          }}
        >
          Created
          <br />
          Date
        </Typography>
      ),
      minWidth: 100,
      flex: 0.05,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.created_date || 'N/A'}</Typography>
      }
    },
    {
      field: 'last_updated_date',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            fontWeight: 500
          }}
        >
          Last Updated
          <br />
          Date
        </Typography>
      ),
      minWidth: 120,
      flex: 0.05,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.last_updated_date || 'N/A'}</Typography>
      }
    },
    {
      field: 'last_updated_user',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            fontWeight: 500
          }}
        >
          Last Updated User
        </Typography>
      ),
      minWidth: 180,
      flex: 0.05,
      valueGetter: params => {
        return params.row.last_updated_user.user_name || 'N/A'
      },
      renderCell: params => {
        return <Typography variant='body2'>{params.row.last_updated_user.user_name || 'N/A'}</Typography>
      }
    },
    {
      field: 'created_user',
      headerName: 'Created User',
      minWidth: 180,
      flex: 0.05,
      valueGetter: params => {
        return params.row.created_user.user_name || 'N/A'
      },
      renderCell: params => {
        return <Typography variant='body2'>{params.row.created_user.user_name || 'N/A'}</Typography>
      }
    },
    {
      field: 'department',
      headerName: 'Department',
      minWidth: 180,
      flex: 0.5,
      valueGetter: params => {
        return params.row.created_user.departments.department_name || 'N/A'
      },
      renderCell: params => {
        return <Typography variant='body2'>{params.row.created_user.departments.department_name || 'N/A'}</Typography>
      }
    },
    {
      field: 'actions',
      filterable: false,
      sortable: false,
      headerName: 'Actions',
      minWidth: 150,
      flex: 0.1,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return (
          <Box
            sx={{
              px: 2,
              py: 1,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <IconButton color='primary'>
              <Icon
                icon='fluent:open-24-filled'
                style={{ fontSize: '22px' }}
                onClick={() => handlePORequestView(params.row.id)}
              />
            </IconButton>
          </Box>
        )
      }
    }
  ]

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant='h6'>Purchase Order Request</Typography>
            {role !== 'item-admin' ? (
              <Button
                variant='contained'
                startIcon={<Icon icon='mdi:clipboard-text-history-outline' />}
                onClick={handleAddItemSummeryDialog}
              >
                Create PO Request
              </Button>
            ) : null}
          </Box>
        </Grid>

        <Grid item xs={12}>
          {role === 'item-admin' ? (
            <Card sx={{ boxShadow: 2 }}>
              <Box
                sx={{
                  height: 450,
                  width: '100%',
                  '& .actions': {
                    color: 'text.secondary'
                  },
                  '& .textPrimary': {
                    color: 'text.primary'
                  }
                }}
              >
                <DataGrid
                  getRowHeight={() => 'auto'}
                  rows={rows}
                  rowCount={recordCount}
                  columns={adminColumns}
                  pageSizeOptions={[5, 10, 25, 50, 100]}
                  paginationMode='server'
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  disableRowSelectionOnClick
                />
              </Box>
            </Card>
          ) : (
            <Card sx={{ boxShadow: 2 }}>
              <Box
                sx={{
                  height: 450,
                  width: '100%',
                  '& .actions': {
                    color: 'text.secondary'
                  },
                  '& .textPrimary': {
                    color: 'text.primary'
                  }
                }}
              >
                <DataGrid
                  getRowHeight={() => 'auto'}
                  rows={rows}
                  rowCount={recordCount}
                  columns={userColumns}
                  pageSizeOptions={[5, 10, 25, 50, 100]}
                  paginationMode='server'
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  disableRowSelectionOnClick
                />
              </Box>
            </Card>
          )}
        </Grid>
      </Grid>

      <Dialog
        open={addItemSummeryDialog}
        onClose={handleAddItemSummeryDialogClose}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        scroll='paper'
        fullWidth
        sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
      >
        <DialogTitle variant='h5'>
          Add New Item Summary
          <CustomCloseButton aria-label='close' onClick={handleAddItemSummeryDialogClose}>
            <Icon icon='tabler:x' fontSize='1.25rem' />
          </CustomCloseButton>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2} sx={{ py: 1 }}>
            <Grid item xs={6}>
              <FormControl fullWidth required error={!!itemError}>
                <Autocomplete
                  options={itemList}
                  getOptionLabel={option => option.short_name}
                  getOptionKey={option => option.id}
                  value={item}
                  onChange={(event, newValue) => {
                    setItem(newValue)
                    setItemError('')
                  }}
                  renderInput={params => (
                    <TextField {...params} label='Item' error={!!itemError} helperText={itemError} />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Quantity'
                required
                onChange={e => {
                  setQuantity(e.target.value)
                  setQuantityError('')
                }}
                onInput={e => {
                  // Prevent input of letters and other special characters except for '.'
                  const inputValue = e.target.value
                  const sanitizedInput = inputValue.replace(/[^0-9.]/g, '') // Remove anything that's not a number or '.'
                  if (inputValue !== sanitizedInput) {
                    e.target.value = sanitizedInput // Set the input value to the sanitized version
                    setQuantity(sanitizedInput) // Update state if needed
                  }
                }}
                error={!!quantityError}
                helperText={quantityError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                      <Icon icon='iwwa:number-desc' />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label='Note'
                required
                multiline
                minRows={2}
                onChange={e => {
                  setNote(e.target.value)
                  setNoteError('')
                }}
                error={!!noteError}
                helperText={noteError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                      <Icon icon='octicon:note-24' />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MultiVendorAutoComplete
                selectedVendors={selectedVendors}
                setSelectedVendors={setSelectedVendors}
                selectedVendorsError={selectedVendorsError}
                setSelectedVendorsError={setSelectedVendorsError}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='primary' onClick={handleItemSummeryAdd}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Index
