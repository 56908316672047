import React, { useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Badge, Box, Chip, Divider, IconButton, Typography } from '@mui/material'
import { Icon } from '@iconify/react'
import { Button } from '@mui/material'

const initialNotificationList = [
  {
    id: 1,
    title: 'New Order Recieved',
    description: 'Dummy text of the printing and typesetting industry.',
    time: '45 min ago',
    readStatus: false
  },
  {
    id: 2,
    title: 'New User Registered',
    description: 'Dummy text of the printing and typesetting industry.',
    time: '1 hour ago',
    readStatus: false
  },
  {
    id: 3,
    title: 'New Order Recieved',
    description: 'Dummy text of the printing and typesetting industry.',
    time: '45 min ago',
    readStatus: true
  },
  {
    id: 4,
    title: 'New User Registered',
    description: 'Dummy text of the printing and typesetting industry.',
    time: '1 hour ago',
    readStatus: true
  }
]

const NotificationDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [notificationList, setNotificationList] = useState(initialNotificationList)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMarkAllAsRead = () => {
    const updatedNotificationList = notificationList.map(notif => ({
      ...notif,
      readStatus: true
    }))
    setNotificationList(updatedNotificationList)
  }

  return (
    <>
      <Badge
        badgeContent={notificationList.filter(notif => !notif.readStatus).length}
        color='primary'
        overlap='circular'
      >
        <Icon icon='ion:notifications' className='appbar-notification-icon' id='notifs-icon' onClick={handleClick} />
      </Badge>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'notifs-icon'
        }}
        sx={{ mt: 3.3, '& .MuiMenu-paper': { minWidth: 400, p: 1 } }}
      >
        <Box
          sx={{
            mx: 2,
            my: 1,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Typography variant='h6'>Notifications</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 0.5
            }}
          >
            {notificationList && notificationList.filter(notif => !notif.readStatus).length ? (
              <Chip
                label='Mark All as Read'
                color='primary'
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#1264b5'
                  }
                }}
                onClick={handleMarkAllAsRead}
              />
            ) : null}
            <IconButton>
              <Icon icon='mdi:notification-settings-outline' />
            </IconButton>
          </Box>
        </Box>
        <Divider sx={{ mx: 2, my: 1 }} />
        <Box sx={{ mx: 2, my: 1 }}>
          {notificationList.length ? (
            <>
              <Box
                className='content-display-grid-scroll'
                sx={{
                  maxHeight: '250px',
                  overflowY: 'auto',
                  pr: 1
                }}
              >
                {notificationList.slice(0, 5).map(notif => (
                  <MenuItem
                    key={notif.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      backgroundColor: notif.readStatus ? 'inherit' : 'rgba(25, 118, 210, 0.1)',
                      my: 1
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%'
                      }}
                    >
                      <Typography variant='subtitle1' sx={{ fontSize: '14px', fontWeight: 500 }}>
                        {notif.title}
                      </Typography>
                      <Typography variant='body2' sx={{ fontSize: '12px' }}>
                        {notif.time}
                      </Typography>
                    </Box>
                    <Typography variant='body1' sx={{ fontSize: '14px' }}>
                      {notif.description}
                    </Typography>
                  </MenuItem>
                ))}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mt: 2,
                  width: '100%'
                }}
              >
                <Button variant='outlined' onClick={() => console.log('clicked')} fullWidth>
                  View All
                </Button>
              </Box>
            </>
          ) : (
            <Typography>No New Notifications.</Typography>
          )}
        </Box>
      </Menu>
    </>
  )
}

export default NotificationDropdown
