import { Box, Checkbox, Grid, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import apiDefinitions from '../../../api/apiDefinitions'
import { useCurrentRoute, useUserID } from '../../../hooks/customHooks'

import ViewSingleItemDetail from './ViewSingleItemDetail'

const LinkStyled = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.dark,
  textDecoration: 'underline',
  '&:hover': {
    color: theme.palette.primary.light
  }
}))

const ItemAssignment = ({
  rows2,
  setRows2,
  rows,
  setRows,
  selectedRows,
  setSelectedRows,
  setRecordCount,
  setRecordCount2,
  recordCount,
  recordCount2
}) => {
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''

  const [detailPopupID, setDetailPopupID] = useState(null)
  const [openSingleItemDetail, setOpenItemDetail] = useState(false)

  const handleQtyChange = (newValue, rowId) => {
    const updatedRows = rows2.map(r => {
      if (r.id === rowId) {
        return { ...r, assQty: Number(newValue) }
      }
      return r
    })
    setRows2(updatedRows)
  }

  const handleAdminReqChange = (e, row) => {
    const value = e.target.checked
    const updatedRows = rows2.map(r => {
      if (r.id === row.id) {
        return { ...r, adminReq: value }
      }
      return r
    })
    setRows2(updatedRows)
  }

  const handleSingleDetailView = id => {
    setDetailPopupID(id)
    setOpenItemDetail(true)
  }

  const handleCloseSingleItemDetail = () => {
    setOpenItemDetail(false)
    setDetailPopupID(null)
  }
  const columns = [
    { field: 'itemCategory', headerName: 'Item Category', flex: 1 },
    { field: 'itemName', headerName: 'Item Name', flex: 1 },
    {
      field: 'id',
      headerName: 'Item Detail',
      flex: 1,
      renderCell: params => (
        <LinkStyled onClick={e => handleSingleDetailView(params.row.id)}>{params.row.id}</LinkStyled>
      )
    },
    { field: 'qty', headerName: 'Available Quantity', flex: 1 }
  ]
  const columns2 = [
    { field: 'id', headerName: 'ID', width: 100, flex: 1 },
    {
      field: 'qty',
      headerName: 'Assign Quantity',
      width: 200,
      flex: 1,
      renderCell: params => (
        <Box
          sx={{
            py: 1.5
          }}
        >
          <TextField
            type='number'
            onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
            inputProps={{ min: 0, max: params.row.qty }}
            value={params.row.assQty}
            size='small'
            InputProps={{
              endAdornment: <Typography>/{params.row.qty}</Typography>
            }}
            InputLabelProps={{
              shrink: true
            }}
            onChange={e => {
              // const cleanedEntry = e.target.value.replace(/[^0-9]/g, '')
              const cleanedEntry = e.target.value

              if (cleanedEntry > params.row.qty && cleanedEntry !== '' && cleanedEntry !== 0) {
                toast.error('Assignment Quantity should be less than or equal to Assigned Quantity')
              } else {
                handleQtyChange(cleanedEntry, params.row.id)
              }
            }}
          />
        </Box>
      )
    },
    {
      field: 'adminReq',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            textAlign: 'center',
            fontWeight: 500
          }}
        >
          Admin Approval
          <br />
          on Reassignment
        </Typography>
      ),
      headerAlign: 'center',
      align: 'center',
      width: 100,
      flex: 0.5,
      renderCell: params => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Checkbox
            color='primary'
            checked={params?.value ? params.value : false}
            onChange={e => handleAdminReqChange(e, params.row)}
          />
        </Box>
      )
    }
  ]

  const [search, setSearch] = useState(null)

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0
  })

  const [paginationModel2, setPaginationModel2] = useState({
    pageSize: 5,
    page: 0
  })

  const [filteredRows, setFilteredRows] = useState([])

  const handleFilterChange = e => {
    const value = e.target.value.toLowerCase()
    setSearch(value)
    if (value) {
      const filtered = rows.filter(
        row => row.itemName.toLowerCase().includes(value) || row.itemCategory.toLowerCase().includes(value) || row.qty.toString().includes(value) || row.id.toString().includes(value)
      )
      setFilteredRows(filtered)
      setRecordCount(filtered?.length)
    } else {
      setFilteredRows([])
      setRecordCount(rows?.length)
    }
  }

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '') {
      apiDefinitions
        .getAssignedItemByType(
          'department',
          userID,
          currentRouteString,
          paginationModel.page,
          paginationModel.pageSize,
          ''
        )
        .then(res => {
          if (res.data.code === 200) {
            // console.log(res.data.data.data.department_assigned_items)

            const data = res.data.data.data.department_assigned_items.map(item => {
              return {
                id: item.item_detail_id,
                assign_id: item.id,
                itemName: item.item_detail.items.short_name,
                itemCategory: item.item_detail.items.category.category_name,
                qty: item.remaining_quantity
              }
            })

            setRows(data)
            setRecordCount(res.data.data.count)
          } else {
            console.log('error')
            toast.error(`Error: ${res.data.message}`)
            setRows([])
            setRecordCount(0)
          }
        })
        .catch(err => {
          console.log(err)
          toast.error('Error fetching Items List!')
          setRows([])
          setRecordCount(0)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRouteString, paginationModel, userID])

  useEffect(() => {
    const newSelectedRows = selectedRows.filter(rowId => !rows2.some(r => r.id === rowId))

    const rowsToRemove = rows2.filter(row => !selectedRows.includes(row.id))

    const selectedRowData = newSelectedRows.map(rowId => {
      const row = rows.find(r => r.id === rowId)
      return {
        id: row.id,
        itemName: row.itemName,
        qty: row.qty,
        assQty: row.qty,
        adminReq: false,
        assign_id: row.assign_id
      }
    })

    setRows2(oldRows => {
      const rowsToKeep = oldRows.filter(row => !rowsToRemove.some(r => r.id === row.id))
      return [...rowsToKeep, ...selectedRowData]
    })
    setRecordCount2(oldCount => oldCount - rowsToRemove.length + selectedRowData.length)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows, rows])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField label='Search' variant='outlined' fullWidth size='small' onChange={handleFilterChange} />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: 325 }}>
            <DataGrid
              getRowHeight={() => 'auto'}
              rows={search?.length > 0 ? filteredRows : rows}
              rowCount={recordCount}
              columns={columns}
              paginationMode='server'
              pageSizeOptions={[5, 10, 25, 50, 100]}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              disableRowSelectionOnClick
              keepNonExistentRowsSelected
              checkboxSelection
              rowSelectionModel={selectedRows}
              onRowSelectionModelChange={newSelection => {
                setSelectedRows(newSelection)
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: 305 }}>
            <DataGrid
              getRowHeight={() => 'auto'}
              rows={rows2}
              rowCount={recordCount2}
              columns={columns2}
              pageSizeOptions={[5, 10, 25, 50, 100]}
              paginationModel={paginationModel2}
              onPaginationModelChange={setPaginationModel2}
              disableRowSelectionOnClick
            />
          </Box>
        </Grid>
      </Grid>
      <ViewSingleItemDetail itemId={detailPopupID} open={openSingleItemDetail} onClose={handleCloseSingleItemDetail} />
    </>
  )
}

export default ItemAssignment
