import CancelIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { TextField, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { DataGrid, GridActionsCellItem, GridRowEditStopReasons, GridRowModes } from '@mui/x-data-grid'
import * as React from 'react'
import { useState } from 'react'

const StockRecieved = () => {
  const [rows, setRows] = useState([])
  const [rowModesModel, setRowModesModel] = useState({})

  const [loading, setLoading] = useState(false)

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const handleEditClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = id => () => {
    setRows(rows.filter(row => row.id !== id))
  }

  const handleCancelClick = id => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    const editedRow = rows.find(row => row.id === id)
    if (editedRow.isNew) {
      setRows(rows.filter(row => row.id !== id))
    }
  }

  const processRowUpdate = newRow => {
    const updatedRow = { ...newRow, isNew: false }
    setRows(rows.map(row => (row.id === newRow.id ? updatedRow : row)))
    return updatedRow
  }

  const handleRowModesModelChange = newRowModesModel => {
    setRowModesModel(newRowModesModel)
  }

  const columns = [
    {
      field: 'product',
      headerName: 'Product',
      flex: 1.5,
      minWidth: 150,
      editable: true
    },
    {
      field: 'batch_serial',
      headerName: 'Batch / Serial #',
      flex: 1.5,
      minWidth: 150,
      editable: true
    },
    {
      field: 'exp_date',
      headerName: 'Expiry Date',
      headerAlign: 'center',
      align: 'right',
      type: 'date',
      flex: 1,
      minWidth: 100,
      editable: true,
      valueFormatter: ({ value }) => (value ? new Date(value).toLocaleDateString() : ''),
      renderEditCell: params => {
        return (
          <TextField
            sx={{
              '& input': {
                textAlign: 'right'
              },
              '& fieldset': { border: 'none' }
            }}
            onFocus={event => event.target.select()}
            fullWidth
            type='date'
            value={params.value}
            onChange={event =>
              params.api.setEditCellValue({
                id: params.id,
                field: 'exp_date',
                value: event.target.value
              })
            }
          />
        )
      }
    },
    {
      field: 'supplier_SKU',
      headerName: 'Supplier SKU',
      flex: 1,
      minWidth: 120,
      editable: true
    },
    {
      field: 'unit',
      headerName: 'Unit',
      flex: 0.75,
      minWidth: 80,
      editable: true
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      headerAlign: 'center',
      align: 'right',
      type: 'number',
      flex: 0.75,
      minWidth: 80,
      editable: true,
      renderEditCell: params => {
        return (
          <TextField
            sx={{
              '& input': {
                textAlign: 'right'
              },
              '& fieldset': { border: 'none' }
            }}
            onFocus={event => event.target.select()}
            onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
            fullWidth
            type='number'
            value={params.value}
            onChange={event =>
              params.api.setEditCellValue({
                id: params.id,
                field: 'quantity',
                value: event.target.value
              })
            }
            inputProps={{ min: 0 }}
          />
        )
      }
    },
    {
      field: 'location',
      headerName: 'Location',
      flex: 1.5,
      minWidth: 160,
      editable: true
    },
    {
      field: 'date_received',
      headerName: 'Date Received',
      headerAlign: 'center',
      align: 'right',
      type: 'date',
      flex: 1,
      minWidth: 120,
      editable: true,
      valueFormatter: ({ value }) => (value ? new Date(value).toLocaleDateString() : ''),
      renderEditCell: params => {
        return (
          <TextField
            sx={{
              '& input': {
                textAlign: 'right'
              },
              '& fieldset': { border: 'none' }
            }}
            onFocus={event => event.target.select()}
            fullWidth
            type='date'
            value={params.value}
            onChange={event =>
              params.api.setEditCellValue({
                id: params.id,
                field: 'date_received',
                value: event.target.value
              })
            }
          />
        )
      }
    },
    {
      field: 'is_recieved',
      headerName: 'Recieved',
      headerAlign: 'center',
      align: 'center',
      type: 'boolean',
      flex: 0.5,
      minWidth: 80,
      editable: true
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      flex: 0.75,
      minWidth: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label='Save'
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(id)}
              color='inherit'
            />
          ]
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label='Edit'
            className='textPrimary'
            onClick={handleEditClick(id)}
            color='inherit'
          />,
          <GridActionsCellItem icon={<DeleteIcon />} label='Delete' onClick={handleDeleteClick(id)} color='inherit' />
        ]
      }
    }
  ]

  return (
    <>
      <Box
        sx={{
          height: 265,
          width: '100%',
          '& .actions': {
            color: 'text.secondary'
          },
          '& .textPrimary': {
            color: 'text.primary'
          }
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          editMode='row'
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          loading={loading}
          slots={{
            footer: () => (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  padding: '8px 16px',
                  backgroundColor: 'rgba(0, 0, 0, 0.04)'
                }}
              >
                <Typography variant='body1' fontWeight={500}>
                  Total QTY:&ensp;
                  {rows.reduce((acc, row) => {
                    const quantity = row.quantity || 0
                    return acc + quantity
                  }, 0)}
                </Typography>
              </Box>
            ),

            noRowsOverlay: () => {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                  }}
                >
                  <Typography variant='body2' color='textSecondary'>
                    You do not have any stock received lines.
                  </Typography>
                </Box>
              )
            }
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel }
          }}
        />
      </Box>
    </>
  )
}

export default StockRecieved
