import { Icon } from '@iconify/react'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  styled,
  Radio,
  RadioGroup,
  Checkbox,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import apiDefinitions from '../../../../../../api/apiDefinitions'
import { useCurrentRoute, useUserID, useUserItemRole, validColorCodes } from '../../../../../../hooks/customHooks'

const LocationList = [
  {
    value: '1',
    label: 'KIU'
  },
  {
    value: '2',
    label: 'KYO'
  },
  {
    value: '3',
    label: 'SAKURA'
  }
]

const ShippingDetails = () => {
  const [blindReceiptSelected, setBlindReceiptSelected] = useState(false)
  const [shipDifferentSelected, setShipDifferentSelected] = useState(false)

  const [date, setDate] = useState('')
  const [dateError, setDateError] = useState('')

  const [location, setLocation] = useState('KIU')
  const [locationError, setLocationError] = useState('')

  const [shippingAddress1, setShippingAddress1] = useState('')
  const [shippingAddress1Error, setShippingAddress1Error] = useState('')

  const [shippingAddress2, setShippingAddress2] = useState('')
  const [shippingAddress2Error, setShippingAddress2Error] = useState('')

  const [isDisabled, setIsDisabled] = useState(true)

  const handleBillReceiptChange = event => {
    setBlindReceiptSelected(event.target.checked)
  }

  const handleShipDifferentChange = event => {
    const isChecked = event.target.checked;
    setShipDifferentSelected(isChecked);
    setIsDisabled(!isChecked);
    if (!isChecked) {
      setLocation('KIU'); // Reset to default value
      setLocationError(''); // Clear any location error
    }
  };

  const handleSelect = e => {
    setLocation(e.target.value)
    setLocationError('')
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={blindReceiptSelected} onChange={handleBillReceiptChange} />}
            label='Blind Receipt'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size='small'
            label='Date'
            type='date'
            onChange={e => {
              setDate(e.target.value)
              setDateError('')
            }}
            error={!!dateError}
            helperText={dateError}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                  <Icon icon='uil:calender' />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth error={!!locationError}>
            <InputLabel>Location</InputLabel>
            <Select
              size='small'
              value={location}
              label='Location'
              disabled={isDisabled}
              onChange={handleSelect}
            >
              {LocationList.map((item, index) => (
                <MenuItem key={item.value} value={item.label}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            {!!locationError && <FormHelperText>{locationError}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={shipDifferentSelected} onChange={handleShipDifferentChange} />}
            label='Ship to Different Company'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size='small'
            label='Shipping Address line 1'
            onChange={e => {
              setShippingAddress1(e.target.value)
              setShippingAddress1Error('')
            }}
            error={!!shippingAddress1Error}
            helperText={shippingAddress1Error}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                  <Icon icon='carbon:location' />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size='small'
            label='Shipping Address line 2'
            onChange={e => {
              setShippingAddress2(e.target.value)
              setShippingAddress2Error('')
            }}
            error={!!shippingAddress2Error}
            helperText={shippingAddress2Error}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                  <Icon icon='carbon:location' />
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ShippingDetails
