// CustomHooks.js
import { useKeycloak } from '@react-keycloak/web'
import { useLocation } from 'react-router'

export const useUserID = () => {
  const { keycloak } = useKeycloak()
  const userID = keycloak?.tokenParsed?.sub

  return userID
}

export const useCurrentRoute = () => {
  const location = useLocation()
  const currentRoute = location.pathname

  return currentRoute
}

export const useCurrentQueryParams = paramID => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  return queryParams.get(paramID)
}

export const convertImageLinkToBase64 = async link => {
  try {
    const response = await fetch(link, {
      cache: 'no-store' // Ensure that the browser does not use the cache
    })

    const blob = await response.blob()

    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = () => {
        const stringImage = String(reader.result)
        resolve(stringImage)
      }

      reader.onerror = error => {
        reject(error)
      }

      reader.readAsDataURL(blob)
    })
  } catch (error) {
    console.error('Error fetching image:', error)
    throw error // Re-throw the error to indicate failure
  }
}

export const useUserItemRole = () => {
  const { keycloak } = useKeycloak()
  const roles = keycloak?.tokenParsed?.realm_access?.roles || []

  if (roles.includes('item-admin')) {
    return 'item-admin'
  } else if (roles.includes('item-department-head')) {
    return 'item-department-head'
  } else {
    return 'item-user'
  }
}

export const returnDGFieldType = fieldType => {
  switch (fieldType) {
    case 'text':
      return 'text'
    case 'multiline':
      return ''
    case 'date':
      return 'date'
    case 'time':
      return ''
    case 'date_time':
      return 'dateTime'
    case 'select':
      return 'singleSelect'
    case 'number':
      return 'number'
    case 'multiple_select':
      return 'multilineColumn'
    default:
      return 'string'
  }
}

export const validColorCodes = ['success', 'warning', 'info', 'error', 'secondary']
