import { Icon } from '@iconify/react'
import { Box, Card, ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'
import { useState, useEffect } from 'react'
import navigation from '../navigation/nav'
import { useNavigate, useLocation } from 'react-router-dom'

const NavBar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [openNestedMenuIndex, setOpenNestedMenuIndex] = useState(-1)
  const [selectedTopMenuIndex, setSelectedTopMenuIndex] = useState(-1)
  const [selectedChildMenuIndex, setSelectedChildMenuIndex] = useState(-1)

  useEffect(() => {
    const currentPath = location.pathname
    let matchingTopMenuItemIndex = -1
    let matchingChildMenuItemIndex = -1

    navigation().forEach((item, index) => {
      if (currentPath === item.path || currentPath.startsWith(item.path)) {
        matchingTopMenuItemIndex = index
        matchingChildMenuItemIndex = -1
      } else if (item.children && item.children.length > 0) {
        item.children.forEach((child, childIndex) => {
          if (currentPath === child.path || currentPath.startsWith(child.path)) {
            matchingTopMenuItemIndex = index
            matchingChildMenuItemIndex = childIndex
          }
        })
      }
    })

    if (matchingTopMenuItemIndex !== -1) {
      setSelectedTopMenuIndex(matchingTopMenuItemIndex)
      if (matchingChildMenuItemIndex !== -1) {
        setSelectedChildMenuIndex(matchingChildMenuItemIndex)
        setOpenNestedMenuIndex(matchingTopMenuItemIndex)
      } else {
        setSelectedChildMenuIndex(-1)
        setOpenNestedMenuIndex(-1)
      }
    } else {
      setSelectedTopMenuIndex(-1)
      setSelectedChildMenuIndex(-1)
      setOpenNestedMenuIndex(-1)
    }
  }, [location.pathname])

  const handleTopMenuItemClick = index => {
    const currentItem = navigation()[index]

    if (currentItem.path) {
      navigate(currentItem.path)
    }

    setOpenNestedMenuIndex(prevIndex => (prevIndex === index ? -1 : index))
    setSelectedChildMenuIndex(-1)
    setSelectedTopMenuIndex(index)
  }

  const handleChildMenuClick = (parentIndex, childIndex) => {
    const currentTopMenuItem = navigation()[parentIndex]
    const currentChildMenuItem = currentTopMenuItem.children[childIndex]

    setSelectedChildMenuIndex(childIndex)
    setOpenNestedMenuIndex(parentIndex)
    setSelectedTopMenuIndex(parentIndex)

    if (currentChildMenuItem.path) {
      navigate(currentChildMenuItem.path)
    }
  }

  return (
    <Card className='navbarCard'>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2, pb: 0 }}>
        <img src='/images/KIU-LOGO.png' alt='KIU Logo' width={160} height={75} />
      </Box>
      <MenuList sx={{ padding: 1.5 }}>
        {navigation().map((menuItem, index) => {
          // console.log('menuItem', menuItem?.children?.length)
          return (
            <div key={index}>
              <MenuItem
                className={`navbarMenuItem ${
                  selectedChildMenuIndex === -1 &&
                  selectedTopMenuIndex === index &&
                  (!menuItem.children || menuItem.children.length === 0)
                    ? 'selectedMenuItem'
                    : ''
                }`}
                onClick={() => handleTopMenuItemClick(index)}
              >
                <ListItemIcon>
                  <Icon icon={menuItem.icon} className='navbarIcon' />
                </ListItemIcon>
                <ListItemText className='navbarText'>{menuItem.title}</ListItemText>
                {menuItem.children && menuItem.children.length > 0 && (
                  <ListItemIcon>
                    <Icon
                      icon={openNestedMenuIndex === index ? 'tabler:chevron-down' : 'tabler:chevron-right'}
                      className='navbarCehvronIcon'
                    />
                  </ListItemIcon>
                )}
              </MenuItem>
              {openNestedMenuIndex === index && menuItem.children && menuItem.children.length > 0 && (
                <MenuList key={`nested-${index}`} sx={{ paddingLeft: 2 }}>
                  {menuItem.children.map((child, childIndex) => (
                    <MenuItem
                      key={childIndex}
                      className={`navbarMenuItem ${
                        selectedChildMenuIndex === childIndex && selectedTopMenuIndex === index
                          ? 'selectedMenuItem'
                          : ''
                      }`}
                      onClick={() => handleChildMenuClick(index, childIndex)}
                    >
                      <ListItemIcon>
                        <Icon icon='cib:circle' className='navbarNestedIcon' />
                      </ListItemIcon>
                      <ListItemText className='navbarText'>{child.title}</ListItemText>
                    </MenuItem>
                  ))}
                </MenuList>
              )}
            </div>
          )
        })}
      </MenuList>
    </Card>
  )
}

export default NavBar
