// ** React Imports
import { Fragment, useEffect, useState } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'

// ** Styles Imports
import { styled } from '@mui/material/styles'

// ** Icon Imports
import { Icon } from '@iconify/react'

// ** Third Party Components
import { useDropzone } from 'react-dropzone'
import toast from 'react-hot-toast'
import FsLightbox from 'fslightbox-react'

const DropzoneWrapper = styled(Box)(({ theme, hasfiles }) => ({
  '&.dropzone, & .dropzone': {
    minHeight: 80,
    flexWrap: 'wrap',
    cursor: 'pointer',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    border: `2px dashed ${theme.palette.divider}`,
    display: hasfiles ? 'none' : 'flex',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    },
    '&:focus': {
      outline: 'none'
    },
    '& + .MuiList-root': {
      padding: 0,
      marginTop: theme.spacing(2.5),
      '& .MuiListItem-root': {
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1.5, 2.5, 1.5, 6),
        border: `1px solid ${theme.palette.mode === 'light' ? 'rgba(93, 89, 98, 0.14)' : 'rgba(247, 244, 254, 0.14)'}`,
        '& .file-details': {
          display: 'flex',
          alignItems: 'center'
        },
        '& .file-preview': {
          display: 'flex',
          marginRight: theme.spacing(3.75),
          '& svg': {
            fontSize: '2rem'
          }
        },
        '& img': {
          width: 70,
          height: 70,
          padding: theme.spacing(0.75),
          borderRadius: theme.shape.borderRadius,
          border: `1px solid ${
            theme.palette.mode === 'light' ? 'rgba(93, 89, 98, 0.14)' : 'rgba(247, 244, 254, 0.14)'
          }`,
          cursor: 'pointer'
        },
        '& .file-name': {
          fontWeight: 600
        },
        '& + .MuiListItem-root': {
          marginTop: theme.spacing(3.5)
        }
      },
      '& + .buttons': {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(6.25),
        '& > :first-of-type': {
          marginRight: theme.spacing(3.5)
        }
      }
    },
    '& img.single-file-image': {
      objectFit: 'cover',
      position: 'absolute',
      width: 'calc(100% - 1rem)',
      height: 'calc(100% - 1rem)',
      borderRadius: theme.shape.borderRadius
    }
  }
}))

const sanitizeFilename = filename => {
  return filename.replace(/[^\w\s.-]/gi, '')
}

const FileUploaderRestrictions = ({ onUpload, uploadedFile }) => {
  // ** State
  const [files, setFiles] = useState(uploadedFile || [])
  const [lightBoxImage, setLightBoxImage] = useState('')
  const [toggler, setToggler] = useState(false)

  // ** Function to render file preview
  const handleImagePreview = img => {
    setLightBoxImage(img)
    setToggler(!toggler)
  }

  // ** Hooks
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 5000000,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg']
    },
    onDrop: acceptedFiles => {
      const uniqueFiles = acceptedFiles.filter(
        file => !files.some(existingFile => sanitizeFilename(existingFile.name) === sanitizeFilename(file.name))
      )

      const sanitizedFiles = uniqueFiles.map(file => {
        // Sanitize the filename
        const sanitizedName = sanitizeFilename(file.name)

        return new File([file], sanitizedName, { type: file.type })
      })

      const totalFiles = files.length + sanitizedFiles.length

      if (totalFiles <= 1) {
        setFiles(prevFiles => [...prevFiles, ...sanitizedFiles])
      } else {
        toast.error('You can only upload 1 file.', {
          duration: 2000
        })
      }
    },
    onDropRejected: fileRejections => {
      let fileTypeError = false
      let fileCountError = false
      let fileSizeError = false

      fileRejections.forEach(({ errors }) => {
        errors.forEach(error => {
          if (error.code === 'file-invalid-type') {
            fileTypeError = true
          } else if (error.code === 'file-too-large') {
            fileSizeError = true
          } else if (error.code === 'too-many-files') {
            fileCountError = true
          }
        })
      })

      if (fileTypeError) {
        toast.error('File type not allowed. Please check file type.', {
          duration: 2000
        })
      }

      if (fileSizeError) {
        toast.error('File size too large. Please check file size.', {
          duration: 2000
        })
      }

      if (fileCountError) {
        toast.error('You can only upload 1 file.', {
          duration: 2000
        })
      }
    }
  })

  const renderFilePreview = file => {
    if (file.type.startsWith('image')) {
      return (
        <img
          alt={file.name}
          src={URL.createObjectURL(file)}
          onClick={() => handleImagePreview(URL.createObjectURL(file))}
        />
      )
    } else {
      return <Icon icon='tabler:file-description' />
    }
  }

  const handleRemoveFile = file => {
    const uploadedFiles = files
    const filtered = uploadedFiles.filter(i => i.name !== file.name)
    setFiles([...filtered])
  }

  useEffect(() => {
    onUpload(files)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  const fileList = files.map(file => (
    <ListItem key={file.name}>
      <div className='file-details'>
        <div className='file-preview'>{renderFilePreview(file)}</div>
        <div>
          <Typography className='file-name'>{file.name}</Typography>
          <Typography className='file-size' variant='body2'>
            {Math.round(file.size / 100) / 10 > 1000
              ? `${(Math.round(file.size / 100) / 10000).toFixed(1)} mb`
              : `${(Math.round(file.size / 100) / 10).toFixed(1)} kb`}
          </Typography>
        </div>
      </div>
      <IconButton onClick={() => handleRemoveFile(file)}>
        <Icon icon='tabler:x' fontSize={20} />
      </IconButton>
    </ListItem>
  ))

  return (
    <>
      <DropzoneWrapper hasfiles={files.length}>
        <Fragment>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <Box sx={{ display: 'flex', textAlign: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  display: 'flex',
                  borderRadius: 1,
                  alignItems: 'center',
                  justifyContent: 'center'
                  // backgroundColor: theme => `rgba(${theme.palette.customColors.main}, 0.08)`
                }}
              >
                <Icon icon='mdi:cloud-upload-outline' fontSize='2rem' />
              </Box>
              <Typography variant='body1'>Drop file here or click to upload.</Typography>
              <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                Allowed *.jpeg, *.jpg, *.png
              </Typography>
              <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                Max 1 file and max size of 5 MB
              </Typography>
            </Box>
          </div>
          {files.length ? (
            <Fragment>
              <List>{fileList}</List>
            </Fragment>
          ) : null}
        </Fragment>
      </DropzoneWrapper>
      <FsLightbox toggler={toggler} sources={[`${lightBoxImage}`]} />
    </>
  )
}

export default FileUploaderRestrictions
