import { Box, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'

import { useState } from 'react'
import AdditionalCosts from './CreditNoteComponents/AdditionalCosts'
import CreditNotesLinesSummary from './CreditNoteComponents/CreditNotesLinesSummary'
import CreditNotesValueSummary from './CreditNoteComponents/CreditNotesValueSummary'
import CreditNotesRefunds from './CreditNoteComponents/CreditNotesRefunds'

const CreditNote = () => {
  const [creditNoteNumber, setCreditNoteNumber] = useState('')
  const [creditNoteDate, setCreditNoteDate] = useState('')

  const [creditNoteNumberError, setCreditNoteNumberError] = useState('')
  const [creditNoteDateError, setCreditNoteDateError] = useState('')

  const [taxInclusive, setTaxInclusive] = useState(true)
  const [taxRate, setTaxRate] = useState(0.1)

  const [rawLineTotal, setRawLineTotal] = useState(0)
  const [rawAdditionalTotal, setRawAdditionalTotal] = useState(0)

  const [refundTotal, setRefundTotal] = useState(0)

  const [summaryValues, setSummaryValues] = useState({
    lineTotal: 0,
    lineTax: 0,
    lineWithoutTax: 0,
    additionalTotal: 0,
    additionalTax: 0,
    additionalWithoutTax: 0
  })

  const [balance, setBalance] = useState(0)

  useEffect(() => {
    let lineTaxVal = 0
    let additionalTaxVal = 0

    if (taxInclusive) {
      lineTaxVal = rawLineTotal - rawLineTotal / (1 + taxRate)
      additionalTaxVal = rawAdditionalTotal - rawAdditionalTotal / (1 + taxRate)

      setSummaryValues({
        lineTotal: rawLineTotal,
        lineTax: lineTaxVal,
        lineWithoutTax: rawLineTotal - lineTaxVal,
        additionalTotal: rawAdditionalTotal,
        additionalTax: additionalTaxVal,
        additionalWithoutTax: rawAdditionalTotal - additionalTaxVal
      })
    } else {
      lineTaxVal = rawLineTotal * taxRate
      additionalTaxVal = rawAdditionalTotal * taxRate

      setSummaryValues({
        lineTotal: rawLineTotal + lineTaxVal,
        lineTax: lineTaxVal,
        lineWithoutTax: rawLineTotal,
        additionalTotal: rawAdditionalTotal + additionalTaxVal,
        additionalTax: additionalTaxVal,
        additionalWithoutTax: rawAdditionalTotal
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawLineTotal, rawAdditionalTotal, taxRate, taxInclusive])

  useEffect(() => {
    const value = (summaryValues.lineTotal + summaryValues.additionalTotal - refundTotal).toFixed(2)
    setBalance(value)
  }, [summaryValues, refundTotal])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label='Credit Note #'
                variant='outlined'
                InputLabelProps={{
                  shrink: true
                }}
                required
                value={creditNoteNumber}
                onChange={e => {
                  setCreditNoteNumber(e.target.value)
                  setCreditNoteNumberError('')
                }}
                helperText={creditNoteNumberError}
                error={creditNoteNumberError.length > 0}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label='Credit Note Date'
                variant='outlined'
                type='date'
                InputLabelProps={{
                  shrink: true
                }}
                required
                value={creditNoteDate}
                onChange={e => {
                  setCreditNoteDate(e.target.value)
                  setCreditNoteDateError('')
                }}
                helperText={creditNoteDateError}
                error={creditNoteDateError.length > 0}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              mt: 2
            }}
          >
            <CreditNotesLinesSummary setLineTotal={setRawLineTotal} />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              mt: 4
            }}
          >
            <AdditionalCosts setAdditionalTotal={setRawAdditionalTotal} />
          </Box>
        </Grid>

        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              mt: 2
            }}
          >
            <CreditNotesValueSummary summaryValues={summaryValues} />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              mt: 4
            }}
          >
            <CreditNotesRefunds setRefundTotal={setRefundTotal} />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'felx-end',
              border: '1px solid #e0e0e0',
              borderRadius: '10px',
              padding: '10px',
              gap: 1
            }}
          >
            {balance >= 0 ? (
              <>
                <Typography variant='body1'>Balance Due:</Typography>
                <Typography variant='body1' fontWeight={600}>
                  {balance}
                </Typography>
              </>
            ) : (
              <>
                <Typography variant='body1'>Balance Credited:</Typography>
                <Typography variant='body1' fontWeight={600}>
                  {-balance}
                </Typography>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default CreditNote
