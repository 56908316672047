import { Autocomplete, FormControl, Grid, InputLabel, MenuItem, Select, TextField, InputAdornment } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useCurrentRoute, useUserID } from '../../../../../../hooks/customHooks'
import apiDefinitions from '../../../../../../api/apiDefinitions'
import toast from 'react-hot-toast'
import { Icon } from '@iconify/react'

import { MuiTelInput } from 'mui-tel-input'

const SupplierDetails = () => {
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''

  const [vendors, setVendors] = useState([])

  const [tel, setTel] = useState('')

  const [contactName, setContactName] = useState('')
  const [contactNameError, setContactNameError] = useState('')

  useEffect(() => {
    apiDefinitions
      .getAllDataList(userID, currentRouteString, 'vendor')
      .then(response => {
        if (response.data.code === 200) {
          setVendors(response.data.data)
        } else throw new Error(response.data.message)
      })
      .catch(error => {
        console.error('Error fetching vendors:', error)
        toast.error(`Error fetching vendors: ${error}`)
      })
  }, [userID, currentRouteString])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                size='small'
                options={vendors}
                getOptionLabel={option => option.vendor_name}
                getOptionKey={option => option.id}
                renderInput={params => <TextField {...params} label='Supplier' variant='outlined' />}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size='small'
            label='Contact'
            onChange={e => {
              setContactName(e.target.value)
              setContactNameError('')
            }}
            error={!!contactNameError}
            helperText={contactNameError}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                  <Icon icon='fluent:person-32-regular' />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <MuiTelInput
            size='small'
            fullWidth
            defaultCountry='LK'
            focusOnSelectCountry
            label='Telephone'
            value={tel}
            onChange={value => {
              setTel(value)
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField label='Vendor Address Line 1' variant='outlined' fullWidth size='small' />
        </Grid>
        <Grid item xs={6}>
          <TextField label='Vendor Address Line 2' variant='outlined' fullWidth size='small' />
        </Grid>
      </Grid>
    </>
  )
}

export default SupplierDetails
