import { Icon } from '@iconify/react'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  styled,
  Radio,
  RadioGroup,
  Checkbox
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import apiDefinitions from '../../../../../../api/apiDefinitions'
import { useCurrentRoute, useUserID, useUserItemRole, validColorCodes } from '../../../../../../hooks/customHooks'
import Journel from './ManualJournelComponents/Journel'

const ManualJournals = () => {
  return (
    <>
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{my: 1}}>
        <Box>
          <Journel />
        </Box>
      </Grid>
    </Grid>
    </>
  )
}

export default ManualJournals
