import ViewItem from '../pages/items/viewitem'
import AddItemDetails from '../pages/items/viewitem/additemdetails'
import DamagedItem from '../pages/items/viewitem/carddetails/DamagedItem'
import NIGItem from '../pages/items/viewitem/carddetails/NIGItem'
import ReturnItem from '../pages/items/viewitem/carddetails/ReturnItem'
import ViewItemDetails from '../pages/items/viewitem/viewitemdetails'
import AssignAItem from '../pages/profile/assignitem'
import PoReqView from '../pages/purchaseOrder/porequest/view-request'
import POCreate from '../pages/purchaseOrder/purchaseorder/createpo'
import PODocument from '../pages/purchaseOrder/purchaseorder/components/podocument'

const subnav = () => {
  return [
    {
      id: 'viewItem',
      pID: 'items',
      title: 'View Item',
      path: '/items/viewitem',
      component: ViewItem
    },
    {
      id: 'addItem',
      pID: 'items',
      subPIDs: ['viewItem'],
      title: 'Add Item',
      path: '/items/viewitem/additemdetails',
      component: AddItemDetails
    },
    {
      id: 'viewPoReq',
      pID: 'purchaseOrder',
      title: 'View PO Request',
      path: '/purchase-order/purchase-order-request/view-request',
      component: PoReqView
    },
    {
      id: 'CreatePO',
      pID: 'purchaseOrder',
      title: 'Create Purchase Order',
      path: '/purchase-order/purchase-order-view/create-purchase-order',
      component: POCreate
    },
    {
      id: 'PODocument',
      pID: 'purchaseOrder',
      title: 'View PO Document',
      path: '/purchase-order/purchase-order-view/view-purchase-order-document',
      component: PODocument
    },
    {
      id: 'editItem',
      pID: 'items',
      subPIDs: ['viewItem'],
      title: 'Edit Item',
      path: '/items/viewitem/edititemdetails',
      component: ViewItemDetails
    },
    {
      id: 'assignItem',
      pID: 'profile',
      title: 'Assign Item',
      path: '/profile/assignitem',
      component: AssignAItem
    },
    {
      id: 'returnItems',
      pID: 'items',
      subPIDs: ['viewItem'],
      title: 'Returned Items',
      path: '/items/viewitem/returnitems',
      component: ReturnItem
    },
    {
      id: 'damagedItems',
      pID: 'items',
      subPIDs: ['viewItem'],
      title: 'Damaged Items',
      path: '/items/viewitem/damageditems',
      component: DamagedItem
    },
    {
      id: 'nigItems',
      pID: 'items',
      subPIDs: ['viewItem'],
      title: 'Not In Good Items',
      path: '/items/viewitem/nigitems',
      component: NIGItem
    }
  ]
}

export default subnav
