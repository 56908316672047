import { Icon } from '@iconify/react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import apiDefinitions from '../../../api/apiDefinitions'
import { useCurrentRoute, useUserID } from '../../../hooks/customHooks'

import toast from 'react-hot-toast'
import { MuiChipsInput } from 'mui-chips-input'
import Chip from '@mui/material/Chip'

const CustomCloseButton = styled(IconButton)(({ theme }) => ({
  top: 0,
  right: 0,
  color: 'grey.500',
  position: 'absolute',
  boxShadow: theme.shadows[2],
  transform: 'translate(10px, -10px)',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: `${theme.palette.background.paper} !important`,
  transition: 'transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out',
  '&:hover': {
    transform: 'translate(7px, -5px)'
  }
}))

const UpdateOptionalField = ({
  updateOptionalFieldFormOpen,
  onCloseOptionalFieldForm,
  onFormSubmit,
  updateOptionalFieldID
}) => {
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''
  const updateFieldId = updateOptionalFieldID ?? ''

  const [addFormOpen, setAddFormOpen] = useState(updateOptionalFieldFormOpen)

  const handleUpdateOptionalFieldFormClose = () => {
    setAddFormOpen(false)
    onCloseOptionalFieldForm()

    // Reset field data to initial values
    setFieldLabel(initialFieldLabel)
    setFieldType(initialFieldType)
    setIsRequired(initialIsRequired)
    setIsDisabled(initialIsDisabled)
    setListItem(initialListItem)
    setAddedItems(initialAddedItems)

    // Clear any error messages
    setFieldLabelError(false)
    setFieldTypeError(false)
    setListItemError(false)
    setListItemsError(false)
  }

  const [fieldLabel, setFieldLabel] = useState('')
  const [fieldType, setFieldType] = useState('')
  const [isRequired, setIsRequired] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [listItem, setListItem] = useState('')
  const [addedItems, setAddedItems] = useState([])

  const [fieldLabelError, setFieldLabelError] = useState(false)
  const [fieldTypeError, setFieldTypeError] = useState(false)
  const [isRequiredError, setIsRequiredError] = useState(false)
  const [isDisabledError, setIsDisabledError] = useState(false)
  const [listItemError, setListItemError] = useState(false)
  const [listItemsError, setListItemsError] = useState(false)

  const [initialFieldLabel, setInitialFieldLabel] = useState('')
  const [initialFieldType, setInitialFieldType] = useState('')
  const [initialIsRequired, setInitialIsRequired] = useState(false)
  const [initialIsDisabled, setInitialIsDisabled] = useState(false)
  const [initialListItem, setInitialListItem] = useState('')
  const [initialAddedItems, setInitialAddedItems] = useState([])

  const [oldChips, setOldChips] = useState([])

  const handleSelect = e => {
    setFieldType(e.target.value)
    setFieldTypeError('')
  }

  const handleRequired = e => {
    setInitialIsRequired(e.target.checked)
    setIsRequiredError('')
  }

  const handleDisabled = e => {
    setInitialIsDisabled(e.target.checked)
    setIsDisabledError('')
  }

  const initialAddedItemValues = initialAddedItems.map(item => item.value)

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '' && updateFieldId !== '') {
      apiDefinitions
        .getOptionalFieldByID(userID, currentRouteString, updateFieldId)
        .then(res => {
          if (res.data.code === 200) {
            // console.log('Optional field details:', res.data.data)
            const data = res.data.data
            // Set initial values based on retrieved data
            setInitialFieldLabel(data.field_label || '')
            setInitialFieldType(data.type || '')
            setInitialIsRequired(data.required || false)
            setInitialIsDisabled(data.is_disable || false)
            setInitialListItem('') // You may set this to whatever the initial list item should be
            setInitialAddedItems(data.list_values || [])
            setOldChips(data.list_values || [])
            // Rest of your code...
          } else {
            console.log('Error fetching optional field:', res.data.message)
            toast.error('Error: ' + res.data.message)
          }
        })
        .catch(error => {
          console.error('Error fetching optional field:', error)
          toast.error('Error fetching optional field!')
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID, currentRouteString, updateFieldId, updateOptionalFieldFormOpen])

  // const payload = {
  //   id: updateFieldId,
  //   field_label: initialFieldLabel,
  //   type: initialFieldType,
  //   required: initialIsRequired ? 1 : 0,
  //   is_disable: initialIsDisabled ? 1 : 0,
  //   list_values: initialAddedItems
  // }

  // console.log('Payload:', payload)

  const handleUpdateOptionalField = () => {
    let hasError = false

    // Check if Added List Items is empty for select or multiple_select field types
    if ((initialFieldType === 'select' || initialFieldType === 'multiple_select') && initialAddedItems.length === 0) {
      setListItemsError('Added List Items cannot be empty for Select or Multiple Select field types')
      hasError = true
    } else {
      setListItemsError('')
    }

    // If any error is detected, return early
    if (hasError) return

    // If all validations pass, proceed with adding the optional field
    Swal.fire({
      title: 'Are you sure you want to update this Optional Field?',
      showCancelButton: true,
      confirmButtonText: 'update',
      cancelButtonText: 'Cancel',
      customClass: {
        popup: 'swal2-popup'
      }
    }).then(result => {
      if (result.isConfirmed) {
        const payload = {
          id: updateFieldId,
          field_label: initialFieldLabel,
          field_type: initialFieldType,
          required: initialIsRequired ? 1 : 0,
          is_disabled: initialIsDisabled ? 1 : 0,
          additional_values: initialAddedItems
        }

        // console.log('Payload:', payload)

        apiDefinitions
          .putOptionalFieldUpdate(userID, currentRouteString, payload)
          .then(res => {
            if (res.data.code === 200) {
              toast.success('Optional Field Updated Successfully')
              onFormSubmit()
              handleUpdateOptionalFieldFormClose()
            } else {
              console.log('error')
              toast.error(`Error: ${res.data.message}`)
            }
          })
          .catch(err => {
            console.log(err)
            toast.error('Error Updating Optional Field!')
          })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled Updating New Optional Field', '', 'info')
      }
    })
  }

  return (
    <Dialog
      open={updateOptionalFieldFormOpen}
      onClose={handleUpdateOptionalFieldFormClose}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      scroll='paper'
      fullWidth
      sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
    >
      <DialogTitle variant='h5' sx={{ pb: 1 }}>
        Update Optional Field | ID: {updateOptionalFieldID}
        <CustomCloseButton aria-label='close' onClick={handleUpdateOptionalFieldFormClose}>
          <Icon icon='tabler:x' fontSize='1.25rem' />
        </CustomCloseButton>
      </DialogTitle>
      <DialogContent className='content-display-grid-scroll'>
        <Grid container spacing={2} sx={{ pt: 1 }}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Field Label'
              required
              disabled
              value={initialFieldLabel}
              onChange={e => {
                setFieldLabel(e.target.value)
                setFieldLabelError('')
              }}
              error={!!fieldLabelError}
              helperText={fieldLabelError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                    <Icon icon='bxs:category-alt' />
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth error={!!fieldTypeError}>
              <InputLabel>Field Type</InputLabel>
              <Select
                value={initialFieldType}
                label='Field Type'
                disabled
                onChange={e => {
                  handleSelect(e)
                  setFieldTypeError('')
                }}
              >
                <MenuItem value='text'>Text</MenuItem>
                <MenuItem value='number'>Number</MenuItem>
                <MenuItem value='date'>Date</MenuItem>
                <MenuItem value='select'>Select</MenuItem>
                <MenuItem value='time'>Time</MenuItem>
                <MenuItem value='datetime'>Date & Time</MenuItem>
                <MenuItem value='multiline'>Multiline</MenuItem>
                <MenuItem value='multiple_select'>Multiple Select</MenuItem>
              </Select>
              {!!fieldTypeError && <FormHelperText>{fieldTypeError}</FormHelperText>}
            </FormControl>
          </Grid>

          {(initialFieldType === 'select' || initialFieldType === 'multiple_select') && (
            // <>
            //   <Grid item xs={10}>
            //     <TextField
            //       id='listItemInput'
            //       fullWidth
            //       label='List Item'
            //       required
            //       value={listItem}
            //       onChange={e => {
            //         setListItem(e.target.value)
            //         setListItemError('')
            //       }}
            //       error={!!listItemError}
            //       helperText={listItemError}
            //       InputProps={{
            //         startAdornment: (
            //           <InputAdornment position='start' sx={{ fontSize: '20px' }}>
            //             <Icon icon='bx:bx-list-ul' />
            //           </InputAdornment>
            //         )
            //       }}
            //     />
            //   </Grid>
            //   <Grid item xs={2} sx={{ mt: 1 }}>
            //     <IconButton color='primary' onClick={handleAddItem}>
            //       <Icon icon='akar-icons:plus' />
            //     </IconButton>
            //   </Grid>

            //   <Grid item xs={12}>
            //     <TextField
            //       fullWidth
            //       label='Added List Items'
            //       multiline
            //       rows={4}
            //       value={initialAddedItemValues.join('\n')}
            //       error={addedItems.length === 0 && !!listItemsError}
            //       helperText={listItemsError}
            //       onChange={handleListItemsChange}
            //       InputProps={{
            //         startAdornment: (
            //           <InputAdornment position='start' sx={{ fontSize: '20px' }}>
            //             <Icon icon='bx:bx-list-ul' />
            //           </InputAdornment>
            //         )
            //       }}
            //     />
            //   </Grid>
            // </>

            <Grid item xs={12}>
              <MuiChipsInput
                fullWidth
                value={initialAddedItemValues}
                error={addedItems.length === 0 && !!listItemsError}
                helperText={listItemsError}
                disableDeleteOnBackspace
                disableEdition
                hideClearAll
                onChange={input => {
                  // prevent adding empty items or duplicates
                  if (input.some(value => value === '')) {
                    setListItemsError('List Items cannot be empty')
                    return
                  }

                  //filter out duplicates
                  const duplicates = input.filter((value, index, self) => self.indexOf(value) !== index)
                  if (duplicates.length > 0) {
                    setListItemsError('List Items cannot contain duplicates')
                    return
                  }

                  setInitialAddedItems(input.map((value, index) => ({ key: index + 1, value })))
                  setListItemsError('')
                }}
                label='List Items'
                InputLabelProps={{
                  shrink: true
                }}
                renderChip={(Component, key, props) => {
                  const newProps = {
                    ...props,
                    onDelete: () => {
                      if (!oldChips.some(chip => chip.value === props.label)) {
                        const newKey = Number(key.replace('chip-', ''))
                        const newChips = initialAddedItems.filter((chip, index) => index !== newKey)

                        setInitialAddedItems(newChips)
                      } else {
                        toast.error('Cannot delete old list values!')
                      }
                    }
                  }

                  return <Component key={key} {...newProps} />
                }}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ m: 2, mt: 1, gap: 2 }}>
        <Grid item xs={3.5}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={initialIsRequired}
                  onChange={e => {
                    handleRequired(e)
                    setIsRequiredError('')
                  }}
                  error={!!isRequiredError}
                  helperText={isRequiredError}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label='Is Required'
            />
          </FormGroup>
        </Grid>
        <Grid item xs={3.5}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={initialIsDisabled}
                  onChange={e => {
                    handleDisabled(e)
                    setIsDisabledError('')
                  }}
                  error={!!isDisabledError}
                  helperText={isDisabledError}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label='Is Disabled'
            />
          </FormGroup>
        </Grid>
        <Button variant='contained' sx={{ px: 4 }} onClick={handleUpdateOptionalField}>
          Update Field
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UpdateOptionalField
