import { Box, Button, Card, Grid, Step, StepLabel, Stepper } from '@mui/material'
import * as React from 'react'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import apiDefinitions from '../../../api/apiDefinitions'
import { useCurrentRoute, useUserID } from '../../../hooks/customHooks'
import AssignEmpDept from './AssignEmpDept'
import AssignSummary from './AssignSummary'
import ItemAssingment from './ItemAssingment'
import { useEffect } from 'react'

const steps = ['Select Department/Employee', 'Assign Item', 'Summary']

const AssignItem = () => {
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''

  const navigate = useNavigate()

  const [checked, setChecked] = useState([])
  const [employees, setEmployees] = useState([])

  const [selectedItemData, setSelectedItemData] = useState([])

  const [activeStep, setActiveStep] = useState(0)

  const [selectedRows, setSelectedRows] = useState([])
  const [rows2, setRows2] = useState([])
  const [rows, setRows] = useState([])
  const [recordCount, setRecordCount] = useState(0)
  const [recordCount2, setRecordCount2] = useState(0)

  useEffect(() => {
    setSelectedItemData(rows2)
  }, [rows2])

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]
    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  const handleEmployees = value => {
    setEmployees(value)
  }

  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <AssignEmpDept
            handleToggle={handleToggle}
            checked={checked}
            employees={employees}
            handleEmployees={handleEmployees}
          />
        )
      case 1:
        return (
          <ItemAssingment
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            rows2={rows2}
            setRows2={setRows2}
            rows={rows}
            setRows={setRows}
            setRecordCount={setRecordCount}
            setRecordCount2={setRecordCount2}
            recordCount={recordCount}
            recordCount2={recordCount2}
          />
        )
      default:
        return (
          <AssignSummary
            selectedItemData={selectedItemData}
            selectedEmployees={employees}
            selectedDepartments={checked}
          />
        )
    }
  }

  const handleNext = () => {
    if (activeStep === 0 && checked.length === 0 && employees.length === 0) {
      toast.error('Please select at least one employee or department!')
      return
    }

    if (activeStep === 1 && selectedItemData.length === 0) {
      toast.error('Please select at least one item to assign!')
      return
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const createAssignment = () => {
    let isValid = true

    if (employees.length === 0 && checked.length === 0) {
      isValid = false
    }

    if (selectedItemData.length === 0) {
      isValid = false
    }

    if (isValid) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to assign the selected items to the selected employees and/or departments?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(result => {
        if (result.isConfirmed) {
          const payload = {
            assign: [
              {
                item_detail: selectedItemData.map(item => {
                  console.log(item, 'item')
                  return {
                    item_detail_id: item.id,
                    assign_quantity: item.assQty,
                    is_admin_approval: item.adminReq,
                    item_assign_id: item.assign_id
                  }
                }),
                assigned_to: {
                  department: [...checked],
                  user: employees.map(emp => {
                    return emp.user_id
                  })
                },
                date: new Date().toISOString().split('T')[0]
              }
            ]
          }

          const promise = new Promise((resolve, reject) => {
            apiDefinitions
              .createFirstLevelAssignment(userID, currentRouteString, payload)
              .then(response => {
                if (response.data.code === 201) {
                  resolve(response.data.message)
                } else {
                  reject(new Error(response.data.message))
                }
              })
              .catch(error => {
                reject(error)
              })
          })

          toast
            .promise(promise, {
              loading: 'Creating item assignment...',
              success: message => `Success: ${message}`,
              error: error => `Error: ${error.message}`,
              duration: 5000
            })
            .then(() => {
              navigate('/profile', {
                replace: true
              })
            })
            .catch(error => {
              console.error(error)
            })
        } else if (result.isDismissed) {
          Swal.fire('Cancelled', 'Assignment cancelled', 'info')
        }
      })
    } else {
      toast.error('Please select at least one employee or department and item to assign!')
    }
  }

  return (
    <Card sx={{ p: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Stepper
              activeStep={activeStep}
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: '2rem'
                }
              }}
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel
                    sx={{
                      '& .MuiStepLabel-label': {
                        fontSize: '1.2rem',
                        fontWeight: 'bold'
                      }
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length ? null : (
              <React.Fragment>
                <Box sx={{ py: 3 }}>{getStepContent(activeStep)}</Box>
                {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  {activeStep !== 0 && (
                    <Button color='primary' variant='outlined' onClick={handleBack} sx={{ mr: 1 }}>
                      Back
                    </Button>
                  )}
                  <Box sx={{ flex: '1 1 auto' }} />
                  {activeStep === steps.length - 1 ? (
                    <Button color='primary' variant='contained' onClick={createAssignment}>
                      Complete Assignment
                    </Button>
                  ) : (
                    <Button color='primary' variant='contained' onClick={handleNext}>
                      Next
                    </Button>
                  )}
                </Box>
              </React.Fragment>
            )}
          </Box>
        </Grid>
      </Grid>
    </Card>
  )
}

export default AssignItem
