import React from 'react'

import FileUploader from './AttachmentsComponents/FileUploader'
import UploadedFiles from './AttachmentsComponents/UploadedFiles'
import { useState, useEffect } from 'react'
import { randomId } from '@mui/x-data-grid-generator'

import { Grid } from '@mui/material'

const Attachments = () => {
  const [files, setFiles] = useState([])
  const [processedFiles, setProcessedFiles] = useState([])

  useEffect(() => {
    if (files && files.length > 0) {
      console.log('files', files)
      setProcessedFiles(oldFiles => [
        ...oldFiles,
        ...files.map(file => {
          return {
            id: randomId(),
            name: file.name,
            type: file.type,
            preview: URL.createObjectURL(file),
            attached_by: 'sandupa@kiu.ac.lk',
            attached_date: new Date(),
            isProcessed: false
          }
        })
      ])

      setFiles([])
    }
  }, [files])

  useEffect(() => {
    console.log('processedFiles', processedFiles)
  }, [processedFiles])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FileUploader files={files} setFiles={setFiles} />
      </Grid>
      <Grid item xs={12}>
        <UploadedFiles processedFiles={processedFiles} />
      </Grid>
    </Grid>
  )
}

export default Attachments
