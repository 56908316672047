import { Icon } from '@iconify/react'
import { Box, Button, Chip, Grid, IconButton, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import FsLightbox from 'fslightbox-react'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import LazyLoad from 'react-lazy-load'
import { useNavigate } from 'react-router'

import { useCurrentQueryParams } from '../../../hooks/customHooks'

import apiDefinitions from '../../../api/apiDefinitions'
import { convertImageLinkToBase64, useCurrentRoute, useUserID, validColorCodes } from '../../../hooks/customHooks'

const ItemDetailsTable = () => {
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''
  const itemID = useCurrentQueryParams('id') ?? ''

  const [rows, setRows] = useState([])
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 5 })
  const [recordCount, setRecordCount] = useState(0)

  const navigate = useNavigate()

  const itemId = useCurrentQueryParams('id') ?? ''

  const [toggler, setToggler] = useState(false)
  const [lightBoxImage, setLightBoxImage] = useState('')
  const ItemFallBackImage = '/images/default-fallbacks/item-fallback.png'

  const handleAddItemDetailsClick = id => {
    navigate(`/items/viewitem/additemdetails?id=${id}`)
  }

  const handleViewItemDetailsClick = (id, itemId) => {
    navigate(`/items/viewitem/edititemdetails?lotId=${id}&itemId=${itemId}`)
  }

  const handleToggler = async link => {
    try {
      const imageString = await convertImageLinkToBase64(link)
      console.log('Image fetched successfully!')
      setLightBoxImage(imageString)
      setToggler(!toggler)
    } catch (error) {
      console.error('Error handling toggler:', error)
      setLightBoxImage(ItemFallBackImage)
      setToggler(!toggler)
    }
  }

  useEffect(() => {
    if (userID !== '' || currentRouteString !== '' || itemID !== '') {
      apiDefinitions
        .getAllLotDetailsByItemId(userID, currentRouteString, itemID, paginationModel.page, paginationModel.pageSize)
        .then(res => {
          if (res.data.code === 200) {
            setRows(res.data.data.data.lots)
            setRecordCount(res.data.data.count)
          } else {
            console.log('error')
            toast.error(`Error:${res.data.message}`)
            setRows([])
            setRecordCount(0)
          }
        })
        .catch(error => {
          console.log('error', error)
          toast.error('Error fetching lot details')
          setRows([])
          setRecordCount(0)
        })
    }
  }, [currentRouteString, paginationModel, userID, itemID])

  const columns = [
    {
      field: 'id',
      headerName: 'Lot ID',
      flex: 0.5,
      minWidth: 100,
      renderCell: params => {
        return (
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', gap: 3, py: 1.5, mx: 2 }}
          >
            <Typography variant='body2'>{params.row.id || 'N/A'}</Typography>
            <LazyLoad height={40} width={50}>
              <img
                src={params.row.image?.url || ItemFallBackImage}
                height={40}
                width={50}
                alt='item'
                onClick={() => handleToggler(params.row.image?.url || ItemFallBackImage)}
                onError={e => (e.target.src = ItemFallBackImage)}
                style={{
                  cursor: 'pointer'
                }}
              />
            </LazyLoad>
          </Box>
        )
      }
    },
    {
      field: 'lot_name',
      headerName: 'Lot Name',
      flex: 1,
      minWidth: 100,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.lot_name || 'N/A'}</Typography>
      }
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      flex: 0.5,
      minWidth: 100,
      renderCell: params => {
        return <Typography variant='body2'>{String(params.row.quantity) || 'N/A'}</Typography>
      }
    },
    {
      field: 'discount',
      headerName: 'Discount',
      flex: 0.5,
      minWidth: 100,
      renderCell: params => {
        return <Typography variant='body2'>{Number(params.row.discount || 0).toFixed(2)}</Typography>
      }
    },
    {
      field: 'tax',
      headerName: 'Tax',
      flex: 0.5,
      minWidth: 100,
      renderCell: params => {
        return <Typography variant='body2'>{Number(params.row.tax || 0).toFixed(2)}</Typography>
      }
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 0.5,
      minWidth: 80,
      renderCell: params => {
        return <Typography variant='body2'>{Number(params.row.amount || 0).toFixed(2)}</Typography>
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.3,
      minWidth: 100,
      renderCell: params => {
        const color = validColorCodes.includes(params.row.status?.color_code)
          ? params.row.status.color_code
          : 'secondary'
        return (
          <Chip
            variant='filled'
            color={color}
            label={
              <Typography
                variant='body2'
                sx={{
                  fontSize: '0.75rem'
                }}
              >
                {params.row.status?.type ? String(params.row.status?.type).toUpperCase() : 'Status Not Found'}
              </Typography>
            }
          />
        )
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.5,
      minWidth: 100,
      headerAlign: 'center',
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 1,
              minWidth: '100%'
            }}
          >
            <IconButton
              aria-label='view'
              color='primary'
              onClick={() => handleViewItemDetailsClick(params.row.id, itemID)}
            >
              <Icon icon='lets-icons:view' />
            </IconButton>
            {/* <IconButton aria-label='delete' color='primary'>
              <Icon icon='fluent:delete-12-regular' />
            </IconButton> */}
          </Box>
        )
      }
    }
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => handleAddItemDetailsClick(itemId)}
            sx={{ '& svg': { mr: 2 }, mb: { xs: 2, md: 0 } }}
          >
            <Icon fontSize='1.125rem' icon='fluent:add-12-filled' />
            Add Lot Details
          </Button>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{
            height: 435,
            width: '100%',
            '& .actions': {
              color: 'text.secondary'
            },
            '& .textPrimary': {
              color: 'text.primary'
            }
          }}
        >
          <DataGrid
            getRowHeight={() => 'auto'}
            rows={rows}
            rowCount={recordCount}
            columns={columns}
            paginationMode='server'
            pageSizeOptions={[5, 10, 25, 50, 100]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            disableRowSelectionOnClick
            checkboxSelection
          />
        </Box>
      </Grid>

      <FsLightbox toggler={toggler} sources={[`${lightBoxImage}`]} />
    </Grid>
  )
}

export default ItemDetailsTable
