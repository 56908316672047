import { Icon } from '@iconify/react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import apiDefinitions from '../../../../../api/apiDefinitions'
import { useCurrentRoute, useUserID, useCurrentQueryParams } from '../../../../../hooks/customHooks'
import toast from 'react-hot-toast'

const RequestDetails = ({ tableRefresh }) => {
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''
  const porID = useCurrentQueryParams('id') ?? ''

  const [requestNo, setRequestNo] = useState('')
  const [requestedDate, setRequestedDate] = useState('')
  const [requestStatus, setRequestStatus] = useState('')
  const [requestedBy, setRequestedBy] = useState('')
  const [requestedItems, setRequestedItems] = useState('')

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '' && porID !== '') {
      apiDefinitions
        .getPORGeneralDetails(userID, currentRouteString, porID)
        .then(res => {
          if (res.data.code === 200) {
            setRequestNo(res.data.data.por_code)
            setRequestedDate(res.data.data.created_date)
            setRequestStatus(res.data.data.status.type)
            setRequestedBy(res.data.data.created_user.user_name)
            setRequestedItems(res.data.data.tot_item)
          } else {
            toast.error(`Error: ${res.data.message}`)
          }
        })
        .catch(err => {
          console.log(err)
          toast.error('Error fetching Vendor List!')
        })
    }
  }, [userID, currentRouteString, porID, tableRefresh])

  return (
    <Grid container spacing={2} sx={{ py: 1 }}>
      <Grid item xs={2}>
        <TextField
          fullWidth
          label='Request No'
          required
          disabled
          value={requestNo}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                <Icon icon='iwwa:number-asc' />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          label='Request Date'
          required
          disabled
          value={requestedDate}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                <Icon icon='fluent-mdl2:date-time' />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label='Request Status'
          required
          disabled
          value={requestStatus}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                <Icon icon='pajamas:status' />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label='Requested By'
          required
          disabled
          value={requestedBy}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                <Icon icon='akar-icons:person' />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          label='Requested Items'
          required
          disabled
          value={requestedItems}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                <Icon icon='ep:goods' />
              </InputAdornment>
            )
          }}
        />
      </Grid>
    </Grid>
  )
}

export default RequestDetails
