import { Icon } from '@iconify/react'
import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'

const CardSection = () => {
  const navigate = useNavigate()

  const handleReturnItemClick = () => {
    navigate('/items/viewitem/returnitems')
  }

  const handleDamagedItemClick = () => {
    navigate('/items/viewitem/damageditems')
  }

  const handleNIGItemClick = () => {
    navigate('/items/viewitem/nigitems')
  }
  return (
    <>
      {/* <Grid item xs={4}>
        <Card
          sx={{
            borderRadius: 2,
            cursor: 'pointer',
            transition: 'box-shadow 0.3s',
            '&:hover': {
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)' // Add your desired shadow values here
            }
          }}
          onClick={handleReturnItemClick}
        >
          <CardContent sx={{ gap: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant='h5' sx={{ mb: 0.5 }}>
                100%
              </Typography>
              <Typography variant='body1' sx={{ fontWeight: 600 }}>
                Return Item
              </Typography>
            </Box>
            <Avatar sx={{ width: 42, height: 42, bgcolor: '#1565C0' }}>
              <Icon icon='tabler:truck-return' fontSize='1.625rem' />
            </Avatar>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card
          sx={{
            borderRadius: 2,
            cursor: 'pointer',
            transition: 'box-shadow 0.3s',
            '&:hover': {
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)' // Add your desired shadow values here
            }
          }}
          onClick={handleDamagedItemClick}
        >
          <CardContent sx={{ gap: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant='h5' sx={{ mb: 0.5 }}>
                100%
              </Typography>
              <Typography variant='body1' sx={{ fontWeight: 600 }}>
                Damaged Item
              </Typography>
            </Box>
            <Avatar sx={{ width: 42, height: 42, bgcolor: '#1565C0' }}>
              <Icon icon='icon-park-outline:damage-map' fontSize='1.625rem' />
            </Avatar>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card
          sx={{
            borderRadius: 2,
            cursor: 'pointer',
            transition: 'box-shadow 0.3s',
            '&:hover': {
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)' // Add your desired shadow values here
            }
          }}
          onClick={handleNIGItemClick}
        >
          <CardContent sx={{ gap: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant='h5' sx={{ mb: 0.5 }}>
                100%
              </Typography>
              <Typography variant='body1' sx={{ fontWeight: 600 }}>
                Not In Good Item
              </Typography>
            </Box>
            <Avatar sx={{ width: 42, height: 42, bgcolor: '#1565C0' }}>
              <Icon icon='icon-park-outline:bad-two' fontSize='1.625rem' />
            </Avatar>
          </CardContent>
        </Card>
      </Grid> */}
    </>
  )
}

export default CardSection
