import { Icon } from '@iconify/react'
import { Box, Button, Card, Chip, Grid, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import FsLightbox from 'fslightbox-react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import LazyLoad from 'react-lazy-load'
import LogHistory from '../../components/LogTimeline/LogHistory'
import AddItemForm from './additem'

import toast from 'react-hot-toast'
import apiDefinitions from '../../api/apiDefinitions'
import { convertImageLinkToBase64, useCurrentRoute, useUserID, validColorCodes } from '../../hooks/customHooks'

const Items = () => {
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''

  const navigate = useNavigate()

  const [toggler, setToggler] = useState(false)
  const [lightBoxImage, setLightBoxImage] = useState('')
  const ItemFallBackImage = '/images/default-fallbacks/item-fallback.png'

  const [openLogHistory, setOpenLogHistory] = useState(false)
  const [openAddForm, setOpenAddForm] = useState(false)

  const [rows, setRows] = useState([])
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 5 })
  const [recordCount, setRecordCount] = useState(0)
  const [tableRefresh, setTableRefresh] = useState(false)

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '') {
      apiDefinitions
        .getAllItemsPaginated(userID, currentRouteString, paginationModel.page, paginationModel.pageSize)
        .then(res => {
          if (res.data.code === 200) {
            console.log(res)
            setRows(res.data.data.data)
            setRecordCount(res.data.data.count)
          } else {
            console.log('error')
            toast.error(`Error: ${res.data.message}`)
            setRows([])
            setRecordCount(0)
          }
        })
        .catch(err => {
          console.log(err)
          toast.error('Error fetching Items List!')
          setRows([])
          setRecordCount(0)
        })
    }
  }, [currentRouteString, paginationModel, userID, tableRefresh])

  const handleAddFormOpen = () => {
    setOpenAddForm(true)
  }

  const handleCloseAddForm = () => {
    setOpenAddForm(false)
  }

  const handleOpenLogHistory = () => {
    setOpenLogHistory(true)
  }

  const handleCloseLogHistory = () => {
    setOpenLogHistory(false)
  }

  const handleViewItemClick = id => {
    navigate(`/items/viewitem?id=${id}`)
  }

  const handleToggler = async link => {
    try {
      const imageString = await convertImageLinkToBase64(link)
      console.log('Image fetched successfully!')
      setLightBoxImage(imageString)
      setToggler(!toggler)
    } catch (error) {
      console.error('Error handling toggler:', error)
      setLightBoxImage(ItemFallBackImage)
      setToggler(!toggler)
    }
  }

  const handleFormSubmit = () => {
    // console.log('Form Submitted!')
    setTableRefresh(!tableRefresh)
  }

  const columns = [
    {
      field: 'item_code',
      headerName: 'Item Code',
      minWidth: 120,
      flex: 0.5,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.item_code || 'N/A'}</Typography>
      }
    },
    {
      field: 'category_name',
      valueGetter: params => params.row.category.category_name,
      headerName: 'Category',
      minWidth: 120,
      flex: 0.5,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.category.category_name || 'N/A'}</Typography>
      }
    },
    {
      field: 'short_name',
      headerName: 'Item Name',
      minWidth: 200,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return (
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', gap: 3, py: 1, mx: 1 }}
          >
            <LazyLoad height={40} width={50}>
              <img
                src={params.row.image.url || ItemFallBackImage}
                height={40}
                width={50}
                alt='item'
                onClick={() => handleToggler(params.row.image.url || ItemFallBackImage)}
                onError={e => (e.target.src = ItemFallBackImage)}
                style={{
                  cursor: 'pointer'
                }}
              />
            </LazyLoad>
            <Typography variant='body2'>{params.row.short_name || 'N/A'}</Typography>
          </Box>
        )
      }
    },
    {
      field: 'item_description',
      headerName: 'Item Description',
      minWidth: 180,
      flex: 1,
      headerAlign: 'center',
      renderCell: params => {
        return (
          <Box
            sx={{
              maxHeight: '60px',
              overflowX: 'hidden',
              overflowY: 'auto',
              scrollbarWidth: 'thin',
              paddingRight: 4,
              my: 1
            }}
          >
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                lineHeight: '1em',
                py: 1,
                textAlign: 'justify',
                letterSpacing: 0
              }}
              variant='body2'
            >
              {params.row.item_description || 'N/A'}
            </Typography>
          </Box>
        )
      }
    },
    {
      field: 'total_quantity',
      headerName: 'Qty',
      headerAlign: 'center',
      align: 'center',
      minWidth: 80,
      flex: 0.35,
      renderCell: params => {
        return <Typography variant='body2'>{String(params.row.total_quantity) || 'N/A'}</Typography>
      }
    },
    {
      field: 'in_stock',
      headerName: 'In Stock',
      headerAlign: 'center',
      align: 'center',
      minWidth: 80,
      flex: 0.35,
      renderCell: params => {
        return <Typography variant='body2'>{String(params.row.in_stock) || 'N/A'}</Typography>
      }
    },
    {
      field: 'type',
      headerName: 'Status',
      valueGetter: params => params.row.item_status.type,
      minWidth: 150,
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        const color = validColorCodes.includes(params.row.item_status?.color_code)
          ? params.row.item_status.color_code
          : 'secondary'
        return (
          <Chip
            variant='filled'
            color={color}
            label={
              <Typography
                variant='body2'
                sx={{
                  fontSize: '0.75rem'
                }}
              >
                {params.row.item_status?.type ? String(params.row.item_status?.type).toUpperCase() : 'Status Not Found'}
              </Typography>
            }
          ></Chip>
        )
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.7,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => {
        return (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', py: 1.8 }}>
            <Button variant='outlined' onClick={() => handleViewItemClick(params.row.id)}>
              View
            </Button>

            <Button variant='contained' onClick={handleOpenLogHistory}>
              <Icon
                icon='tdesign:history-setting'
                style={{
                  fontSize: '20px'
                }}
              />
            </Button>
          </Box>
        )
      }
    }
  ]

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2 }}>
                <Typography variant='h5'>ITEMS</Typography>
                <Button
                  variant='contained'
                  startIcon={<Icon icon='material-symbols-light:add' />}
                  onClick={handleAddFormOpen}
                >
                  Add Item
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ boxShadow: 2 }}>
                <Box
                  sx={{
                    height: 440,
                    width: '100%',
                    '& .actions': {
                      color: 'text.secondary'
                    },
                    '& .textPrimary': {
                      color: 'text.primary'
                    }
                  }}
                >
                  <DataGrid
                    getRowHeight={() => 'auto'}
                    rows={rows}
                    rowCount={recordCount}
                    columns={columns}
                    paginationMode='server'
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    disableRowSelectionOnClick
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FsLightbox toggler={toggler} sources={[`${lightBoxImage}`]} />

      <AddItemForm openAddForm={openAddForm} onCloseAddForm={handleCloseAddForm} onFormSubmit={handleFormSubmit} />

      <LogHistory openLogHistory={openLogHistory} onCloseLogHistory={handleCloseLogHistory} />
    </>
  )
}

export default Items
