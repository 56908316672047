import { Icon } from '@iconify/react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  styled
} from '@mui/material'
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import apiDefinitions from '../../../api/apiDefinitions'
import { useCurrentRoute, useUserID } from '../../../hooks/customHooks'

import toast from 'react-hot-toast'

const CustomCloseButton = styled(IconButton)(({ theme }) => ({
  top: 0,
  right: 0,
  color: 'grey.500',
  position: 'absolute',
  boxShadow: theme.shadows[2],
  transform: 'translate(10px, -10px)',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: `${theme.palette.background.paper} !important`,
  transition: 'transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out',
  '&:hover': {
    transform: 'translate(7px, -5px)'
  }
}))

const Addcategory = ({ addCategoryFormOpen, onCloseCategoryForm, onFormSubmit }) => {
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''

  const [addFormOpen, setAddFormOpen] = useState(addCategoryFormOpen)

  const [categoryName, setCategoryName] = useState('')
  const [categoryPrefix, setCategoryPrefix] = useState('')

  const [categoryNameError, setCategoryNameError] = useState(false)
  const [categoryPrefixError, setCategoryPrefixError] = useState(false)

  const handleKeyDown = e => {
    // Allow only letters and underscores
    if (!/^[a-zA-Z_]*$/.test(e.key)) {
      e.preventDefault()
    }
  }

  const handleCategoryPrefixChange = e => {
    const inputValue = e.target.value
    setCategoryPrefix(inputValue)
    // You can add further validation if needed
  }

  const handleAddCategoryFormClose = () => {
    setAddFormOpen(false)
    onCloseCategoryForm()
    setCategoryName('')
    setCategoryPrefix('')
    setCategoryNameError('')
    setCategoryPrefixError('')
  }

  const handleAddCategory = () => {
    if (categoryName.trim() === '' && categoryPrefix.trim() === '') {
      setCategoryNameError('Category Name is required')
      setCategoryPrefixError('Category Prefix is required')
    } else if (categoryName.trim() === '') {
      setCategoryNameError('Category Name is required')
    } else if (categoryPrefix.trim() === '') {
      setCategoryPrefixError('Category Prefix is required')
    } else {
      Swal.fire({
        title: 'Are you sure you want to add this category?',
        showCancelButton: true,
        confirmButtonText: 'Add',
        cancelButtonText: 'Cancel',
        customClass: {
          popup: 'swal2-popup'
        }
      }).then(result => {
        if (result.isConfirmed) {
          if (categoryName.trim() !== '' && categoryPrefix.trim() !== '') {
            const payload = {
              category_name: categoryName,
              category_code: categoryPrefix.toUpperCase()
            }
            apiDefinitions
              .postCategoryCreate(userID, currentRouteString, payload)
              .then(res => {
                if (res.data.code === 200) {
                  toast.success('Category Added Successfully')
                  onFormSubmit()
                  handleAddCategoryFormClose()
                } else {
                  console.log('error')
                  toast.error(`Error: ${res.data.message}`)
                }
              })
              .catch(err => {
                console.log(err)
                toast.error('Error Creating Category!')
              })
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Cancelled Adding New Category', '', 'info')
        }
      })
    }
  }

  return (
    <Dialog
      open={addCategoryFormOpen}
      onClose={handleAddCategoryFormClose}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      scroll='paper'
      fullWidth
      sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
    >
      <DialogTitle variant='h5' sx={{ pb: 1 }}>
        Add New Category
        <CustomCloseButton aria-label='close' onClick={handleAddCategoryFormClose}>
          <Icon icon='tabler:x' fontSize='1.25rem' />
        </CustomCloseButton>
      </DialogTitle>
      <DialogContent className='content-display-grid-scroll'>
        <Grid container spacing={2} sx={{ pt: 1 }}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Category Name'
              required
              onChange={e => {
                setCategoryName(e.target.value)
                setCategoryNameError('')
              }}
              error={!!categoryNameError}
              helperText={categoryNameError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                    <Icon icon='bxs:category-alt' />
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Category Prefix'
              required
              value={categoryPrefix}
              onChange={handleCategoryPrefixChange}
              onKeyDown={handleKeyDown}
              error={!!categoryPrefixError}
              helperText={categoryPrefixError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                    <Icon icon='mdi:code-json' />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ m: 2, mt: 1, gap: 2 }}>
        <Button variant='contained' sx={{ px: 4 }} onClick={() => handleAddCategory()}>
          Add Category
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Addcategory
