import { Icon } from '@iconify/react'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  styled,
  Radio,
  RadioGroup,
  Checkbox,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import apiDefinitions from '../../../../../../api/apiDefinitions'
import { useCurrentRoute, useUserID, useUserItemRole, validColorCodes } from '../../../../../../hooks/customHooks'
import InvoiceSummary from './InvoiceComponents/InvoiceSummary'
import AdditionalCostsInvoice from './InvoiceComponents/AdditionalCostsInvoice'
import InvoiceValueSummary from './InvoiceComponents/InvoiceValueSummary'
import Accounts from './InvoiceComponents/Accounts'

const Invoice = () => {
  const [inNumber, setInNumber] = useState('')
  const [inNumberError, setInNumberError] = useState('')

  const [inDate, setInDate] = useState('')
  const [inDateError, setInDateError] = useState('')

  const [dueDate, setDueDate] = useState('')
  const [dueDateError, setDueDateError] = useState('')

  const [completeTotal, setCompleteTotal] = useState('')
  const [completeTotalError, setCompleteTotalError] = useState('')

  //implementing the tax inclusive and exclusive with invoice summary
  const [taxInclusive, setTaxInclusive] = useState(true)
  const [taxRate, setTaxRate] = useState(0.1)

  const [rawLineTotal, setRawLineTotal] = useState(0)
  const [rawAdditionalTotal, setRawAdditionalTotal] = useState(0)
  const [accountRowTotal, setAccountRowTotal] = useState(0)

  const [balance, setBalance] = useState(0)

  const [summaryValues, setSummaryValues] = useState({
    lineTotal: 0,
    lineTax: 0,
    lineWithoutTax: 0,
    additionalTotal: 0,
    additionalTax: 0,
    additionalWithoutTax: 0
  })

  useEffect(() => {
    let lineTaxVal = 0
    let additionalTaxVal = 0

    if (taxInclusive) {
      lineTaxVal = rawLineTotal - rawLineTotal / (1 + taxRate)
      additionalTaxVal = rawAdditionalTotal - rawAdditionalTotal / (1 + taxRate)

      setSummaryValues({
        lineTotal: rawLineTotal,
        lineTax: lineTaxVal,
        lineWithoutTax: rawLineTotal - lineTaxVal,
        additionalTotal: rawAdditionalTotal,
        additionalTax: additionalTaxVal,
        additionalWithoutTax: rawAdditionalTotal - additionalTaxVal
      })
    } else {
      lineTaxVal = rawLineTotal * taxRate
      additionalTaxVal = rawAdditionalTotal * taxRate

      setSummaryValues({
        lineTotal: rawLineTotal + lineTaxVal,
        lineTax: lineTaxVal,
        lineWithoutTax: rawLineTotal,
        additionalTotal: rawAdditionalTotal + additionalTaxVal,
        additionalTax: additionalTaxVal,
        additionalWithoutTax: rawAdditionalTotal
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawLineTotal, rawAdditionalTotal, taxRate, taxInclusive])

  useEffect(() => {
    const value = (summaryValues.lineTotal + summaryValues.additionalTotal - accountRowTotal).toFixed(2)
    setBalance(value)
  }, [summaryValues, accountRowTotal])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            {/* Left side text fields */}
            <Grid container spacing={2} direction='column' xs={4}>
              <Grid item>
                <TextField
                  fullWidth
                  label='Invoice Number'
                  required
                  onChange={e => {
                    setInNumber(e.target.value)
                    setInNumberError('')
                  }}
                  error={!!inNumberError}
                  helperText={inNumberError}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                        <Icon icon='fluent:book-number-24-regular' />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label='Invoice Date'
                  required
                  type='date'
                  onChange={e => {
                    setInDate(e.target.value)
                    setInDateError('')
                  }}
                  error={!!inDateError}
                  helperText={inDateError}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                        <Icon icon='ic:baseline-date-range' />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label='Due Date'
                  required
                  type='date'
                  onChange={e => {
                    setDueDate(e.target.value)
                    setDueDateError('')
                  }}
                  error={!!dueDateError}
                  helperText={dueDateError}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                        <Icon icon='ic:baseline-date-range' />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>

            {/* Right side text field */}
            <Grid item xs={4}>
              <TextField
                fullWidth
                label='Total (LKR)'
                required
                onChange={e => {
                  setCompleteTotal(e.target.value)
                  setCompleteTotalError('')
                }}
                onInput={e => {
                  // Prevent input of letters and other special characters except for '.'
                  const inputValue = e.target.value
                  const sanitizedInput = inputValue.replace(/[^0-9.]/g, '') // Remove anything that's not a number or '.'
                  if (inputValue !== sanitizedInput) {
                    e.target.value = sanitizedInput // Set the input value to the sanitized version
                    setCompleteTotal(sanitizedInput) // Update state if needed
                  }
                }}
                error={!!completeTotalError}
                helperText={completeTotalError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                      <Icon icon='iwwa:number-desc' />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Box>
            <InvoiceSummary setLineTotal={setRawLineTotal} />
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ mt: 1 }}>
          <Box>
            <AdditionalCostsInvoice setAdditionalTotal={setRawAdditionalTotal} />
          </Box>
        </Grid>

        <Grid item xs={6} sx={{ mt: 1 }}></Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Box>
            <InvoiceValueSummary summaryValues={summaryValues} />
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ mt: 1 }}>
          <Box>
            <Accounts setAccountRowTotal={setAccountRowTotal} />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'felx-end',
              border: '1px solid #e0e0e0',
              borderRadius: '10px',
              padding: '10px',
              gap: 1
            }}
          >
            {balance >= 0 ? (
              <>
                <Typography variant='body1'>Balance Due:</Typography>
                <Typography variant='body1' fontWeight={600}>
                  {balance}
                </Typography>
              </>
            ) : (
              <>
                <Typography variant='body1'>Balance Credited:</Typography>
                <Typography variant='body1' fontWeight={600}>
                  {-balance}
                </Typography>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Invoice
