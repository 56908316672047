import { Icon } from '@iconify/react'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Chip,
  DialogActions,
  Button,
  InputAdornment,
  TextField
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import apiDefinitions from '../../../../../api/apiDefinitions'
import {
  useCurrentQueryParams,
  useCurrentRoute,
  useUserID,
  validColorCodes,
  useUserItemRole
} from '../../../../../hooks/customHooks'

import toast from 'react-hot-toast'
import Swal from 'sweetalert2'

const CustomCloseButton = styled(IconButton)(({ theme }) => ({
  top: 0,
  right: 0,
  color: 'grey.500',
  position: 'absolute',
  boxShadow: theme.shadows[2],
  transform: 'translate(10px, -10px)',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: `${theme.palette.background.paper} !important`,
  transition: 'transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out',
  '&:hover': {
    transform: 'translate(7px, -5px)'
  }
}))

const RequestItemSummary = ({ tableRefresh }) => {
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''
  const role = useUserItemRole() ?? ''

  const isPaginate = true

  const acceptStatus = 'approved'
  const rejectStatus = 'rejected'

  console.log('role', role)

  const poReqID = useCurrentQueryParams('id') ?? ''

  const [rows, setRows] = useState([])
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 3 })
  const [recordCount, setRecordCount] = useState(0)

  const [vendorListOpen, setVendorListOpen] = useState(false)
  const [vendorValues, setVendorValues] = useState([])

  const [summaryTableRefresh, setSummaryTableRefresh] = useState(false)

  const [acceptFormOpen, setAcceptFormOpen] = useState(false)
  const [rejectFormOpen, setRejectFormOpen] = useState(false)
  const [reason, setReason] = useState('')
  const [reasonError, setReasonError] = useState('')
  const [itemID, setItemID] = useState('')

  const [historyForm, setHistoryForm] = useState(false)

  const [historyCreateUser, setHistoryCreateUser] = useState('')
  const [historyCreateDate, setHistoryCreateDate] = useState('')
  const [historyAcceptRejectUser, setHistoryAcceptRejectUser] = useState('')
  const [historyAcceptRejectDate, setHistoryAcceptRejectDate] = useState('')
  const [historyReason, setHistoryReason] = useState('')
  const [historyStatus, setHistoryStatus] = useState('')

  const handleVendorListOpen = id => {
    const selectedRow = rows.find(row => row.id === id)
    if (selectedRow) {
      setVendorValues(selectedRow.vendor) // Set the list values for the selected row
      setVendorListOpen(true)
    }
  }

  const handleVendorListClose = () => {
    setVendorListOpen(false)
  }

  const handleAcceptFormOpen = id => {
    setItemID(id)
    setAcceptFormOpen(true)
  }

  const handleAcceptFormClose = () => {
    setAcceptFormOpen(false)
    setReason('')
    setReasonError('')
  }

  const handleRejectFormOpen = id => {
    setItemID(id)
    setRejectFormOpen(true)
  }

  const handleRejectFormClose = () => {
    setRejectFormOpen(false)
    setReason('')
    setReasonError('')
  }

  const handleHistoryFormOpen = id => {
    setItemID(id)
    setHistoryForm(true)
    fetchData()
  }

  const handleHistoryFormClose = () => {
    setHistoryForm(false)
  }

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '' && poReqID !== '' && isPaginate === true) {
      apiDefinitions
        .getItemSummeryByPORID(
          userID,
          currentRouteString,
          poReqID,
          paginationModel.page,
          paginationModel.pageSize,
          isPaginate
        )
        .then(res => {
          if (res.data.code === 200) {
            console.log(res)
            setRows(res.data.data.data)
            setRecordCount(res.data.data.total_record_count)
          } else {
            console.log('error')
            toast.error(`Error: ${res.data.message}`)
            setRows([])
            setRecordCount(0)
          }
        })
        .catch(err => {
          console.log(err)
          toast.error('Error fetching Items List!')
          setRows([])
          setRecordCount(0)
        })
    }
  }, [currentRouteString, paginationModel, userID, poReqID, tableRefresh, summaryTableRefresh, isPaginate])

  const fetchData = () => {
    if (userID !== '' && currentRouteString !== '' && itemID !== '') {
      apiDefinitions
        .getItemSummeryHistoryById(userID, currentRouteString, itemID)
        .then(res => {
          if (res.data.code === 200) {
            const historyData = res.data.data

            setHistoryCreateUser(historyData[0]?.created_by?.user_name)
            setHistoryCreateDate(historyData[0]?.created_at)

            const historyStatus = historyData[1]?.status || historyData[0]?.status
            setHistoryStatus(historyStatus)

            if (historyStatus?.type === 'Approved') {
              setHistoryAcceptRejectUser(historyData[1]?.approved_by?.user_name)
              setHistoryAcceptRejectDate(historyData[1]?.approved_at)
            } else if (historyStatus?.type === 'Rejected') {
              setHistoryAcceptRejectUser(historyData[1]?.rejected_by?.user_name)
              setHistoryAcceptRejectDate(historyData[1]?.rejected_at)
            }

            setHistoryReason(historyData[1]?.reason)
          } else {
            console.log('error')
            toast.error(`Error: ${res.data.message}`)
          }
        })
        .catch(err => {
          console.log(err)
          toast.error('Error fetching Item History!')
        })
    }
  }

  useEffect(() => {
    fetchData()
  }, [currentRouteString, itemID, userID])

  const columns = [
    {
      field: 'item_name',
      filterable: false,
      sortable: false,
      headerName: 'Item Name',
      minWidth: 150,
      flex: 1,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.item.short_name || 'N/A'}</Typography>
      }
    },
    {
      field: 'item_quantity',
      filterable: false,
      sortable: false,
      headerName: 'Quantity',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 0.5,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.item_qty || 'N/A'}</Typography>
      }
    },
    {
      field: 'notes',
      filterable: false,
      sortable: false,
      headerName: 'Notes',
      minWidth: 200,
      flex: 1,
      renderCell: params => {
        return (
          <Typography
            variant='body2'
            sx={{
              whiteSpace: 'pre-line',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              lineHeight: '1.25em',
              py: 1,
              textAlign: 'justify',
              letterSpacing: 0
            }}
          >
            {params.row.special_notes || 'N/A'}
          </Typography>
        )
      }
    },
    {
      field: 'vendor_list',
      filterable: false,
      sortable: false,
      headerName: 'Vendors',
      minWidth: 80,
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return (
          <IconButton aria-label='view' color='primary' onClick={() => handleVendorListOpen(params.row.id)}>
            <Icon icon='majesticons:open-line'></Icon>
          </IconButton>
        )
      }
    },
    {
      field: 'status',
      filterable: false,
      sortable: false,
      headerName: 'Status',
      minWidth: 180,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        const color = validColorCodes.includes(params.row.status?.color_code)
          ? params.row.status.color_code
          : 'secondary'
        return (
          <Chip
            variant='filled'
            color={color}
            label={
              <Typography
                variant='body2'
                sx={{
                  fontSize: '0.75rem'
                }}
              >
                {params.row.status?.type ? String(params.row.status?.type).toUpperCase() : 'Status Not Found'}
              </Typography>
            }
          ></Chip>
        )
      }
    },
    // {
    //   field: 'reason',
    //   headerName: 'Reason',
    //   minWidth: 250,
    //   flex: 1,
    //   renderCell: params => {
    //     return (
    //       <Typography
    //         variant='body2'
    //         sx={{
    //           whiteSpace: 'pre-line',
    //           overflow: 'hidden',
    //           textOverflow: 'ellipsis',
    //           lineHeight: '1em',
    //           py: 1,
    //           textAlign: 'justify',
    //           letterSpacing: 0
    //         }}
    //       >
    //         {params.row.reason || 'N/A'}
    //       </Typography>
    //     )
    //   }
    // },
    {
      field: 'actions',
      filterable: false,
      sortable: false,
      headerName: 'Actions',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return (
          <>
            {role !== 'item-admin' ? (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 0.9 }}>
                <IconButton aria-label='view' color='primary' onClick={() => handleHistoryFormOpen(params.row.id)}>
                  <Icon icon='fluent-mdl2:full-history' fontSize={22} />
                </IconButton>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 0.9, gap: 2 }}>
                <IconButton
                  aria-label='view'
                  color='primary'
                  onClick={() => handleAcceptFormOpen(params.row.id)}
                  disabled={params.row.status.type !== 'Pending Approval'}
                >
                  <Icon icon='el:ok-circle' fontSize={22} />
                </IconButton>

                <IconButton
                  aria-label='view'
                  color='primary'
                  onClick={() => handleRejectFormOpen(params.row.id)}
                  disabled={params.row.status.type !== 'Pending Approval'}
                >
                  <Icon icon='mingcute:close-fill' fontSize={22} />
                </IconButton>
              </Box>
            )}
          </>
        )
      }
    }
  ]

  const handleFormSubmit = () => {
    // console.log('Form Submitted!')
    setSummaryTableRefresh(!summaryTableRefresh)
  }

  const handleAcceptance = () => {
    let hasError = false

    if (reason.trim() === '') {
      setReasonError('Reason is required!')
      hasError = true
    } else {
      setReasonError('')
    }

    if (hasError) return
    Swal.fire({
      title: 'Are you sure you want to accept this Item?',
      showCancelButton: true,
      confirmButtonText: 'Accept',
      cancelButtonText: 'Cancel',
      customClass: {
        popup: 'swal2-popup'
      }
    }).then(result => {
      if (result.isConfirmed) {
        const payload = {
          por_detail_id: itemID,
          status: acceptStatus,
          reason: reason
        }

        console.log('Payload:', payload)

        apiDefinitions
          .putPORItemSummaryUpdate(userID, currentRouteString, payload)
          .then(res => {
            if (res.data.code === 200) {
              toast.success('Item Accepted Successfully')
              handleFormSubmit()
              handleAcceptFormClose()
            } else {
              console.log('error')
              toast.error(`Error: ${res.data.message}`)
            }
          })
          .catch(err => {
            console.log(err)
            toast.error('Error Accepting Item!')
          })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled Accepting Item', '', 'info')
      }
    })
  }

  const handleRejection = () => {
    let hasError = false

    if (reason.trim() === '') {
      setReasonError('Reason is required!')
      hasError = true
    } else {
      setReasonError('')
    }

    if (hasError) return
    Swal.fire({
      title: 'Are you sure you want to reject this Item?',
      showCancelButton: true,
      confirmButtonText: 'Reject',
      cancelButtonText: 'Cancel',
      customClass: {
        popup: 'swal2-popup'
      }
    }).then(result => {
      if (result.isConfirmed) {
        const payload = {
          por_detail_id: itemID,
          status: rejectStatus,
          reason: reason
        }

        console.log('Payload:', payload)

        apiDefinitions
          .putPORItemSummaryUpdate(userID, currentRouteString, payload)
          .then(res => {
            if (res.data.code === 200) {
              toast.success('Item Rejected Successfully')
              handleFormSubmit()
              handleRejectFormClose()
            } else {
              console.log('error')
              toast.error(`Error: ${res.data.message}`)
            }
          })
          .catch(err => {
            console.log(err)
            toast.error('Error Rejecting Item!')
          })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled Rejecting Item', '', 'info')
      }
    })
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              height: 272,
              width: '100%',
              '& .actions': {
                color: 'text.secondary'
              },
              '& .textPrimary': {
                color: 'text.primary'
              }
            }}
          >
            <DataGrid
              getRowHeight={() => 'auto'}
              rows={rows}
              rowCount={recordCount}
              columns={columns}
              paginationMode='server'
              pageSizeOptions={[3, 5, 10, 25, 50, 100]}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              disableRowSelectionOnClick
            />
          </Box>
        </Grid>
      </Grid>

      <Dialog
        open={vendorListOpen}
        onClose={handleVendorListClose}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        scroll='paper'
        fullWidth
        sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
      >
        <DialogTitle variant='h5' sx={{ pb: 1 }}>
          List Items
          <CustomCloseButton aria-label='close' onClick={handleVendorListClose}>
            <Icon icon='tabler:x' fontSize='1.25rem' />
          </CustomCloseButton>
        </DialogTitle>
        <DialogContent className='content-display-grid-scroll'>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Vendor ID</TableCell>
                  <TableCell>Vendor Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vendorValues.map(item => (
                  <TableRow key={item.id}>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.vendor_name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>

      <Dialog
        open={acceptFormOpen}
        onClose={handleAcceptFormClose}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        scroll='paper'
        fullWidth
        sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
      >
        <DialogTitle variant='h5' sx={{ pb: 1 }}>
          Accept Item Request | Item ID: {itemID}
          <CustomCloseButton aria-label='close' onClick={handleAcceptFormClose}>
            <Icon icon='tabler:x' fontSize='1.25rem' />
          </CustomCloseButton>
        </DialogTitle>
        <DialogContent className='content-display-grid-scroll'>
          <Grid item xs={12} sx={{ py: 1 }}>
            <TextField
              fullWidth
              label='Reason'
              required
              multiline
              minRows={2}
              onChange={e => {
                setReason(e.target.value)
                setReasonError('')
              }}
              error={!!reasonError}
              helperText={reasonError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                    <Icon icon='octicon:note-24' />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='primary' onClick={handleAcceptance}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={rejectFormOpen}
        onClose={handleRejectFormClose}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        scroll='paper'
        fullWidth
        sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
      >
        <DialogTitle variant='h5' sx={{ pb: 1 }}>
          Reject Item Request | Item ID: {itemID}
          <CustomCloseButton aria-label='close' onClick={handleRejectFormClose}>
            <Icon icon='tabler:x' fontSize='1.25rem' />
          </CustomCloseButton>
        </DialogTitle>
        <DialogContent className='content-display-grid-scroll'>
          <Grid item xs={12} sx={{ py: 1 }}>
            <TextField
              fullWidth
              label='Reason'
              required
              multiline
              minRows={2}
              onChange={e => {
                setReason(e.target.value)
                setReasonError('')
              }}
              error={!!reasonError}
              helperText={reasonError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                    <Icon icon='octicon:note-24' />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='primary' onClick={handleRejection}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={historyForm}
        onClose={handleHistoryFormClose}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        scroll='paper'
        fullWidth
        sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
      >
        <DialogTitle variant='h5' sx={{ pb: 1 }}>
          Item Request History | Item ID: {itemID} |{' '}
          <Chip
            variant='filled'
            color={historyStatus?.color_code || 'secondary'}
            label={
              <Typography
                variant='body2'
                sx={{
                  fontSize: '0.75rem'
                }}
              >
                {historyStatus?.type ? String(historyStatus?.type).toUpperCase() : 'Status Not Found'}
              </Typography>
            }
          ></Chip>
          <CustomCloseButton aria-label='close' onClick={handleHistoryFormClose}>
            <Icon icon='tabler:x' fontSize='1.25rem' />
          </CustomCloseButton>
        </DialogTitle>
        <DialogContent className='content-display-grid-scroll'>
          <Grid container spacing={2} sx={{ py: 1 }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Created User'
                value={historyCreateUser || 'N/A'}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                      <Icon icon='fa6-regular:user' />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Created Date'
                value={historyCreateDate || 'N/A'}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                      <Icon icon='fluent-mdl2:date-time' />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Accepted/Rejected User'
                value={historyAcceptRejectUser || 'N/A'}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                      <Icon icon='fa6-regular:user' />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Accepted/Rejected Date'
                value={historyAcceptRejectDate || 'N/A'}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                      <Icon icon='fluent-mdl2:date-time' />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label='Reason'
                value={historyReason || 'N/A'}
                disabled
                multiline
                minRows={2}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start' sx={{ fontSize: '20px' }}>
                      <Icon icon='octicon:note-24' />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default RequestItemSummary
