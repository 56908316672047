import { Sync } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { DataGrid } from '@mui/x-data-grid'
import * as React from 'react'
import { useState } from 'react'

const initalRows = [
  { id: 1, order: 'A001', type: 'Electronics', orderDate: '2023-01-14', status: 'Shipped', total: 299.99 },
  { id: 2, order: 'A002', type: 'Books', orderDate: '2023-01-16', status: 'Pending', total: 19.99 },
  { id: 3, order: 'A003', type: 'Clothing', orderDate: '2023-01-17', status: 'Delivered', total: 59.99 },
  { id: 4, order: 'A004', type: 'Accessories', orderDate: '2023-01-18', status: 'Returned', total: 15.0 },
  { id: 5, order: 'A005', type: 'Furniture', orderDate: '2023-01-19', status: 'Shipped', total: 499.99 },
  { id: 6, order: 'A006', type: 'Electronics', orderDate: '2023-01-20', status: 'Pending', total: 249.99 },
  { id: 7, order: 'A007', type: 'Books', orderDate: '2023-01-21', status: 'Delivered', total: 25.99 },
  { id: 8, order: 'A008', type: 'Clothing', orderDate: '2023-01-22', status: 'Shipped', total: 79.99 },
  { id: 9, order: 'A009', type: 'Accessories', orderDate: '2023-01-23', status: 'Pending', total: 12.99 },
  { id: 10, order: 'A010', type: 'Furniture', orderDate: '2023-01-24', status: 'Delivered', total: 599.99 }
]

const RelatedOrders = () => {
  const [rows, setRows] = useState(initalRows)

  const [loading, setLoading] = useState(false)

  const columns = [
    {
      field: 'order',
      headerName: 'Order',
      flex: 1,
      width: 150
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      width: 150
    },
    {
      field: 'orderDate',
      headerName: 'Order Date',
      flex: 1,
      type: 'date',
      width: 150,
      valueFormatter: params => {
        return new Date(params.value).toLocaleDateString()
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      width: 150
    },
    {
      field: 'total',
      headerName: 'Total',
      flex: 1,
      type: 'number',
      valueFormatter: params => {
        return Number(params.value).toFixed(2)
      },
      width: 110
    }
  ]

  const handleRefresh = () => {
    setRows([])
    setLoading(true)
    setTimeout(() => {
      setRows(initalRows)
      setLoading(false)
    }, 5000)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 2 }}>
        <Button variant='outlined' color='secondary' size='small' startIcon={<Sync />} onClick={handleRefresh}>
          Refresh
        </Button>
      </Box>
      <DataGrid
        autoHeight
        rows={rows}
        columns={columns}
        loading={loading}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5
            }
          }
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        slots={{
          noRowsOverlay: () => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%'
                }}
              >
                <Typography variant='body2' color='textSecondary'>
                  No related orders created.
                </Typography>
              </Box>
            )
          }
        }}
      />
    </Box>
  )
}

export default RelatedOrders
