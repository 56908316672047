import AddIcon from '@mui/icons-material/Add'
import CancelIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { Divider, MenuItem, Select, TextField, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { DataGrid, GridActionsCellItem, GridRowEditStopReasons, GridRowModes } from '@mui/x-data-grid'
import { randomId } from '@mui/x-data-grid-generator'
import * as React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'

import toast from 'react-hot-toast'

const initialRows = []

const CreditNotesRefunds = ({ setRefundTotal }) => {
  const [rows, setRows] = useState(initialRows)
  const [rowModesModel, setRowModesModel] = useState({})

  const [loading, setLoading] = useState(false)

  const [accounts, setAccounts] = useState([
    { value: 1, label: 'Account 1' },
    { value: 2, label: 'Account 2' },
    { value: 3, label: 'Account 3' }
  ])

  useEffect(() => {
    // Calculate the total of all the lines
    const total = rows.reduce((acc, row) => {
      const amount = row.amount || 0

      return acc + amount
    }, 0)

    setRefundTotal(total)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows])

  const handleClick = () => {
    const id = randomId()
    setRows(oldRows => [
      ...oldRows,
      {
        id,
        account: '',
        reference: '',
        date_paid: '',
        amount: 0,
        isNew: true
      }
    ])
    setRowModesModel(oldModel => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' }
    }))
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const handleEditClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = id => () => {
    setRows(rows.filter(row => row.id !== id))
  }

  const handleCancelClick = id => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    const editedRow = rows.find(row => row.id === id)
    if (editedRow.isNew) {
      setRows(rows.filter(row => row.id !== id))
    }
  }

  const processRowUpdate = newRow => {
    const updatedRow = { ...newRow, isNew: false }
    setRows(rows.map(row => (row.id === newRow.id ? updatedRow : row)))
    return updatedRow
  }

  const handleRowModesModelChange = newRowModesModel => {
    setRowModesModel(newRowModesModel)
  }

  const columns = [
    {
      field: 'account',
      headerName: 'Account',
      type: 'singleSelect',
      flex: 1.5,
      minWidth: 150,
      editable: true,
      valueOptions: accounts,

      renderEditCell: params => (
        <Select
          fullWidth
          sx={{
            '& fieldset': { border: 'none' }
          }}
          value={params.value}
          onChange={e => {
            params.api.setEditCellValue({
              id: params.id,
              field: 'account',
              value: e.target.value
            })
          }}
        >
          {accounts.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )
    },
    {
      field: 'reference',
      headerName: 'Reference',
      flex: 2,
      minWidth: 180,
      editable: true
    },
    {
      field: 'date_paid',
      headerName: 'Date Paid',
      headerAlign: 'center',
      align: 'right',
      type: 'date',
      flex: 1,
      minWidth: 130,
      editable: true,
      valueFormatter: ({ value }) => (value ? new Date(value).toLocaleDateString() : ''),
      renderEditCell: params => {
        return (
          <TextField
            sx={{
              '& input': {
                textAlign: 'right'
              },
              '& fieldset': { border: 'none' }
            }}
            onFocus={event => event.target.select()}
            fullWidth
            type='date'
            value={params.value}
            onChange={event =>
              params.api.setEditCellValue({
                id: params.id,
                field: 'date_paid',
                value: event.target.value
              })
            }
          />
        )
      }
    },
    {
      field: 'amount',
      headerName: 'Amount',
      headerAlign: 'center',
      align: 'right',
      type: 'number',
      flex: 1,
      minWidth: 100,
      editable: true,
      renderEditCell: params => {
        return (
          <TextField
            sx={{
              '& input': {
                textAlign: 'right'
              },
              '& fieldset': { border: 'none' }
            }}
            onFocus={event => event.target.select()}
            onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
            fullWidth
            type='number'
            value={params.value}
            onChange={event =>
              params.api.setEditCellValue({
                id: params.id,
                field: 'amount',
                value: event.target.value
              })
            }
            inputProps={{ min: 0 }}
          />
        )
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      flex: 0.5,
      minWidth: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label='Save'
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(id)}
              color='inherit'
            />
          ]
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label='Edit'
            className='textPrimary'
            onClick={handleEditClick(id)}
            color='inherit'
          />,
          <GridActionsCellItem icon={<DeleteIcon />} label='Delete' onClick={handleDeleteClick(id)} color='inherit' />
        ]
      }
    }
  ]

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2
        }}
      >
        <Typography variant='body1' fontWeight={500}>
          Refunds
        </Typography>
        <Button color='primary' variant='outlined' size='small' startIcon={<AddIcon />} onClick={handleClick}>
          Add
        </Button>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box
        sx={{
          height: 213.65,
          width: '100%',
          '& .actions': {
            color: 'text.secondary'
          },
          '& .textPrimary': {
            color: 'text.primary'
          }
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          editMode='row'
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          loading={loading}
          slots={{
            footer: () => null,

            noRowsOverlay: () => {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                  }}
                >
                  <Typography variant='body2' color='textSecondary'>
                    You do not have any refunds.
                  </Typography>
                </Box>
              )
            }
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel }
          }}
        />
      </Box>
    </>
  )
}

export default CreditNotesRefunds
