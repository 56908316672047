import { Icon } from '@iconify/react'
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  styled,
  Chip
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'

import toast from 'react-hot-toast'
import apiDefinitions from '../../api/apiDefinitions'
import { useCurrentRoute, useUserID, validColorCodes, useUserItemRole } from '../../hooks/customHooks'

const CustomCloseButton = styled(IconButton)(({ theme }) => ({
  top: 0,
  right: 0,
  color: 'grey.500',
  position: 'absolute',
  boxShadow: theme.shadows[2],
  transform: 'translate(10px, -10px)',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: `${theme.palette.background.paper} !important`,
  transition: 'transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out',
  '&:hover': {
    transform: 'translate(7px, -5px)'
  }
}))

const AssignPending = () => {
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''
  const tableType = 'assign-pending'

  const [rows, setRows] = useState([])
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 5 })
  const [recordCount, setRecordCount] = useState(0)
  const [selectReason, setSelectReason] = useState(false)

  const [historyRows, setHistoryRows] = useState([])
  const [historyPaginationModel, setHistoryPaginationModel] = useState({ page: 0, pageSize: 5 })
  const [historyRecordCount, setHistoryRecordCount] = useState(0)
  const [history, setHistory] = useState(false)

  const handleSelectReasonOpen = () => {
    setSelectReason(true)
  }

  const handleSelectReasonClose = () => {
    setSelectReason(false)
  }

  const handleHistoryOpen = () => {
    setHistory(true)
  }

  const handleHistoryClose = () => {
    setHistory(false)
  }

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '' && tableType !== '') {
      apiDefinitions
        .getAssignRequestByTypes(userID, currentRouteString, tableType, paginationModel.page, paginationModel.pageSize)
        .then(res => {
          if (res.data.code === 200) {
            const response = res.data.data.data

            const rowModal = response.map((item, index) => {
              return {
                id: item?.item_assign_details?.id,
                item_detail_code: item?.item_assign_details?.item_detail_code || '',
                item_name: item?.item_assign_details?.item_detail?.items.short_name || '',
                category_name: item?.item_assign_details?.item_detail.items?.category?.category_name || '',
                total_quantity: item?.item_assign_details?.assign_quantity || '',
                assign_quantity: item?.assigned_quantity || '',
                assigned_by: item?.requested_by?.user_name || '',
                requested_date: item?.requested_date || '',
                color_code: item?.item_assign_details?.status?.color_code || '',
                item_status: item?.item_assign_details?.status.type || ''
              }
            })

            setRows(rowModal)
            setRecordCount(res.data.data.count)
          } else {
            console.log('error')
            toast.error(`Error: ${res.data.message}`)
            setRows([])
            setRecordCount(0)
          }
        })
        .catch(err => {
          console.log(err)
          toast.error('Error fetching Items List!')
          setRows([])
          setRecordCount(0)
        })
    }
  }, [currentRouteString, paginationModel, userID, tableType])

  const columns = [
    {
      field: 'assignment_id',
      headerName: 'Assignment ID',
      minWidth: 120,
      flex: 0.25,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.id || 'N/A'}</Typography>
      }
    },
    {
      field: 'item_detail_code',
      headerName: 'Item Detail',
      minWidth: 120,
      flex: 0.25,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.item_detail_code || 'N/A'}</Typography>
      }
    },
    {
      field: 'item_name',
      headerName: 'Item Name',
      minWidth: 100,
      flex: 0.4,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.item_name || 'N/A'}</Typography>
      }
    },
    {
      field: 'category_name',
      headerName: 'Category Name',
      minWidth: 100,
      flex: 0.4,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.category_name || 'N/A'}</Typography>
      }
    },
    {
      field: 'qty',
      headerName: 'Quantity',
      minWidth: 100,
      flex: 0.2,
      renderCell: params => {
        return (
          <Typography variant='body2'>
            {params.row.assign_quantity || '0'}/{params.row.total_quantity || '0'}
          </Typography>
        )
      }
    },

    {
      field: 'assigned_by',
      headerName: 'Assigned By',
      minWidth: 100,
      flex: 0.4,
      renderCell: params => <Typography variant='body2'>{params.row.assigned_by || 'N/A'}</Typography>
    },

    {
      field: 'assigned_date',
      headerName: 'Assigned Date',
      minWidth: 100,
      flex: 0.4,
      renderCell: params => (
        <Typography variant='body2'>
          {params.row.requested_date ? new Date(params.row.requested_date).toLocaleDateString() : 'N/A'}
        </Typography>
      )
    },

    {
      field: 'type',
      headerName: 'Status',
      minWidth: 150,
      flex: 0.8,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        const color = validColorCodes.includes(params.row.color_code) ? params.row.color_code : 'secondary'
        return (
          <Chip
            variant='filled'
            sx={{ my: 2 }}
            color={color}
            label={
              <Typography
                variant='body2'
                sx={{
                  fontSize: '0.75rem'
                }}
              >
                {params.row.item_status ? String(params.row.item_status).toUpperCase() : 'Status Not Found'}
              </Typography>
            }
          ></Chip>
        )
      }
    }

    // {
    //   field: 'actions',
    //   headerName: 'Actions',
    //   minWidth: 100,
    //   flex: 0.2,
    //   headerAlign: 'center',
    //   align: 'center',
    //   renderCell: params => {
    //     return (
    //       <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', py: 1.8, gap: 0 }}>
    //         <IconButton aria-label='view' color='primary'>
    //           <Icon icon='el:ok-circle' />
    //         </IconButton>

    //         <IconButton aria-label='view' color='primary' onClick={handleSelectReasonOpen}>
    //           <Icon icon='mingcute:close-fill' />
    //         </IconButton>
    //       </Box>
    //     )
    //   }
    // }
  ]

  const historyColumns = [
    {
      field: 'item_detail_code',
      headerName: 'Item Detail',
      minWidth: 120,
      flex: 0.3,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.item_detail_code || 'N/A'}</Typography>
      }
    },
    {
      field: 'item_name',
      headerName: 'Item Name',
      minWidth: 120,
      flex: 0.3,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.item_detail.items.short_name || 'N/A'}</Typography>
      }
    },
    {
      field: 'accepted_rejected_date',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            fontWeight: 500
          }}
        >
          Accepted /<br />
          Rejected Date
        </Typography>
      ),
      minWidth: 150,
      flex: 0.5,
      renderCell: params => (
        <Typography variant='body2'>{new Date(params.row.date).toLocaleDateString() || 'N/A'}</Typography>
      )
    },
    {
      field: 'accepted_rejected_by',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            fontWeight: 500
          }}
        >
          Accepted /<br />
          Rejected By
        </Typography>
      ),
      minWidth: 150,
      flex: 0.5,
      renderCell: params => <Typography variant='body2'>{params.row.assigned_by.user_name || 'N/A'}</Typography>
    },
    {
      field: 'reason',
      headerName: 'Reason',
      minWidth: 120,
      flex: 0.3,
      renderCell: params => {
        // return <Typography variant='body2'>{params.row.level || 'N/A'}</Typography>
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant='body2'
              sx={{
                color: 'text.primary',
                whiteSpace: 'pre-wrap', // Allow text to wrap
                overflow: 'hidden', // Hide overflowing text
                textOverflow: 'ellipsis', // Add ellipsis for long text
                lineHeight: '1.5em',
                textAlign: 'justify',
                py: '0.5em'
              }}
            >
              {params.row.level || 'N/A'}
            </Typography>
          </Box>
        )
      }
    },
    {
      field: 'type',
      headerName: 'Status',
      minWidth: 120,
      flex: 0.3,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        const color = validColorCodes.includes(params.row.item_status?.color_code)
          ? params.row.item_status.color_code
          : 'secondary'
        return (
          <Chip
            variant='filled'
            color={color}
            sx={{ my: 1 }}
            label={
              <Typography
                variant='body2'
                sx={{
                  fontSize: '0.75rem'
                }}
              >
                {params.row.item_status?.type ? String(params.row.item_status?.type).toUpperCase() : 'Status Not Found'}
              </Typography>
            }
          ></Chip>
        )
      }
    }
  ]

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant='h6'>Item Pending Assignments</Typography>
            <Button
              variant='contained'
              onClick={handleHistoryOpen}
              startIcon={<Icon icon='mdi:clipboard-text-history-outline' />}
            >
              View History
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ boxShadow: 2 }}>
            <Box
              sx={{
                height: 460,
                width: '100%',
                '& .actions': {
                  color: 'text.secondary'
                },
                '& .textPrimary': {
                  color: 'text.primary'
                }
              }}
            >
              <DataGrid
                getRowHeight={() => 'auto'}
                rows={rows}
                rowCount={recordCount}
                columns={columns}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                paginationModel={paginationModel}
                paginationMode='server'
                onPaginationModelChange={setPaginationModel}
                disableRowSelectionOnClick
              />
            </Box>
          </Card>
        </Grid>
      </Grid>

      <Dialog
        open={selectReason}
        onClose={handleSelectReasonClose}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        scroll='paper'
        fullWidth
        sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
      >
        <DialogTitle variant='h5' sx={{ pb: 1 }}>
          Rejection Reason
          <CustomCloseButton aria-label='close' onClick={handleSelectReasonClose}>
            <Icon icon='tabler:x' fontSize='1.25rem' />
          </CustomCloseButton>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label='Reason'
              multiline
              minRows={2.5}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions sx={{ m: 2, mt: 0, gap: 2 }}>
          <Button variant='contained' sx={{ px: 4 }}>
            Reject
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={history}
        onClose={handleHistoryClose}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        scroll='paper'
        fullWidth
        sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
      >
        <DialogTitle variant='h5' sx={{ pb: 1 }}>
          Unassign Approval History
          <CustomCloseButton aria-label='close' onClick={handleHistoryClose}>
            <Icon icon='tabler:x' fontSize='1.25rem' />
          </CustomCloseButton>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <Card sx={{ boxShadow: 2 }}>
              <Box
                sx={{
                  height: 460,
                  width: '100%',
                  '& .actions': {
                    color: 'text.secondary'
                  },
                  '& .textPrimary': {
                    color: 'text.primary'
                  }
                }}
              >
                <DataGrid
                  getRowHeight={() => 'auto'}
                  rows={historyRows}
                  rowCount={historyRecordCount}
                  columns={historyColumns}
                  pageSizeOptions={[5, 10, 25, 50, 100]}
                  paginationModel={historyPaginationModel}
                  onPaginationModelChange={setHistoryPaginationModel}
                  disableRowSelectionOnClick
                  checkboxSelection
                />
              </Box>
            </Card>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default AssignPending
