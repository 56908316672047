import React, { useState, useEffect } from 'react'
import {
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  Grid,
  TextField,
  Autocomplete
} from '@mui/material'
import apiDefinitions from '../../../api/apiDefinitions'
import { useCurrentRoute, useUserID } from '../../../hooks/customHooks'

import toast from 'react-hot-toast'

const AssignEmpDept = ({ handleToggle, checked, employees, handleEmployees }) => {
  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''

  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)

  const [departments, setDepartments] = useState([])

  useEffect(() => {
    apiDefinitions
      .getUsersAndDepartmentsExcludingCurrentUser(userID, currentRouteString, 'department', '')
      .then(response => {
        if (response.data.code === 200) {
          //sort in ascending order of department_name

          const sortedData = response.data.data.sort((a, b) => {
            if (a.department_name < b.department_name) {
              return -1
            }
            if (a.department_name > b.department_name) {
              return 1
            }
            return 0
          })

          setDepartments(sortedData)
        } else {
          console.log('Error in fetching departments')
        }
      })
  }, [userID, currentRouteString])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiDefinitions.getAllDataList(userID, currentRouteString, 'user', inputValue)
        if (response.data.code === 200) {
          setOptions(response.data.data)
        } else {
          console.log('Error in fetching users')
          toast.error('Error in fetching users!')
          setOptions([])
        }
      } catch (error) {
        console.log('Error in fetching users', error)
        toast.error('Error in fetching users!')
        setOptions([])
      } finally {
        setLoading(false)
      }
    }

    setLoading(true)
    fetchData()
  }, [currentRouteString, inputValue, userID])

  return (
    <Grid container spacing={2} sx={{ px: 2 }}>
      <Grid item xs={12}>
        <Typography variant='h6'>Assign Employee</Typography>
        <Autocomplete
          multiple
          limitTags={5}
          id='multiple-limit-tags'
          options={options}
          loading={loading}
          getOptionLabel={option => option.user_name}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue)
          }}
          value={employees}
          onChange={(event, newValue) => {
            handleEmployees(newValue)
          }}
          isOptionEqualToValue={(option, value) => option.user_name === value.user_name}
          renderInput={params => <TextField sx={{ mt: 2 }} {...params} label='Employees' />}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant='h6'>Assign Department</Typography>

        <List sx={{ maxHeight: 170, overflowY: 'scroll' }} className='content-display-grid-scroll'>
          {departments.map(item => (
            <ListItem key={item.dept_id} disablePadding>
              <ListItemButton onClick={handleToggle(item.dept_id)}>
                <ListItemText id={`checkbox-list-label-${item.dept_id}`} primary={item.department_name} />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge='end'
                    tabIndex={-1}
                    disableRipple
                    onChange={handleToggle(item.dept_id)}
                    checked={checked.indexOf(item.dept_id) !== -1}
                    inputProps={{ 'aria-labelledby': `checkbox-list-label-${item.dept_id}` }}
                  />
                </ListItemSecondaryAction>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  )
}

export default AssignEmpDept
