import {
  Box,
  Checkbox,
  Divider,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import apiDefinitions from '../../../api/apiDefinitions'
import { useCurrentRoute, useUserID } from '../../../hooks/customHooks'
const AssignSummary = ({ selectedItemData, selectedEmployees, selectedDepartments }) => {
  console.log('selectedItemData', selectedItemData)
  console.log('selectedEmployees', selectedEmployees)
  console.log('selectedDepartments', selectedDepartments)

  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const handlePageChange = (event, value) => {
    setPage(value)
  }

  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''

  const [departments, setDepartments] = useState([])

  useEffect(() => {
    apiDefinitions.getAllDataList(userID, currentRouteString, 'department').then(response => {
      if (response.data.code === 200) {
        const sortedData = response.data.data.sort((a, b) => {
          if (a.department_name < b.department_name) {
            return -1
          }
          if (a.department_name > b.department_name) {
            return 1
          }
          return 0
        })

        setDepartments(sortedData)
      } else {
      }
    })
  }, [userID, currentRouteString])

  useEffect(() => {
    setPageCount(Math.ceil(selectedItemData.length / 3))
    setPage(1)
  }, [selectedItemData])

  return (
    <Box>
      <Typography variant='h5' gutterBottom>
        Assignment Summary
      </Typography>
      {selectedDepartments.length > 0 && (
        <>
          <Box
            sx={{
              my: 3
            }}
          >
            <Typography variant='h6' gutterBottom>
              Selected Departments
            </Typography>
            <Typography
              variant='body1'
              gutterBottom
              sx={{
                ml: 3
              }}
            >
              {selectedDepartments.map((element, index) => {
                const department = departments.find(dept => dept.dept_id === element)
                return department ? (
                  <>
                    <span key={department.dept_id}>
                      {index + 1}.&ensp;{department.department_name}
                    </span>
                    <br />
                  </>
                ) : null
              })}
            </Typography>
          </Box>

          <Divider
            sx={{
              mx: 5
            }}
          />
        </>
      )}

      {selectedEmployees.length > 0 && (
        <>
          <Box
            sx={{
              my: 3
            }}
          >
            <Typography variant='h6' gutterBottom>
              Selected Employees
            </Typography>
            <Typography
              variant='body1'
              gutterBottom
              sx={{
                ml: 3
              }}
            >
              {selectedEmployees.map((element, index) => (
                <span key={element.user_id}>
                  {index + 1}.&ensp;{element.user_name}
                  <br />
                </span>
              ))}
            </Typography>
          </Box>

          <Divider
            sx={{
              mx: 5
            }}
          />
        </>
      )}

      <Box
        sx={{
          my: 3
        }}
      >
        <Typography variant='h6' gutterBottom>
          Selected Item
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Item Name</TableCell>
                <TableCell>Assign Quantity</TableCell>
                <TableCell>Require Admin Approval</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedItemData.slice((page - 1) * 3, page * 3).map((element, index) => (
                <TableRow key={element.id}>
                  <TableCell>{(page - 1) * 3 + index + 1}</TableCell>
                  <TableCell>{element.itemName}</TableCell>
                  <TableCell>{element.assQty}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={element.adminReq}
                      disabled
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: '1.5rem'
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={pageCount}
            page={page}
            onChange={handlePageChange}
            size='small'
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 2
            }}
          />
        </TableContainer>
      </Box>
    </Box>
  )
}

export default AssignSummary
