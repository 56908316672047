import { Icon } from '@iconify/react'
import { Box, Button, Card, Grid, IconButton, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import Swal from 'sweetalert2'
import apiDefinitions from '../../../api/apiDefinitions'
import { useCurrentRoute, useUserID } from '../../../hooks/customHooks'

import AddVendorForm from './addvendor'
import EditVendorForm from './editvendor'

const Vendor = () => {
  const [rows, setRows] = useState([])
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 5 })
  const [recordCount, setRecordCount] = useState(0)
  const [tableRefresh, setTableRefresh] = useState(false)

  const [addVendorFormOpen, setAddVendorFormOpen] = useState(false)
  const [editVendorFormOpen, setEditVendorFormOpen] = useState(false)

  const [editVendorData, setEditVendorData] = useState([])

  const handleAddVendorFormOpen = () => {
    setAddVendorFormOpen(true)
  }

  const handleAddVendorFormClose = () => {
    setAddVendorFormOpen(false)
  }

  const handleEditVendorFormOpen = rowData => {
    if (rowData !== undefined && rowData !== null && rowData !== '') {
      setEditVendorData(rowData)
      setEditVendorFormOpen(true)
    } else {
      toast.error('Null Vendor Data!')
    }
  }

  const handleEditVendorFormClose = () => {
    setEditVendorFormOpen(false)
  }

  const handleFormSubmit = () => {
    // console.log('Form Submitted!')
    setTableRefresh(!tableRefresh)
  }

  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''

  const columns = [
    // {
    //   field: 'id',
    //   headerName: 'Vendor ID',
    //   minWidth: 100,
    //   flex: 0.25,
    //   renderCell: params => {
    //     return <Typography variant='body2'>{params.row.id || 'N/A'}</Typography>
    //   }
    // },
    {
      field: 'vendor_name',
      headerName: 'Vendor',
      minWidth: 100,
      flex: 1,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.vendor_name || 'N/A'}</Typography>
      }
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 100,
      flex: 1.25,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.description || 'N/A'}</Typography>
      }
    },
    {
      field: 'address',
      headerName: 'Address',
      minWidth: 100,
      flex: 1.5,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.address || 'N/A'}</Typography>
      }
    },
    {
      field: 'telephone_number',
      headerName: 'Telephone Number',
      minWidth: 180,
      flex: 0.75,
      renderCell: params => {
        return <Typography variant='body2'>{params.row.telephone_number || 'N/A'}</Typography>
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 80,
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      renderCell: params => {
        return (
          <Box
            sx={{
              my: 1,
              gap: 2,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <IconButton aria-label='view' color='primary' onClick={() => handleEditVendorFormOpen(params.row)}>
              <Icon icon='fluent:edit-16-regular' />
            </IconButton>
            <IconButton
              aria-label='view'
              color='primary'
              disabled={params.row.assign_status}
              onClick={() => handleDelete(params.row.id)}
            >
              <Icon icon='fluent:delete-32-regular' />
            </IconButton>
          </Box>
        )
      }
    }
  ]

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '') {
      apiDefinitions
        .getAllVendors(userID, currentRouteString, paginationModel.page, paginationModel.pageSize)
        .then(res => {
          if (res.data.code === 200) {
            setRows(res.data.data.data)
            setRecordCount(res.data.data.count)
          } else {
            toast.error(`Error: ${res.data.message}`)
            setRows([])
            setRecordCount(0)
          }
        })
        .catch(err => {
          console.log(err)
          toast.error('Error fetching Vendor List!')
          setRows([])
          setRecordCount(0)
        })
    }
  }, [currentRouteString, paginationModel, userID, tableRefresh])

  const handleDelete = id => {
    Swal.fire({
      title: 'Do you want to delete this vendor?',
      showDenyButton: true,
      confirmButtonText: 'Accept',
      denyButtonText: 'Cancel',
      customClass: {
        popup: 'swal2-popup'
      }
    }).then(result => {
      if (result.isConfirmed) {
        if (userID !== '' && currentRouteString !== '') {
          apiDefinitions
            .putVendorDelete(userID, currentRouteString, id)
            .then(res => {
              if (res.data.code === 200) {
                toast.success('Vendor Deleted Successfully')
                setTableRefresh(!tableRefresh)
              } else {
                console.log('error')
                toast.error(`Error: ${res.data.message}`)
              }
            })
            .catch(err => {
              console.log(err)
              toast.error('Error Deleting Vendor!')
            })
        }
      } else if (result.isDenied) {
        Swal.fire('Vendor Delete Canceled', '', 'info')
      }
    })
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2 }}>
            <Typography variant='h5'>VENDORS</Typography>
            <Button
              variant='contained'
              onClick={handleAddVendorFormOpen}
              startIcon={<Icon icon='material-symbols-light:add' />}
            >
              Create Vendor
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ boxShadow: 2 }}>
            <Box
              sx={{
                height: 395,
                width: '100%',
                '& .actions': {
                  color: 'text.secondary'
                },
                '& .textPrimary': {
                  color: 'text.primary'
                }
              }}
            >
              <DataGrid
                getRowHeight={() => 'auto'}
                rows={rows}
                rowCount={recordCount}
                columns={columns}
                paginationMode='server'
                pageSizeOptions={[5, 10, 25, 50, 100]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                disableRowSelectionOnClick
              />
            </Box>
          </Card>
        </Grid>
      </Grid>

      <AddVendorForm
        addVendorFormOpen={addVendorFormOpen}
        onCloseVendorForm={handleAddVendorFormClose}
        onFormSubmit={handleFormSubmit}
      />

      <EditVendorForm
        editVendorFormOpen={editVendorFormOpen}
        onCloseVendorForm={handleEditVendorFormClose}
        onFormSubmit={handleFormSubmit}
        editVendorData={editVendorData}
      />
    </>
  )
}

export default Vendor
