import { TabContext, TabPanel } from '@mui/lab'
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import HeaderStepper from './components/HeaderStepper'
import HeaderTabsH from './components/HeaderTabsH'
import SupplierDetails from './components/Supplier'

import Order from './components/HeaderComponents/Order'
import Invoice from './components/HeaderComponents/Invoice'
import StockRecieved from './components/HeaderComponents/StockRecieved'
import CreditNote from './components/HeaderComponents/CreditNote'
import Unstock from './components/HeaderComponents/Unstock'
import ManualJournals from './components/HeaderComponents/ManualJournals'
import RelatedOrders from './components/HeaderComponents/RelatedOrders'
import Attachments from './components/HeaderComponents/Attachments'
import LogsAndAttributes from './components/HeaderComponents/LogsAndAttributes'
import Financials from './components/HeaderComponents/Financials'

import LogsAndAttributes2 from './components/HeaderComponents/LogsAndAttributes-2'

const POCreate = () => {
  const [expanded, setExpanded] = useState('supplier')
  const [contextValue, setContextValue] = useState(null)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
    setContextValue(null)
  }

  useEffect(() => {
    if (contextValue !== null) {
      setExpanded('header2')
    }
  }, [contextValue])

  return (
    <>
      <Accordion expanded={expanded === 'supplier'} onChange={handleChange('supplier')}>
        <AccordionSummary>
          <Typography variant='h6'>Supplier</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SupplierDetails />
        </AccordionDetails>
      </Accordion>
      <Card
        sx={{
          mt: 2
        }}
      >
        <CardContent
          sx={{
            p: '5px !important',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <TabContext value={contextValue}>
            <HeaderTabsH contextValue={contextValue} setContextValue={setContextValue} />
          </TabContext>
        </CardContent>
      </Card>
      <TabContext value={contextValue}>
        <Card
          sx={{
            mt: 2
          }}
        >
          <CardContent
            sx={{
              p: '0 !important'
            }}
          >
            <TabPanel value='1'>
              <Order />
            </TabPanel>
            <TabPanel value='2'>
              <Invoice />
            </TabPanel>
            <TabPanel value='3'>
              <StockRecieved />
            </TabPanel>
            <TabPanel value='4'>
              <CreditNote />
            </TabPanel>
            <TabPanel value='5'>
              <Unstock />
            </TabPanel>
            <TabPanel value='6'>
              <ManualJournals />
            </TabPanel>
            <TabPanel value='7'>
              <RelatedOrders />
            </TabPanel>
            <TabPanel value='8'>
              <Attachments />
            </TabPanel>
            <TabPanel value='9'>
              {/* <LogsAndAttributes /> */}
              <LogsAndAttributes2 />
            </TabPanel>
            <TabPanel value='10'>
              <Financials />
            </TabPanel>
          </CardContent>
        </Card>
      </TabContext>
    </>
  )
}

export default POCreate
