import React from 'react'
import { Icon } from '@iconify/react'
import { Box, Card, CardContent, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import UserDropdown from './AppBarComponents/AppBarUserDropdown/UserDropdown'
import NotificationDropdown from './AppBarComponents/NotificationMenu/NotificationDropdown'
import ThemeToggle from './AppBarComponents/ThemeToggler/ThemeToggle'
import Search from './AppBarComponents/Search/Search'

const AppBar = ({ navCollapse }) => {
  const [isLeftGridCollapsed, setIsLeftGridCollapsed] = useState(true)

  const handleToggleSideBar = () => {
    setIsLeftGridCollapsed(!isLeftGridCollapsed)
  }

  useEffect(() => {
    navCollapse(isLeftGridCollapsed)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLeftGridCollapsed])

  return (
    <>
      <Card sx={{ boxShadow: 2 }}>
        <CardContent
          sx={{ display: 'flex', justifyContent: 'space-between', py: '10px !important', px: '15px !important' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* <Icon icon='ep:menu' className='expand-collapse-icon' onClick={handleToggleSideBar} /> */}
            <Typography variant='h5'>Finance Manager</Typography>
          </Box>
          <Box className='appbar-icon-box'>
            {/* <Search />
            <ThemeToggle /> */}
            {/* <NotificationDropdown /> */}
            <UserDropdown />
          </Box>
        </CardContent>
      </Card>
    </>
  )
}

export default AppBar
