import { Icon } from '@iconify/react'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  styled
} from '@mui/material'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import apiDefinitions from '../../../api/apiDefinitions'
import { useCurrentRoute, useUserID } from '../../../hooks/customHooks'
import Swal from 'sweetalert2'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const CustomCloseButton = styled(IconButton)(({ theme }) => ({
  top: 0,
  right: 0,
  color: 'grey.500',
  position: 'absolute',
  boxShadow: theme.shadows[2],
  transform: 'translate(10px, -10px)',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: `${theme.palette.background.paper} !important`,
  transition: 'transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out',
  '&:hover': {
    transform: 'translate(7px, -5px)'
  }
}))

const EditSingleItemDetail = ({ open, onClose, itemId }) => {
  const handleClose = () => {
    onClose()
  }

  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''
  const rowID = itemId ?? ''

  const oldFields = [
    {
      id: 'vendor',
      label: 'Vendor',
      type: 'select',
      required: true,
      size: 12,
      isEditable: false,
      listValues: [],
      value: ''
    },
    {
      id: 'quantity',
      label: 'Quantity',
      type: 'number',
      required: true,
      size: 4,
      isEditable: true,
      value: ''
    },
    {
      id: 'unit_price',
      label: 'Unit Price',
      type: 'number',
      required: true,
      size: 4,
      isEditable: true,
      value: ''
    },
    {
      id: 'total',
      label: 'Total Price',
      type: 'number',
      required: true,
      size: 4,
      isEditable: false,
      value: ''
    },
    {
      id: 'tax',
      label: 'Tax',
      type: 'number',
      required: true,
      size: 4,
      isEditable: true,
      value: ''
    },
    {
      id: 'discount',
      label: 'Discount',
      type: 'number',
      required: true,
      size: 4,
      isEditable: true,
      value: ''
    },
    {
      id: 'final_price',
      label: 'Final Price',
      type: 'number',
      required: true,
      size: 4,
      isEditable: false,
      value: ''
    },
    {
      id: 'special_note',
      label: 'Special Note',
      type: 'multiline',
      required: false,
      size: 12,
      isEditable: true,
      value: ''
    }
  ]

  const [updatedFields, setUpdatedFields] = useState([])

  const [vendors, setVendors] = useState([])
  const [calc, setCalc] = useState(false)

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '' && rowID !== '') {
      apiDefinitions
        .getItemDetailsByItemDetailsID(userID, currentRouteString, rowID)
        .then(res => {
          if (res.data.code === 200) {
            console.log('Item details:', res.data.data)
            const newFields = res.data.data?.optional_field?.map((field, index) => {
              return {
                id: field.field.data_grid_field,
                label: field.field.field_label,
                type: field.field.type,
                order: field.field.order,
                required: field.field.required,
                size: field.field.size ? field.field.size : 6,
                isEditable: field.field.is_editable,
                listValues: field.field.list_values,
                value: field.value,
                isOptional: true
              }
            })

            const sortedFields = newFields.sort((a, b) => {
              return a.order - b.order
            })

            //set value of fields
            let changedFields = oldFields?.map(field => {
              let updatedField = field
              if (res.data.data.item_detail[field.id]) updatedField.value = res.data.data.item_detail[field.id]
              return updatedField
            })

            const allFields = [...changedFields, ...sortedFields]
            setUpdatedFields(allFields)
          } else {
            console.log('Error fetching item details:', res.data.message)
            toast.error('Error: ' + res.data.message)
          }
        })
        .catch(error => {
          console.error('Error fetching item details:', error)
          toast.error('Error fetching item details!')
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID, currentRouteString, rowID])

  useEffect(() => {
    if (userID !== '' && currentRouteString !== '') {
      apiDefinitions
        .getAllDataList(userID, currentRouteString, 'vendor')
        .then(response => {
          if (response.data.code === 200) {
            setVendors(response.data.data)
          } else {
            console.log('Error fetching vendors:', response.data.message)
            toast.error('Error: ' + response.data.message)
          }
        })
        .catch(error => {
          console.error('Error fetching vendors:', error)
          toast.error('Error fetching vendors!')
        })
    }
  }, [userID, currentRouteString])

  useEffect(() => {
    let newFields = updatedFields?.map(field => {
      if (field.id === 'total') {
        let quantity = parseInt(updatedFields.find(f => f.id === 'quantity').value) || 0
        let unitPrice = parseFloat(updatedFields.find(f => f.id === 'unit_price').value) || 0
        let tax = parseFloat(updatedFields.find(f => f.id === 'tax').value) || 0
        let discount = parseFloat(updatedFields.find(f => f.id === 'discount').value) || 0
        let total = parseFloat(quantity * unitPrice)
        let finalPrice = total + tax - discount
        field.value = total
        updatedFields.find(f => f.id === 'final_price').value = finalPrice
      }
      return field
    })

    // console.log('Updated fields:', newFields)
    setUpdatedFields(newFields)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calc])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      scroll='paper'
      fullWidth
      sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
    >
      <DialogTitle>
        View Item Detail - #{itemId}
        <CustomCloseButton aria-label='close' onClick={handleClose}>
          <Icon icon='tabler:x' fontSize='1.25rem' />
        </CustomCloseButton>
      </DialogTitle>
      <DialogContent sx={{ maxHeight: 500 }} className='content-display-grid-scroll'>
        <FormControl fullWidth margin='normal'>
          <Grid container spacing={3}>
            {updatedFields?.map((field, index) => {
              if (field.type === 'select') {
                return (
                  <Grid item xs={field.size ? field.size : 6} key={index}>
                    <FormControl fullWidth variant='outlined' error={field.helperText ? true : false}>
                      <InputLabel id={field.id}>
                        {field.label}
                        {field.required ? <span> *</span> : null}
                      </InputLabel>
                      <Select
                        labelId={field.id}
                        id={field.id}
                        value={field.id === 'vendor' ? field.value.id : field.value}
                        label={field.label}
                        disabled
                        required={field.required}
                      >
                        <MenuItem value=''>
                          <em>None</em>
                        </MenuItem>
                        {field.id === 'vendor'
                          ? vendors?.map((vendor, index) => (
                              <MenuItem key={index} value={vendor.id}>
                                {vendor.vendor_name}
                              </MenuItem>
                            ))
                          : field?.listValues?.map((value, index) => (
                              <MenuItem key={index} value={value.key}>
                                {value.value}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )
              } else if (field.type === 'number') {
                return (
                  <Grid item xs={field.size ? field.size : 6} key={index}>
                    <TextField
                      fullWidth
                      id={field.id}
                      label={field.label}
                      type='number'
                      onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                      variant='outlined'
                      value={field.value}
                      disabled
                      required={field.required}
                      error={field.helperText ? true : false}
                    />
                  </Grid>
                )
              } else if (field.type === 'multiline') {
                return (
                  <Grid item xs={field.size ? field.size : 6} key={index}>
                    <TextField
                      fullWidth
                      id={field.id}
                      label={field.label}
                      type='text'
                      variant='outlined'
                      multiline
                      disabled
                      required={field.required}
                      error={field.helperText ? true : false}
                      minRows={3}
                      value={field.value}
                    />
                  </Grid>
                )
              } else if (field.type === 'date') {
                return (
                  <Grid item xs={field.size ? field.size : 6} key={index}>
                    <TextField
                      fullWidth
                      id={field.id}
                      label={field.label}
                      disabled
                      required={field.required}
                      error={field.helperText ? true : false}
                      type='date'
                      variant='outlined'
                      value={field.value}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                )
              } else if (field.type === 'time') {
                return (
                  <Grid item xs={field.size ? field.size : 6} key={index}>
                    <TextField
                      fullWidth
                      id={field.id}
                      label={field.label}
                      disabled
                      required={field.required}
                      error={field.helperText ? true : false}
                      type='time'
                      variant='outlined'
                      value={field.value}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                )
              } else if (field.type === 'datetime') {
                return (
                  <Grid item xs={field.size ? field.size : 6} key={index}>
                    <TextField
                      fullWidth
                      id={field.id}
                      label={field.label}
                      disabled
                      required={field.required}
                      error={field.helperText ? true : false}
                      type='datetime-local'
                      variant='outlined'
                      value={field.value}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                )
              } else if (field.type === 'text') {
                return (
                  <Grid item xs={field.size ? field.size : 6} key={index}>
                    <TextField
                      fullWidth
                      id={field.id}
                      label={field.label}
                      disabled
                      required={field.required}
                      error={field.helperText ? true : false}
                      type='text'
                      variant='outlined'
                      value={field.value}
                    />
                  </Grid>
                )
              } else if (field.type === 'multiple_select') {
                return (
                  <Grid item xs={field.size ? field.size : 6} key={index}>
                    <FormControl fullWidth variant='outlined' error={field.helperText ? true : false}>
                      <InputLabel id={field.id}>
                        {field.label}
                        {field.required ? <span> *</span> : null}
                      </InputLabel>
                      <Select
                        labelId={field.id}
                        id={field.id}
                        multiple
                        value={typeof field.value === 'array' ? field.value : []}
                        label={field.label}
                        disabled
                        input={<OutlinedInput label={field.label} required={field.required} />}
                        renderValue={selected => (
                          console.log('Selected:', selected),
                          (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, my: 0.5 }}>
                              {typeof selected === 'array' &&
                                selected?.length > 0 &&
                                selected?.map(value =>
                                  field?.listValues?.map((item, index) => {
                                    if (Number(item.key) === value) {
                                      return <Chip key={index} label={item.value} />
                                    }
                                    return null
                                  })
                                )}
                            </Box>
                          )
                        )}
                        MenuProps={MenuProps}
                        InputLabelProps={{
                          shrink: true
                        }}
                      >
                        {field?.listValues?.map((value, index) => (
                          <MenuItem key={index} value={Number(value.key)}>
                            {value.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )
              } else {
                return (
                  <Grid item xs={6} key={index}>
                    <Typography variant='body1' color='error'>
                      Error: Unknown field type
                    </Typography>
                  </Grid>
                )
              }
            })}
          </Grid>
        </FormControl>
      </DialogContent>
    </Dialog>
  )
}

export default EditSingleItemDetail
