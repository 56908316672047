import { TextField } from '@mui/material'
import React from 'react'

const Comments = () => {
  return (
    <TextField
      fullWidth
      multiline
      rows={5}
      label='Comments'
      variant='outlined'
    />
  )
}

export default Comments
