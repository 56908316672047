import AddIcon from '@mui/icons-material/Add'
import CancelIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { Alert, AlertTitle, MenuItem, Select, TextField, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer
} from '@mui/x-data-grid'
import { randomId } from '@mui/x-data-grid-generator'
import * as React from 'react'
import { useEffect, useState } from 'react'
import apiDefinitions from '../../../../../api/apiDefinitions'
import { useCurrentQueryParams, useCurrentRoute, useUserID } from '../../../../../hooks/customHooks'

function EditToolbar(props) {
  const { setRows, setRowModesModel, setRowsWithoutItem } = props

  const handleClick = () => {
    const id = randomId()
    setRows(oldRows => [
      ...oldRows,
      {
        id,
        item: '',
        req_qty: 0,
        available_qty: 0,
        unit_price: 0,
        tax: 0,
        discount: 0,
        total_price: 0,
        notes: '',
        isNew: true
      }
    ])
    setRowModesModel(oldModel => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'item' }
    }))
    setRowsWithoutItem(oldRowsWithoutItem => [...oldRowsWithoutItem, id])
  }

  return (
    <GridToolbarContainer>
      <Button color='primary' startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  )
}

const QuotationItemTable = ({ rows, setRows, rowEditModel }) => {
  const [rowModesModel, setRowModesModel] = useState({})

  const userID = useUserID() ?? ''
  const currentRouteString = JSON.stringify(useCurrentRoute()) ?? ''
  const poReqID = useCurrentQueryParams('id') ?? ''

  const [itemList, setItemList] = useState([''])

  const [rowsWithoutItem, setRowsWithoutItem] = useState([])
  const [selectedItems, setSelectedItems] = useState([])

  useEffect(() => {
    if (poReqID !== '' && userID !== '' && currentRouteString !== '') {
      apiDefinitions.getItemSummeryByPORID(userID, currentRouteString, poReqID, 0, 0, false).then(response => {
        const data = response.data.data

        const items = data
          .filter(itemDets => itemDets.status.id === 36)
          .map(itemDets => ({
            por_detail_id: itemDets.id,
            id: itemDets.item.id,
            item: itemDets.item.short_name,
            req_qty: itemDets.item_qty
          }))

        setItemList(items)
      })
    }
  }, [poReqID, userID, currentRouteString])

  useEffect(() => {
    rowEditModel(rowModesModel)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowModesModel])

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const handleEditClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = id => () => {
    console.log('rowsWithoutItem', rowsWithoutItem)
    console.log('id', id)
    //check if row.item is empty
    if (rowsWithoutItem.includes(id)) {
      alert('Please select an item')
    } else setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = id => () => {
    setRows(rows.filter(row => row.id !== id))

    setRowsWithoutItem(rowsWithoutItem.filter(rowId => rowId !== id))

    setSelectedItems(selectedItems.filter(selectedItem => selectedItem.rowID !== id))
  }

  const handleCancelClick = id => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    const editedRow = rows.find(row => row.id === id)

    if (editedRow.isNew) {
      setRows(rows.filter(row => row.id !== id))
    }

    setRowsWithoutItem(rowsWithoutItem.filter(rowId => rowId !== id))

    setSelectedItems(selectedItems.filter(selectedItem => selectedItem.rowID !== id))
  }

  const processRowUpdate = newRow => {
    const updatedRow = { ...newRow, isNew: false }
    setRows(rows.map(row => (row.id === newRow.id ? updatedRow : row)))
    return updatedRow
  }

  const handleRowModesModelChange = newRowModesModel => {
    setRowModesModel(newRowModesModel)
  }

  const updateRequestedQty = (id, reqQty) => {
    const updatedRow = { ...rows.find(row => row.id === id), req_qty: reqQty }
    setRows(rows.map(row => (row.id === id ? updatedRow : row)))
  }

  const updateTotalForRow = (row, currentValue) => {
    console.log('rowwwwwwwwwwwww', row)

    const { field, amount } = currentValue
    let updatedRow = { ...row }

    // Update the field with the new amount
    updatedRow[field] = Number(amount)

    // Calculate the total price
    const total_price = (
      Number(updatedRow.available_qty) * Number(updatedRow.unit_price) +
      Number(updatedRow.tax) -
      Number(updatedRow.discount)
    ).toFixed(2)

    // Update the total price in the row
    updatedRow.total_price = total_price

    setRows(rows.map(r => (r.id === row.id ? updatedRow : r)))
  }

  const columns = [
    {
      field: 'item',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            fontWeight: 'bold',
            fontSize: '0.75rem',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-line',
            overflow: 'hidden'
          }}
        >
          Item
        </Typography>
      ),
      width: 220,
      editable: true,
      valueGetter: params => {
        return params.value.item
      },
      renderEditCell: params => {
        return (
          <Select
            fullWidth
            value={params.value.id}
            onChange={event => {
              const itemId = event.target.value

              const item = itemList.find(item => item.id === itemId)
              console.log('item', item)
              params.api.setEditCellValue({
                id: params.id,
                field: 'item',
                value: item
              })

              setRowsWithoutItem(rowsWithoutItem.filter(rowId => rowId !== params.id))
              setSelectedItems([
                ...selectedItems,
                {
                  rowID: params.id,
                  itemID: item.id
                }
              ])

              updateRequestedQty(params.id, item.req_qty)
            }}
            sx={{
              '& fieldset': { border: 'none' }
            }}
          >
            {/* {itemList
              .filter(
                item =>
                  ((!selectedItems.some(selectedItem => selectedItem.itemID === item.id) ||
                    item.id === params.value.id) &&
                    !rows.some(row => row.item.id === item.id)) ||
                  item.id === params.value.id
              )
              .map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.item}
                </MenuItem>
              ))} */}
            {itemList
              .filter(
                item =>
                  item.id === params.value.id ||
                  (!selectedItems.some(selectedItem => selectedItem.itemID === item.id) &&
                    !rows.some(row => row.item.id === item.id))
              )
              .map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.item}
                </MenuItem>
              ))}
          </Select>
        )
      },
      renderCell: params => {
        return params.value.item
      }
    },
    {
      field: 'req_qty',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            fontWeight: 'bold',
            fontSize: '0.75rem',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-line',
            overflow: 'hidden'
          }}
        >
          Requested Quantity
        </Typography>
      ),
      type: 'number',
      width: 120,
      align: 'left',
      headerAlign: 'left',
      editable: false
    },
    {
      field: 'available_qty',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            fontWeight: 'bold',
            fontSize: '0.75rem',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-line',
            overflow: 'hidden'
          }}
        >
          Available Quantity
        </Typography>
      ),
      type: 'number',
      width: 120,
      align: 'left',
      headerAlign: 'left',
      editable: true,
      renderEditCell: params => {
        return (
          <TextField
            fullWidth
            type='number'
            onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
            onFocus={event => event.target.select()}
            inputProps={{ min: 0 }}
            value={params.value}
            onChange={event => {
              params.api.setEditCellValue({
                id: params.id,
                field: 'available_qty',
                value: event.target.value
              })

              updateTotalForRow(params.row, {
                field: 'available_qty',
                amount: event.target.value
              })
            }}
            sx={{
              '& fieldset': { border: 'none' }
            }}
          />
        )
      }
    },
    {
      field: 'unit_price',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            fontWeight: 'bold',
            fontSize: '0.75rem',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-line',
            overflow: 'hidden'
          }}
        >
          Unit Price
        </Typography>
      ),
      type: 'number',
      width: 180,
      align: 'left',
      headerAlign: 'left',
      editable: true,
      valueGetter: params => {
        return Number(params.row.unit_price || 0).toFixed(2)
      },
      renderEditCell: params => {
        return (
          <TextField
            fullWidth
            type='number'
            onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
            onFocus={event => event.target.select()}
            inputProps={{ min: 0 }}
            value={params.value}
            onChange={event => {
              params.api.setEditCellValue({
                id: params.id,
                field: 'unit_price',
                value: event.target.value
              })

              updateTotalForRow(params.row, {
                field: 'unit_price',
                amount: event.target.value
              })
            }}
            sx={{
              '& fieldset': { border: 'none' }
            }}
          />
        )
      }
    },
    {
      field: 'tax',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            fontWeight: 'bold',
            fontSize: '0.75rem',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-line',
            overflow: 'hidden'
          }}
        >
          Tax
        </Typography>
      ),
      type: 'number',
      width: 180,
      align: 'left',
      headerAlign: 'left',
      editable: true,
      valueGetter: params => {
        return Number(params.row.tax || 0).toFixed(2)
      },
      renderEditCell: params => {
        return (
          <TextField
            fullWidth
            type='number'
            onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
            onFocus={event => event.target.select()}
            inputProps={{ min: 0 }}
            value={params.value}
            onChange={event => {
              params.api.setEditCellValue({
                id: params.id,
                field: 'tax',
                value: event.target.value
              })

              updateTotalForRow(params.row, {
                field: 'tax',
                amount: event.target.value
              })
            }}
            sx={{
              '& fieldset': { border: 'none' }
            }}
          />
        )
      }
    },
    {
      field: 'discount',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            fontWeight: 'bold',
            fontSize: '0.75rem',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-line',
            overflow: 'hidden'
          }}
        >
          Discount
        </Typography>
      ),
      type: 'number',
      width: 180,
      align: 'left',
      headerAlign: 'left',
      editable: true,
      valueGetter: params => {
        return Number(params.row.discount || 0).toFixed(2)
      },
      renderEditCell: params => {
        return (
          <TextField
            fullWidth
            type='number'
            onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
            onFocus={event => event.target.select()}
            inputProps={{ min: 0 }}
            value={params.value}
            onChange={event => {
              params.api.setEditCellValue({
                id: params.id,
                field: 'discount',
                value: event.target.value
              })

              updateTotalForRow(params.row, {
                field: 'discount',
                amount: event.target.value
              })
            }}
            sx={{
              '& fieldset': { border: 'none' }
            }}
          />
        )
      }
    },
    {
      field: 'total_price',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            fontWeight: 'bold',
            fontSize: '0.75rem',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-line',
            overflow: 'hidden'
          }}
        >
          Total Price
        </Typography>
      ),
      type: 'number',
      width: 180,
      align: 'left',
      headerAlign: 'left',
      valueGetter: params => {
        return Number(params.row.total_price || 0).toFixed(2)
      }
    },
    {
      field: 'notes',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            fontWeight: 'bold',
            fontSize: '0.75rem',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-line',
            overflow: 'hidden'
          }}
        >
          Notes
        </Typography>
      ),
      width: 280,
      editable: true
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: (
        <Typography
          variant='body2'
          sx={{
            fontWeight: 'bold',
            fontSize: '0.75rem',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-line',
            overflow: 'hidden'
          }}
        >
          Actions
        </Typography>
      ),
      width: 120,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label='Save'
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(id)}
              color='inherit'
            />
          ]
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label='Edit'
            className='textPrimary'
            onClick={handleEditClick(id)}
            color='inherit'
          />,
          <GridActionsCellItem icon={<DeleteIcon />} label='Delete' onClick={handleDeleteClick(id)} color='inherit' />
        ]
      }
    }
  ]

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      {itemList.length === 0 ? (
        <Alert severity='error'>
          <AlertTitle>Error: No Items Found!</AlertTitle>
          No Item Requests have been approved for this Purchase Order Request.
          <br />
          Please approve any pending item requests before adding a quotation.
        </Alert>
      ) : (
        <DataGrid
          rows={rows}
          columns={columns}
          editMode='row'
          disableColumnFilter
          disableRowSelectionOnClick
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: EditToolbar
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel, rowsWithoutItem, setRowsWithoutItem }
          }}
        />
      )}
    </Box>
  )
}

export default QuotationItemTable
